import React from "react";
import SelectListStaticWithLabel from "../Form/SelectListStaticWithLabel";
import ProductSearchService from "../../Services/ProductSearchService";
import toastr from "toastr";
import { isMobile, isTablet } from "../Media/Media";
import spinnerIcon from "../../assets/img/spinner.svg";
import SearchButton from "../Shop/SearchButton";
import SearchByDiagramIcon from "../../assets/img/shop-page/search-diagram";
import { Button, Stack } from '@mui/material';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';

const $ = window.jQuery;

class FilterCommonFieldComp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterValuesManufacturer: -1,
            filterValuesAppliance: -1,
            filterPartType: -1,
            filterValuesModel: -1,
            filterValuesModelContent: -1,
            filterValuesPartsListSection: -1,
            searchResultCount: 3,
            manufacturers: [],
            appliances: [],
            partTypes: [],
            productRanges: [],
            models: [],
            modelContents: [],
            partsListSections: [],
            partsListRows: [],
            gcSearchObj: {},
            modelContentSelect: false,
            partsListSelect: false,
            manufacturerNotSpecified: false,
            applianceNotSpecified: false,
            partNotSpecified: false,
        };
    }

    componentDidMount() {
        this.fetchManufacturers();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.filterValuesModel !== this.state.filterValuesModel &&
            this.state.filterValuesModelContent === -1 &&
            this.state.filterValuesModel !== -1
        ) {
            this.setState({
                modelContentSelect: true,
                partsListSelect: true,
            });
        }
        if (
            prevState.filterValuesModelContent !==
            this.state.filterValuesModelContent &&
            this.state.filterValuesPartsListSection === -1 &&
            this.state.filterValuesModelContent !== -1
        ) {
            this.setState({
                partsListSelect: true,
            });
        }
        if (
            prevState.filterValuesModelContent !== this.state.filterValuesModelContent
        ) {
            this.setState({
                modelContentSelect: false,
            });
        }
        if (
            prevState.filterValuesPartsListSection !==
            this.state.filterValuesPartsListSection
        ) {
            this.setState({
                partsListSelect: false,
            });
        }
    }

    fetchManufacturers = () => {
        ProductSearchService.manufacturer().then((data) => {
            const sortedData = (data || []).sort((a, b) => {
                if (a.man < b.man) {
                    return -1;
                }
                if (a.man > b.man) {
                    return 1;
                }
                return 0;
            });

            this.setState({ manufacturers: sortedData });
        });
    };

    getManufacturer = ({ value }) => {
        this.setState(
            {
                manufacturerNotSpecified: false,
                filterValuesManufacturer: value,
                filterValuesAppliance: -1,
                filterValuesProductRange: -1,
                filterValuesModel: -1,
                filterValuesModelContent: -1,
                filterValuesPartsListSection: -1,
                filterPartType: -1,
                partsListRows: [],
            },
            () => {
                this.loadAppliances();
                this.triggerPartsChanged();
            }
        );
    };

    getAppliance = ({ value }) => {
        this.setState(
            {
                applianceNotSpecified: false,
                filterValuesAppliance: value,
                filterValuesProductRange: -1,
                filterValuesModel: -1,
                filterValuesModelContent: -1,
                filterValuesPartsListSection: -1,
                filterPartType: -1,
                partsListRows: [],
            },
            () => {
                this.loadPartTypes();
                this.triggerPartsChanged();
            }
        );
    };

    setPartType = ({ value }) => {
        this.setState(
            {
                partNotSpecified: false,
                filterPartType: value,
                filterValuesModel: -1,
                filterValuesModelContent: -1,
                filterValuesPartsListSection: -1,
                partsListRows: [],
            },
            () => {
                this.triggerPartsChanged();
            }
        );
    };

    loadAppliances = () => {
        ProductSearchService.appliances(this.state.filterValuesManufacturer).then((response) => {
            const sortedData = (response.data || []).sort((a, b) => {
                if (a.app < b.app) {
                    return -1;
                }
                if (a.app > b.app) {
                    return 1;
                }
                return 0;
            });
            this.setState({ appliances: sortedData });
        });
    };

    loadPartTypes = () => {
        ProductSearchService.partTypes(this.state.filterValuesAppliance).then((response) => {
            const sortedData = (response || []).sort((a, b) => {
                if (a.part_type < b.part_type) {
                    return -1;
                }
                if (a.part_type > b.part_type) {
                    return 1;
                }
                return 0;
            });
            this.setState({ partTypes: sortedData });
        });
    };

    onSearch = () => {
        if (!~this.state.filterValuesManufacturer) return this.setState({ manufacturerNotSpecified: true });
        if (!~this.state.filterValuesAppliance) return this.setState({ applianceNotSpecified: true });
        if (!~this.state.filterPartType) return this.setState({ partNotSpecified: true });
        this.setState({ manufacturerNotSpecified: false, applianceNotSpecified: false, partNotSpecified: false });
        this.props.onSearch &&
            this.props.onSearch(
                this.state.filterValuesManufacturer,
                this.state.filterValuesAppliance,
                this.state.filterPartType,
                this.state.manufacturers.find(manufacturer => manufacturer.manid === this.state.filterValuesManufacturer).man,
                this.state.appliances.find(appliance => appliance.appid === this.state.filterValuesAppliance).app,
                this.state.partTypes.find(partType => partType.part_type_id === this.state.filterPartType).part_type
            );
    };

    onGetDiagram = () => {
        if (!~this.state.filterValuesManufacturer) return this.setState({ manufacturerNotSpecified: true });
        if (!~this.state.filterValuesAppliance) return this.setState({ applianceNotSpecified: true });
        this.setState({ manufacturerNotSpecified: false, applianceNotSpecified: false, partNotSpecified: true });
        this.props.onSearch &&
            this.props.onSearch(
                this.state.filterValuesManufacturer,
                this.state.filterValuesAppliance,
                this.state.manufacturers.find(manufacturer => manufacturer.manid === this.state.filterValuesManufacturer).man,
                this.state.appliances.find(appliance => appliance.appid === this.state.filterValuesAppliance).app,
            );
        this.props.setViewDiagrams(true);
    };

    triggerPartsChanged = () => {
        this.props.partsListChanged(this.state.partsListRows);
    };

    changeSearchTerm = (val) => {
        this.setState({ searchTerm: val });
    };

    search = () => {
        const { searchTerm, prevSearchTerm } = this.state;
        //search logic here

        $.ajax({
            url: "/SparesFinder/GetGcSearch",
            data: {
                gcNumber: searchTerm,
            },
            success: (data) => {
                if (data.length > 0) {
                    if (prevSearchTerm !== searchTerm) {
                        this.setState({
                            filterValuesManufacturer: -1,
                            filterValuesAppliance: -1,
                            filterValuesProductRange: -1,
                            filterValuesModel: -1,
                            filterValuesModelContent: -1,
                            filterValuesPartsListSection: -1,
                            searchResultCount: 3,
                            productTypes: [],
                            productRanges: [],
                            models: [],
                            modelContents: [],
                            partsListSections: [],
                            partsListRows: [],
                            gcSearchObj: {},
                        });
                    }
                    this.setState(
                        {
                            filterValuesModelContent: -1,
                            filterValuesPartsListSection: -1,
                            filterValuesManufacturer: data[0].manufacturerId,
                            filterValuesProductType: data[0].productTypeId,
                            filterValuesProductRange: data[0].productRangeId,
                            filterValuesModel: data[0].modelId,
                            prevSearchTerm: searchTerm,
                        },
                        () => {
                            this.loadProductTypes();
                            this.loadProductRanges();
                            this.loadModel();
                            this.loadModelContent();

                            if (this.state.modelContents.length == 1) {
                                this.setState(
                                    {
                                        filterValuesModelContent:
                                            this.state.modelContents[0].contentId,
                                    },
                                    () => {
                                        this.loadPartsListSection();
                                    }
                                );
                            }
                            this.triggerPartsChanged();
                        }
                    );
                }
            },
        });
    };

    showViewManualError = () => {
        toastr.error("Please enter Manufacturer and Appliance.")
    }

    onViewManual = () => {
        const { filterValuesAppliance } = this.state;
        if (!~this.state.filterValuesManufacturer) {
            return this.setState({ manufacturerNotSpecified: true }/* , this.showViewManualError */);
        }
        if (!~this.state.filterValuesAppliance) {
            return this.setState({ applianceNotSpecified: true }/* , this.showViewManualError */);
        }

        ProductSearchService.applianceDocumentInTab(filterValuesAppliance);
    }

    downloadFile = (url) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.click();
    }

    applianceDocumentExists = () => {
        if (!!~this.state.filterValuesAppliance) {
            const appliance = this.state.appliances.find(({ appid }) => this.state.filterValuesAppliance === appid);
            return appliance.extras !== "" && appliance.extras !== null;
        } else {
            return false;
        }
    }

    render() {
        const { isLoading } = this.props;
        return (
            <div className='search-fields-wrapper'>
                <>
                    <div className='search-fields'>
                        <SelectListStaticWithLabel
                            placeholder="Manufacturer"
                            valueProperty="manid"
                            textProperty="man"
                            collection={this.state.manufacturers}
                            onChange={this.getManufacturer}
                            value={this.state.filterValuesManufacturer}
                            outlined={!~this.state.filterValuesManufacturer && this.state.manufacturerNotSpecified}
                        />
                        <SelectListStaticWithLabel
                            placeholder="Appliance"
                            valueProperty="appid"
                            textProperty="app"
                            collection={this.state.appliances}
                            onChange={this.getAppliance}
                            value={this.state.filterValuesAppliance}
                            outlined={!~this.state.filterValuesAppliance && this.state.applianceNotSpecified}
                        />
                        <SelectListStaticWithLabel
                            placeholder="Parts Types"
                            valueProperty="part_type_id"
                            textProperty="part_type"
                            collection={this.state.partTypes}
                            onChange={this.setPartType}
                            value={this.state.filterPartType}
                            outlined={!~this.state.filterPartType && this.state.partNotSpecified}
                        />
                        <SearchButton loading={isLoading} onSearch={this.onSearch} />
                    </div>
                    <div
                        className='selected-appliance-text'
                        style={{ marginTop: '10px', display: isMobile() ? 'block' : 'flex', opacity: !!~this.state.filterValuesAppliance ? 1 : 0 }}
                    >
                        <p>
                            You have
                            selected: {this.state.appliances.find(({ appid }) => this.state.filterValuesAppliance === appid)?.app}
                        </p>
                    </div>

                    <div>
                            <Stack direction="row">
                                {this.applianceDocumentExists() && (
                                    <a
                                        onClick={this.onViewManual}
                                        className="link-like" >
                                        <ArticleOutlinedIcon sx={{ color: '#fff' }} />
                                        <span>View Manual</span>
                                    </a>
                                )}
                                {this.props.showSearchByDiagram == true && !!~this.state.filterValuesAppliance &&
                                    <a
                                        onClick={this.onGetDiagram}
                                        className="link-like" >
                                        <QueryStatsOutlinedIcon sx={{ color: '#fff' }} />
                                        <span>View Diagrams</span>
                                    </a>
                                }
                            </Stack>
                    </div>
                </>
            </div>
        );
    }
}

export default FilterCommonFieldComp;
