import React from "react";

class TextAreaEntry extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div
        className={
          "form-group " +
          (this.props.parentClassName ? this.props.parentClassName : "")
        }
      >
        {!!this.props.title && <label>{this.props.title}</label>}
        <textarea
          className={
            "form-control " + (this.props.className ? this.props.className : "")
          }
          value={this.props.value}
          onChange={this.change.bind(this)}
          placeholder={this.props.placeholder ? this.props.placeholder : ""}
          required
        ></textarea>
      </div>
    );
  }

  change(d) {
    this.props.onChange(d.target.value);
  }
}

export default TextAreaEntry;
