import React, { useEffect, useState } from "react";
import ByBoxService from "../../Services/ByBoxService";
import BasketService from "../../Services/BasketService";
import SelectListStaticWithLabel from "../Form/SelectListStaticWithLabel";
import toastr from "toastr";
import ModalComp from '../Modal/ModalComp';
import TextBoxWithLabel from "../Form/TextBoxWithLabel";
import { isMobile } from "../Media/Media";

function ByBoxModal({ onCloseModal, onByBoxAccepted }) {
    const [byBoxReference, setByBoxReference] = useState(null)
    const [byBoxReferenceError, setByBoxReferenceError] = useState(false);
    const [byBoxLocations, setByBoxLocations] = useState([])
    const [byBoxLocationId, setByBoxLocationId] = useState(null)

    const handleByBoxReferenceChange = (e) => {
        setByBoxReference(e);
        setByBoxReferenceError(false);
    }

    useEffect(() => {
        ByBoxService.getByBoxLocations().then(response => setByBoxLocations(response.map(r => { return { ...r, exchangePostalCode: r.exchange + " " + r.postalCode } })))
    }, [])

    const addByBox = () => {
        BasketService.addByBoxToBasket({
            byBoxLocationId: byBoxLocationId,
            byBoxReference: byBoxReference,
            imageUrl: '/img/ByBox.png'
        }).then((response) => {
            if (response) {
                onByBoxAccepted()
            }

        }).catch(() => {
            toastr.error('Unable to process your request, please try again later.')
        });
    }

    return (
        <ModalComp style={{ width: '100%' }} onClose={onCloseModal}>
            <div className="delivery-modal-container">
                <div className="delivery-modal-header">
                    <span>BY-BOX DELIVERY</span>
                </div>

                <div style={{ marginTop: 50 }}>
                    <div className="row">
                        <div className="col-md-6">
                            <TextBoxWithLabel
                                labelClassName={isMobile() ? 'customer-label account-label' : 'customer-label'}
                                addCssClass={`group__row ${byBoxReferenceError ? "group__row__error " : ""}`}
                                label="BYBOX REFERENCE:"
                                placeholder="Enter by box reference..."
                                value={byBoxReference}
                                onChange={handleByBoxReferenceChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <SelectListStaticWithLabel
                                placeholder="By box locations"
                                label="BYBOX LOCATIONS:"
                                valueProperty="id"
                                textProperty="exchangePostalCode"
                                collection={byBoxLocations}
                                onChange={({ value }) => setByBoxLocationId(value)}
                                value={byBoxLocationId}
                                className="search-field-list"
                                withoutWrapper
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <button
                                type="button"
                                className="btn group__btn button-large button-light-blue"
                                disabled={!byBoxReference || !byBoxLocationId}
                                onClick={addByBox}
                            >
                                Continue to checkout
                            </button>
                        </div>
                    </div>
                </div>
            </div>
                  
        </ModalComp>);
}

export default ByBoxModal;