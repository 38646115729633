import React from "react";
import PropTypes from "prop-types";

export class InputBase extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: "",
      error: false,
      required: false,
      visible: true,
      controller: "",
      parent: null,
      child: null,
    };
  }

  componentWillMount() {
    this.setState({
      ...this.props.model,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps.model,
    });
  }

  handleEvent = (value, handler, optionalProp) => {
    if (handler) {
      if (optionalProp) {
        handler(value, optionalProp);
      } else {
        handler(value);
      }
    }
  };

  onCheck = (e) => {
    if (!e) return;
    const { onChange, onFocus } = this.props;
    const { controller } = this.state;
    const { checked } = e.target;
    this.setState({
      value: checked,
    });
    this.handleEvent(
      { ...this.state, value: checked, error: !checked },
      onChange,
      controller
    );
    this.handleEvent(checked, onFocus);
  };

  onChange = (e) => {
    if (!e) return;
    const { onChange, onFocus } = this.props;
    const { controller, required } = this.state;
    const { value, checked } = e.target;
    this.setState({
      value,
    });
    this.handleEvent(
      { ...this.state, value, error: !value.length },
      onChange,
      controller
    );
    if (checked && required) this.handleEvent(checked, onFocus);
  };

  handleValidation = (e) => {
    const { value } = e.target;
    const { required, controller } = this.state;
    if (!required) return;
    const { onBlur } = this.props;
    if (!value.length) {
      this.setState({
        error: true,
      });
    }
    this.handleEvent({ ...this.state, error: true }, onBlur, controller);
  };

  onFocus = (e) => {
    const { required, controller } = this.state;
    if (!e || !required) return;
    const { onFocus } = this.props;
    this.setState({
      error: false,
    });
    this.handleEvent({ ...this.state, error: false }, onFocus, controller);
  };

  render() {
    return null;
  }
}

export default InputBase;
