import React from "react";
import Grid from "@mui/material/Grid";


const PartsIdentificationModeSelector = (
    {
        modes,
        selectedMode,
        onChangeMode
    }
) => {
    return (<Grid container spacing={4} justifyContent='center'>
        {modes.map((mode, index)=>
            <Grid item xs={11} sm={11} md={4} lg={4} key={index}>
                <div
                    className={`navigation-item ${selectedMode?.viewKey === mode.viewKey ? "active" : ""}`}
                    onClick={() => onChangeMode(mode)}
                    style={{ padding: '16px 22px' }}
                >
                    {mode.icon}
                    <h4 style={{ fontSize: '14px', lineHeight: '16px' }}>{mode.text}</h4>
                </div>
            </Grid>
        )}
    </Grid>);
}

export default PartsIdentificationModeSelector;