import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function searchByNumberIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 52 53">
            <path d="M51.7533 51.5003L39.8531 39.018C40.0775 38.791 40.302 38.5596 40.5175 38.3193C44.3151 34.1007 46.4115 28.6627 46.4115 23.0023C46.4115 10.3196 36.0015 0 23.208 0C10.4144 0 0 10.3196 0 23.0023C0 35.6849 10.4099 46.0045 23.2035 46.0045C28.882 46.0045 34.3361 43.9308 38.5558 40.2417L50.4515 52.7196C50.6266 52.9065 50.8645 53 51.1024 53C51.3224 53 51.5469 52.9199 51.7174 52.7552C52.081 52.4215 52.0945 51.8608 51.7533 51.5003ZM1.79559 23.0023C1.79559 11.3031 11.402 1.78002 23.2035 1.78002C35.005 1.78002 44.6159 11.3031 44.6159 23.0023C44.6159 28.2222 42.6856 33.2418 39.1797 37.1356C35.1217 41.6435 29.2995 44.229 23.208 44.229C11.402 44.229 1.79559 34.7059 1.79559 23.0023Z" fill="url(#paint0_linear_8166_26356)" />
            <path d="M12.0139 26.8972H13.0139V20.2272H12.1339L10.3439 22.0572L10.9339 22.6672L12.0139 21.5372V26.8972Z" fill="url(#paint1_linear_8166_26356)" />
            <path d="M14.2776 26.8972H18.9776V26.0173H15.8676C17.6976 24.6073 18.9476 23.4372 18.9476 22.1372C18.9476 20.8072 17.8176 20.1272 16.6176 20.1272C15.6876 20.1272 14.7776 20.4972 14.2276 21.1872L14.8176 21.8372C15.2276 21.3472 15.8376 21.0173 16.6276 21.0173C17.2776 21.0173 17.9276 21.3572 17.9276 22.1372C17.9276 23.1872 16.7776 24.1873 14.2776 26.1073V26.8972Z" fill="url(#paint2_linear_8166_26356)" />
            <path d="M19.9258 25.9272C20.3558 26.5273 21.2158 27.0173 22.3758 27.0173C23.8858 27.0173 24.8158 26.2073 24.8158 25.0872C24.8158 24.0373 23.8958 23.5172 23.2658 23.4572C23.9258 23.3372 24.7158 22.8472 24.7158 21.9172C24.7158 20.7972 23.7358 20.1272 22.3758 20.1272C21.3058 20.1272 20.5158 20.5772 20.0158 21.1672L20.5458 21.7872C21.0358 21.2872 21.5858 21.0173 22.2858 21.0173C23.0758 21.0173 23.7158 21.3772 23.7158 22.0572C23.7158 22.7472 23.0558 23.0472 22.2458 23.0472C21.9858 23.0472 21.6358 23.0472 21.5258 23.0372V23.9472C21.6258 23.9372 21.9758 23.9372 22.2458 23.9372C23.2158 23.9372 23.8158 24.2472 23.8158 24.9872C23.8158 25.6873 23.2658 26.1272 22.3458 26.1272C21.5958 26.1272 20.9058 25.7872 20.4858 25.2772L19.9258 25.9272Z" fill="url(#paint3_linear_8166_26356)" />
            <path d="M28.7901 26.8972H29.7901V25.2972H30.7101V24.4172H29.7901V20.2272H28.4201L25.6201 24.4772V25.2972H28.7901V26.8972ZM28.7901 21.1372V24.4172H26.6101L28.7901 21.1372Z" fill="url(#paint4_linear_8166_26356)" />
            <path d="M31.6235 25.9672C32.1435 26.6172 32.9035 27.0173 34.0435 27.0173C35.4535 27.0173 36.4935 26.1572 36.4935 24.7872C36.4935 23.4172 35.4835 22.6472 34.3635 22.6472C33.7135 22.6472 33.1535 22.9172 32.8335 23.2372V21.1072H36.0535V20.2272H31.8335V23.8872L32.5535 24.1172C32.9935 23.6972 33.4735 23.5173 34.0535 23.5173C34.9135 23.5173 35.4835 24.0273 35.4835 24.8272C35.4835 25.5572 34.9035 26.1272 34.0335 26.1272C33.2535 26.1272 32.6535 25.8272 32.2135 25.2872L31.6235 25.9672Z" fill="url(#paint5_linear_8166_26356)" />
            <defs>
                <linearGradient id="paint0_linear_8166_26356" x1="-2.69251e-08" y1="26.719" x2="85.7439" y2="26.719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00C9FF" />
                    <stop offset="0.421875" stopColor="#25D6E4" />
                    <stop offset="1" stopColor="#92FE93" />
                </linearGradient>
                <linearGradient id="paint1_linear_8166_26356" x1="-2.69251e-08" y1="26.719" x2="85.7439" y2="26.719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00C9FF" />
                    <stop offset="0.421875" stopColor="#25D6E4" />
                    <stop offset="1" stopColor="#92FE93" />
                </linearGradient>
                <linearGradient id="paint2_linear_8166_26356" x1="-2.69251e-08" y1="26.719" x2="85.7439" y2="26.719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00C9FF" />
                    <stop offset="0.421875" stopColor="#25D6E4" />
                    <stop offset="1" stopColor="#92FE93" />
                </linearGradient>
                <linearGradient id="paint3_linear_8166_26356" x1="-2.69251e-08" y1="26.719" x2="85.7439" y2="26.719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00C9FF" />
                    <stop offset="0.421875" stopColor="#25D6E4" />
                    <stop offset="1" stopColor="#92FE93" />
                </linearGradient>
                <linearGradient id="paint4_linear_8166_26356" x1="-2.69251e-08" y1="26.719" x2="85.7439" y2="26.719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00C9FF" />
                    <stop offset="0.421875" stopColor="#25D6E4" />
                    <stop offset="1" stopColor="#92FE93" />
                </linearGradient>
                <linearGradient id="paint5_linear_8166_26356" x1="-2.69251e-08" y1="26.719" x2="85.7439" y2="26.719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00C9FF" />
                    <stop offset="0.421875" stopColor="#25D6E4" />
                    <stop offset="1" stopColor="#92FE93" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}