import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function searchByKeywordIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 52 53">
            <path d="M51.7561 51.5003L39.696 38.7065C39.8158 38.5774 39.9401 38.4528 40.0599 38.3193C43.8151 34.1007 45.888 28.6627 45.888 23.0023C45.888 10.3196 35.5945 0 22.944 0C10.2935 0 0 10.3196 0 23.0023C0 35.6849 10.2935 46.0045 22.944 46.0045C28.7011 46.0045 34.2318 43.824 38.4398 39.9569L50.4689 52.7196C50.642 52.9065 50.8772 53 51.1125 53C51.33 53 51.5519 52.9199 51.7206 52.7552C52.0801 52.4215 52.0935 51.8608 51.7561 51.5003ZM22.9396 44.229C11.2656 44.229 1.77107 34.7059 1.77107 23.0067C1.77107 11.3076 11.2656 1.78002 22.9396 1.78002C34.6136 1.78002 44.1081 11.3031 44.1081 23.0023C44.1081 28.2222 42.1994 33.2418 38.7327 37.1356C34.7201 41.6435 28.963 44.229 22.9396 44.229Z" fill="url(#paint0_linear_8166_26354)" />
            <path d="M26.8767 28.1462H11.7627V30.3446H26.8767V28.1462Z" fill="url(#paint1_linear_8166_26354)" />
            <path d="M34.8532 22.1922H11.7627V24.3905H34.8532V22.1922Z" fill="url(#paint2_linear_8166_26354)" />
            <path d="M11.7676 16.2276V18.4348H34.8536V16.2276H11.7676Z" fill="url(#paint3_linear_8166_26354)" />
            <defs>
                <linearGradient id="paint0_linear_8166_26354" x1="-2.69251e-08" y1="26.719" x2="85.7439" y2="26.719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00C9FF" />
                    <stop offset="0.421875" stopColor="#25D6E4" />
                    <stop offset="1" stopColor="#92FE93" />
                </linearGradient>
                <linearGradient id="paint1_linear_8166_26354" x1="-2.69251e-08" y1="26.719" x2="85.7439" y2="26.719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00C9FF" />
                    <stop offset="0.421875" stopColor="#25D6E4" />
                    <stop offset="1" stopColor="#92FE93" />
                </linearGradient>
                <linearGradient id="paint2_linear_8166_26354" x1="-2.69251e-08" y1="26.719" x2="85.7439" y2="26.719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00C9FF" />
                    <stop offset="0.421875" stopColor="#25D6E4" />
                    <stop offset="1" stopColor="#92FE93" />
                </linearGradient>
                <linearGradient id="paint3_linear_8166_26354" x1="-2.69251e-08" y1="26.719" x2="85.7439" y2="26.719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00C9FF" />
                    <stop offset="0.421875" stopColor="#25D6E4" />
                    <stop offset="1" stopColor="#92FE93" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}