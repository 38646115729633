import React from 'react';
import { Box } from "@mui/material"
import SearchResultsRow from "./DiagramResultsRow/DiagramResultsRow";
import usePartsIdentificationStore from "../../PartsIdentification/Store/PartsIdentificationStore";

function SearchResultsList({ results, onAddToBasket, toggleLike }) {
    var partsIdentificationStore=usePartsIdentificationStore();
    
    return (
        <Box sx={{
            borderTop: '1px solid #BACAD1',
            maxHeight: '740px',
            overflowY: 'scroll',
        }}>
            {results?.map((item) => {
                var isLiked=partsIdentificationStore.likedProducts.filter(x=>x.mpn==item.mpn).length>0;
                
                return (<SearchResultsRow product={item} liked={isLiked} toggleLike={toggleLike} onAddToBasket={onAddToBasket}/>);
            })}
        </Box>
    );
}

export default SearchResultsList;