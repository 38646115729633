import React, { useEffect } from "react";
import Wrapper from "./Components/Layout/Wrapper";
import getBasketCount from "./Helpers/common/getBasketCount";
import BasketService from "./Services/BasketService";
import { isMobile } from "./Components/Media/Media";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import AccountService from "./Services/AccountService";
import _ from "lodash";
import { capitalizeWords } from "./Helpers/string/capitalizeWords";
import { Stack, Grid, Portal, Link, Breadcrumbs } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SearchResultsList from "./Components/Shop/SearchResultsList";
import { Checkbox } from './Components/Form/Checkbox';
import LikedProductService from "./Services/LikedProductService";
import MegaMenuService from "./Services/MegaMenuService";
import { Accordion, AccordionDetails, AccordionSummary, Drawer } from "@mui/material";
import RelatedProduct from "./Components/RelatedProducts/RelatedProduct";
import ProductSearchService from "./Services/ProductSearchService";


const SparesFinder3 = (props) => {
    const history = useHistory();
    const [isAccountCompleted, setIsAccountCompleted] = useState(false);
    const [relatedProductOpen, setRelatedProductOpen] = useState(false);
    const [newItemPopUpOpen, setNewItemPopUpOpen] = useState(false);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [likedProducts, setLikedProducts] = useState([])
    const [groupedAttributesPagingData, setGroupedAttributesPagingData]=useState([]);
    const [basket, setBasket] = useState({
        basketKey: "",
        lines: [],
    });
    
    const pageSize= 28;

    /* for products */
    const SELECT_VALUES = [
        { sortDir: "ASC", text: "Recommended", sortProp: "PREF" },
        { sortDir: "ASC", text: "Product Name - A-Z", sortProp: "Name" },
        { sortDir: "DESC", text: "Product Name - Z-A", sortProp: "Name" }
    ];
    const [queryAttributeCounts, setQueryAttributeCounts] = useState([]);
    const [classifications, setClassifications] = useState([]);
    const [breadCrumb, setBreadCrumb] = useState([]);
    const [childClassifications, setChildClassifications] = useState([]);
    const query = new URLSearchParams(window.location.search);
    const classificationParam = query.get('classification');
    const searchTermParam = query.get('searchTerm');
    const [state, setState] = useState({
        isFilterActived: false,
        searched: false,
        data: [],
        sortDir: SELECT_VALUES[0].sortDir,
        sortProp: SELECT_VALUES[0].sortProp,
        searchText: "",
        loading: true,
        skip: 0,
        take: pageSize,
        allProducts: [],
        itemAttributes: [],
        attributesLoading: true,
        groupedAttributes: [],
        selectedAttributes: []
    })
    

    const updatePagingData = (attributeId, pageSize) => {
        const currentPagingData = [...groupedAttributesPagingData];
        
        const currentPagingDataLines = currentPagingData.filter(row=> row.attributeId==attributeId).length>0;
        
        if (currentPagingDataLines){
            for(var i=0; i<currentPagingData.length; i++) {
                if (currentPagingData[i].attributeId==attributeId)
                {
                    currentPagingData[i].pageSize=pageSize;
                }
            }
        } else {
            currentPagingData.push({attributeId, pageSize});
        }
        
        setGroupedAttributesPagingData(currentPagingData);
    }
    
    const getPagingSize = (attributeId) => {
        var pagingData=groupedAttributesPagingData.filter(pagingData=>pagingData.attributeId==attributeId)
        if (pagingData.length>0)
        {
            return pagingData[0].pageSize;
        }
        
        return 5;
    }

    useEffect(() => {
        let unregisterHistoryListener;
        checkAccount()
            .then(getLikedProducts)
            .then(getBasket)
            .then(getBreadCrumbData)
            .then(() => {
                const basketIcon = document.getElementById("basket-icon");
                basketIcon &&
                    setBasketIconPosition(basketIcon?.getBoundingClientRect());
                window.addEventListener("scroll", () => {
                    setWindowScrollY(window.scrollY);
                });
            });

        return () => {
            window.removeEventListener("popstate", () => {
            });
            unregisterHistoryListener && unregisterHistoryListener();
            window.removeEventListener("scroll", () => {
            });
        };
    }, []);

    const getBreadCrumbData = () => {
        return ProductSearchService.getBreadCrumb(classificationParam).then((response) => {
            setBreadCrumb(response.breadcrumb);
            setChildClassifications(response.children);
        });
    }

    const getPaginatedData = (skip, take, lastAttributeId, selectedAttributes = []) => {
        setLastSelectedAttributeId(lastAttributeId);
        console.log(lastAttributeId);
        const classificationId = classificationParam;

        const listOfClassifications = childClassifications.length > 0 ? childClassifications.map((child) => child.classificationId) : classificationId ? [classificationId] : [];
        ProductSearchService.getIndexedSearch(skip, take, selectedAttributes, lastAttributeId, listOfClassifications, classificationParam ?? 0, state.sortDir, state.sortProp, searchTermParam).then(response => {
            let newItemAttributes = response.filters.map((el, index) => {
                return { ...el._id, children: el.values, temporaryId: index + 1, selected: false }
            })
            setQueryAttributeCounts(response.counts);

            setState({
                ...state,
                allProducts: response.collection.map(product => {
                    return {
                        ...product,
                        qty: basket.lines.find(line => line.manufacturerCode === product.manufactureCode)?.qty ?? 0,
                        liked: likedProducts.some(likedProduct => likedProduct.itemId === product.productId)
                    }
                }),
                totalRecords: response.count,
                loading: false,
                searched: true,
                itemAttributes: newItemAttributes,
                groupedAttributes: groupByAttributeId(newItemAttributes),
                skip,
                take
            });
        });
    }

    useEffect(() => {
        GetAllClassifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getPaginatedData(state.skip, state.take, lastSelectedAttributeId)
    }, [state.sortDir, state.sortProp, breadCrumb, childClassifications]);

    const NewItemPopup = () => {
        if (!basketIconPosition && !isMobile()) return null;

        const posX = isMobile() ? 0 : basketIconPosition.x - 184.5;
        const posY = isMobile()
            ? 0
            : basketIconPosition.y + basketIconPosition.height + 20;
        const fixedStyles = { top: 16, right: 16, position: "fixed", zIndex: 9999, width: "420px" };
        const absoluteStyles = { top: posY, left: posX + 27, position: "absolute", zIndex: 9999, width: "420px" };

        const goToBasket = () => {
            history.push("/Basket");
        };
        return isMobile() ? (
            <div className="header-new-basket-item-notification-mobile">
                <p>Item(s) added to basket</p>
                <div className="new-basket-item-description">
                    <div style={{ width: "45%" }}>
                        <p>{newBasketItem?.item.description}</p>
                    </div>
                    <img src={newBasketItem?.item.imageUrl} alt="product" />
                </div>
                <button className={`button-large button-white`} onClick={goToBasket}>Go to Basket</button>
            </div>
        ) : (
                <Portal>
                    <div
                        className="header-new-basket-item-notification"
                        style={windowScrollY > 200 ? fixedStyles : absoluteStyles} ph
                    >
                        {!(windowScrollY > 200) && <span className="notification-arrow" />}
                        <p>Item(s) added to basket</p>
                        <div className="new-basket-item-description">
                            <div style={{ width: "45%", fontSize: "12px" }}>
                                <p>MPN: {newBasketItem?.item.mpn}</p>
                                <p>{newBasketItem?.item.description}</p>
                            </div>
                            <img src={newBasketItem?.item.imageUrl} alt="product" />
                        </div>
                        <button className={`button-large button-white`} style={{ margin: "15px 0px" }} onClick={goToBasket}>Go
                        to Basket
                    </button>
                    </div>
                </Portal>
            );
    };

    const getBasket = () => {
        return BasketService.getBasketForUser().then((response) => {
            if (response.lines == null) response.lines = [];
            setBasket(response);
        });
    };

    const getLikedProducts = () => {
        LikedProductService.searchLikedProducts('').then((response) => {
            setLikedProducts(response)
        });
    };

    const closeRelatedProduct = () => {
        setRelatedProductOpen(false);
    };
    const checkAccount = () => {
        return AccountService.isAccountCompleted().then((response) => {
            setIsAccountCompleted(response);
        });
    };

    const [basketIconPosition, setBasketIconPosition] = useState(null);
    const [newBasketItem, setNewBasketItem] = useState(null);
    const [windowScrollY, setWindowScrollY] = useState(0);
    const [relatedProducts, setRelatedProducts] = useState(null)

    const GetAllClassifications = () => {
        MegaMenuService.GetAllClassifications().then(response => {
            if (response && response.collection) {
                setClassifications(response.collection);
            }
        })
    }

    const changeSort = (option) => {
        let index = option.selected;
        setState(
            {
                ...state,
                sortDir: SELECT_VALUES[index].sortDir,
                sortProp: SELECT_VALUES[index].sortProp,
            }
        );
    };
    const toggleLike = (item) => {
        LikedProductService.toggle(item.manufactureCode, item.description, item.imageUrl, null, null, null, null, null, item.buyingGroupCost, null, item.productId).then(() => {
            item.liked = !item.liked;
            setState(prev => {
                return {
                    ...prev,
                    data: [
                        ...state.data,
                        item
                    ]
                }
            })
        });
    };
    const addRelatedProductToBasket = (basketItem) => {
        addItemToBasket(basketItem)
    }
    const addItemToBasket = (basketItem) => {
        let basketCopy = _.cloneDeep(basket);
        const newItem = {
            qty: basketItem.quantityChildValue,
            manufacturerCode: basketItem.item.manufactureCode,
            description: basketItem.item.description,
            imageUrl: basketItem.item.imageUrl || basketItem.item.imageUrlDisplayString,
            manufacturer: basketItem.item.manufacturerName,
            basketLineTypeId: 1
        };

        const currentIndex = basketCopy.lines
            .map((item) => item.manufacturerCode)
            .indexOf(newItem.manufacturerCode);

        if (currentIndex > -1) {
            basketCopy.lines.splice(currentIndex, 1);
        }

        basketCopy.lines.push(newItem);
        basketCopy.lines = basketCopy.lines.filter(({ qty }) => qty !== 0);

        updateBasket(basketCopy);

        setState(prevState => {

            let products = [...prevState.allProducts];
            if (products && products.length && newBasketItem) {
                let toBeUpdated = products.find(x => x.productId === newBasketItem.item.productId)
                if (toBeUpdated) {
                    toBeUpdated.quantityChildValue = newBasketItem.quantityChildValue;
                    toBeUpdated.qty = newBasketItem.quantityChildValue;
                    return { ...prevState, allProducts: [...products] }
                }
            }
            return { ...prevState }

        })


    };
    const sortRelatedProductsBySortOrderAndThenDescription = (relatedProducts) => {
        return relatedProducts.sort((a, b) => {
            return (a.childItem.category?.sortOrder - b.childItem.category?.sortOrder) ||
                (-1 * (a.childItem.description?.localeCompare(b.childItem.description?.sortOrder)));
        })
    }
    const getRelatedProducts = (itemID) => {
        BasketService.getRelatedItems(itemID, 0, 24, []).then(response => {
            setRelatedProducts(sortRelatedProductsBySortOrderAndThenDescription(response.collection));

            if (response.collection && response.collection.length)
                setRelatedProductOpen(true)
            else {
                if (newItemPopUpOpen)
                    return;
                setNewItemPopUpOpen(true)
                setTimeout(() => setNewItemPopUpOpen(false), 3000)

            }
        });
    }
    const updateBasket = (basketCopy) => {
        BasketService.updateBasketForUser(basketCopy).then((basket) => {
            if (basket.lines == null) basket.lines = [];
            setBasket(basket);
        });
    };
    const changeUrl = (part = '', subpart = '') => {
        let newUrl = `/products?part=${part}`;
        if (subpart) {
            newUrl = `/products?part=${part}&subpart=${subpart}`;
        }
        history.push(newUrl);
    };
    const changeParentClassification = ({ value }) => {
        setState({
            ...state,
            searched: false
        })

        changeUrl(value)
        setSelectedAttributes([]);
    }
    const changeChildClassification = ({ value }) => {
        setState({
            ...state,
            searched: false
        })

        const partId = classifications?.find(el => el.id === +value)?.parentId
        changeUrl(partId, value);
        setSelectedAttributes([]);
    }

    const minusQuantity = (basketItem) => {
        setNewBasketItem((prev) => ({
            ...prev,
            quantityChildValue: basketItem.quantityChildValue - 1
        }));
    }
    const addQuantity = (basketItem) => {
        setNewBasketItem((prev) => ({
            ...prev,
            quantityChildValue: basketItem.quantityChildValue + 1
        }));
    }
    useEffect(() => {
        if (newBasketItem) {
            addItemToBasket(newBasketItem)
            getRelatedProducts(newBasketItem.item.productId);
        }
    }, [newBasketItem])

    const groupByAttributeId = (arr) => {
        return arr.map(r => {
            return {
                ...r,
                show_count: 5,
                selected: false
            }
        });
    }

    const [lastSelectedAttributeId, setLastSelectedAttributeId] = useState(null);

    const [expanded, setExpanded] = React.useState(false);
    const [showFilter, setShowFilter] = useState(false);

    const handleChangeExpand = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const pageChanged = (e) => {
        getPaginatedData(e.skip, e.take, null, selectedAttributes)
    };

    /* for navbar */
    const getFilteredAttributes = (newState) => {
        //need to rework this and how the attributes are loaded.
        console.log("newState", newState);
    }

    const onSelectChildCategory = (attributeId, sub_cat_child) => {
        let currentAttributes = selectedAttributes;
        let itemToRemove = -1;
        for (var i = 0; i < currentAttributes.length; i++) {
            if (currentAttributes[i].attributeId == attributeId
                && currentAttributes[i].value == sub_cat_child) {
                itemToRemove = i;
                break;
            }
        }

        if (itemToRemove == -1) {
            currentAttributes.push({
                attributeId,
                value: sub_cat_child
            })
        } else {
            currentAttributes.splice(itemToRemove, 1);
        }

        setSelectedAttributes(currentAttributes);

        getPaginatedData(0, pageSize, attributeId, currentAttributes);
    }

    const showMoreSubCategory = (keyId) => {
        const clonedData = [...state.groupedAttributes];
        console.log(clonedData);
        const newData = clonedData.map((it) => {
            if (+it.attributeId === keyId) {
                const attributeObject = {...it, show_count: it.show_count + it.children.length};
                updatePagingData(keyId,attributeObject.show_count); 
                return attributeObject
            }

            return it
        });

        setState({ ...state, groupedAttributes: newData })
    }

    const showLessSubCategory = (keyId) => {
        const clonedData = [...state.groupedAttributes];
        console.log(clonedData);
        const newData = clonedData.map((it) => {
            if (+it.attributeId === keyId) {
                const attributeObject = { ...it, show_count: 5 };
                updatePagingData(keyId,attributeObject.show_count);
                return attributeObject;
            }

            return it
        });

        setState({ ...state, groupedAttributes: newData })
    }

    const getChildAttributeCount = (attributeId, attributeChild) => {
        const attributeGrouping = queryAttributeCounts.filter(f => f._id.attributeId == attributeId);

        if (attributeGrouping?.length == 0)
            return 0;
        var childValue = attributeGrouping[0].values.filter(x => x.value == attributeChild.value)[0];

        return childValue?.number ?? 0;
    }



    const getBreadCrumbs = () => breadCrumb.map((crumb, index) => {
            return (<Link underline="hover" key="1" color="#BACAD1" fontSize={16} href={index === 0 ? '#' : `/products?classification=${crumb.classificationId}`}>
                {capitalizeWords(crumb.classificationName)}
            </Link>);
    });

    const navigateToClassification = (classificationId) => {
        window.location = `/products?classification=${classificationId}`;
    }

    return (
        <>
            <Wrapper
                text="SparesFinder"
                basket={basket}
                showDrawer={true}
            >



                <div className='shop-categories-wrapper'>
                    {relatedProductOpen &&
                        <RelatedProduct
                            relatedProducts={relatedProducts}
                            newBasketItem={newBasketItem}
                            onAddToBasket={setNewBasketItem}
                            onAddRelatedProductToBasket={addRelatedProductToBasket}
                            addQuantity={addQuantity}
                            minusQuantity={minusQuantity}
                            onClose={closeRelatedProduct}
                        />}
                    {newItemPopUpOpen &&
                        <NewItemPopup />
                    }


                    {/*
                    <div className='wrapper-content'>
                        {isMobile() ? null : <div className={'max-width-1300'}>
                            <div className={'search-header'}>
                                What are you looking for?
                            </div>
                        </div>}
                        <div className={'search-form-container max-width-1300'}>
                            <div className='search-form-content max-width-1300'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <div className="search-input">
                                            <div className="search-input-select">
                                                <SelectListStaticWithLabel
                                                    placeholder="Select Category"
                                                    valueProperty="id"
                                                    textProperty="name"
                                                    collection={getParentClassification || []}
                                                    onChange={changeParentClassification}
                                                    value={classifications?.find(el => el.id === +partParams)?.id || ''}
                                                    className="search-field-list"
                                                    outlined={false}
                                                    withoutWrapper
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <div className="search-input">
                                            <div className="search-input-select">
                                                <SelectListStaticWithLabel
                                                    placeholder={"Select Sub Category"}
                                                    valueProperty="id"
                                                    textProperty="name"
                                                    collection={getSubMenuClassification || []}
                                                    onChange={changeChildClassification}
                                                    value={classifications?.find(el => el.id === +subpartParams)?.id || ''}
                                                    className="search-field-list"
                                                    outlined={false}
                                                    withoutWrapper
                                                    disabled={!partParams}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <div className="search-input search-btn">
                                            <SearchButton
                                                disabled={!partParams || (getSubMenuClassification?.length && !subpartParams)}
                                                onSearch={() => getFilteredData()}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    */}


                    {/* {!state.allProducts.length && <ResultsInfoBox />} */}


                    {state.searched &&
                        <div className="max-width-1300 shop-categories-content">

                            {breadCrumb.length > 0 &&
                                <div className="breadcrumb-container">
                                    <Breadcrumbs
                                        separator={<NavigateNextIcon fontSize="medium" />}
                                        aria-label="breadcrumb"
                                    >
                                        {getBreadCrumbs()}
                                    </Breadcrumbs>
                                </div>
                            }

                            {searchTermParam &&
                                <>
                                    <div className="search-term-container">
                                        Search Results for <span className="searched-term">'{searchTermParam}'</span> <span className="product-count">({state.totalRecords} products)</span>
                                    </div>

                                    {!state.totalRecords &&
                                        <p>Your search has found no results. Please re-try using a spares/products search as appropriate, or contact us for assistance during office hours on <a href="tel:01978 666 888">01978 666 888</a> or <a href="mailto:info@buytrade.co.uk">info@buytrade.co.uk</a> .</p>
                                    }
                                </>
                            }


                            {childClassifications.length > 0 &&
                                <Stack container className="category-button-container" direction="row" spacing={2} sx={{ maxWidth: '100%' }}>
                                    {childClassifications.map((response) => {
                                        return (
                                            <button className="category-button" onClick={() => {
                                                navigateToClassification(response.classificationId);
                                            }}>
                                                {capitalizeWords(response.classificationName)}
                                            </button>
                                        )
                                    })}
                                </Stack>
                            }


                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={2} lg={2}>
                                    {isMobile() ?
                                        null
                                        : <div className="sub-categories-list">
                                            <Grid container spacing={2}>
                                                {state.groupedAttributes.length > 0 &&
                                                    <>
                                                        <Grid item xs={6} sm={4} md={12} lg={12}>
                                                            <span>Refine results</span>
                                                        </Grid>

                                                        <Grid item xs={6} sm={4} md={12} lg={12}>
                                                            <div onClick={() => {
                                                                setSelectedAttributes([])
                                                                getPaginatedData(state.skip, state.take, lastSelectedAttributeId);
                                                            }} className="sub-category-show-more">Clear filters</div>
                                                        </Grid>
                                                    </>
                                                }
                                                {state.groupedAttributes.map((itemAttribute, itemAttribute_index) => {
                                                    return (
                                                        <Grid item xs={6} sm={4} md={12} lg={12}
                                                            key={itemAttribute_index}>
                                                            <div className="sub-categories-item">
                                                                <div
                                                                    className="sub-categories-item-header">{itemAttribute.attributeName || ''}</div>
                                                                    {console.log(itemAttribute.children)}
                                                                {itemAttribute.children && itemAttribute.children?.map((sub_cat_child, sub_cat_child_index) => {
                                                                    const countValue = itemAttribute.attributeId == lastSelectedAttributeId ? sub_cat_child.number : getChildAttributeCount(itemAttribute.attributeId, sub_cat_child);

                                                                    if (countValue === 0)
                                                                        return;

                                                                    return (<div className="sub-category-child"
                                                                        key={sub_cat_child_index}>
                                                                        <div className="sub-category-child-checkbox">
                                                                            <Checkbox
                                                                                id={`sub-category-child-checkbox-${sub_cat_child.value}`}
                                                                                onChange={() => onSelectChildCategory(itemAttribute.attributeId, sub_cat_child.value)}
                                                                                additionalStyle="checkbox-clear-wrapper"
                                                                                titlePlacement="aside"
                                                                                filled
                                                                                noError={true}
                                                                                value={selectedAttributes.filter((attribute) => attribute.attributeId == itemAttribute.attributeId && attribute.value == sub_cat_child.value).length > 0}
                                                                                classes={{
                                                                                    wrapper: "sub-category-child-checkbox-wrapper",
                                                                                    adornment: `sub-category-child-checkbox-adornment ${sub_cat_child.selected ? 'selected' : ''}`,
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            className="sub-category-child-name">{sub_cat_child.value} ({countValue})
                                                                                </div>
                                                                    </div>);
                                                                }).splice(0, getPagingSize(itemAttribute.attributeId))}


                                                                {getPagingSize(itemAttribute.attributeId) === 5 && itemAttribute.children.length > 5 ? 
                                                                    <button 
                                                                        className="sub-category-show-more"
                                                                        onClick={() => showMoreSubCategory(itemAttribute.attributeId)}
                                                                        >Show More
                                                                    </button> 
                                                                : getPagingSize(itemAttribute.attributeId) > 5 && itemAttribute.children.length > 5 ?
                                                                    <button 
                                                                        className="sub-category-show-more"
                                                                        onClick={() => showLessSubCategory(itemAttribute.attributeId)}
                                                                        >Show Less
                                                                    </button> 
                                                                : null }
                                                            </div>
                                                        </Grid>
                                                    )
                                                }
                                                )}
                                            </Grid>
                                        </div>
                                    }

                                    {isMobile() ?
                                        <Drawer
                                            anchor={"right"}
                                            open={showFilter}
                                            onClose={() => setShowFilter(false)}
                                            classes={{
                                                overflowY: "hidden",
                                                backgroundColor: "#F5F7F9",
                                            }}
                                        >
                                            <div role="presentation" style={{ minWidth: '300px' }}>
                                                <div
                                                    onClick={() => setShowFilter(false)}
                                                    className="close_mobile_nav"
                                                    style={{ cursor: 'pointer', marginLeft: 'auto', marginRight: '20px' }}
                                                >
                                                    <svg
                                                        width="19"
                                                        height="19"
                                                        viewBox="0 0 19 19"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <rect
                                                            x="1.34326"
                                                            y="0.431763"
                                                            width="23.7442"
                                                            height="1.89953"
                                                            transform="rotate(45 1.34326 0.431763)"
                                                            fill="#00C9FF"
                                                        />
                                                        <rect
                                                            x="18.1763"
                                                            y="1.7749"
                                                            width="23.7442"
                                                            height="1.89953"
                                                            transform="rotate(135 18.1763 1.7749)"
                                                            fill="#00C9FF"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className={'filter-nav-menu'}>
                                                <div className={'header'}>
                                                        <div>FILTERS</div>
                                                        <div onClick={() => {
                                                            setSelectedAttributes([])
                                                            getPaginatedData(state.skip, state.take, lastSelectedAttributeId);
                                                        }}
                                                            className="sub-category-show-more">
                                                            Clear filters
                                                        </div>
                                                </div>
                                                    <div className={'filter-list'}>
                                                        {state.groupedAttributes.map((itemAttribute, itemAttribute_index) =>
                                                            <Accordion
                                                                style={{
                                                                    boxShadow: 'none',
                                                                    borderBottom: "1px solid #EAEFF3",
                                                                    borderRadius: 'none',
                                                                    margin: '0'
                                                                }}
                                                                key={itemAttribute_index}
                                                                expanded={expanded === 'panel' + itemAttribute_index}
                                                                onChange={handleChangeExpand('panel' + itemAttribute_index)}
                                                            >
                                                                <AccordionSummary
                                                                    expandIcon={<svg width="15" height="9"
                                                                        viewBox="0 0 15 9" fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M14.5 1.25L7.5 8.25L0.5 1.25"
                                                                            stroke="#BACAD1" stroke-linecap="round" />
                                                                    </svg>
                                                                    }
                                                                    aria-controls="panel1bh-content"
                                                                    id="panel1bh-header"
                                                                    style={{ paddingLeft: 20, minHeight: 40 }}
                                                                >
                                                                    {itemAttribute.attributeName || ''}
                                                                </AccordionSummary>
                                                                <AccordionDetails style={{ padding: 0 }}>
                                                                    {itemAttribute.children && itemAttribute.children?.map((sub_cat_child, sub_cat_child_index) => {

                                                                        const countValue = itemAttribute.attributeId == lastSelectedAttributeId ? sub_cat_child.number : getChildAttributeCount(itemAttribute.attributeId, sub_cat_child);

                                                                        if (countValue === 0)
                                                                            return;

                                                                        return (
                                                                            <div className="sub-category-child"
                                                                                key={sub_cat_child_index}>
                                                                                <div
                                                                                    className="sub-category-child-checkbox">
                                                                                    <Checkbox
                                                                                        id={`sub-category-child-checkbox-${sub_cat_child.value}`}
                                                                                        onChange={() => onSelectChildCategory(itemAttribute.attributeId, sub_cat_child.value)}
                                                                                        additionalStyle="checkbox-clear-wrapper"
                                                                                        titlePlacement="aside"
                                                                                        filled
                                                                                        noError={true}
                                                                                        value={selectedAttributes.filter((attribute) => attribute.attributeId == itemAttribute.attributeId && attribute.value == sub_cat_child.value).length > 0}
                                                                                        classes={{
                                                                                            wrapper: "sub-category-child-checkbox-wrapper",
                                                                                            adornment: `sub-category-child-checkbox-adornment ${sub_cat_child.selected ? 'selected' : ''}`,
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div
                                                                                    className="sub-category-child-name">{sub_cat_child.value} ({countValue})
                                                                            </div>
                                                                            </div>);
                                                                    }).splice(0, getPagingSize(itemAttribute.attributeId))}

                                                                {getPagingSize(itemAttribute.attributeId) === 5 && itemAttribute.children.length > 5 ? 
                                                                    <button 
                                                                        className="sub-category-show-more"
                                                                        onClick={() => showMoreSubCategory(itemAttribute.attributeId)}
                                                                        >Show More
                                                                    </button> 
                                                                : getPagingSize(itemAttribute.attributeId) > 5 && itemAttribute.children.length > 5 ?
                                                                    <button 
                                                                        className="sub-category-show-more"
                                                                        onClick={() => showLessSubCategory(itemAttribute.attributeId)}
                                                                        >Show Less
                                                                    </button> 
                                                                : null }
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Drawer>
                                        : null}
                                </Grid>


                                <Grid item xs={12} sm={12} md={10} lg={10}>
                                    <div className="products-container">
                                        <SearchResultsList
                                            skip={state.skip}
                                            take={state.take}
                                            results={state.allProducts}
                                            loading={state.loading}
                                            toggleLike={toggleLike}
                                            onAddToBasket={(obj) => {
                                                setNewBasketItem(obj);
                                            }}
                                            onRemoveFromBasket={addItemToBasket}
                                            changeSort={changeSort}
                                            sortValues={SELECT_VALUES}
                                            sectionName={'filter'}
                                            noItemsText={''}
                                            noResults={!state.allProducts.length}
                                            redirectToSearch
                                            isAccountCompleted={isAccountCompleted}
                                            showGroupCost
                                            totalRecords={state.totalRecords}
                                            onPageChange={pageChanged}
                                            pageSize={pageSize}
                                            filterOnClick={() => setShowFilter(true)}
                                        />
                                    </div>
                                </Grid>

                            </Grid>
                        </div>}
                </div>
            </Wrapper>
        </>
    );
};

export default SparesFinder3;