import { createTheme } from '@mui/material/styles';

const fontFamily = ["proxima-nova", "sans-serif"].join(", ");

const fontSize = {
    small: 14,
    main: 18,
    large: 24,
    subheading: 20,
    heading: 32,
};

const fontWeight = {
    fontWeightLight: 100,
    fontWeightRegular: 300,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontWeightBlack: 900,
};

const colors = {
    whiteColor: "#fff",
    black: "#454545",
    darkColor: "#000",
    cyan: "#21ABDF",
    navy: "#00226A",
    primaryColor: "#3EC4F4",
    primaryColorDarker: "hsl(202, 40%, 27%)",
    primaryColorLighter: "hsl(199, 75%, 53%)",
    primaryColorLightest: "#DDF3FB",
    secondaryColor: "#54A9DA",
    infoBackground: "#E5F6FD",
    infoContent: "#014360",
    errorColor: "#FE5C62",
    redColor: "#ED1F45",
    grayColor: "#e9e9e9",
    grayApprox: "#878787",
    whiteSmoke: "#F3F3F3",
    placeholderColor: "#c4c4c4",
    aliceBlue: "#F6F7F9",
    summerSky: "#009FDF",
    turquoiseBlue: "#48D4FF",
    yellowGreen: "#95C22A",
    gainsboro: "#E0E0E0",
    gainsboroApprox: "#DFDFDF",
    quartz: "#DAD6EF",
    arapawa: "#2A4D61",
    atlantis: "#92C23E",
    wildStrawberry: "#FE368B",
    limerick: "#8EBA24",
    conifer: "#AEDE3D",
    ruby: "#E61B74",
    brilliantRose: "#FF4B9A",
    mantis: "#81C454",
    baliHai: "#86A0AE",
    transparent: "rgba(255, 255, 255, 0)", // for a correct render on iOS
};

// Create a theme instance.
const theme = createTheme({
    boxShadow: {
        main: "0px 6px 12px rgba(0, 0, 0, 0.07), 0px 4px 15px rgba(84, 169, 218, 0.15)",
    },
    typography: {
        fontFamily: fontFamily
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1024,
            lg: 1200,
            xl: 1920,
        },
    },
    margins: {
        row: 15,
        primary: 16,
        container: 24,
    },
    shape: {
        borderRadius: 6,
    },
    overrides: {
        MuiCssBaseline: {
            styleOverrides: `
            @font-face {
              font-family: 'proxima-nova';
            }
          `,
        },
        MuiAccordion: {
            root: {
                boxShadow: "none",
                "&:not(:last-child)": {
                    borderBottom: 0,
                },
                "&:before": {
                    content: null,
                },
                "&$expanded": {
                    margin: "auto",
                },
            },
        },
        MuiAccordionSummary: {
            root: {
                borderBottom: `1px solid ${colors.gainsboro}`,
                minHeight: 40,
                padding: 0,
                "&$expanded": {
                    minHeight: 40,
                },
            },
            content: {
                "&$expanded": {
                    margin: "12px 0",
                },
            },
            expandIcon: {
                transform: "rotate(-90deg)",
                fontSize: 24,
                "&$expanded": {
                    transform: "rotate(0deg)",
                },
            },
        },
        MuiAccordionDetails: {
            root: {
                padding: 0,
            },
        },
        MuiList: {
            root: {
                width: "100%",
            },
        },
        MuiListItem: {
            root: {
                paddingTop: null,
                paddingBottom: null,
            },
            gutters: {
                paddingRight: 8,
                paddingLeft: 16,
            },
        },
        MuiListItemText: {
            root: {
                marginTop: null,
                marginBottom: null,
                flex: "1 0 auto",
            },
        },
        MuiSvgIcon: {
            root: {
                fontSize: "1em",
            },
        },
        MuiFormLabel: {
            root: {
                "&, &$focused": {
                    color: colors.black,
                },
            },
        },
        MuiFormControlLabel: {
            root: {
                marginLeft: null,
                marginRight: null,
                paddingRight: 8,
                "&:hover": {
                    backgroundColor: colors.aliceBlue,
                },
            },
        },
        MuiRadio: {
            root: {
                color: colors.summerSky,
                fontSize: 29,
                padding: 8,
            },
        },
        MuiCheckbox: {
            root: {
                color: colors.quartz,
                fontSize: 24,
            },
        },
        MuiOutlinedInput: {
            notchedOutline: {
                borderColor: colors.gainsboro,
            },
        },
        MuiInput: {
            underline: {
                "&:before": {
                    borderColor: colors.primaryColor,
                },
                "&:hover:not($disabled):before": {
                    borderBottom: `2px solid ${colors.primaryColor}`,
                },
            },
        },
        MuiButton: {
            root: {
                textTransform: 'capitalize',
                borderRadius: 6,
            },
            containedSizeLarge: {
                padding: "14px 32px",
                fontSize: fontSize.main,
                minWidth: 160,
            },
        },
        MuiTypography: {
            root: {
                fontFamily: 'proxima-nova'
                }
            }
    },
    palette: {
        primary: {
            main: colors.cyan,
        },
        secondary: {
            main: colors.summerSky,
            contrastText: colors.whiteColor,
        },
        text: {
            primary: colors.black,
        },
        background: {
            default: colors.whiteColor,
        },
        grey: {
            300: colors.grayColor,
        },
        cyan: {
            main: colors.cyan
        },
        navy: {
            main: colors.navy
        },
        brand: {
            summerSky: colors.summerSky,
            arapawa: colors.arapawa,
            atlantis: colors.atlantis,
            wildStrawberry: colors.wildStrawberry,
            mantis: colors.mantis,
            ruby: colors.ruby,
            quartz: colors.quartz,
            baliHai: colors.baliHai,
            grayApprox: colors.grayApprox,
            aliceBlue: colors.aliceBlue,
            gainsboroApprox: colors.gainsboroApprox,
            gainsboro: colors.gainsboro,
            placeholderColor: colors.placeholderColor,
            infoBackground: colors.infoBackground,
            infoContent: colors.infoContent
        },
        gradient: {
            summerSky: `linear-gradient(57.2deg, ${colors.summerSky} 0%, ${colors.turquoiseBlue} 86.07%)`,
            limerick: `linear-gradient(55.01deg, ${colors.limerick} 0%, ${colors.conifer} 87.65%)`,
            ruby: `linear-gradient(53.33deg, ${colors.ruby} 0%, ${colors.brilliantRose} 89.09%)`,
        },
    },
    typography: {
        h1: {
            fontFamily,
            fontWeight: fontWeight.fontWeightLight,
            fontSize: fontSize.heading,
            lineHeight: 1.19,
            margin: 0,
            textTransform: "capitalize",
        },
        h2: {
            fontFamily,
            fontWeight: fontWeight.fontWeightBold,
            fontSize: fontSize.subheading,
            textTransform: "uppercase",
        },
        h3: {
            fontFamily,
            fontWeight: fontWeight.fontWeightMedium,
            fontSize: fontSize.main,
            textTransform: "uppercase",
        },
        h6: {
            fontFamily,
            fontSize: fontSize.small,
            fontWeight: fontWeight.fontWeightRegular,
            lineHeight: 1.2,
            color: colors.primaryColor,
        },
        subtitle1: {
            fontFamily,
            fontWeight: fontWeight.fontWeightBold,
            fontSize: fontSize.subheading,
            textTransform: "uppercase",
            margin: 0,
            lineHeight: 1.2,
            color: colors.primaryColor,
        },
        subtitle2: {
            fontFamily,
            fontSize: fontSize.main,
            fontWeight: fontWeight.fontWeightBold,
            lineHeight: 1.166,
        },
        subtitle3: {
            fontFamily,
            fontSize: fontSize.small,
            fontWeight: fontWeight.fontWeightMedium,
            lineHeight: 1.2,
            textTransform: "uppercase",
        },
        body1: {
            fontFamily,
            fontSize: fontSize.main,
            fontWeight: fontWeight.fontWeightRegular,
            lineHeight: 1.42857,
        },
        body2: {
            fontFamily,
            fontSize: fontSize.main,
            fontWeight: fontWeight.fontWeightRegular,
            lineHeight: 1.166,
        },
        useNextVariants: true,
        fontSize: fontSize.main,
        fontSizeSmall: fontSize.small,
        fontSizeLarge: fontSize.large,
        fontFamily,
        ...fontWeight,
    },
});

export default theme;
