import React, { useState } from "react";
import AutoCompleteSearchBar from "../AutoCompleteSearchBar/AutoCompleteSearchBar"

const MobileSearchPopup = ({ onClose, show }) => {

    return (

        <div className={`mobileSearchPopup ${show ? '' : 'mobileSearchPopupHidden'}`}>
            <div className="topSection">
                <div className="searchHeading">Search</div>
                <button className="closeButton"
                    onClick={onClose}
                >
                    <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            x="1.34326"
                            y="0.431763"
                            width="23.7442"
                            height="1.89953"
                            transform="rotate(45 1.34326 0.431763)"
                            fill="#00C9FF"
                        />
                        <rect
                            x="18.1763"
                            y="1.7749"
                            width="23.7442"
                            height="1.89953"
                            transform="rotate(135 18.1763 1.7749)"
                            fill="#25D6E4"
                        />
                    </svg>
                </button>
            </div>
            <div className="header__search">
                <AutoCompleteSearchBar />
            </div>
        </div>

    )
}

export default MobileSearchPopup;