import React from 'react';
import ClearButton from "./ClearButton";

function FinderInput({searchTerm, searchTermError, placeholder, onChange, onClear}) {
    return (
        <div className='finder-input-relative-wrapper'>
            <input
                value={searchTerm}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
                className={`finder-input ${searchTermError && "dropdown-outlined"}`}
            />
            {onClear && <ClearButton onClear={onClear}/>}
        </div>
    );
}

export default FinderInput;