import React from "react";
import SortDropDown from "../Layout/SortDropDown";
import ProductCard from "./ProductCard/ProductCard";
import LikedProductService from "../../Services/LikedProductService";
import { isMobile, isTablet } from "../Media/Media";
import Loader from "../Layout/Loader";
import SearchResultsHeader from "./SearchResultsHeader";
import SearchResultsList from "./SearchResultsList";
import spinnerIcon from "../../assets/img/spinner.svg";
import SearchButton from "./SearchButton";
import ClearButton from "./ClearButton";
import FinderInput from "./FinderInput";
import Header from "../Header/Header";
import FeatureFlagContext from "../../Context/FeatureFlagContext";

const SELECT_VALUES = [
    { sortDir: "asc", text: "Product Name - A-Z", sortProp: "Description" },
    { sortDir: "desc", text: "Product Name - Z-A", sortProp: "Description" },
];

class SparesFinderSavedFavouritesCont extends React.Component {
    static contextType = FeatureFlagContext;
    
    constructor(props) {
        super(props);
        this.state = {
            isFilterActived: false,
            searched: true,
            data: [],
            sortDir: SELECT_VALUES[0].sortDir,
            sortProp: SELECT_VALUES[0].sortProp,
            searchText: "",
            loading: true
        };
    }
    
    isAllProducts(){
        return this.context.checkFeatures("all-products");
    }

    componentDidMount() {
        this.getLikedProducts();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.searchText !== this.state.searchText) this.getLikedProducts();
    }

    getLikedProducts = () => {
        const { sortProp, sortDir, searchText } = this.state;

        LikedProductService.searchLikedProducts(searchText, sortDir, sortProp).then((response) => {
            this.setState({
                loading: false,
                data:
                    response.map((product) => {
                        return {
                            qty: this.props.basket?.lines.find((line) => line.manufacturerCode === product.manufacturerCode)?.qty ?? 0,
                            cost: product.cost,
                            mpn: product.manufacturerCode,
                            img_url: product.imageUrl,
                            description: product.description,
                            manid: product.iBasisManId,
                            app: product.iBasisAppliance,
                            appid: product.iBasisApplianceId,
                            pgcn: product.iBasisGCN,
                            appgc: product.iBasisApplianceGC,
                            man: product.manufacturer,
                            itemId: product.itemId,
                            id: product.id
                        };
                    }) || [],
            })
        });
    };

    changeSort = (option) => {
        let index = option.selected;
        this.setState(
            {
                sortDir: SELECT_VALUES[index].sortDir,
                sortProp: SELECT_VALUES[index].sortProp,
            },
            () => this.getLikedProducts()
        );
    };

    toggleLike = (item) => {
        LikedProductService.toggle(item.mpn, item.description, item.img_url, item.manid, item.app, item.appid, item.pgcn, item.appgc, item.price, item.man, item.itemId).then(() => {
            this.setState(prev => {
                return {
                    ...prev,
                    data: prev.data.filter(product => item.id !== product.id)
                }
            })
        });
    };

    search = () => {
        this.getLikedProducts();
    };

    handleSearch = (value) => {
        this.setState({ searchText: value });
    };

    handleClear = () => {
        this.setState({ searchText: "" });
    };

    finderInput = () => (
        <FinderInput
            searchTerm={this.state.searchText}
            placeholder="Search Favourites"
            onChange={this.handleSearch}
            onClear={this.handleClear}
            style={{ '&::placeholder': { color: '#454545' } }}
        />
    );

    render() {
        const { data, loading } = this.state;

        return (
            <div className="spares-finder-recently_wrapper sp-common-spares-finder" style={{ margin: 0 }}>

                {!this.isAllProducts() ?
                    <>
                        {isMobile() ? 
                            <div className="order_history_search order_history_search_mobile">
                                <div className={`order_history_search_box ${isTablet() ? "bg-max-width-tablet" : ""}`}>
                                    <div className="finder-inputs-wrapper">
                                        {this.finderInput()}
                                    </div>
                                </div>
                            </div>
                       :
                        <div className={"sp-desktop-spares-finder"}>
                            <div className={"max-width-1300 bg-gray-container full-width-fav"}>
                                <div className="finder-inputs-wrapper no-margin-left" data-columns='2'>
                                    {this.finderInput()}
                                    <SearchButton loading={loading} onSearch={this.props.onSearch} />
                                </div>
                            </div>
                        </div>
                         }
                    </>

                    :


                    <>
                    {isMobile() ? 
                        <div style={{ marginTop: -50 }}>
                            <Header
                                body="Favourites"
                            />
                            <div style={{
                                padding: 20,
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: '#fff',
                            }}>
                                {this.finderInput()}
                                <SearchButton loading={loading} onSearch={this.props.onSearch} />
                            </div>
                         </div>
                       :
                            <>
                                <Header
                                    body="Favourites"
                                />
                                <div className="finder-inputs-wrapper no-margin-left" data-columns='2'>
                                    {this.finderInput()}
                                    <SearchButton loading={loading} onSearch={this.props.onSearch} />
                                </div>
                            </>
                         }
                    </>

                }



                <div className={isTablet() ? 'results-wrapper-tablet' : ''}>
                    <SearchResultsList
                        results={data}
                        loading={loading}
                        toggleLike={this.toggleLike}
                        alwaysLiked
                        onAddToBasket={this.props.onAddToBasket}
                        changeSort={this.changeSort}
                        sortValues={SELECT_VALUES}
                        sectionName={isMobile() ? 'Favourites' : 'Favourites List'}
                        noItemsText={isMobile() ? 'No items have been added to the Favourites List'
                            : 'Select the star icon on a product to add it to this list'}
                        redirectToSearch
                        isAccountCompleted={this.props.isAccountCompleted}
                        showNewSortFilter={false}
                        onRemoveFromBasket={this.props.onRemoveFromBasket}
                    />
                </div>
            </div>
        );
    }
}

export default SparesFinderSavedFavouritesCont;
