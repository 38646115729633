import React from 'react';
import ExampleImg from "../../../../assets/img/no_image_available.png";
import BasketIcon from "../../../../assets/img/BasketIcon";
import { Button, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';


function ProductCard({ imgSrc, description, code, removeFromSaved, moveToBasket }) {

    const theme = useTheme();

    return (

        <div className="productCard">

            <img src={imgSrc ? imgSrc : ExampleImg} />

            <div className="description">{description}</div>

            <Box sx={{ display: 'flex', marginBottom: '15px' }}>
                <div className="code">{code}</div>
                <div
                    onClick={removeFromSaved}
                    className="removeFromSaved"
                >
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 11L11 1M11 11L1 1" stroke="currentcolor" />
                    </svg>
                    <span>Remove from saved items</span>
                 </div>
            </Box>

            <button
                onClick={moveToBasket}
            >
                <BasketIcon viewBox='0 0 17 12' width='10px' height='10px' />
                Move to Basket
            </button>

        </div>

    )
};

export default ProductCard;
