import React from "react";
import SvgIcon from '@mui/material/SvgIcon';


function BasketIcon(props) {
    return (
        <SvgIcon {...props}>
            <ellipse cx="7.36333" cy="11.9489" rx="1.0518" ry="1.05051" />
            <ellipse cx="11.5714" cy="11.9489" rx="1.05183" ry="1.05051" />
            <path clipRule="evenodd" d="M2.16523 1.31313H0V0H2.62951C2.90782 0 3.15597 0.175041 3.24904 0.437005L5.98624 8.14143H12.9795L15.4371 2.49579L16.6429 3.01937L14.0133 9.05979C13.909 9.29951 13.6722 9.45457 13.4105 9.45457H5.52197C5.24366 9.45457 4.99551 9.27952 4.90244 9.01756L2.16523 1.31313Z" fill="white" />
        </SvgIcon>
    );
}

export default BasketIcon
