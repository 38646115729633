import http from '../utils/http';

const searchRequestedProducts = (searchText, sortDir, sortProp) => {
    const formData = new FormData();
    if(searchText) {
        formData.append('searchText', searchText);
    }
    if(sortDir){
        formData.append('sortDir', sortDir);
    }
    if(sortProp){
        formData.append('sortProp', sortProp)
    }

    return http.post(`RequestedProducts/SearchRequestedProducts`, formData , { headers: {
        "Content-Type": "multipart/form-data"
    }})
}

const addProductToRequestedProducts = (product) => {
    return http.post(`RequestedProducts/AddProductToRequestedProducts`, product)
}
const addRangeProductToRequestedProducts = (request) => {
    return http.post(`RequestedProducts/AddRangeProductToRequestedProducts`, request)
}

const removeProductFromRequestedProducts = (id) => {
    const formData = new FormData();

    if(id){
        formData.append('id', id)
    }

    return http.post(`RequestedProducts/RemoveProductFromRequestedProducts`, formData , { headers: {
        "Content-Type": "multipart/form-data"
    }});
}

export default {
    searchRequestedProducts,
    addProductToRequestedProducts,
    addRangeProductToRequestedProducts,
    removeProductFromRequestedProducts
};