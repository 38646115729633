import React from 'react';
import isPlural from "../../Helpers/common/isPlural";
import SortDropDown from "../Layout/SortDropDown";
import { Button } from "@mui/material";

function SearchResultsHeader({ quantity, sortItems, onSelectSort, sectionName, noItemsText, loading, restProp, filterOnClick, noItemsHTML, showNewSortFilter = {} }) {
    const found = sectionName ? `in ${sectionName}...` : 'found...';
    const inStock = (quantity || 0) + ` ${isPlural(quantity, 'item')} ${found}`;
    const { headerText } = restProp;

    return (
        <div className='search-results-text-wrapper'>

            {showNewSortFilter ?

                <div className="sort-filter-container" style={{ marginTop: 10 }}>
                    <SortDropDown
                        sortItems={sortItems}
                        onSelectSort={onSelectSort}
                        noBorder
                        withHeader
                        visible={!loading && !!quantity}
                        showSortIconOnly={false}
                    />
                    <Button onClick className="filter-button" onClick={filterOnClick}>Filter</Button>
                </div>
                :
                <div class="border-bottom-gray" style={{ paddingTop: 25, paddingBottom: 5 }}>
                    <div className='found-items-text'>
                        {
                            quantity
                                ? headerText || inStock
                                : noItemsHTML || noItemsText
                        }
                    </div>
                    <SortDropDown
                        sortItems={sortItems}
                        onSelectSort={onSelectSort}
                        noBorder
                        withHeader
                        visible={!loading && !!quantity}
                        showSortIconOnly={true}
                    />
                </div>


            }



        </div>
    );
}

export default SearchResultsHeader;