import React from "react";
import { isMobile } from "../Media/Media";
import SortIcon from "../../assets/img/sort-icon.svg"
import { Button } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

class SortDropDown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 0,
            modalOpen: false,
            showSortIconOnly: true,
        };
    }

    changeHandler = (selected) => {
        this.setState({ selected });
        this.props.onSelectSort({ selected });
        this.toggleModal();
    };

    toggleModal = () =>
        this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }));

    renderPopup = () => {
        return (
            <div className={`sort-drop-down-popup swing-in-top-fwd`}>
                {this.props.sortItems.map(({ text }, index) => (
                    <div
                        key={index}
                        className={`sort-drop-down-item ${this.state.selected === index ? "selected-item" : ""}`}
                        onClick={() => this.changeHandler(index)}
                    >
                        {text}
                    </div>
                ))}
            </div>
        );
    };

    render() {
        const { modalOpen, selected } = this.state;
        const { customClassName, withHeader, visible = true } = this.props;
        const { text } = this.props.sortItems[selected];
        const textStyle = `text-advice webkit-inline-flex ${modalOpen ? "text-advice-active" : ""}`;

        return (

                <>
                    {modalOpen && (
                        <div
                            onClick={this.toggleModal}
                            className={`sort-modal-overlay${isMobile() ? '-mobile' : ''}`}
                        />
                    )}

                    {this.props.showSortIconOnly ?

                        <div style={{ opacity: visible ? 1 : 0, position: "relative" }}>
                            <button onClick={this.toggleModal}>
                                <img src={SortIcon} alt="" />
                            </button>
                            {modalOpen && this.renderPopup()}
                        </div>

                        :

                    <>
                        <div className="sort-button-container-desktop" style={{ opacity: visible ? 1 : 0 }}>
                            <button onClick={this.toggleModal}>
                                <img src={SortIcon} alt="" />
                            </button>
                            {modalOpen && this.renderPopup()}
                        </div>

                        <div className="sort-button-container-mobile">
                            <Button className="sort-button" onClick={this.toggleModal} endIcon={<ExpandMoreIcon fontSize="large" />}>Sort</Button>
                            {modalOpen && this.renderPopup()}
                        </div>
                    </>


                    }


                </>

            


        );
    }
}

export default SortDropDown;
