import React from "react";
import {
    Route
} from "react-router-dom";
import AuthService from "../Services/authService";


export default function PrivateRoute({ component: Component, ...rest }) {
    return (
        AuthService.isAuthenticated ? <Route {...rest} render={(props) => {
            return <Component {...props}/>
        }} /> : <></>
    )
}