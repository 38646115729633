/* eslint-disable jsx-a11y/anchor-is-valid */
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import ApiAddressianService from "../../Services/ApiAddressianService";
import SelectListStaticWithLabel from "../Form/SelectListStaticWithLabel";
import TextBoxWithLabel from "../Form/TextBoxWithLabel";
import toastr from "toastr";
import ApiPostcodesService from "../../Services/ApiPostcodesService";
import { isMobile as isMobileMedia } from "../Media/Media";

const useStyles = makeStyles(() => ({
    overlay: {
        zIndex: 1000,
        background: "#00000033",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    modal: {
        position: 'initial',
        overflowY: "auto",
        maxHeight: "90vh",
        minHeight: '40vh',
        height: 'auto',
        padding: isMobileMedia() ? '20px 25px;' : '20px 50px;',
        width: isMobileMedia() ? 'calc(100vw - 40px)' : '33vw',
        maxWidth: isMobileMedia() ? 'calc(100vw - 40px)' : 560,
        '& svg': {
            transform: 'scale(1) translateY(0px) !important'
        }
    },
    mobileModal: {
        overflowY: "auto",
        maxHeight: "90vh",
        minHeight: '40vh',
        height: 'auto',
        padding: '20px 50px;',
    },
    closeModalBtnWrapper: {
        position: 'relative',
    },
    closeModalBtn: {
        position: 'absolute',
        right: 0,
    },
    modalHeader: {
        textAlign: 'left',
        '& h1': {
            paddingTop: 20,
            paddingBottom: 5,
            fontSize: isMobileMedia() ? 20 : 26,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '32px',
            color: '#00226A',
        },
    },
    actionButtonWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: isMobileMedia() ? 'column' : 'row',
        justifyContent: 'flex-end',
        alignItems: isMobileMedia() ? 'flex-end' : 'center',
        '& span': {
            color: '#ED1F45',
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '23px',
            padding: '0 10px',
        }
    },
    actionButton: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        // color: 'white',
        width: '150px',
        // padding: '15px 20px',
        // borderRadius: '6px',
        // backgroundColor: '#3EC4F4'
    },
    warningMessage: !isMobileMedia() ? {} : {
        display: 'flex',
        alignItems: 'center',
        marginTop: -20,
        marginBottom: 20,
    },
    content: {
        '& span': {
            fontSize: isMobileMedia() ? 14 : 16,
        }
    }
}));

const CHANGE_LOCATION_OPTIONS = [
    { id: 1, name: "Enter postcode" },
    { id: 3, name: "Use default location (from settings)" }
]

const MOBILE_CHANGE_LOCATION_OPTIONS = [
    { id: 1, name: "Enter postcode" },
    { id: 2, name: "Use my current location" },
    { id: 3, name: "Use default location (from settings)" }
]

const Location = (props) => {
    const classes = useStyles();
    const { isMobile } = props;

    const [postcode, setPostcode] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [optionId, setOptionId] = useState(-1);
    const [isInvalidPostcode, setIsInvalidPostcode] = useState(false);

    const onClickChangeLocation = () => {
        setIsModalOpen(true);
    }

    const onCloseModal = () => {
        setIsModalOpen(false);
    }

    const changeOption = (option) => {
        setIsInvalidPostcode(false);
        setOptionId(option.value);
    };

    const changePostcode = (value) => {
        setIsInvalidPostcode(false);
        setPostcode(value);
    }

    const onChangeLocation = () => {
        const { onChange, onGetDefaultLocation } = props;
        if (optionId == 1) {
            ApiAddressianService.getPostCodeDetails(postcode).then((response) => {
                if (response.latitude && response.longitude) {
                    setIsModalOpen(false);
                    onChange && onChange(response.latitude, response.longitude, response.postcode)
                } else {
                    onInvalidPostcode();
                }
            });
        } else if (optionId == 2) {
            const geolocation = navigator.geolocation;
            geolocation.getCurrentPosition(onSuccessGetGeolocation, onErrorGetGeolocation);
        } else if (optionId == 3) {
            setIsModalOpen(false);
            onGetDefaultLocation && onGetDefaultLocation()
        }
    }

    const onSuccessGetGeolocation = (position) => {
        ApiPostcodesService.getPostcodesByGeo(position.coords.latitude, position.coords.longitude).then((response) => {
            if (response.result && response.result.length > 0) {
                setIsModalOpen(false);
                props.onChange &&
                    props.onChange(position.coords.latitude, position.coords.longitude, response.result[0].postcode);
            } else {
                onInvalidCurrentPosition();
            }
        })
    }

    const onErrorGetGeolocation = (error) => {
        onInvalidCurrentPosition();
    }

    const onInvalidCurrentPosition = () => {
        toastr.error("Your location could not be determined");
    }

    const onInvalidPostcode = () => {
        setIsInvalidPostcode(true);
    }

    return (
        <div className='change-location'
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {isMobileMedia()
                    ? <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.93476 13.7175C0.772578 7.95788 0 7.36676 0 5.25C0 2.3505 2.46242 0 5.5 0C8.53758 0 11 2.3505 11 5.25C11 7.36676 10.2274 7.95788 6.06524 13.7175C5.7921 14.0942 5.20787 14.0941 4.93476 13.7175ZM5.5 7.4375C6.76566 7.4375 7.79167 6.45813 7.79167 5.25C7.79167 4.04187 6.76566 3.0625 5.5 3.0625C4.23434 3.0625 3.20833 4.04187 3.20833 5.25C3.20833 6.45813 4.23434 7.4375 5.5 7.4375Z" fill="#009FDF" />
                    </svg>
                    : <svg width='21' height='28'>
                        <path d="M9.42091 27.4351C1.47492 15.9158 0 14.7335 0 10.5C0 4.70099 4.70099 0 10.5 0C16.299 0 21 4.70099 21 10.5C21 14.7335 19.5251 15.9158 11.5791 27.4351C11.0576 28.1883 9.9423 28.1883 9.42091 27.4351ZM10.5 14.875C12.9163 14.875 14.875 12.9163 14.875 10.5C14.875 8.08374 12.9163 6.125 10.5 6.125C8.08374 6.125 6.125 8.08374 6.125 10.5C6.125 12.9163 8.08374 14.875 10.5 14.875Z" fill="#009FDF" />
                    </svg>
                }
                <p>Your Location: {props.postcode ?? 'N/A'}</p>
            </div>
            <a onClick={onClickChangeLocation}
                style={{ marginBottom: isMobile ? '' : '1rem', textDecoration: 'underline' }}>Change Location?</a>
            {isModalOpen && (
                <>
                    <div
                        className={`modal-overlay ${classes.overlay}`}
                    >
                        <div
                            className={`dropdown_select_bar_modal ${isMobile ? classes.mobileModal : classes.modal}`}
                        >
                            <div className={classes.modalHeader}>
                                <div className={classes.closeModalBtnWrapper}>
                                    <a className={classes.closeModalBtn} onClick={onCloseModal}>
                                        <svg width="19" height="20" viewBox="0 0 19 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.438477 19L18.4385 1M18.4385 19L0.438477 1" stroke="#007AAB" />
                                        </svg>
                                    </a>
                                </div>
                                <h1>{"Change location"}</h1>
                            </div>
                            <div className={classes.content}>
                                <SelectListStaticWithLabel
                                    valueProperty="id"
                                    textProperty={"name"}
                                    collection={isMobile ? MOBILE_CHANGE_LOCATION_OPTIONS : CHANGE_LOCATION_OPTIONS}
                                    onChange={changeOption}
                                    value={optionId}
                                    className="select-list-account select-account-placeholder"
                                    selectOnly
                                />
                                {optionId == 1 && (
                                    <TextBoxWithLabel
                                        labelClassName="customer-label"
                                        addCssClass={`group__row ${isInvalidPostcode ? 'error-postcode-label' : ''}`}
                                        textboxClassName='placeholder-postcode'
                                        label={'POSTCODE'}
                                        placeholder="e.g. NE02745"
                                        value={postcode}
                                        onChange={changePostcode}
                                    />
                                )}
                            </div>
                            <div className={classes.actionButtonWrapper}>
                                {isInvalidPostcode && (
                                    <div className={classes.warningMessage}>
                                        <svg width="19" height="20" viewBox="0 0 52 53" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M26 52.0162C11.6602 52.0162 0 40.3398 0 26C0 11.6602 11.6602 0 26 0C40.3398 0 52 11.6602 52 26C52 40.3398 40.3398 52.0162 26 52.0162ZM26 3.41037C13.544 3.41037 3.41037 13.544 3.41037 26C3.41037 38.456 13.544 48.5896 26.0162 48.5896C38.4722 48.5896 48.6059 38.456 48.6059 26C48.6059 13.544 38.4722 3.41037 26 3.41037Z"
                                                fill="#ED1F45" />
                                            <path
                                                d="M26.1475 31.2449H26.0176C24.9783 31.2449 24.1338 30.4004 24.1338 29.361V14.258C24.1338 13.2186 24.9783 12.3741 26.0176 12.3741H26.1475C27.1869 12.3741 28.0314 13.2186 28.0314 14.258V29.361C28.0314 30.4004 27.1869 31.2449 26.1475 31.2449Z"
                                                fill="#ED1F45" />
                                            <path
                                                d="M25.9983 39.6408C27.7114 39.6408 29.1001 38.252 29.1001 36.5389C29.1001 34.8259 27.7114 33.4371 25.9983 33.4371C24.2852 33.4371 22.8965 34.8259 22.8965 36.5389C22.8965 38.252 24.2852 39.6408 25.9983 39.6408Z"
                                                fill="#ED1F45" />
                                        </svg>
                                        <span>Please enter a valid postcode</span>
                                    </div>
                                )}
                                <div className={classes.actionButton}>
                                    <button className={`${classes.button} button-large button-light-blue`}
                                        onClick={onChangeLocation}>Change
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Location;