import React from "react";
import { isMobile } from "../Media/Media";

const SELECT_VALUES = [
  { value: "select1", text: "Purchase Date (Newest to Oldest)" },
  { value: "select2", text: "Purchase Date (Oldest to Newest)" },
  { value: "select3", text: "Product Name - A-Z" },
  { value: "select4", text: "Product Name - Z-A" },
];

class DropDown extends React.Component {
  static defaultProps = {
    valueProperty: "value",
    textProperty: "text" || ["text"],
  };

  state = {
    modalOpen: false,
    searchedList: [],
    searchText: "",
    regexWhiteSpace: /\s/g,
  };

  onChange = (value, text) => {
    this.props.onChange({ value, text });
  };

  getDisplayText = (textProperty, item) => {
    if (Array.isArray(textProperty)) {
      const values = textProperty.map((property) => item[property]);
      return values.join(" - ");
    } else {
      return item[textProperty];
    }
  };

  itemIncludesSearchTextWithIgnore = (
    textProperty,
    item,
    searchText,
    replacePattern
  ) => {
    if (Array.isArray(textProperty)) {
      for (let i = 0; i < textProperty.length; ++i) {
        const hasMatchesWithSearch = item[textProperty[i]]
          .toLowerCase()
          .replaceAll(replacePattern, "")
          .includes(searchText.toLowerCase().replaceAll(replacePattern, ""));
        if (hasMatchesWithSearch) {
          return true;
        }
      }
      return false;
    } else {
      return item[textProperty]
        .toLowerCase()
        .replaceAll(replacePattern, "")
        .includes(searchText.toLowerCase().replaceAll(replacePattern, ""));
    }
  };

  itemIncludesSearchText = (textProperty, item, searchText) => {
    if (Array.isArray(textProperty)) {
      for (let i = 0; i < textProperty.length; ++i) {
        const hasMatchesWithSearch = item[textProperty[i]]
          .toLowerCase()
          .includes(searchText.toLowerCase());
        if (hasMatchesWithSearch) {
          return true;
        }
      }
      return false;
    } else {
      return item[textProperty]
        .toLowerCase()
        .includes(searchText.toLowerCase());
    }
  };

  toggleModal = () => {
    const { collection, disabled } = this.props;
    if (!collection.length || disabled) return;

    this.setState(({ modalOpen }) => ({
      modalOpen: !modalOpen,
      searchedList: [],
      searchText: "",
    }));
  };

  renderArrowSvg = () => {
    const { modalOpen } = this.state;

    if (modalOpen) {
      return (
        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 7.78564L7 0.99993L1 7.78564"
            stroke="#007AAB"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }

    return (
      <svg
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L7 7L13 1"
          stroke="#007AAB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  getHighlightedText = (text = '', highlight = '') => {
    const trimHighlight = highlight.trim()

    if (trimHighlight && text) {
      const parts = text.split(new RegExp(`(${trimHighlight})`, 'gi'));

      return (
        <span>
          {parts.map((part, i) =>
            <span key={i} style={part.toLowerCase() === trimHighlight.toLowerCase() ? { fontWeight: 'bold' } : {}}>
              {part}
            </span>
          )}
        </span>
      )
    }

    return <span>{text}</span>
  }

  renderPopup = () => {
    const { searchedList, searchText } = this.state;
    const { collection, valueProperty, textProperty, popUpStyle = '' } = this.props;
    const itemsForShow = searchText ? searchedList : collection;

    return (
      <div className={isMobile() ? `pop-up-mobile pop-up pop-up-special pop-up-special-mobile ${popUpStyle}` : `pop-up pop-up-special ${popUpStyle}`}>
        {itemsForShow.map((option, i) => {
          const displayText = this.getDisplayText(textProperty, option);
          return (
            <div
              key={i}
              className="item"
              onClick={() => this.onChange(option[valueProperty], displayText)}
            >
              {this.getHighlightedText(displayText, searchText)}
            </div>
          );
        })}
      </div>
    );
  };

  searchChange = (e) => {
    const { searchIgnoresWhiteSpace } = this.props;
    e.stopPropagation();
    const {
      target: { value },
    } = e;
    const { collection, textProperty } = this.props;
    const { regexWhiteSpace } = this.state;
    let searchedList;
    if (searchIgnoresWhiteSpace) {
      searchedList = collection.filter((item) =>
        this.itemIncludesSearchTextWithIgnore(
          textProperty,
          item,
          value,
          regexWhiteSpace
        )
      );
    } else {
      searchedList = collection.filter((item) =>
        this.itemIncludesSearchText(textProperty, item, value)
      );
    }

    this.setState({ searchText: value, searchedList });
  };

  render() {
    const { modalOpen, searchText } = this.state;
    const {
      collection,
      selectedVal,
      valueProperty,
      textProperty,
      error,
      width,
      placeholder,
      disabled,
      newStyle,
      selectOnly,
      selectBarClass = ''
    } = this.props;
    const selectedItem = collection.find(
      (item) => item[valueProperty] === selectedVal
    );
    const selectedBarCls = `${newStyle ? "select-bar select-bar-new select-bar-additional" : "select-bar select-bar-additional"} ${modalOpen ? "active-bar select-bar-special-open" : ""} ${selectBarClass} ${(!collection.length || disabled) ? "disable-dropdown" : ""
      } ${error ? "dropdown-error" : ""}`;
    const renderPopup = Boolean(modalOpen && collection.length);
    const displaySelectedText = selectedItem
      ? this.getDisplayText(textProperty, selectedItem)
      : placeholder || "-PLEASE SELECT-";

    return (
      <>
        {modalOpen && (
          <div onClick={this.toggleModal} className="modal-overlay" />
        )}
        <div className="dropdown-wrapper-main">
          <div
            onClick={this.toggleModal}
            className={[selectedBarCls, this.props.outlined ? "dropdown-outlined" : "",].join(' ')}
            style={isMobile() ? { height: 48 } : {}}
          >
            {(!renderPopup || selectOnly) ? (
              <span className="selected-text thin-text" style={width ? { width } : {}}>
                {displaySelectedText}
              </span>
            ) : (
              <input
                className="selected-text"
                onClick={(e) => e.stopPropagation()}
                style={
                  width
                    ? { width, height: "100%", border: "none", color: "#828282" }
                    : { height: "100%", border: "none", color: "#828282" }
                }
                onChange={this.searchChange}
                value={searchText}
                autoFocus
                autoComplete={"no"}
              />
            )}{" "}
            {this.renderArrowSvg()}
            {renderPopup && this.renderPopup()}
          </div>
        </div>
      </>
    );
  }
}

export default DropDown;
