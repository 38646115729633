import http from '../utils/http';

const select = (request) => {
    return http.post(`SparesAvailability/Select`, request)
}

const selectDelivery = (request) => {
    return http.post(`SparesAvailability/SelectDelivery`, request)
}

export default {
    select,
    selectDelivery,
}
