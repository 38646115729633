export const trimText = (text, num, showDots = true) => {
    let newText = ''
    if (text) {
        if (text.length > num) {
            newText = text.substr(0, num) + (showDots ? '...' : '')
        } else {
            newText = text
        }
    }

    return newText
}