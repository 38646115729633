import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import Wrapper from './Components/Layout/Wrapper';
import BackButton from './Components/Button/BackButton';
import moment from 'moment';
import notificationService from './Services/NotificationService'
import { withStyles } from "@material-ui/styles";

const $ = window.jQuery;

const styles = (theme) => ({
    notificationTitle: {
        fontFamily: 'PROXIMA NOVA',
        color: '#00226a !important',
        fontWeight: 600
    },
    notificationHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 55,
        paddingBottom: 40
    },
    backToNotifications: {
        display: "flex",
        justifyContent: "flex-end",
        "& a": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            color: "#00226A !important",
            "& img": {
                width: 17,
            },
            "& p": {
                margin: 0,
                padding: "0 0 0 16px",
            },
            "&:hover *": {
                color: "#009FDF",
                textDecoration: "none",
            },
        },
    }
})

function NotificationDetails(props) {
    const { classes } = props;

    return (<NotificationDetailsPage ID={props.match.params.notificationId} classes={classes} />);
}

class NotificationDetailsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = { logDateTime: '', title: '', description: '', longDescription: '', unreadNotificationCount: 0 };
        this.markAsRead(this.props.ID);
        this.getData();
    }

    markAsRead = (id) => {
        notificationService.MarkAsRead([this.props.ID]).then(response => {
            if (response) {
                notificationService.GetUnreadNotificationsCount().then(notificationsResult => {
                    if (notificationsResult) {
                        this.setState({ unreadNotificationCount: notificationsResult.count })
                    }
                })
            }
        });
    };

    getData() {
        $.ajax({
            url: '/NotificationTriggerLog/Get/' + this.props.ID,
            success: (data) => {
                this.setState({
                    logDateTime: data.logDateTime,
                    notificationTitle: data.notificationTitle,
                    notificationText: data.computedNotificationText,
                    longDescription: data.fullDescription,
                });
            },
        });
    }

    render() {
        const { logDateTime, notificationTitle, notificationText, longDescription } = this.state;
        const time = moment(logDateTime).format('hh:mm A');
        const date = moment(logDateTime).format('DD MMMM YYYY');
        const { classes } = this.props;

        return (
            <Wrapper unreadNotificationCount={this.state.unreadNotificationCount} selected="Notifications">
                <header className="template__header">
                    <div className={classes.notificationHeader} >
                        <h1 className="template__title">Notification</h1>
                        <div className={classes.backToNotifications}>
                            <Link to={'/Notifications'}>
                                <img src="/img/goBack.png" alt="back" onClick={() => { }} />
                                <p>Back to notifications</p>
                            </Link>
                        </div>
                    </div>
                </header>
                <div className="notification-wrapper">
                    <div className="date-container">
                        <div className="time-container">
                            <svg
                                width="14"
                                height="25"
                                viewBox="0 0 14 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M10.7059 12.75H3.29412V7.225C3.29412 5.1 4.94118 3.4 7 3.4C9.05882 3.4 10.7059 5.1 10.7059 7.225V12.75ZM12.3529 11.9V7.225C12.3529 4.6155 10.5906 2.431 8.23529 1.853V1.275C8.23529 0.936849 8.10515 0.612548 7.87349 0.373439C7.64182 0.13433 7.32762 0 7 0C6.67238 0 6.35818 0.13433 6.12651 0.373439C5.89485 0.612548 5.76471 0.936849 5.76471 1.275V1.853C3.40118 2.431 1.64706 4.6155 1.64706 7.225V11.9L0 13.6V14.45H14V13.6L12.3529 11.9ZM7 17C7.43683 17 7.85576 16.8209 8.16465 16.5021C8.47353 16.1833 8.64706 15.7509 8.64706 15.3H5.35294C5.35294 15.7509 5.52647 16.1833 5.83535 16.5021C6.14424 16.8209 6.56317 17 7 17Z"
                                    fill="white"
                                />
                            </svg>
                            <span>{time}</span>
                        </div>
                        <span className="date">{date}</span>
                    </div>
                    <div className="main-container">
                        <h2 className={classes.notificationTitle}>{notificationTitle}</h2>
                        <p>{longDescription}</p>
                    </div>
                </div>
            </Wrapper>
        );
    }
}

export default withStyles(styles)(NotificationDetails);