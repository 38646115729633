import {useState, useEffect} from 'react';
import LikedProductService from "./Services/LikedProductService";
import ProductSearchService from './Services/ProductSearchService';
import {isEmpty} from './utils/funcs';

const SELECT_VALUES = [
    {sortDir: "asc", text: "Product Name - A-Z", sortProp: "Description"},
    {sortDir: "desc", text: "Product Name - Z-A", sortProp: "Description"},
];

function useTextSearch(isSearchByCode, basket, setShowFilterHeader) {
    const [isFilterActived, setIsFilterActived] = useState(false)
    const [searched, setSearched] = useState(false)
    const [data, setData] = useState([])
    const [searchTerm, setSearchTerm] = useState(isSearchByCode
        ? (new URL(window.location.href).searchParams.get("q") ?? '')
        : '')
    const [totalRecords, setTotalRecords] = useState(0)
    const [skip, setSkip] = useState(0)
    const [take, setTake] = useState(10)
    const [manufacturers, setManufacturers] = useState([])
    const [filterManufacturerId, setFilterManufacturerId] = useState(0)
    const [filterManufacturerName, setFilterManufacturerName] = useState("All Manufacturers")
    const [appliances, setAppliances] = useState([])
    const [searchedAppliances, setSearchedAppliances] = useState(false)
    const [likedProducts, setLikedProducts] = useState([])
    const [appliance, setAppliance] = useState(null)
    const [sortDir, setSortDir] = useState(SELECT_VALUES[0].sortDir)
    const [sortProp, setSortProp] = useState(SELECT_VALUES[0].sortProp)
    const [loadedRecords, setLoadedRecords] = useState(false)
    const [errorSearchTerm, setErrorSearchTerm] = useState(false)
    const [searchingByAppliance, setSearchingByAppliance] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [searchTermError, setSearchTermError] = useState(false)
    const [manufacturerError, setManufacturerError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [multiple_appliance_modal, setMultiple_appliance_modal] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [refreshSearchResults, setRefreshSearchResults] = useState()
    const [state, setState] = useState({
        isFilterActived: false,
        searched: false,
        data: [],
        searchTerm: isSearchByCode
            ? (new URL(window.location.href).searchParams.get("q") ?? '')
            : '',
        totalRecords: 0,
        skip: 0,
        take: 10,
        manufacturers: [],
        filterManufacturerId: 0,
        filterManufacturerName: "All Manufacturers",
        appliances: [],
        searchedAppliances: false,
        likedProducts: [],
        appliance: {},
        sortDir: SELECT_VALUES[0].sortDir,
        sortProp: SELECT_VALUES[0].sortProp,
        loadedRecords: false,
        errorSearchTerm: false,
        searchingByAppliance: false,
        searchText: "",
        searchTermError: false,
        manufacturerError: false,
        loading: true,
        multiple_appliance_modal: false,
        pageNumber: 1
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!likedProducts.length) getLikedProducts();
        if (!manufacturers.length) getManufacturers();

        if (isSearchByCode && manufacturers.length) {
            const currentUrl = new URL(window.location.href);
            setSearchTerm(currentUrl.searchParams.get("q") ?? '');

            if (currentUrl.searchParams.get("q")) {
                search()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manufacturers.length])

    useEffect(() => {
        if (isSearchByCode) {
            search()
        } else {
            searchPartsByAppliance(appliance);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortDir, sortProp, pageNumber])

    const pageChanged = ({skip, take}) => {
        setSkip(skip);
        setTake(take)

        setTimeout(() => {
            search()
        }, 0);
    }

    const changeSearchTerm = (val) => {
        setSearchTermError(false)
        setSearchTerm(val)
        setRefreshSearchResults(true)
    };

    const toggleLike = (item, isLiked = false) => {
        LikedProductService.toggle(item.mpn, item.description, item.img_url, item.manid, item.app, item.appid, item.pgcn, item.appgc, item.price, item.man).then(() => {
            if (!isLiked) {// add to the list
                setData(prev => prev.map(product =>
                    product.mpn === item.mpn ? { ...product, liked: !isLiked } : product))
                setLikedProducts(prev => [
                    ...prev,
                    {
                        mpn: item.mpn,
                        img_url: item.img_url,
                        description: item.description,
                    }
                ])
            } else {// remove from the list    })
                setData(prev => prev.map(product => product.mpn === item.mpn ? {
                    ...product,
                    liked: !isLiked
                } : product))
                setLikedProducts(prev => prev.filter(likedProduct => likedProduct.mpn !== item.mpn))
            }
        });
    };

    const search = () => {

        if (searchTerm === '') return setSearchTermError(true)

        setSearched(true)
        setLoading(true)
        setRefreshSearchResults(false)

        if (
            isSearchByCode === true &&
            searchTerm != "" &&
            filterManufacturerName != ""
        ) {
            setData([])
            setLoadedRecords(false)

            setTimeout(() => {

                ProductSearchService.searchPartsByFilter(searchTerm, filterManufacturerName, filterManufacturerId, pageNumber, sortDir, sortProp)
                    .then((response) => {
                        let items = response.data.map((product) => {
                            product.qty = basket.lines.find((line) => line.manufacturerCode === product.mpn)?.qty ?? 0
                            product.liked = likedProducts.findIndex(
                                (p) => p.mpn == (product.manufacturerCode ?? product.mpn)
                            ) !== -1;
                            return product;
                        });
                        setData(items || [])
                        setTotalRecords(response.totalCount)
                        setSearched(true)
                        setLoadedRecords(true)
                        setLoading(false)
                    });
            }, 0);
        } else if (
            isSearchByCode === true &&
            (searchTerm === "" || filterManufacturerName === "")
        ) {
           
            setLoadedRecords(false)
            setSearched(false)
            setData([])
            setLoading(false)
        } else if (isSearchByCode !== true) {
            searchAppliances();
        }
    };

    const searchAppliances = () => {
        setSearched(true)
        setSearchedAppliances(true)
        setLoading(true)
        const selectedManufacturer = manufacturers.find(el => el.man === filterManufacturerName)
        const manId = selectedManufacturer?.manid || null
        
        ProductSearchService.searchAppliances(searchTerm, filterManufacturerName, manId).then((response) => {
            setAppliances(response || [])
            setData([])
            setLoading(false)
           
            if (response?.length === 1) {
                onSelectAppliance(response[0])
            } else if (response?.length > 1) {
                setMultiple_appliance_modal(true)
            }
        });
    };

    const onSelectAppliance = async (appliance) => {
        searchPartsByAppliance(appliance);
    };

    const searchPartsByAppliance = (appliance) => {
        if (!appliance) return

        setData([])
        setSearched([])
        setSearched(true)
        setSearchedAppliances(false)
        setSearchingByAppliance(true)
        setLoadedRecords(false)
        setLoading(true)
        setFilterManufacturerName(appliance.man)
        setAppliance(appliance)

        ProductSearchService.partsByAppliance(appliance.manid, appliance.appid, pageNumber, sortDir, sortProp).then((response) => {
            let items = response.data.map((product) => {
                product.qty = basket.lines.find((line) => line.manufacturerCode === product.mpn)?.qty ?? 0
                product.liked = likedProducts.findIndex(
                    (p) => p.mpn == (product.manufacturerCode ?? product.mpn)
                ) !== -1;
                return product;
            });
            setShowFilterHeader(false);
            setAppliance(appliance)
            setData(items || [])
            setSearchingByAppliance(false)
            setLoadedRecords(true)
            setLoading(false)
            setTotalRecords(response.totalCount)
        });
    };

    const getLikedProducts = async () => {
        await LikedProductService.searchLikedProducts('').then((response) => {
            setLikedProducts(
                response ? response.map((product) => {
                    return {
                        mpn: product.manufacturerCode,
                        img_url: product.imageUrl,
                        description: product.description,
                    };
                }) : [],
            )
        });
    };

    const getManufacturers = async () => {
        setLoading(true);
        await ProductSearchService.manufacturer().then(async (data) => {
            const sortedData = (data || []).sort((a, b) => {
                if (a.man < b.man) {
                    return -1;
                }
                if (a.man > b.man) {
                    return 1;
                }
                return 0;
            });
            sortedData.unshift({manid: 0, man: 'All Manufacturers'})
            await setManufacturers(sortedData)
            await setLoading(false)
        });
    };

    const setManufacturer = ({value, text}) => {
        setManufacturerError(false)
        setFilterManufacturerName(text)
        setFilterManufacturerId(value)
        setData([])
        setAppliances([])
        setAppliance({})
        setTotalRecords(0)
    };

    const onPartCodeKeywordOnPaging = async (pageNumber) => {
        setPageNumber(pageNumber)
    }

    const onSearchCodeKeywordOnPaging = async (pageNumber) => {
        setPageNumber(pageNumber)
    }

    const changeSort = (option) => {
        let index = option.selected;
        setSortDir(SELECT_VALUES[index].sortDir)
        setSortProp(SELECT_VALUES[index].sortProp)
    };

    const searchAmongResults = (e) => {
        setSearchText(e.target.value)
    };

    const clearSearchText = () => {
        setSearchText("")
    };

    const filterSearchResults = (item) => {
        return searchText !== ""
            ? item.description
                .toLowerCase()
                .includes(searchText.toLowerCase())
            : true;
    };

    const applianceDocumentExists = () => {
        if (appliance && !isEmpty(appliance)) {
            return appliance.extras !== "" && appliance.extras !== null;
        } else {
            return false;
        }
    }

    const onViewManual = () => {
        if (appliance && !isEmpty(appliance)) {
            ProductSearchService.applianceDocumentInTab(appliance.appid);
        }
    }

    return {
        searchTerm,
        searchTermError,
        loading,
        data,
        searched,
        skip,
        take,
        totalRecords,
        searchedAppliances,
        appliances,
        multiple_appliance_modal,
        manufacturers,
        filterManufacturerId,
        filterManufacturerName,
        appliance,
        pageNumber,
        manufacturerError,
        setData,
        pageChanged,
        changeSearchTerm,
        toggleLike,
        search,
        searchAppliances,
        onSelectAppliance,
        searchPartsByAppliance,
        getLikedProducts,
        setManufacturer,
        changeSort,
        searchAmongResults,
        clearSearchText,
        filterSearchResults,
        applianceDocumentExists,
        onViewManual,
        onPartCodeKeywordOnPaging,
        setTotalRecords,
        onSearchCodeKeywordOnPaging,
        refreshSearchResults
    }
}

export default useTextSearch