import { useState } from 'react';
import LikedProductService from "./Services/LikedProductService";
import ProductSearchService from './Services/ProductSearchService';
import { useEffect } from 'react';

function useNewSearch(basket) {
    const SELECT_VALUES = [
        { sortDir: "asc", text: "Product Name - A-Z", sortProp: "Description" },
        { sortDir: "desc", text: "Product Name - Z-A", sortProp: "Description" },
    ];

    const [state, setState] = useState({
        isFilterActived: false,
        searched: false,
        loading: false,
        data: [],
        searchTerm: "",
        totalRecords: 0,
        skip: 0,
        take: 10,
        gcSearchObj: {},
        likedProducts: [],
        sortDir: SELECT_VALUES[0].sortDir,
        sortProp: SELECT_VALUES[0].sortProp,
        manufacturerId: -1,
        applianceId: -1,
        partTypeId: -1,
        manufacturerName: null,
        applianceName: null,
        partTypeName: null
    })


    useEffect(() => {
        getLikedProducts();
    }, [])

    const onSearch = (manufacturerId, applianceId, partTypeId, manufacturerName, applianceName, partTypeName) => {
        const { sortDir, sortProp } = state;
        setState(
            {
                ...state,
                loading: true,
            },
        )

        ProductSearchService.partsByType(applianceId, partTypeId, manufacturerId, manufacturerName, applianceName, partTypeName, sortDir, sortProp)
            .then((response) => {
                let items = response.map((product) => {
                    product.qty = basket.lines.find((line) => line.manufacturerCode === product.mpn)?.qty ?? 0
                    product.liked =
                        state.likedProducts?.findIndex(
                            (p) => p.mpn == (product.manufacturerCode ?? product.mpn)
                        ) !== -1;
                    return product;
                });

                setState({
                    ...state,
                    manufacturerId,
                    applianceId,
                    partTypeId,
                    manufacturerName,
                    applianceName,
                    partTypeName,
                    data: items || [],
                    searched: true,
                    loading: false,
                });
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    useEffect(() => {
        const { manufacturerId, applianceId, partTypeId, manufacturerName, applianceName, partTypeName } = state;
        if (manufacturerId !== -1) {
            onSearch(manufacturerId, applianceId, partTypeId, manufacturerName, applianceName, partTypeName)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.sortDir, state.sortProp])

    const getLikedProducts = () => {
        LikedProductService.searchLikedProducts('').then((response) => {
            setState({
                ...state,
                likedProducts:
                    response ? response.map((product) => {
                        return {
                            mpn: product.manufacturerCode,
                            img_url: product.imageUrl,
                            description: product.description,
                        };
                    }) : [],
            })
        });
    };

    const toggleLike = (item, isLiked = false) => {
        LikedProductService.toggle(item.mpn, item.description, item.img_url, item.manid, item.app, item.appid, item.pgcn, item.appgc, item.price, item.man).then(() => {
            if (!isLiked) {// add to the list
                setState(prev => {
                    return {
                        ...prev,
                        data: prev.data.map(product =>
                            product.mpn === item.mpn ? { ...product, liked: !isLiked } : product
                        ),
                        likedProducts: [
                            ...prev.likedProducts,
                            {
                                mpn: item.mpn,
                                img_url: item.img_url,
                                description: item.description,
                            }
                        ]
                    }
                })
            } else {// remove from the list
                setState(prev => {
                    return {
                        ...prev,
                        data: prev.data.map(product =>
                            product.mpn === item.mpn ? { ...product, liked: !isLiked } : product
                        ),
                        likedProducts: prev.likedProducts.filter(likedProduct => likedProduct.mpn !== item.mpn)
                    }
                })
            }
        });
    };

    const changeSort = (option) => {
        let index = option.selected;
        setState(
            {
                ...state,
                sortDir: SELECT_VALUES[index].sortDir,
                sortProp: SELECT_VALUES[index].sortProp,
            }
        );
    };

    const onPartsListChanged = (parts) => {
        setState({ ...state, data: parts, searched: false });
    };

    return { getLikedProducts, state, toggleLike, onPartsListChanged, SELECT_VALUES, onSearch, changeSort, setState }
}

export default useNewSearch