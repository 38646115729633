import http from '../utils/http'

const manufacturer = () => {
    return http.get(`ProductSearch/Manufacturer`);
}

const appliances = (manufacturerId) => {
    const params = new URLSearchParams();
    params.append('manufacturerId', manufacturerId);

    return http.get(`ProductSearch/Appliances`, {params});
}

const partTypes = (applianceId) => {
    const params = new URLSearchParams();
    params.append('applianceId', applianceId);

    return http.get(`ProductSearch/PartTypes`, {params});
}

const searchAppliances = (gcNumber) => {
    const params = new URLSearchParams();
    params.append('gcNumber', gcNumber);

    return http.get(`ProductSearch/SearchAppliances`, {params});
}

const partsByType = (applianceId, partTypeId, manufacturerId, manufacturerName, applianceName, partTypeName, sortDir = null, sortProp = null) => {
    const params = new URLSearchParams();
    params.append('applianceId', applianceId);
    params.append('applianceName', applianceName);
    params.append('partTypeId', partTypeId);
    params.append('partTypeName', partTypeName);
    params.append('manufacturerId', manufacturerId);
    params.append('manufacturerName', manufacturerName);

    if (sortDir !== null) {
        params.append('sortDir', sortDir);
    }

    if (sortProp !== null) {
        params.append('sortProp', sortProp);
    }

    return http.get(`ProductSearch/PartsByType`, {params});
}

const partsByAppliance = (manufacturerId, applianceId, pageNumber, sortDir = null, sortProp = null, pageSize = 52) => {
    const params = new URLSearchParams();
    params.append('manufacturerId', manufacturerId);
    params.append('applianceId', applianceId);
    params.append('pageNumber', pageNumber);
    params.append('pageSize', pageSize);

    if (sortDir !== null) {
        params.append('sortDir', sortDir);
    }

    if (sortProp !== null) {
        params.append('sortProp', sortProp);
    }

    return http.get(`ProductSearch/PartsByAppliance`, {params});
}

const searchPartsByFilter = (keyword, filterManufacturerName, filterManufacturerId, pageNumber, sortDir = null, sortProp = null) => {
    const params = new URLSearchParams();
    params.append('keyword', keyword);
    params.append('manufacturersNames', filterManufacturerName);
    params.append('manufacturerId', filterManufacturerId);
    params.append('pageNumber', pageNumber);

    if (sortDir !== null) {
        params.append('sortDir', sortDir);
    }

    if (sortProp !== null) {
        params.append('sortProp', sortProp);
    }

    return http.get(`ProductSearch/SearchPartsByFilter`, {params});
}

const image = (partId) => {
    return http.get(`/ProductSearch/Image?partID=${partId}`);
}

const partFits = (manufacturerId, mpn) => {
    return http.get(`/ProductSearch/PartFits?manufacturerId=${manufacturerId}&mpn=${mpn}`);
}

const applianceDocument = (applianceId) => {
    return http.get(`/ProductSearch/ApplianceDocument?applianceId=${applianceId}`);
}

const applianceDocumentInTab = (applianceId) => {
    window.open(`/ProductSearch/ApplianceDocument?applianceId=${applianceId}`, '_blank')
}

const searchTechnicalSpecifications = (itemId) => {
    return http.get(`/Item/SearchTechnicalSpecifications?itemId=${itemId}`);
}

const getIndexedSearch = (skip, take, selectedAttributes, lastAttributeId, classificationId, selectedNodeId, sortDirection, sortProp, searchTerm) => {
    return http.post("/ProductSearch/SearchIndex", {
        skip,
        take,
        attributes: selectedAttributes,
        lastAttributeId,
        selectedNodeId: selectedNodeId,
        classificationId: classificationId,
        sortDirection,
        sortProp,
        searchTerm
    });
}

const getBreadCrumb = (startNodeId) => {
    return http.get(`/ProductSearch/GetBreadCrumb?startNodeId=${startNodeId}`);
}

const getAutoComplete = (searchTerm)=> {
    return http.get(`/ProductSearch/GetAutoComplete?searchTerm=${searchTerm}`);
}

const getDiagrams = (appId) => {
    return http.get(`/ProductSearch/Diagrams/diagrams?appid=${appId}`)
}

const getDiagram = (diagramid) => {
    return http.get(`/ProductSearch/Diagram/diagram?diagramId=${diagramid}`)
}

const getDiagramData = (manid, appid, diagramid, pageSize, pageNumber) => {
    return http.get(`/ProductSearch/DiagramData/diagramData?manid=${manid}&appid=${appid}&diagramid=${diagramid}&pageSize=${pageSize}&pageNumber=${pageNumber}`)
}

export default {
    manufacturer,
    appliances,
    getDiagrams,
    getDiagram,
    getDiagramData,
    partTypes,
    searchAppliances,
    partsByType,
    partsByAppliance,
    searchPartsByFilter,
    image,
    partFits,
    applianceDocument,
    applianceDocumentInTab,
    searchTechnicalSpecifications,
    getIndexedSearch,
    getBreadCrumb,
    getAutoComplete
};