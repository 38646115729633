import {isMobile} from "../../Components/Media/Media";

function boundToElement(elementRef) {
    let xPos = 0;
    let yPos = 0;
    const checkoutButtonPosition = elementRef.getBoundingClientRect();
    xPos = isMobile() ? 0 : checkoutButtonPosition.x - 285;
    yPos = isMobile() ? 0 : checkoutButtonPosition.y + checkoutButtonPosition.height + 10;

    return [
        {top: elementRef ? yPos : 16, left: elementRef ? xPos + 27 : 16, position: "absolute", zIndex: 9999},
        {top: 16, right: 16, position: "fixed", zIndex: 9999}
    ];
}

export default boundToElement;