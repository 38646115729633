import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function CalendarIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 17" {...props}>
            <g clip-path="url(#clip0_9780_22869)">
                <path d="M12.5253 0H3.47475C1.53535 0 0 1.53535 0 3.47475V12.5253C0 14.4646 1.53535 16 3.47475 16H12.5253C14.4646 16 16 14.4646 16 12.5253V3.47475C16 1.53535 14.4646 0 12.5253 0ZM3.47475 1.77778H12.5253C13.495 1.77778 14.2222 2.50505 14.2222 3.47475V3.71717H1.77778V3.47475C1.77778 2.50505 2.50505 1.77778 3.47475 1.77778ZM12.5253 14.2222H3.47475C2.50505 14.2222 1.77778 13.495 1.77778 12.5253V5.41414H14.2222V12.5253C14.2222 13.495 13.495 14.2222 12.5253 14.2222Z" fill="#007AAB" />
                <path d="M5.01093 6.86914H3.55638C3.31396 6.86914 3.15234 7.03076 3.15234 7.27318V8.80853C3.15234 9.05096 3.31396 9.21257 3.55638 9.21257H5.09174C5.33416 9.21257 5.49578 9.05096 5.49578 8.80853V7.27318C5.41497 7.03076 5.25335 6.86914 5.01093 6.86914Z" fill="#007AAB" />
                <path d="M5.01093 10.3438H3.55638C3.31396 10.3438 3.15234 10.5054 3.15234 10.7478V12.2831C3.15234 12.5256 3.31396 12.6872 3.55638 12.6872H5.09174C5.33416 12.6872 5.49578 12.5256 5.49578 12.2831V10.7478C5.41497 10.5054 5.25335 10.3438 5.01093 10.3438Z" fill="#007AAB" />
                <path d="M8.72773 6.86914H7.27318C7.03076 6.86914 6.86914 7.03076 6.86914 7.27318V8.80853C6.86914 9.05096 7.03076 9.21257 7.27318 9.21257H8.80853C9.05096 9.21257 9.21257 9.05096 9.21257 8.80853V7.27318C9.13177 7.03076 8.97015 6.86914 8.72773 6.86914Z" fill="#007AAB" />
                <path d="M8.72773 10.3438H7.27318C7.03076 10.3438 6.86914 10.5054 6.86914 10.7478V12.2831C6.86914 12.5256 7.03076 12.6872 7.27318 12.6872H8.80853C9.05096 12.6872 9.21257 12.5256 9.21257 12.2831V10.7478C9.13177 10.5054 8.97015 10.3438 8.72773 10.3438Z" fill="#007AAB" />
                <path d="M12.4453 6.78809H10.9099C10.6675 6.78809 10.5059 6.9497 10.5059 7.19213V8.72748C10.5059 8.9699 10.6675 9.13152 10.9099 9.13152H12.4453C12.6877 9.13152 12.8493 8.9699 12.8493 8.72748V7.19213C12.8493 7.03051 12.6877 6.78809 12.4453 6.78809Z" fill="#007AAB" />
                <path d="M12.4453 10.3438H10.9099C10.6675 10.3438 10.5059 10.5054 10.5059 10.7478V12.2831C10.5059 12.5256 10.6675 12.6872 10.9099 12.6872H12.4453C12.6877 12.6872 12.8493 12.5256 12.8493 12.2831V10.7478C12.8493 10.5054 12.6877 10.3438 12.4453 10.3438Z" fill="#007AAB" />
            </g>
        </SvgIcon>
    );
}

export default CalendarIcon;