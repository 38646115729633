export const FULFILMENT_OPTION_TYPES=[
    {
        id: 0,
        description: 'to collect from ',
        description_saturday_collection: 'to collect before noon ' // The Saturday collection options
    },
    {
        id: 1,
        description: 'to collect from ',
        description_saturday_collection: 'to collect before noon ' // The Saturday collection options
    },
    {
        id: 2,
        description: 'for delivery from '
    },
    {
        id: 3,
        description: 'to collect from '
    },
    {
        id: 4,
        description: 'EXPRESS SAME-DAY DELIVERY '
    },
    {
        id: 5,
        description: 'ByBox Delivery'
    }
];