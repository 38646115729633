import React, { useState } from 'react';
import TablePager from "../Table/TablePager";
import Loader from "../Layout/Loader";
import ProductCard from "./ProductCard/ProductCard";
import ObsoleteProduct from "./ObsoleteProduct";
import SearchResultsHeader from "./SearchResultsHeader";
import { isMobile } from "../Media/Media";
import { CashBackModal } from './CashBackModal';

function SearchResultsList({
    results,
    loading,
    skip,
    take,
    onPageChange,
    totalRecords,
    toggleLike,
    onAddToBasket,
    isAccountCompleted,
    changeSort,
    sortValues,
    noItemsText,
    onRemoveFromBasket,
    sectionName,
    children,
    redirectToSearch,
    onRemove,
    alwaysLiked,
    restProp = {},
    resultListWrapperClasses = '',
    showGroupCost = false,
    pageSize,
    filterOnClick,
    showNewSortFilter,
    noItemsHTML
}) {
    const productCard = (product) => {
        const { price, partId, liked, id } = product;
        return price !== 0 || alwaysLiked ? (
            <ProductCard
                toggleLike={toggleLike}
                product={product}
                key={id}
                onAddToBasket={onAddToBasket}
                onRemoveFromBasket={onRemoveFromBasket}
                desktop={!isMobile()}
                liked={liked || alwaysLiked}
                isAccountCompleted={isAccountCompleted}
                redirectToSearch={redirectToSearch}
                onRemove={onRemove}
                restProp={restProp}
                showGroupCost={showGroupCost}
                quantity={product.qty}
                diagramRef={product.diagram_placement_ref}
            />
        ) : (
            <ObsoleteProduct
                product={product}
                key={partId}
                desktop={!isMobile()}
                onRemove={onRemove}
            />
        )
    };

    return (
        <div className='search-results-container'>
            {!loading && <SearchResultsHeader
                quantity={results.length}
                sortItems={sortValues}
                onSelectSort={changeSort}
                noItemsText={noItemsText}
                loading={loading}
                sectionName={sectionName}
                restProp={restProp}
                filterOnClick={filterOnClick}
                showNewSortFilter={showNewSortFilter}
                noItemsHTML={!results.length ? noItemsHTML : null}
            />}
            {loading ? <Loader /> :

                results.length > 0 ?
                    <div className={['search-results-list-wrapper', resultListWrapperClasses].join(' ')}>
                        {results.map(productCard)}
                    </div>
                    : null
            }
            {children}

            {!!results.length && totalRecords ? (
                <div className="pagination-wrapper">
                    <TablePager
                        skip={skip}
                        take={take}
                        pageSize={pageSize || 10}
                        onChange={onPageChange}
                        totalRecords={totalRecords}
                    /></div>) : null}

        </div>
    );
}

export default SearchResultsList;