import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ZoomChip from './ZoomChip/ZoomChip';
import ExampleDiagram from "../../../assets/img/example-ibasis-diagram.png";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import InnerImageZoom from 'react-inner-image-zoom';


export default function Diagram({ imgUrl }) {



    return (
        <Box
            sx={{
                borderRadius: 2,
                border: '1px solid #BACAD1',
                background: '#FFF',
                p: 3,
                position: 'relative',
                minHeight: 200,
            }}
        >
            <div>
                <InnerImageZoom
                    src={imgUrl}
                    zoomType="hover"
                    zoomScale={1.5}
                    zoomPreload={true}
                />
            </div>
        {/* <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'absolute',
                    left: '20%',
                    right: '20%',
                    bottom: '34px'
                }}
            >
                <ZoomChip />
            </Box>*/}
        </Box >
    );
};