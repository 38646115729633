import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import FeatureFlagContext from "../../Context/FeatureFlagContext";


const FooterShopLinks = (props) => {
    const { wrapperElementName, disableBuyTradeCheckout } = props;
    const featureFlagContext = useContext(FeatureFlagContext);
    const isAllProducts = featureFlagContext.checkFeatures("all-products");
    const isNewSpares = featureFlagContext.checkFeatures("check-features");
    const history = useHistory();

    const changeLocation = (placeToGo) => {
        if (window.location.pathname.startsWith(placeToGo)) {
            history.push(placeToGo);
            window.location.reload();
        }
    }

    return (<>
        {isAllProducts && <li><Link to="/products?part=3&origin=home" onClick={() => changeLocation('/products?part=3&origin=home')} >Find Products</Link></li>}
        {isNewSpares ? <li><Link to={isAllProducts ? "/Spares" : "/shop"} onClick={() => changeLocation(isAllProducts ? "/Spares" : "/shop")}>Find Spares</Link></li> : <li><Link to={isAllProducts ? "/shop-2" : "/shop"} >Find Spares</Link></li>}
        <li><Link to="/orderHistory" onClick={() => changeLocation('/orderHistory')}>Order History</Link></li>
        <li><Link to="/settings" onClick={() => changeLocation('/settings')}>My Account</Link></li>
    </>);
};

export default FooterShopLinks;