import React, {useEffect, useState} from "react";
import QtyMinus from "../../../../assets/img/QtyMinus";
import QtyPlus from "../../../../assets/img/QtyPlus";
import { useTheme } from '@mui/material/styles';


function QuantityButtons({ qty, increaseQty,decreaseQty}) {

    const theme = useTheme();

    return (

        <div className="quantityButtons">

            <button
                onClick={decreaseQty}
            >
                <QtyMinus
                    sx={{ color: '#009FDF' }}
                    viewBox="0 0 21 19"
                    fontSize="inherit"
                />
            </button>

            <span> {qty} </span>

            <button
                onClick={increaseQty}
            >
                <QtyPlus
                    sx={{ color: '#009FDF' }}
                    viewBox="0 0 21 19"
                    fontSize="inherit"
                />
            </button>
            
        </div>

    )
}




export default QuantityButtons;