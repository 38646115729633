import React from "react";


function NoItems({heading, body})
    {
        return (
            <div className="noItemsContainer">
                <h3 className="noItemsHeading">{heading}</h3>
                <p className="noItemsBody">{body}</p>
            </div>
        )
    }


export default NoItems;