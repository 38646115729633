import React, {useState, useEffect} from "react";
import {isMobile} from "../../../Media/Media";
import moment from "moment";
import {FULFILMENT_OPTION_TYPES} from "../../Constants/AvailabilityConstants";
import AvailabilityFulfilmentOptionLine from "./AvailabilityFulfilmentOptionLine/AvailabilityFulfilmentOptionLine";

const AvailabilityFulfilmentOptions = ({
                                           item, 
                                           allItemsCount,
    featureFlagContext,
    onFulfilmentTypeChanged,
    orderDetailsOnClick,
    byBoxEnabled
                                        })=>
{
    const isSaturdayCollection = (day) => day.includes('Sat')

    const [selectedOption, setSelectedOption] = useState(0);
    
    const sameDayAvailable = item.sameDayAvailableQty > 0;
    const nextDayAvailable = item.nextDayAvailableQty > 0;
    const nextDayDeliveryAvailable = item.nextDayDeliveryAvailableQty > 0;
    const futureDayAvailable = item.futureDayAvailableQty > 0;
    
    const processTime = (todaysDate, timeString)=>{
        return moment(`${todaysDate.toLocaleDateString()} ${timeString}`, 'dd/MM/yyyy hh:mm:ss')
    }

    const isExpress = item.branch?.isOpen && item.branch?.branchSameDayDeliveryTypes?.length > 0 && sameDayAvailable && moment(item.sameDayDateTime).isSame(moment(), 'day');
    const isByBox = item.branch?.supplier.name == "HRP Trade" && item.nextDayDeliveryAvailableQty > 0

    useEffect(() => {
        if (isExpress === true) {
            changeSelectedOptionTypeId(4);
        }
        else if (sameDayAvailable === true) {
            changeSelectedOptionTypeId(0);
        } else if (nextDayAvailable===true){
            changeSelectedOptionTypeId(1);
        } else if (nextDayDeliveryAvailable===true){
            changeSelectedOptionTypeId(2);
        }
        else if (isByBox) {
            changeSelectedOptionTypeId(5);
        } else if (futureDayAvailable === true) {
            changeSelectedOptionTypeId(3);
        } else {
            changeSelectedOptionTypeId(0);
        }
    },[]);
    
    const changeSelectedOptionTypeId = (newTypeId)=>{
        setSelectedOption(newTypeId);
        onFulfilmentTypeChanged(newTypeId);
    }

    const handleOrderDetailsOnClick = (optionType) => {
        changeSelectedOptionTypeId(optionType.id);
        orderDetailsOnClick()
    }
    
    const getText = (fulfilmentOptionType, availableFromText) => (fulfilmentOptionType.id === 0 || fulfilmentOptionType.id === 1)
        ? isSaturdayCollection(availableFromText) ? fulfilmentOptionType.description_saturday_collection : fulfilmentOptionType.description
        : fulfilmentOptionType.description;

    const getAvailabilityOptionLine = (isExpress, isByBox, availableQty, optionType, availableFromDate) => {
        return <AvailabilityFulfilmentOptionLine
            isExpress={isExpress}
            isByBox={isByBox}
            availableQtyText={availableQty >= allItemsCount ? 'All' : `${availableQty} of ${allItemsCount}`}
            fulfilmentOptionDescription={getText(optionType,availableFromDate)}
            availableFromText={availableFromDate}
            isSelected={optionType.id==selectedOption}
            onSelected={()=> {
                changeSelectedOptionTypeId(optionType.id);
            }
            }
            orderDetailsOnClick={handleOrderDetailsOnClick}
        />;
    }
        
    
    if (!sameDayAvailable && !nextDayAvailable && !nextDayDeliveryAvailable && !futureDayAvailable)
        return (<div>
            <label style={isMobile() ? { fontSize: 12 } : {}}>
                No items available
            </label>
        </div>)
    
   

    return (<div className="selectContainer">
        {isExpress &&
            getAvailabilityOptionLine(true, false, item.sameDayAvailableQty, FULFILMENT_OPTION_TYPES[4], null)}
        {sameDayAvailable &&
            getAvailabilityOptionLine(false, false, item.sameDayAvailableQty, FULFILMENT_OPTION_TYPES[0], moment(item.sameDayDateTime).format("ddd Do MMM"))}
        {nextDayAvailable &&
            getAvailabilityOptionLine(false, false, item.nextDayAvailableQty, FULFILMENT_OPTION_TYPES[1], moment(item.nextDayDateTime).format("ddd Do MMM"))}
        {nextDayDeliveryAvailable &&
            getAvailabilityOptionLine(false, false, item.nextDayDeliveryAvailableQty, FULFILMENT_OPTION_TYPES[2], moment(item.nextDayDeliveryDateTime).format("ddd Do MMM"))}
        {isByBox && byBoxEnabled && featureFlagContext.checkFeatures("buy-box") &&
            getAvailabilityOptionLine(false, true, item.nextDayDeliveryAvailableQty, FULFILMENT_OPTION_TYPES[5], moment(item.nextDayDeliveryDateTime).format("ddd Do MMM"))}
        {futureDayAvailable &&
            getAvailabilityOptionLine(false, false, item.futureDayAvailableQty, FULFILMENT_OPTION_TYPES[3], moment(item.futureDateDateTime).format("ddd Do MMM"))}
    </div>);
}

export default AvailabilityFulfilmentOptions;