import React from "react";
import DeleteModal from "../Modal/DeleteModal";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    deleteModal: {},
    Content: {
        padding: '44px 10px'
    },
    deleteTitle: {
        color: "#00226A",
        margin: 0,
        marginBottom: 8,
        padding: 0,
        fontWeight: '500 !important'
    },

    deleteDesc: {
        fontSize: 16,
        lineHeight: '19px',
        color: "#828282",
        margin: 0,
        padding: 0
    },
    buttons: {
        display: "flex",
        width: "100%",
        marginTop: 26,
    },
    button: {
        padding: '13px 20px',
        color: "#FFFFFF",
        fontSize: 14,
        lineHeight: ' 17px',
        borderRadius: '4px',
        fontWeight: 600,
        width: "50%",
    },
    CancelBTN: {
        background: '#CDCDCD',
        marginRight: 12
    },
    DeleteBTN: {
        background: '#ED1F45',
    },
    [`@media (max-width:768px)`]: {
        Content: {
            padding: '28px 0',
            margin: '0 -10px'
        },
        deleteTitle: {
            fontSize: 20,
            lineHeight: '24px',
            marginBottom: 4,
        },
        buttons: {
            marginTop: 15,
        },
        CancelBTN: {
            marginRight: 8
        },
    },
}));

const SavedCardDeletePopup = ({ onClose, onDelete }) => {
    const classes = useStyles();

    return (
        <DeleteModal
            className={classes.deleteModal}
            onClose={onClose}
        >
            <div className={classes.Content}>
                <h1 className={`${classes.deleteTitle} modal-popup-header`}>Remove saved card?</h1>
                <p className={classes.deleteDesc}>Are you sure you would like to remove this saved card from your account?</p>
                <div className={classes.buttons}>
                    <button
                        className={`${classes.button} ${classes.CancelBTN}`}
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className={`${classes.button} ${classes.DeleteBTN}`}
                        onClick={onDelete}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </DeleteModal>)
};

export default SavedCardDeletePopup;