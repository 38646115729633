import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from "../../assets/img/close-popup-icon.svg"


const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '90%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: '40px 30px',
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        borderRadius: 31,
        [theme.breakpoints.up('md')]: {
            width: 550
        }
    },
    closeButton: {
        position: 'absolute',
        right: 40,
        top: 40
    },
    heading: {
        fontWeight: '500',
        fontSize: 26,
        color: '#00226A',
        textTransform: 'normal',
        margin: 15
    },
    body: {
        fontWeight: '400',
        fontSize: 16,
        color: '#828282',
        margin: 15,
        paddingBottom: 15
    }
}));


export const CashBackModal = ({ modalOpen, handleClose, onRegister, modalId, emailSent }) => {

    const classes = useStyles();

    return (
        <Modal
            open={modalOpen}
            onClose={handleClose}
            id={modalId}
        >
            <section className={classes.paper}>

                <button className={classes.closeButton} onClick={handleClose}>
                    <img alt="close" src={CloseIcon} />
                </button>

                
                <h4 className={classes.heading}>
                    {emailSent ? 'Thank you' : 'Cash Back Available'}
                </h4>
                <p className={classes.body}>
                    {emailSent ?
                        `Your request has been sent and we will be in touch shortly.` :
                        (<span>Cash back is available on this product to members of the 
                        free TradeHelp Buying Group. If you are not yet a member 
                        of the Buying Group, please click below to register for your 
                        free membership (and accept the <a href='https://www.tradehelp.co.uk/tradehelp-buying-group-terms-conditions/' target="_blank">t&cs</a>). Claims for existing 
                        members will be managed automatically on your behalf, in 
                        accordance with normal Buying Group processes.</span>)
                    }
                </p>
                <div className="confirmation-footer">
                    {emailSent || 
                        <a className="btn" onClick={() => onRegister()}>
                            Register
                        </a>
                    }

                </div>
            </section>
        </Modal>

    )
}