//// <reference path="sparesfinderbasketdeliverythirdparty.jsx" />
import React, {useState, useEffect, useRef, useContext} from "react";
import RequestedProductsService from "./Services/RequestedProductsService";
import { isMobile, isTablet } from "./Components/Media/Media";
import RequestedProductDelete from './Components/Shop/RequestedProductDelete';
import SearchResultsList from "./Components/Shop/SearchResultsList";
import SearchButton from "./Components/Shop/SearchButton";
import FinderInput from "./Components/Shop/FinderInput";
import isPlural from './Helpers/common/isPlural';
import Header from "./Components/Header/Header";
import FeatureFlagContext from "./Context/FeatureFlagContext";
import Wrapper from "./Components/Layout/Wrapper";

const SELECT_VALUES = [
    { sortDir: "asc", text: "Product Name - A-Z", sortProp: "Description" },
    { sortDir: "desc", text: "Product Name - Z-A", sortProp: "Description" },
];

const RequestedProducts = ({ onAddToBasket, isAccountCompleted, basket }) => {
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [sortState, setSortState] = useState({
        sortDir: SELECT_VALUES[0].sortDir,
        sortProp: SELECT_VALUES[0].sortProp,
    });
    const [loading, setLoading] = useState(true);
    const [selectedRemoveProduct, setSelectedRemoveProduct] = useState(null);

    const mounted = useRef(false);

    const featureFlagContext = useContext(FeatureFlagContext);
    const isAllProducts=featureFlagContext.checkFeatures("all-products");
    
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true
        }
        else {
            searchRequestedProducts();
        }
    }, [sortState.sortDir, sortState.sortProp]);

    const handleSort = (option) => {
        let index = option.selected;
        setSortState({
            sortDir: SELECT_VALUES[index].sortDir,
            sortProp: SELECT_VALUES[index].sortProp,
        });
    };

    const onSearch = () => {
        searchRequestedProducts();
    };

    const searchRequestedProducts = () => {
        getRequestedProducts(searchText, sortState.sortDir, sortState.sortProp);
    };

    const removeProduct = (product) => {
        setSelectedRemoveProduct(product)
    };

    const removeProductFromList = () => {
        if (selectedRemoveProduct) {
            RequestedProductsService.removeProductFromRequestedProducts(selectedRemoveProduct.id).then((response) => {
                searchRequestedProducts();
                setSelectedRemoveProduct(null)
            });
        }
    };

    const getRequestedProducts = (searchText, sortDir, sortProp) => {
        setLoading(true);
        RequestedProductsService.searchRequestedProducts(searchText, sortDir, sortProp).then((response) => {
            setData(
                response ? response.map((product) => {
                    return {
                        id: product.id,
                        cost: product.cost,
                        mpn: product.manufacturerCode,
                        img_url: product.imageUrl,
                        description: product.description,
                        qty: basket?.lines.find((line) => line.manufacturerCode === product.manufacturerCode)?.qty ?? 0,
                        manid: product.iBasisManId,
                        app: product.iBasisAppliance,
                        appid: product.iBasisApplianceId,
                        pgcn: product.iBasisGCN,
                        appgc: product.iBasisApplianceGC,
                        createdOn: product.createdOn
                    };
                }) : []
            );
            setLoading(false);
        });
    };

    const handleAutoSearch = value => setSearchText(value);

    const handleClear = () => {
        setSearchText("");
    };

    useEffect(onSearch, [searchText]);

    const finderInput = () => (
        <FinderInput
            searchTerm={searchText}
            placeholder="Search Requested Products"
            onChange={handleAutoSearch}
            onClear={handleClear}
        />
    );

    return (
        <div >
            <Wrapper
                wrapperClass=""
                text="RequestedProducts"
                selected="Requested Products"
                basket={basket}
            >


            {!isAllProducts ?
                <>
                    {isMobile() ?
                        <div className="order_history_search order_history_search_mobile">
                            <div className={`order_history_search_box ${isTablet() ? "bg-max-width-tablet" : ""}`}>
                                <div className="finder-inputs-wrapper">
                                    {finderInput()}
                                </div>
                            </div>
                        </div>
                        :
                        <div className={"sp-desktop-spares-finder"}>
                            <div className={"max-width-1300 bg-gray-container full-width-fav"}>
                                <div className="finder-inputs-wrapper no-margin-left" data-columns='2'>
                                    {finderInput()}
                                    <SearchButton loading={loading} onSearch={onSearch} />
                                </div>
                            </div>
                        </div>
                    }
                </>

                :


                <>
                    {isMobile() ?
                        <div style={{ marginTop: -50 }}>
                            <Header
                                body="Requested Products"
                            />
                            <div style={{
                                padding: 20,
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: '#fff',
                            }}>
                                {finderInput()}
                                <SearchButton loading={loading} onSearch={onSearch} />
                            </div>
                        </div>
                        :
                        <>
                            <Header
                                body="Requested Products"
                            />
                            <div className="finder-inputs-wrapper no-margin-left" data-columns='2'>
                                {finderInput()}
                                <SearchButton loading={loading} onSearch={onSearch} />
                            </div>
                        </>
                    }
                </>

            }


            <div className={isTablet() ? 'results-wrapper-tablet' : ''}>
                <SearchResultsList
                    results={data}
                    loading={loading}
                    onAddToBasket={onAddToBasket}
                    alwaysLiked
                    changeSort={handleSort}
                    sortValues={SELECT_VALUES}
                    sectionName='Requested products'
                    noItemsText='No items have been added to the Requested Products'
                    redirectToSearch
                    isAccountCompleted={isAccountCompleted}
                    showNewSortFilter={false}
                    onRemove={removeProduct}
                    restProp={{
                        headerText: `${data.length || 0} requested ${isPlural(data.length, 'product')} (please see your emails for updates)`,
                        specialButton: {
                            page: 'requestedProducts',
                            text: `Requested on `
                        }
                    }}
                />
            </div>
            {selectedRemoveProduct &&
                <RequestedProductDelete
                    onClose={() => setSelectedRemoveProduct(null)}
                    onDelete={removeProductFromList}
                />
            }
            </Wrapper>
        </div>
    );
};
export default RequestedProducts;