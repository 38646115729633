import { Box, Typography } from '@mui/material';

export const PromoAlert = ({ contents }) => {
    return (
        <Box
            sx={{
                background: '#47E3CA',
                textAlign: 'center',
                p: 1.7,
                marginTop: { xs: '83px', md: 0 },
            }}>
            <Typography textAlign='center' fontSize={'1.5rem'}
                dangerouslySetInnerHTML={{
                    __html: contents
                }}>
            </Typography>
        </Box>
    );
};
