import http from '../utils/http';

const authService = {
    isAuthenticated: false,
    authenticate(callBack)
    {
        http.get(`Account/Authenticate`).then((response) => {
            this.isAuthenticated = response;

            if (!response)
                window.location.replace("/bff/login");

            http.get('Account/UserDetails').then(userDetails => {
                    if (userDetails.roleName != "CompanyAdmin")
                        window.location.replace("/account/logout");
            })
            
            callBack();
        });
    },    
    signOut(callBack) 
    {
        this.isAuthenticated = false;
        callBack && callBack();
    }
}

export default authService;