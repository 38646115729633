import React, { useState, useEffect } from "react";
import SelectListStaticWithLabel from "../Form/SelectListStaticWithLabel";
import ProductSearchService from "../../Services/ProductSearchService";
import { isMobile, isTablet } from "../Media/Media";
import SearchButton from "../Shop/SearchButton";
import Grid from '@mui/material/Grid';


function DiagramSearchComp({ onSearch, isLoading, getDiagrams }) {

    const [gcSearchObj, setGcSearchObj] = useState({});
    const [manufacturers, setManufacturers] = useState([]);
    const [filterValuesManufacturer, setFilterValuesManufacturer] = useState(-1);
    const [appliances, setAppliances] = useState([]);
    const [filterValuesAppliance, setFilterValuesAppliance] = useState(-1);
    const [filterValuesModel, setFilterValuesModel] = useState(-1);
    const [filterValuesModelContent, setFilterValuesModelContent] = useState(-1);
    const [filterValuesPartsListSection, setFilterValuesPartsListSection] = useState(-1);
    const [searchResultCount, setSearchResultCount] = useState(3);
    const [modelContentSelect, setModelContentSelect] = useState(false);
    const [manufacturerNotSpecified, setManufacturerNotSpecified] = useState(false);
    const [applianceNotSpecified, setApplianceNotSpecified] = useState(false);



    const loadAppliances = () => {
        ProductSearchService.appliances(filterValuesManufacturer).then((response) => {
            const sortedData = (response.data || []).sort((a, b) => {
                if (a.app < b.app) {
                    return -1;
                }
                if (a.app > b.app) {
                    return 1;
                }
                return 0;
            });
            setAppliances(sortedData)
        });
    }

    const fetchManufacturers = () => {
        
    };

    const handleSelectManufacturer = ({ value }) => {
        setManufacturerNotSpecified(false);
        setFilterValuesAppliance(-1);
        setFilterValuesModel(-1);
        setFilterValuesModelContent(-1);
        setFilterValuesManufacturer(value);
    };

    const getAppliance = ({ value }) => {
        setApplianceNotSpecified(false);
        setFilterValuesAppliance(value);
        setFilterValuesModel(-1);
        setFilterValuesModelContent(-1);
    };

    const handleSearch = () => {
        if (!filterValuesManufacturer) return setManufacturerNotSpecified(true);
        if (!filterValuesAppliance) return setApplianceNotSpecified(true);
        setManufacturerNotSpecified(false);
        setApplianceNotSpecified(false);
        onSearch &&
            onSearch(
                filterValuesManufacturer,
                filterValuesAppliance,
                manufacturers.find(manufacturer => manufacturer.manid === filterValuesManufacturer).man,
                appliances.find(appliance => appliance.appid === filterValuesAppliance).app,
                getDiagrams,
            );
    };

    const onViewManual = () => {
        if (!filterValuesManufacturer) {
            return setManufacturerNotSpecified(true);
        }
        if (!filterValuesAppliance) {
            return setManufacturerNotSpecified(true);
        }

        ProductSearchService.applianceDocumentInTab(filterValuesAppliance);
    }

    const downloadFile = (url) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.click();
    }

    const applianceDocumentExists = () => {
        if (filterValuesAppliance) {
            const appliance = appliances.find(({ appid }) => filterValuesAppliance === appid);
            return appliance.extras !== "" && appliance.extras !== null;
        } else {
            return false;
        }
    }

    useEffect(() => {
        fetchManufacturers();
    }, [])

    useEffect(() => {
        loadAppliances();
    }, [filterValuesManufacturer])

    return (
        <div className='search-fields-wrapper'>
            <>
                <Grid container className='search-fields-new' spacing={2}>
                    <Grid item xs={12} md={4}>
                        <SelectListStaticWithLabel
                            placeholder="Manufacturer"
                            valueProperty="manid"
                            textProperty="man"
                            collection={manufacturers}
                            onChange={handleSelectManufacturer}
                            value={filterValuesManufacturer}
                            outlined={!filterValuesManufacturer && manufacturerNotSpecified}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SelectListStaticWithLabel
                            placeholder="Appliance"
                            valueProperty="appid"
                            textProperty="app"
                            collection={appliances}
                            onChange={getAppliance}
                            value={filterValuesAppliance}
                            outlined={!filterValuesAppliance && applianceNotSpecified}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SearchButton loading={isLoading} onSearch={handleSearch} />
                    </Grid>
                </Grid>

                <div
                    className='selected-appliance-text'
                    style={{ opacity: filterValuesAppliance ? 0 : 0 }}
                >
                    <p>
                        You have
                        selected: {appliances.find(({ appid }) => filterValuesAppliance === appid)?.app}
                    </p>

                </div>
            </>
        </div>
    );
}

export default DiagramSearchComp;
