import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ModalWindow from "../Modal/ModalWindowMobile";
import OrderDetails from "./OrderDetails";
import moment from "moment";
import getTotalQty from "../../Helpers/common/getTotalQty";
import formatPrice from "../../Helpers/price/formatPrice";

const useStyles = makeStyles({
  orderCard: {
    height: 195,
    borderBottom: "1px solid #BACAD1",
    background: "white",
    display: "flex",
    justifyContent: "space-between",
    padding: "30px 25px",
    margin: "20px 30px",
    position: "relative",
    "& p": {
      color: "#00226A",
      fontSize: 13,
      lineHeight: 1.3,
    },
    "&:first-of-type": {
      borderTop: "1px solid #BACAD1",
    }
  },
  reOrderBtn: {
    minWidth: "105px",
    width: "100%",
    fontSize: "14px",
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  orderTitle: {
    fontSize: "16px",
  },
  price: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#81C454",
  },
  date: {
    fontWeight: 500,
    fontSize: "12px",
    color: "#454545",
    opacity: "50%",
  },
  details: {
    color: "#3EC4F4",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
  },
  orderHistoryItemLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingRight: "10px",
    "& p": {
      margin: 0,
    },
    "& a": {
      marginTop: 0
    }
  },
  '@media (min-width: 640px) and (max-width: 900px)': {
    orderCard: {
      margin: "20px",
      minWidth: '95%'
    },
  }
});

export default function ShortOrderCard(props) {
  const { order, setDesktopItem, onReorder, downloadInvoice, setItemForReturn } = props;
  const classes = useStyles();

  const formatDate = (date) => {
    return [moment(date).format("DD MMM YYYY"), moment(date).format("HH:mm")];

  };


  return (
    <div className={classes.orderCard}>
      <div className={classes.orderHistoryItemLeft}>
        <div>
          {
            <p>
              Order:{" "}
              <a className="link-like" onClick={() => setDesktopItem(order)}>
                {order.customerOrderNo ?? "-"}
              </a>
              {" "} ({getTotalQty(order)})
            </p>
          }
          <p>{formatDate(order.orderDate)[0]} at {formatDate(order.orderDate)[1]}</p>
        </div>
        <div>
          <p style={{ padding: '10px 0' }}>{order.selectedBranch?.name ?? "N/A"}</p>
        </div>
        <div>
          <p>Total cost</p>
          <p style={{ fontWeight: 600 }}>
            {formatPrice(order.totalCostWithVat)}
          </p>
        </div>
      </div>
      <div className="order_history_item_right" style={{ width: 150, marginTop: 3 }}>
        <img
          src={order.selectedBranch?.supplier.imageUrl ?? "N/A"}
          alt="supplier"
          width="45%"
        />
        <a
          className="link"
          onClick={() => setItemForReturn(order)}
          style={{ marginTop: 5 }}
        >
          Return
        </a>
        <a
          className="link"
          onClick={onReorder}
          style={{ marginTop: 5 }}
        >
          Reorder
        </a>
        {order.paymentMethodTypeId == 1 && <a
          className="link"
          onClick={downloadInvoice}
          style={{ marginTop: 5 }}
        >
          Download Invoice
        </a>}
      </div>
      <ModalWindow id="view-details">
        <OrderDetails order={order} />
      </ModalWindow>
    </div>
  );
}
