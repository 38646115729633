import React, { useContext, useEffect, useState } from "react";
import Wrapper from "./Components/Layout/Wrapper";
import { isMobile, isTablet } from "./Components/Media/Media";
import SavedFavouritesCont from "./Components/Shop/SavedFavouritesCont";
import BasketService from "./Services/BasketService";
import FeatureFlagContext from "./Context/FeatureFlagContext";
import _ from "lodash";
import AccountService from "./Services/AccountService";
import ReactGA from "react-ga4";
import configurationService from './Services/ConfigurationService'

const FavouriteProducts = () => {
    const [basket, setBasket] = useState(null);
    const [newBasketItem, setNewBasketItem] = useState(null);
    const [isAccountCompleted, setIsAccountCompleted] = useState(false);

    const featureFlagContext = useContext(FeatureFlagContext);

    const isAllProducts = featureFlagContext.checkFeatures("all-products");

    const addToBasket = (basketItem) => {
        let basketCopy = _.cloneDeep(basket);
        const interval = setInterval(() => {
            setNewBasketItem(null);
            clearInterval(interval);
        }, 3000);

        setNewBasketItem(basketItem);

        const newItem = {
            qty: basketItem.quantityChildValue,
            manufacturerCode: basketItem.item.mpn,
            description: basketItem.item.description,
            imageUrl: basketItem.item.img_url,
            iBasisManId: basketItem.item.manid,
            iBasisAppliance: basketItem.item.app || basketItem.item.iBasisAppliance,
            iBasisApplianceId:
                basketItem.item.appid || basketItem.item.iBasisApplianceId,
            iBasisGCN: basketItem.item.pgcn || basketItem.item.iBasisGCN,
            IBasisApplianceGC:
                basketItem.item.appgc || basketItem.item.IBasisApplianceGC,
            cost: basketItem.item.price,
            manufacturer: basketItem.item.man,
            basketLineTypeId: 1
        };

        const currentIndex = basketCopy.lines
            .map((item) => item.manufacturerCode)
            .indexOf(newItem.manufacturerCode);

        if (currentIndex > -1) {
            basketCopy.lines.splice(currentIndex, 1);
        }

        basketCopy.lines.push(newItem);

        basketCopy.lines = basketCopy.lines.filter(({ qty }) => qty !== 0);

        updateBasket(basketCopy);

        configurationService.getConfigurationVariables().then(res => {
            if (res?.enableGoogleAnalytics) {
                //GA add item to basket event
                ReactGA.event("add_to_cart", {
                    //GA parameters
                    currency: "GBP",
                    value: basketItem.item.price * basketItem.quantityChildValue,
                    items: [
                        {
                            //GA parameters
                            item_id: basketItem.item.mpn || basketItem.item.manufacturerCode,
                            item_name: basketItem.item.description,
                            index: currentIndex > -1 ? currentIndex : basketCopy.lines.length - 1,
                            price: basketItem.item.price,
                            quantity: basketItem.quantityChildValue,

                            //custom parameters
                            manufacturer: basketItem.item.man,
                            manufacturer_id: basketItem.item.manid,
                            location: "Favorite Products",
                            is_liked: basketItem.item.liked
                        }
                    ],

                    //custom parameters
                    basket_key: basketCopy.basketKey,
                    organisation_id: basketCopy.organisationId
                })
            }
        });
    };

    const updateBasket = (basketCopy) => {
        BasketService.updateBasketForUser(basketCopy).then((basket) => {
            if (basket.lines == null) basket.lines = [];
            setBasket(basket);
        });
    };

    const removeFromBasket = (basketItem) => {
        let basketCopy = _.cloneDeep(basket);

        const newItem = {
            qty: basketItem.quantityChildValue,
            manufacturerCode: basketItem.item.mpn,
            description: basketItem.item.description,
            imageUrl: basketItem.item.img_url,
            iBasisManId: basketItem.item.manid,
            iBasisAppliance: basketItem.item.app || basketItem.item.iBasisAppliance,
            iBasisApplianceId:
                basketItem.item.appid || basketItem.item.iBasisApplianceId,
            iBasisGCN: basketItem.item.pgcn || basketItem.item.iBasisGCN,
            IBasisApplianceGC:
                basketItem.item.appgc || basketItem.item.IBasisApplianceGC,
            cost: basketItem.item.price,
            manufacturer: basketItem.item.man,
            basketLineTypeId: 1
        };

        const currentIndex = basketCopy.lines
            .map((item) => item.manufacturerCode)
            .indexOf(newItem.manufacturerCode);

        if (currentIndex > -1) {
            basketCopy.lines.splice(currentIndex, 1);
        }

        basketCopy.lines.push(newItem);

        basketCopy.lines = basketCopy.lines.filter(({ qty }) => qty !== 0);

        updateBasket(basketCopy);
    };

    const checkAccount = () => {
        return AccountService.isAccountCompleted().then((response) => {
            setIsAccountCompleted(response);
        });
    };

    const getBasket = () => {
        return BasketService.getBasketForUser().then((response) => {
            if (response.lines == null) response.lines = [];
            setBasket(response);
        });
    };

    useEffect(() => {
        getBasket();
        checkAccount();
    }, []);

    return (<>
        <Wrapper
            wrapperClass=""
            text="SparesFinder"
            selected="New search"
            basket={basket}
        >


            {!isTablet() && !isMobile() ?
                <div className="template__wrapper sp-desktop-spares-finder max-width-1300">



                    <SavedFavouritesCont
                        basket={basket}
                        onAddToBasket={addToBasket}
                        onRemoveFromBasket={removeFromBasket}
                        isAccountCompleted={isAccountCompleted}
                    />
                </div>
                :

                <div className={"sp-mobile-spares-finder"} style={{ paddingTop: 77 }}>


                    <div style={{ backgroundColor: "#F3F9FC" }}>

                    </div>

                </div>
            }
        </Wrapper>


    </>);
}

export default FavouriteProducts;