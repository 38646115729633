import React from 'react';

const AddressLine = (props) => {
    const addresses = props.data.address.join(', ');

    const addressClick = () => {
        props.addressSelected(props.data);
    }

    return (
        <li className="autocomplete-option">
            <a onClick={addressClick}>
                {addresses + ', ' +
                    props.data.city +
                    ', ' +
                    (props.data.county ? props.data.county + ', ' : '') +
                        props.data.postcode}
            </a>
        </li>
    )
}

export default AddressLine;
