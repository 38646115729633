import http from '../utils/http';

const getData = () => {
    return http.get(`OrganisationSupplier/GetData`);
}

const create = (organisationSupplier) => {
    return http.post(`OrganisationSupplier/Create`, organisationSupplier);
}

const update = (organisationSupplier) => {
    return http.post(`OrganisationSupplier/Update`, organisationSupplier);
}

const deleteOrganisationSupplier = (id) => {
    const params = new URLSearchParams();
    params.append('id', id);

    return http.get(`OrganisationSupplier/Delete`, { params });
}

const listSuppliers = () => {
    return http.get(`OrganisationSupplier/ListSuppliers`);
}

const listTypes = () => {
    return http.get(`OrganisationSupplier/ListTypes`);
}

const listPaymentMethods = () => {
    return http.get(`PaymentMethodType/GetAll`);
}

const updatePaymentMethodType = (supplierId, methodId) => {
    return http.post(`OrganisationSupplier/UpdatePaymentMethodType`, { id: supplierId, defaultPaymentMethodTypeId: methodId });
}

const listBranches = (supplierID) => {
    const params = new URLSearchParams();
    params.append('SupplierID', supplierID);

    return http.get(`OrganisationSupplier/ListBranches`, { params });
}

const sendValidationFailedEmail = (validationFailedModel) => {
    return http.post(`OrganisationSupplier/sendValidationFailedEmail`, validationFailedModel);
}

const createMerchantAccount = (organisationSupplier) => {
    return http.post(`OrganisationSupplier/CreateMerchantAccount`, organisationSupplier);
}

export default {
    getData,
    create,
    update,
    deleteOrganisationSupplier,
    listSuppliers,
    listTypes,
    listBranches,
    listPaymentMethods,
    updatePaymentMethodType
};