import React from 'react';
import { Box, Grid } from '@mui/material';
import {isMobile} from "../../Media/Media";
import usePartsIdentificationStore from "../../PartsIdentification/Store/PartsIdentificationStore";

export default function CategoryButton({ title, number, isActive, onSelect }) {

    const {
        likedProducts
    } = usePartsIdentificationStore();
    
    const isLiked = () => {
        return likedProducts.filter(item  => item.mpn===number).length>0;
    }
    
    return (
        <button
            onClick={onSelect}
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: 2,
                fontSize: 14,
                border: '1px solid ' + (isActive ? '#009FDF' : '#BACAD1'),
                background: isActive ? '#009FDF' : '#FFF',
                padding: '17px 20px',
                color: isActive ? '#FFF' : '#009FDF',
                width: '100%',
                transition: 'none',
                '&:hover': {
                    cursor: 'pointer',
                    background: isActive ? '#009FDF' : '#E5F5FC'
                }
            }}
        >
            <Box>
                {title}
            </Box>
            <Box sx={{
                color: isActive? '#FFF': '#454545',
                fontWeight: 700
            }}>
                {number}
            </Box>
        </button>
    );
};