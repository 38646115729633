import React from "react";

class IBasisTablePager extends React.Component {
  render() {
    return (
      <div className="section template__section">
          {this.props.pageNumber > 1 && 
            <a onClick={this.onPrevious.bind(this)} className="pager-link">
              Previous
            </a>
          }
        {
          (this.props.pageNumber * 52 < this.props.totalRecords) && (
            <a onClick={this.onNext.bind(this)} className="pager-link">
              Next
            </a>
          )
        }
        Showing {((this.props.pageNumber - 1) * 52) + 1} to{' '}
        {this.props.totalRecords > (this.props.pageNumber * 52)
            ? this.props.pageNumber * 52
            : this.props.totalRecords}{' '}
        of {this.props.totalRecords}
      </div>
    );
  }

  onNext() {
    var pageNumber = this.props.pageNumber + 1
    this.props.onChange(pageNumber);
  }

  onPrevious() {
    var pageNumber = this.props.pageNumber - 1
    this.props.onChange(pageNumber);
  }
}

export default IBasisTablePager;
