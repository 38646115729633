import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../assets/img/header-logo.svg';
import MegaMenuService from '../../Services/MegaMenuService';
import BannerService from '../../Services/BannerService';
import AutoCompleteSearchBar from './AutoCompleteSearchBar/AutoCompleteSearchBar';
import FeatureFlagContext from '../../Context/FeatureFlagContext';
import { PromoAlert } from './PromoAlert/PromoAlert';

const useStyles = makeStyles(() => ({
    countNums: {
        position: 'absolute',
        color: '#ffffff',
        fontWeight: 600,
        fontSize: 11,
        background: 'linear-gradient(90deg, #00C9FF 0%, #25D6E4 64.12%, #92FE93 126.92%)',
        padding: '0.5px',
        minWidth: 19,
        minHeight: 19,
        textAlign: 'center',
        borderRadius: '50%',
    },
    notificationTotalCount: {
        top: -6,
        left: 19,
    },
    basketTotalCount: {
        bottom: '14px',
        right: '42%',
        display: 'flex',
        justifyContent: 'center',
    },
    headerMiddle: {
        display: 'grid',
        gridTemplateColumns: '212px minmax(300px, 507px) 1fr',
        alignItems: 'center',
        padding: '40px 0 10px 0',

        '& div': {
            '& input': {
                border: '1px solid #009fdf',
                width: '-webkit-fill-available',
                height: 48,
                padding: '12px 25px',
            },
        },
    },
    headerButtons: {
        display: 'grid',
        gridTemplateColumns: '60px 60px 55px auto',
        alignItems: 'center',
        justifyContent: 'end',
        marginLeft: 18,

        '& > div': {
            position: 'relative',
        },
    },
    checkBtn: {
        marginLeft: 18,
    },
    headerPartCategories: {
        width: '100%',
        height: 70,
        background: '#009FDF',
        position: 'relative',

        '&::before': {
            background: '#009FDF',
            content: '""',
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            height: '100%',
            width: '101vw',
        },
    },
    categoriesItems: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        padding: 0,
        margin: 0,
    },
    categoriesItem: {
        height: '100%',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '19px',
        textTransform: 'uppercase',
        listStyle: 'none',
        position: 'relative',
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        padding: '20px',
        color: '#fff',

        '& a': {
            color: '#fff',
            textDecoration: 'none',
        },
    },
    categoriesItemHover: {
        backgroundColor: '#fff',
        color: '',
    },
    SubCategoryContainer: {
        position: 'absolute',
        background: '#ffffff',
        boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.05)',
        width: '100%',
        zIndex: 20,
        '&::before': {
            background: '#ffffff',
            content: '""',
            position: 'absolute',
            top: 0,
            left: '45%',
            transform: 'translateX(-50%)',
            height: '100%',
            width: '110vw',
            zIndex: -1,
        },
    },
    subCategoriesList: {
        display: 'flex',
        height: '400px',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        gap: '40px 60px',
        flexWrap: 'wrap',
        padding: 0,
        margin: 0,
        overflowY: 'auto',
    },
    subCategoryItem: {
        textTransform: 'uppercase',
        fontWeight: '600',
        fontSize: '14px',
        listStyle: 'none',
        cursor: 'pointer',
        userSelect: 'none',
        marginTop: 14,
        maxWidth: 235,
        '&>a, &>p': {
            height: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            textDecoration: 'none',
            color: '#00226A',
            margin: 0,
        },
    },
    subCategoryItemList: {
        padding: 0,
    },
    subCategoryListItem: {
        fontWeight: '400',
        textTransform: 'initial',
    },
}));

const HeaderBar = (props) => {
    // const [searchText, setSearchText] = useState('');
    // const [options, setOptions]=useState([]);
    const classes = useStyles();
    const featureFlagContext = useContext(FeatureFlagContext);
    const history = useHistory();

    const isAllProducts = featureFlagContext.checkFeatures('all-products');
    const showBanner = featureFlagContext.checkFeatures('banner-display');

    let showNewNavigationBar = isAllProducts || props.newComponent === true;

    const generateClassName = (showKey) => {
        const { pathname, search } = window.location;
        const activeLink = showNewNavigationBar ? pathname + search : props.selected;

        return showKey === activeLink ? 'header_link_active header-nav-fixed-width' : 'header_link header-nav-fixed-width';
    };

    const goToCheckout = () => {
        if (props.basketCount) history.push('/Basket/Availability');
        else history.push('/Basket');
    };

    /*   const headerPartCategoriesArr = [
        { id: 1, text: 'boilers', url: '/products?q=boilers' },
        { id: 2, text: 'cylinders', url: '/cylinders' },
        { id: 3, text: 'radiators', url: '/radiators' },
        { id: 4, text: 'heating', url: '/heating' },
        { id: 5, text: 'plumbing', url: '/plumbing' },
        { id: 6, text: 'electrical', url: '/electrical' },
        { id: 7, text: 'spares', url: '/spares' },
      ] */

    const [allClassifications, setAllClassifications] = useState([]);
    const [bannerText, setBannerText] = useState(undefined);
    const [selectedParent, setSelectedParent] = useState(null);

    const isNewSpares = featureFlagContext.checkFeatures('new-spares');

    useEffect(() => {
        GetAllClassifications();
        getBannerText();
    }, []);

    useEffect(() => {
        setSelectedParent(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.search]);

    const GetAllClassifications = () => {
        MegaMenuService.GetAllClassifications().then((response) => {
            if (response && response.collection) {
                setAllClassifications(response.collection || []);
            }
        });
    };

    const getBannerText = () => {
        BannerService.getBannerText().then((response) => {
            if (response) {
                setBannerText(response);
            }
        })
    };

    const checkSubMenu = (parentId) => {
        const subCollection = allClassifications.filter((el) => el.parentId === parentId);
        return subCollection || [];
    };

    const changeLocation = (placeToGo) => {
        if (window.location.pathname.startsWith(placeToGo)) {
            history.push(placeToGo);
            window.location.reload();
        }
    };

    return (
        <header className='header main template'>
            <div className='header__top'>
                <div className='max-width-1300 header__top--content'>
                    {isAllProducts && !props.disableBuyTradeCheckout ? (
                        <Link to={'/settings'} className={'header__logout'} style={{ marginRight: 22 }}>
                            My Account
                        </Link>
                    ) : (
                        <></>
                    )}
                    <a href='/account/logout' className='header__logout'>
                        Logout
                    </a>
                </div>
            </div>
            <div className='header__wrapper' style={showNewNavigationBar ? {} : { height: '170px' }}>
                <div className='max-width-1300 header__wrapper--content'>
                    <div className={classes.headerMiddle}>
                        <Link to={isAllProducts ? '/homepage-2' : '/'}>
                            <img src={logo} alt='buytrade' />
                        </Link>
                        <div className='header__search'>
                            <AutoCompleteSearchBar />

                            {/*<Autocomplete
                  id="combo-box-demo"
                  options={options}
                  onInputChange={onChangeAutoComplete}
                  getOptionLabel={(option) => option.name}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                      <TextField {...params} label="Combo box" variant="outlined" />
                  )}
              />*/}
                        </div>
                        <div className={classes.headerButtons}>
                            {showNewNavigationBar ? (
                                <div style={props.disableBuyTradeCheckout ? { display: 'none' } : {}}>
                                    <Link to='/settings'>
                                        <svg width='22' height='22' viewBox='0 0 28 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                            <path
                                                d='M21.4718 17.8074C23.1606 15.9848 24.1973 13.5435 24.1973 10.8681C24.1973 5.23309 19.6157 0.634766 13.9639 0.634766C8.31217 0.634766 3.71384 5.23309 3.71384 10.8681C3.71384 13.5435 4.75055 15.9681 6.43939 17.8074C2.5768 20.3323 0.168945 24.5962 0.168945 29.3283C0.168945 30.248 0.921399 31.0005 1.84107 31.0005C2.76073 31.0005 3.51319 30.248 3.51319 29.3283C3.51319 25.332 5.78727 21.7536 9.33216 19.9979C10.72 20.7002 12.2918 21.1182 13.9639 21.1182C15.6361 21.1182 17.1911 20.7169 18.579 19.9979C22.1406 21.7536 24.4147 25.3153 24.4147 29.3283C24.4147 30.248 25.1671 31.0005 26.0868 31.0005C27.0065 31.0005 27.7589 30.248 27.7589 29.3283C27.7589 24.5795 25.3511 20.2989 21.4718 17.8074ZM7.05808 10.8681C7.05808 7.07243 10.1515 3.97901 13.9472 3.97901C17.7429 3.97901 20.8363 7.07243 20.8363 10.8681C20.8363 14.6638 17.7429 17.7573 13.9472 17.7573C10.1515 17.7573 7.05808 14.6638 7.05808 10.8681Z'
                                                fill='#232F5E'
                                            />
                                        </svg>
                                    </Link>
                                </div>
                            ) : (
                                <div />
                            )}
                            <div>
                                <Link to='/Notifications'>
                                    <svg width='23' height='24' viewBox='0 0 23 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            d='M11.4888 27.5234C10.5189 27.5234 9.71631 26.7375 9.71631 25.751H6.37207C6.37207 28.5769 8.66288 30.8677 11.4888 30.8677C14.3146 30.8677 16.6054 28.5769 16.6054 25.751H13.2612C13.2612 26.7208 12.4753 27.5234 11.4888 27.5234Z'
                                            fill='#232F5E'
                                        />
                                        <path
                                            d='M21.438 17.3894L21.3544 17.2891C21.0868 16.9881 20.8527 16.754 20.5852 16.5199L20.1003 16.035V11.7711C20.1003 7.64095 17.3078 4.17966 13.5623 3.15967V2.85869C13.5623 1.63804 12.5757 0.634766 11.3384 0.634766C10.101 0.634766 9.11445 1.62132 9.11445 2.85869V3.15967C5.3689 4.16294 2.57646 7.62423 2.57646 11.7711V16.035L2.09155 16.5199C1.82401 16.754 1.58991 16.9881 1.23876 17.3894C0.101723 18.8943 -0.0153217 19.6133 0.0013995 21.0012C0.0013995 23.0077 1.47286 24.6464 3.27875 24.6464H19.3813C21.1872 24.6464 22.6586 23.0077 22.6586 20.9176C22.6586 19.63 22.5416 18.8943 21.4045 17.3894H21.438ZM19.2642 21.3022H3.42924C3.42924 21.3022 3.36236 21.1182 3.36236 20.9009C3.36236 20.1818 3.36236 20.1651 3.84727 19.4963C4.01449 19.3123 4.13153 19.1786 4.29875 19.0448L5.9207 17.3894V11.7544C5.9207 8.67766 8.34527 6.16948 11.3384 6.16948C14.3315 6.16948 16.756 8.67766 16.756 11.7544V17.3894L18.2442 18.911L18.378 19.0448C18.5285 19.1619 18.6455 19.2956 18.796 19.4461C19.3311 20.1651 19.3311 20.1986 19.3311 20.9845C19.3311 21.135 19.2977 21.2353 19.2642 21.2854V21.3022Z'
                                            fill='#232F5E'
                                        />
                                    </svg>
                                </Link>
                                <span className={[classes.countNums, classes.notificationTotalCount].join(' ')}>
                                    {props.unreadNotificationCount}
                                </span>
                            </div>
                            <div id='basket-icon'>
                                <Link to='/Basket'>
                                    <span className={[classes.countNums, classes.basketTotalCount].join(' ')}>{props.basketCount}</span>
                                    <svg width='28' height='28' viewBox='0 0 37 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            d='M36.2683 7.05473C35.9673 6.58653 35.4322 6.28555 34.8637 6.28555H30.8004C29.8808 6.28555 29.1283 7.03801 29.1283 7.95767C29.1283 8.87734 29.8808 9.62979 30.8004 9.62979H32.2886L27.8241 19.6458H13.4271L7.74192 1.80427C7.52455 1.10198 6.87241 0.633789 6.1534 0.633789H1.67212C0.752454 0.633789 0 1.38624 0 2.30591C0 3.22557 0.752454 3.97803 1.67212 3.97803H4.93276L10.618 21.8195C10.8353 22.5218 11.4875 22.99 12.2065 22.99H28.9109C29.5631 22.99 30.165 22.6054 30.4326 22.0035L36.3853 8.64324C36.6194 8.12489 36.5693 7.52292 36.2683 7.05473Z'
                                            fill='#232F5E'
                                        />
                                        <path
                                            d='M12.6411 30.3798C14.1094 30.3798 15.2998 29.1895 15.2998 27.7212C15.2998 26.2528 14.1094 25.0625 12.6411 25.0625C11.1728 25.0625 9.98242 26.2528 9.98242 27.7212C9.98242 29.1895 11.1728 30.3798 12.6411 30.3798Z'
                                            fill='#232F5E'
                                        />
                                        <path
                                            d='M28.5425 30.3798C30.0108 30.3798 31.2011 29.1895 31.2011 27.7212C31.2011 26.2528 30.0108 25.0625 28.5425 25.0625C27.0741 25.0625 25.8838 26.2528 25.8838 27.7212C25.8838 29.1895 27.0741 30.3798 28.5425 30.3798Z'
                                            fill='#232F5E'
                                        />
                                    </svg>
                                </Link>
                            </div>
                            <button
                                className={[
                                    classes.checkBtn,
                                    `header__checkout button-large button-light-blue button-height-auto`,
                                ].join(' ')}
                                id='checkout-button'
                                onClick={goToCheckout}
                                disabled={props.disableBuyTradeCheckout}>
                                Checkout
                            </button>
                        </div>
                    </div>
                    <div className='header__bottom'>
                        {showNewNavigationBar ? (
                            <ul className='bottom__list'>
                                <li style={props.disableBuyTradeCheckout ? { display: 'none' } : {}}>
                                    <Link
                                        to={'/OrderHistory'}
                                        onClick={() => changeLocation('/OrderHistory')}
                                        className={generateClassName('/OrderHistory') + ' header-nav-link'}>
                                        Order History
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={'/favourite-products'}
                                        onClick={() => changeLocation('/favourite-products')}
                                        className={generateClassName('/Shop?tab=favourites') + ' header-nav-link'}>
                                        Favourites
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={'/recently-ordered'}
                                        onClick={() => changeLocation('/recently-ordered')}
                                        className={generateClassName('/Shop?tab=recently') + ' header-nav-link'}>
                                        Recently Ordered
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={'/requested-products'}
                                        onClick={() => changeLocation('/requested-products')}
                                        className={generateClassName('/Shop?tab=requestedProducts') + ' header-nav-link'}>
                                        Requested Products
                                    </Link>
                                </li>
                            </ul>
                        ) : (
                            <ul className='bottom__list' style={{ margin: 0 }}>
                                <li>
                                    <Link
                                        to={'/Shop'}
                                        onClick={() => changeLocation('/Shop')}
                                        className={generateClassName('New search') + ' header-nav-link'}>
                                        Browse Shop
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        style={props.disableBuyTradeCheckout ? { display: 'none' } : {}}
                                        to={'/OrderHistory'}
                                        onClick={() => changeLocation('/OrderHistory')}
                                        className={generateClassName('Order History') + ' header-nav-link'}>
                                        Order History
                                    </Link>
                                </li>
                                {!props.disableBuyTradeCheckout && (
                                    <li>
                                        <Link
                                            to={'/settings'}
                                            onClick={() => changeLocation('/settings')}
                                            className={generateClassName('Account') + ' header-nav-link'}>
                                            My Account
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        )}
                        {showNewNavigationBar ? (
                            <p className='header-contact-text'>
                                Contact us:{' '}
                                <a href='tel:01978 666 888' className={'header-tel-link'}>
                                    01978 666 888
                                </a>{' '}
                                <br /> Mon – Fri: 8.30am – 5.00pm
                            </p>
                        ) : (
                            <p className='header-contact-text' style={{ textAlign: 'start', padding: 0 }}>
                                Contact us:{' '}
                                <a href='tel:01978 666 888' className={'header-tel-link'}>
                                    01978 666 888
                                </a>{' '}
                                <span>|</span> <br /> Mon – Fri: 8.30am – 5.00pm
                            </p>
                        )}
                    </div>
                </div>
            </div>
            {
                showBanner && bannerText &&
                <PromoAlert contents={bannerText} />
            }
            {showNewNavigationBar ? (
                <div className='new-mega-menu' onMouseLeave={() => setSelectedParent(null)}>
                    <div className='category-container'>
                        <div className='category-list max-width-1300'>
                            {allClassifications
                                .filter((el) => el.parentId === null)
                                .map((part, index) => {
                                    if (checkSubMenu(part.id).length) {
                                        return (
                                            <div
                                                key={index}
                                                className={selectedParent?.id === part.id ? 'category-item hover' : 'category-item'}
                                                onClick={() =>
                                                    setSelectedParent(selectedParent?.id && selectedParent?.id === part.id ? null : part)
                                                }
                                                onMouseEnter={() => setSelectedParent(part)}>
                                                {part.name}
                                            </div>
                                        );
                                    }

                                    return (
                                        <div key={index} className='category-item' onMouseEnter={() => setSelectedParent(null)}>
                                            <Link
                                                to={
                                                    part.name === 'SPARES' && isNewSpares
                                                        ? '/Spares'
                                                        : part.name === 'SPARES'
                                                            ? '/Shop-2'
                                                            : `/products?classication=${part.id}`
                                                }
                                                forceRefresh={true}
                                                replace={true}>
                                                {part.name}
                                            </Link>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>

                    <div className='sub-category-container'>
                        {!!selectedParent?.id && checkSubMenu(selectedParent.id)?.length && (
                            <div className='sub-category-list max-width-1300'>
                                {checkSubMenu(selectedParent?.id).map((subPart, subPartIndex) => {
                                    return (
                                        <div key={subPartIndex} className='sub-category-group'>
                                            {selectedParent.name === 'SPARES' ? (
                                                <Link
                                                    className='sub-category-link'
                                                    reloadDocument
                                                    to={`/${isNewSpares ? subPart.url.replace('Shop-2', 'Spares') : subPart.url}`}>
                                                    {subPart.name}
                                                </Link>
                                            ) : (
                                                checkSubMenu(subPart.id).length > 0 && (
                                                    <Link
                                                        className='sub-category-heading'
                                                        onClick={() => {
                                                            window.location = `/products?classification=${subPart.id}`;
                                                        }}>
                                                        {subPart.name}
                                                    </Link>
                                                )
                                            )}
                                            {!!checkSubMenu(subPart.id).length && (
                                                <ul className='sub-category-item'>
                                                    {checkSubMenu(subPart.id)
                                                        .slice(0, 9)
                                                        .map((subPartItem, subPartItemIndex) => {
                                                            return (
                                                                <li key={subPartItemIndex}>
                                                                    <Link
                                                                        className='sub-category-link'
                                                                        onClick={() => {
                                                                            window.location = `/products?classification=${subPartItem.id}`;
                                                                            setSelectedParent(null);
                                                                        }}>
                                                                        {subPartItem.name}
                                                                    </Link>
                                                                </li>
                                                            );
                                                        })}
                                                </ul>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            ) : null}

            <div className='container page-container'>
                <div className='header__grid-desktop'>{props.headerBarAdd ? props.headerBarAdd : <></>}</div>
            </div>
        </header>
    );
};

export default HeaderBar;
