import React, {useState, useEffect, useContext} from "react";
import Wrapper from "./Components/Layout/Wrapper";
import getBasketCount from "./Helpers/common/getBasketCount";
import { useHistory } from "react-router-dom";
import AccountService from "./Services/AccountService";
import BasketService from "./Services/BasketService";
import TextBox from "./Components/Form/TextBox";
import TextAreaEntry from "./Components/Form/TextAreaEntry";
import clsx from 'clsx';
import CompleteAccountPopUp from "./Components/Account/CompleteAccountPopUp";
import NotificationService from "./Services/NotificationService"
import { Grid } from "@mui/material";
import CheckIcon from "./assets/img/home-categories/check.svg";
import ClockIcon from "./assets/img/home-categories/clock.svg";
import CreditCardIcon from "./assets/img/home-categories/credit-card.svg";
import DeliveryIcon from "./assets/img/home-categories/delivery.svg";
import ManualsIcon from "./assets/img/home-categories/manuals.svg";
import SearchIcon from "./assets/img/home-categories/search.svg";
import FeatureFlagContext from "./Context/FeatureFlagContext";

export default function Homepage() {
  const history = useHistory();
  
  const featureFlagContext = useContext(FeatureFlagContext);

  const [basket, setBasket] = useState({ lines: [] });

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setlastNameError] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [messageError, setmessageError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [showCompleteAccountPopUp, setshowCompleteAccountPopUp] = useState(false);
  
  const isNewSpares = featureFlagContext.checkFeatures("new-spares");

  const goToProductsPage = () => {
    history.push("/products?part=3&origin=home");
  };

  const goToSparesPage = () => {
    if (isNewSpares==true) 
      history.push("/Spares")
    else 
      history.push("/shop-2");
  };

  const getOrganisation = async () => {
    AccountService.isAccountCompleted().then((response) =>
      setshowCompleteAccountPopUp(!response)
    )
  }

  const unShowCompleteAccountPopUp = () => {
    if (showCompleteAccountPopUp) {
      setshowCompleteAccountPopUp(false);
    }
  }

  useEffect(() => {
    getOrganisation();
  }, []);

  const loadBasket = () => {
    BasketService.getBasketForUser().then((response) => {
      if (!response.lines) response.lines = [];
      setBasket(response);
    });
  };

  const onHelpFormSubmit = () => {
    const formIsValid = fieldValidation();

    if (formIsValid) {
      sendForm()
      !formSubmitted && setFormSubmitted(true)
    }
  }

  const sendForm = () => {
    NotificationService.SendContactUsEmail({ firstName, lastName, email, phoneNumber, message })
  }
  
  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const fieldValidation = () => {
    let formIsValid = true;

    if (firstName) {
      setFirstNameError(false)
    } else {
      setFirstNameError(true)
      formIsValid = false
    }

    if (lastName) {
      setlastNameError(false)
    } else {
      setlastNameError(true)
      formIsValid = false
    }

    if (message) {
      setmessageError(false)
    } else {
      setmessageError(true)
      formIsValid = false
    }

    if (validateEmail(email)) {
      setemailError(false)
    } else {
      setemailError(true)
      formIsValid = false
    }

    return formIsValid
  }


  const categories = [
    { id: 1, text: "Instant parts identifier", icon: SearchIcon },
    { id: 2, text: "Search 1.6 million heating and plumbing products", icon: CheckIcon },
    { id: 3, text: "Compare live stock availability in real time", icon: ClockIcon },
    { id: 4, text: "Seamless ordering on credit account and card", icon: CreditCardIcon },
    { id: 5, text: "Same day collection and delivery from over 1000 locations", icon: DeliveryIcon },
    { id: 6, text: "View images, manuals and appliance compatibility", icon: ManualsIcon },
  ]

  return (
    <Wrapper text="HOMEPAGE" selected="Home" count={getBasketCount(basket)}>
      <div className='home-wrapper' onClick={unShowCompleteAccountPopUp}>
        <div className='header-wrapper'>
          <div className={'welcome-section'}>
            <div className={`header-text`}>WELCOME TO BUYTRADE</div>
            <div className='slogan'>
              Right Product, Right Price, Right Now
            </div>
            <div className={'search-box-container'}>
              <Grid container spacing={4} justifyContent='center'>
                <Grid item xs={11} sm={11} md={4.4} lg={3.4}>
                  <div className={'search-box'}>
                    <h4>SHOP HEATING AND PLUMBING</h4>
                    <p>Find Products</p>
                    <button
                      type="button"
                      className={`search-btn`}
                      onClick={goToProductsPage}
                    >
                      New Search
                    </button>
                  </div>
                </Grid>
                <Grid item xs={11} sm={11} md={4.4} lg={3.4}>
                  <div className={'search-box'}>
                    <h4>INSTANT PARTS IDENTIFIER</h4>
                    <p>Find Spares</p>
                    <button
                      type="button"
                      className={`search-btn`}
                      onClick={goToSparesPage}
                    >
                      New Search
                    </button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className={'on-demand-container'}>
          <div className="top-line"></div>
          <h4>Heating & plumbing on demand.</h4>
          <div className="on-demand-categories">
            <Grid container spacing={4}>
              {categories.map((category, index) =>
                <Grid item xs={12} md={6} lg={4} key={index}>
                    <div className="category-icon-box">
                        <img src={category.icon} className="category-icon" alt="category icon" />
                    </div>
                  <div className="category-text">
                    {category.text}
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
        <div className={'form-section'}>
          <div className="top-line"></div>
          <h4>Get in touch.</h4>
          <p>Our friendly team are here to help.  Whether you have a general enquiry or need product or technical assistance, you can call us on <a href='tel:01978 666 888'>01978 666 888</a>, Monday to Friday 8.30am to 5.00pm or complete the form below.</p>
          <div className={'form-container'}>
            {formSubmitted ?
              <div className={'confirmation-container'}>
                <p>Thanks for contacting us! We will be in touch with you shortly.</p>
              </div>
              : <div className={'form-content'}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <TextBox
                      required
                      name="firstName"
                      placeholder="First Name*"
                      value={firstName}
                      onChange={setFirstName}
                      className={clsx(`text-box`, firstNameError ? `error-field` : "")}
                    />
                    {firstNameError && <div className={clsx(`error-message`)} ><strong>First Name</strong> is required </div>}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <TextBox
                      required
                      name="lastName"
                      placeholder="Last Name*"
                      value={lastName}
                      onChange={setLastName}
                      className={clsx(`text-box`, lastNameError ? `error-field` : "")}
                    />
                    {lastNameError && <div className={clsx(`error-message`)} ><strong>Last Name</strong> is required </div>}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <TextBox
                      required
                      name="email"
                      placeholder="Email*"
                      value={email}
                      onChange={setEmail}
                      className={clsx(`text-box`, emailError ? `error-field` : "")} />
                    {emailError && <div className={clsx(`error-message`)} ><strong>Email</strong> is required </div>}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <TextBox
                      name="phoneNumber"
                      placeholder="Contact Number"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      className={clsx(`text-box`)} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextAreaEntry
                      required
                      name="message"
                      placeholder="Message"
                      value={message}
                      onChange={setMessage}
                      className={clsx(`text-box`, `message-box`, messageError ? `error-field` : "")}
                    />
                    {messageError && <div className={clsx(`error-message`)} ><strong>Message</strong> is required </div>}
                  </Grid>
                </Grid>
              </div>}
          </div>
          <div className={'terms-text'}>This site is protected by reCAPTCHA and the Google&nbsp;
            <a href='https://policies.google.com/privacy' target='_blank' rel="noreferrer">Privacy Policy&nbsp; <br /></a> and&nbsp;
            <a href='https://policies.google.com/terms' target='_blank' rel="noreferrer">Terms of Service</a>&nbsp;apply.
          </div>
          <button type="button" className={`submit-btn`} onClick={onHelpFormSubmit}>
            Submit
          </button>
        </div>
      </div>
      {showCompleteAccountPopUp && <CompleteAccountPopUp onClose={unShowCompleteAccountPopUp} />}
    </Wrapper>
  );
}
