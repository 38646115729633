/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Wrapper from "./Components/Layout/Wrapper";
import { isMobile, isTablet } from "./Components/Media/Media";
import formatPrice from "./Helpers/price/formatPrice";
import getTotalQty from "./Helpers/common/getTotalQty";
import moment from "moment";
import BasketService from "./Services/BasketService";
import { Prompt, useHistory } from "react-router-dom";
import Header from "./Components/Header/Header";

const useStyles = makeStyles((theme) => ({
  button: {
    // backgroundColor: "#3EC4F4",
    // padding: "15px 20px",
    width: "100%",
    // color: "white",
  },
  branch: {
    background: "white",
    padding: "15px",
    margin: "10px",
  },

  branchTumbler: {
    color: "#3EC4F4",
    fontWeight: 700,
    fontSize: "14px",
  },
  footer: {
    padding: "10px 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subHeader: {
    color: "#454545",
    opacity: "50%",
    fontWeight: 400,
    fontSize: "14px",
    textAlign: "center",
  },
  [`@media (max-width: 600px)`]: {
    subHeaderNotif: {
      color: "#454545",
      opacity: "50%",
      fontWeight: 400,
      fontSize: "14px",
      textAlign: "center",
    },
  },
  [`@media (min-width:600px)`]: {
    subHeaderNotif: {
      color: "#828282",
      opacity: "50%",
      fontWeight: 400,
      fontSize: "16px",
      textAlign: "left",
    },
  },
  productCard: {
    width: "45%",
    background: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    margin: "10px",
    marginRight: "0px",
    position: "relative",
  },
  number: {
    color: "#454545",
    opacity: "50%",
    fontSize: "16px",
    alignSelf: "self-start",
  },
  wrapper: {
    [theme.breakpoints.up("sm")]: {
      background: "#F5F7F9",
      borderRadius: "41px",
      padding: "65px 70px 0px 70px",
      marginBottom: 100,
    },
  },
  [`@media (max-width: 600px)`]: {
    button: {
      // position: 'absolute',
      // backgroundColor: "#3EC4F4",
      // padding: "15px 20px",
      width: "calc(100% - 20px)",
      maxWidth: "400px",
      // color: "white",
      bottom: "80px",
      left: "10px",
      // borderRadius: 6,
    },
  },
  [`@media (min-width:600px)`]: {
    button: {
      // backgroundColor: "#3EC4F4",
      // padding: "18px 20px",
      width: "calc(100% - 20px)",
      maxWidth: "250px",
      // color: "white",
      margin: "10px",
    },
  },
  transparentButton: {
    border: "1px solid #3EC4F4",
    padding: "15px 20px",
    width: "calc(100% - 20px)",
    maxWidth: "400px",
    color: "#3EC4F4",
    margin: "10px",
  },
  input: {
    width: "80px",
    textAlign: "right",
    paddingLeft: "40px",
    borderRadius: "0px",
  },
  description: {
    alignSelf: "self-start",
  },
  qty: {
    position: "absolute",
    left: "10px",
    color: "#454545",
    opacity: "50%",
    fontSize: "14px",
    top: "50%",
    transform: "translateY(-50%)",
  },
  qtyWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
  },
  productRoot: {
    background: "white",
    borderRadius: "5px",
    margin: "15px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  title: {
    textAlign: "center",
    color: "#3EC4F4",
    fontSize: "16px",
    fontWeight: 600,
    margin: "25px",
  },
  order: {
    border: "1px",
    borderRadius: "6px",
    background: "white",
    padding: "10px",
    margin: "10px",
  },
  productFooter: {
    display: "flex",
    justifyContent: "space-between",
  },
  price: {
    color: "#81C454",
    fontWeight: 700,
    fontSize: "18px",
  },
  plusVAT: {
    color: "#f00 !important",
  },
  numberWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  deliveryWrapper: {
    display: "flex",
    alignItems: "center",
    paddingTop: "15px",
  },
  deliveryTitle: {
    fontSize: "16px",
    fontWeight: 700,
  },
  deliveryTitleWrapper: {
    paddingLeft: "20px",
  },
  priceWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
  },
  deliveryCost: {
    color: "#81C454",
    fontSize: "16px",
    fontWeight: 700,
  },
  totalCost: {
    color: "#81C454",
    fontSize: "18px",
    fontWeight: 700,
  },
  totalVatCost: {
    color: "#81C454",
    fontSize: "24px",
    fontWeight: 700,
  },
  totalVatCostWrapper: {
    padding: "10px",
    background: "#EBFFDD",
  },
  buttons: {
    justifySelf: "",
  },
  vatCost: {
    color: "#454545",
    opacity: "50%",
    fontSize: "12px",
    fontWeight: 500,
  },
  productImg: {
    paddingRight: "10px",
  },
  productImgWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    color: "#454545",
    fontWeight: 300,
    fontSize: "24px",
    textAlign: "center",
    padding: "0px 20px",
  },
  branchTitle: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#454545",
    opacity: "50%",
  },
  branchAddress: {
    fontSize: "14px",
    fontWeight: "400",
  },
  headerRight: {
    paddingLeft: "20px",
  },
  availableBranchTitle: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "12px",
    color: "#454545",
    opacity: "50%",
    padding: "10px 0",
    fontWeight: 400,
  },
  expected: {
    color: "#FEAB4A",
    fontWeight: 500,
    fontSize: "12px",
  },
  flex: {
    display: "flex",
  },
  locationLink: {
    textDecoration: "underline",
    color: "#454545",
    "&:hover": {
      color: "#3EC4F4",
    },
  },
  locationName: {
    padding: "0 16px",
    fontWeight: 900,
  },
  gap: {
    paddingBottom: "10px",
  },
  notificationIcon: {
    borderRadius: "50%",
    padding: "15px",
    background: "white",
    width: "50px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      marginRight: 20,
    },
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  footerWrapper: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
      marginTop: 41,
      width: '100%'
    },
  },
  orderNumber: {
    color: "#454545",
    fontSize: "18px",
  },
  orderCount: {
    fontSize: "12px",
    color: "#454545",
    opacity: "50%",
  },
  orderTotal: {
    fontWeight: 700,
    fontSize: "24px",
    color: "#81C454",
  },
  orderAction: {
    color: "#3EC4F4",
    fontWeight: 500,
    fontSize: "14px",
    position: "absolute",
    right: 0,
  },
  orderFooterWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  orderReference: {
    display: "flex",
    justifyContent: "center",
    fontSize: "12px",
    color: "#454545",
    opacity: "50%",
  },
  main: {
    textAlign: "center",
    position: "relative",
  },
  orderIcon: {
    boxShadow: "3px 5px 3px 1px rgba(0,0,0,0.19)",
    padding: "20px",
    width: "100px",
    height: "100px",
  },
  orderIcon1: {
    position: "absolute",
    left: "calc(50% - 100px)",
  },
  orderIcon2: {
    position: "absolute",
    left: "calc(50% - 50px)",
  },
  orderIcon3: {
    position: "absolute",
    left: "calc(50%)",
  },
  orderIconWrapper: {
    display: "flex",
    justifyContent: "center",
    height: "120px",
  },
  mobileWrapper: {
    backgroundColor: "#fff",
    paddingTop: 27,
    paddingBottom: 108,
  },
  confirmHeader: {
    backgroundColor: "#F3F9FC",
    height: 98,
    fontSize: 20,
    padding: 30,
    boxSizing: "border-box",
    color: "#00226A",
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "transparent",
      fontSize: 32,
      fontWeight: 400,
      color: '#454545',
      padding: '30px 0'
    },
  },
  confirmBody: {
    margin: "0px 30px",
    padding: "47px 30px",
    backgroundColor: "#F5F7F9",
    borderRadius: 41,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& p": {
      textAlign: "center",
    },
    "& p:nth-child(1)": {
      fontSize: 24,
      color: "#454545",
      width: 256,
      lineHeight: "24px",
      marginBottom: 24
    },
    "& p:nth-child(2)": {
      fontSize: 14,
      color: "#454545",
      width: 200,
      lineHeight: "17px",
    },
    "& p:nth-child(4)": {
      fontSize: 20,
      fontWeight: 600,
      color: "#454545",
      margin: 0,
    },
    "& p:nth-child(5)": {
      fontSize: 14,
      color: "#828282",
      marginBottom: 0
    },
    "& p:nth-child(6)": {
      fontSize: 24,
      fontWeight: 500,
      color: "#81C454",
    },
    "& p:nth-child(7)": {
      fontSize: 14,
      color: "#828282",
      marginBottom: 5
    },
    "& a": {
      color: "#009FDF !important",
    },
  },
  confirmBodyDesktop: {
    margin: "42px 30px",
    backgroundColor: "#F5F7F9",
    borderRadius: 41,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& p": {
      textAlign: "center",
    },
    "& p:nth-child(1)": {
      fontSize: 20,
      color: "#454545",
      fontWeight: 600,
      width: 256,
      lineHeight: "24px",
    },
    "& p:nth-child(2)": {
      color: "#828282",
      width: 150,
      fontSize: 14,
      lineHeight: "17px"
    },
    "& p:nth-child(3)": {
      fontSize: 24,
      lineHeight: "29px",
      fontWeight: 600,
      color: "#81C454",
      margin: "0 0 10px 0",
    },
    "& p:nth-child(4)": {
      fontSize: 14,
      lineHeight: "17px",
      color: "#828282",
    },
    "& p:nth-child(5)": {
      fontSize: 24,
      fontWeight: 600,
      color: "#007AAB",
    },
    "& p:nth-child(6)": {
      fontSize: 14,
      color: "#828282",
    },
    "& a": {
      color: "#009FDF !important",
      fontSize: 14,
      lineHeight: "17px",
      width: 274,
      display: "flex",
      textAlign: "right",
      alignItems: "center",
      justifyContent: "flex-end"
    },
  },
  notificationIconMobile: {
    marginTop: 20,
    borderRadius: "50%",
    padding: "15px",
    background: "transparent",
    width: "50px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  LogoDesktop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 35,
    width: '100%',

    "& img": {
      width: 90,
      objectFit: "cover"
    }
  },
  LogoMobile: {
    margin: "15px 0",
  },
  '@media (min-width: 640px) and (max-width: 768px)': {
    confirmBody: {
      padding: "59px 30px",
      "& p:nth-child(1)": {
        fontSize: 24,
        width: 500,
        lineHeight: "29px",
      },
    },
    LogoMobile: {
      margin: "25px 0",
    },
    iconWrapper: {
      flexDirection: "column"
    }
  },
}));

const order = {
  id: "00003",
  price: 1500,
  count: 3,
  date: "12 January 2021 at 17:48",
  details: [
    {
      qty: 3,
      price: "500",
      partsListSectionRowId: 5661119,
      partId: 559604,
      itemKey: "1",
      quantity: 1,
      sequence: 2,
      catalogNumber: "",
      description: "Gasket (Cover Air Inlet)",
      manufacturerPartNumber: "0310828",
      industryNumber: "",
      status: "U",
      remarks: "",
      hasGraphic: false,
      supersessionLevel: 0,
      explodedViewId: 0,
      partsListSectionId: 0,
    },
  ],
  deliveryType: "0",
  address: "City Plumbing, Cardiff",
  deliveryCost: "5",
  totalCost: "1490",
};

const Confirmation = (props) => {
  const basketKey = props.match.params.basketKey;

  const classes = useStyles();
  const history = useHistory();

  const [basket, setBasket] = useState(null);

  useEffect(() => {
    loadBasket();
  }, []);

  const goToSparesFinder = () => {
    history.push("/homepage-2");
  };

  const goToOrderDetails = () => {
    history.push(`/OrderHistory?basketKey=${basketKey}`);
  };

  const loadBasket = () => {
    BasketService.getBasketByKey(basketKey).then((response) => {
      setBasket(response);
    });
  };

  const formatDate = (date) => {
    return moment(date).format("DD MMMM YYYY [at] HH:mm");
  };

  return basket ? (
    <Wrapper text="CONFIRMATION">
      <Header body='Details' />
      {isMobile() ? (
        <div className={classes.mobileWrapper}>
          <div className={classes.confirmBody}>
            <p>Your order has been successfully placed</p>
                      <p style={{ width: isTablet() ? 200 : 150 }}>{basket.quoteId > 0 ? 'Express same-day delivery' : `For ${basket.isDelivery ? "delivery" : "collection"} from ${basket.selectedBranch.supplier.name == "HRP Trade" ? "BuyTrade partners" : basket.selectedBranch.name}`}</p>
            <div className={[classes.LogoDesktop, classes.LogoMobile].join(' ')}>
              <img src={basket.selectedBranch.supplier.imageUrl} alt="supplier imageUrl" />
            </div>
            <p>Order Number: {basket.orderId ?? "-"}</p>
            <p>{getTotalQty(basket)}</p>
            <p>{formatPrice(basket.totalDisplayedCostWithVat)} (Inc VAT)</p>
            <p>{formatDate(order.orderDate)}</p>
            <p>
              <a onClick={goToOrderDetails}>{"View details >"}</a>
            </p>
            <button
              type="button"
              className={`${classes.button} button-large button-light-blue`}
              onClick={goToSparesFinder}
            >
              Continue shopping
            </button>
            <div className={classes.iconWrapper}>
              <div className={classes.notificationIconMobile} style={{ margin: isTablet() ? "4px auto 0" : undefined }}>
                <svg
                  width="26"
                  height="25"
                  viewBox="0 0 22 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 19H13C13 19.5304 12.7893 20.0391 12.4142 20.4142C12.0391 20.7893 11.5304 21 11 21C10.4696 21 9.96086 20.7893 9.58579 20.4142C9.21071 20.0391 9 19.5304 9 19ZM20 17V18H2V17L4 15V9C4 5.9 6.03 3.17 9 2.29C9 2.19 9 2.1 9 2C9 1.46957 9.21071 0.960859 9.58579 0.585786C9.96086 0.210714 10.4696 0 11 0C11.5304 0 12.0391 0.210714 12.4142 0.585786C12.7893 0.960859 13 1.46957 13 2C13 2.1 13 2.19 13 2.29C15.97 3.17 18 5.9 18 9V15L20 17ZM16 9C16 7.67392 15.4732 6.40215 14.5355 5.46447C13.5979 4.52678 12.3261 4 11 4C9.67392 4 8.40215 4.52678 7.46447 5.46447C6.52678 6.40215 6 7.67392 6 9V16H16V9ZM18.75 1.19L17.33 2.61C19.04 4.3 20 6.6 20 9H22C22 6.07 20.84 3.25 18.75 1.19ZM0 9H2C2 6.6 2.96 4.3 4.67 2.61L3.25 1.19C1.16 3.25 0 6.07 0 9Z"
                    fill="#3EC4F4"
                  />
                </svg>
              </div>
              {isTablet()
                ? <p className={classes.subHeaderNotif} style={{ width: isTablet() ? "100%" : undefined }}>
                  Please check your notifications for further updates.
                </p>
                : <p className={classes.subHeaderNotif}>
                  Please check your notifications <br /> for further updates.
                </p>
              }
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={classes.wrapper}>
            <div className={classes.header}>
              Your order has been successfully placed
            </div>
            <div className={classes.subHeader}>
                              {basket.quoteId > 0 ? 'Express same-day delivery' : `For ${basket.isDelivery ? "delivery" : "collection"} from ${basket.selectedBranch.supplier.name == "HRP Trade" ? "BuyTrade partners" : basket.selectedBranch.name}`}
            </div>
            <div className={classes.LogoDesktop}>
              <img src={basket.selectedBranch.supplier.imageUrl} alt="supplier imageUrl" />
            </div>
            <div className={classes.confirmBodyDesktop}>
              <p>Order Number: {basket.orderId ?? "-"}</p>
              <p>{getTotalQty(basket)}</p>
              <p>{formatPrice(basket.totalDisplayedCostWithVat)} (Inc VAT)</p>
              <p>{formatDate(order.orderDate)}</p>
              {!isMobile() && (
                <div className={classes.footerWrapper}>
                  <div className={classes.iconWrapper}>
                    <div className={classes.notificationIcon}>
                      <svg
                        width="26"
                        height="25"
                        viewBox="0 0 22 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 19H13C13 19.5304 12.7893 20.0391 12.4142 20.4142C12.0391 20.7893 11.5304 21 11 21C10.4696 21 9.96086 20.7893 9.58579 20.4142C9.21071 20.0391 9 19.5304 9 19ZM20 17V18H2V17L4 15V9C4 5.9 6.03 3.17 9 2.29C9 2.19 9 2.1 9 2C9 1.46957 9.21071 0.960859 9.58579 0.585786C9.96086 0.210714 10.4696 0 11 0C11.5304 0 12.0391 0.210714 12.4142 0.585786C12.7893 0.960859 13 1.46957 13 2C13 2.1 13 2.19 13 2.29C15.97 3.17 18 5.9 18 9V15L20 17ZM16 9C16 7.67392 15.4732 6.40215 14.5355 5.46447C13.5979 4.52678 12.3261 4 11 4C9.67392 4 8.40215 4.52678 7.46447 5.46447C6.52678 6.40215 6 7.67392 6 9V16H16V9ZM18.75 1.19L17.33 2.61C19.04 4.3 20 6.6 20 9H22C22 6.07 20.84 3.25 18.75 1.19ZM0 9H2C2 6.6 2.96 4.3 4.67 2.61L3.25 1.19C1.16 3.25 0 6.07 0 9Z"
                          fill="#3EC4F4"
                        />
                      </svg>
                    </div>
                    <br />
                    <div className={classes.subHeaderNotif}>
                      Please check your emails
                      <br /> for further updates.
                    </div>
                  </div>
                  <button
                    type="button"
                    className={`${classes.button} button-large button-light-blue`}
                    onClick={goToSparesFinder}
                  >
                    Continue shopping
                  </button>
                  <a onClick={goToOrderDetails}>{"View details >"}</a>
                </div>
              )}
            </div>
            <br />
          </div>
          <br />

        </>
      )}
      <Prompt
        message={(_, action) => {
          if (action === 'POP') {
            window.location.href = '/';
          }

          return true;
        }}
      />
    </Wrapper>
  ) : (
    <></>
  );
};

export default Confirmation;
