import React from 'react';
import successIcon from '../../assets/img/success.svg';

const DEFAULT_SUCCESS_MESSAGE = 'Success!';

function SuccessMessage({
                          text = DEFAULT_SUCCESS_MESSAGE,
                          visible,
                          holdSpace = true,
                          paddingBottom = 0,
                          addClassName = '',
                          style = {}
                      }) {
    const holdSpaceStyles = {
        opacity: visible ? 1 : 0,
        paddingBottom,
        ...style,
    };

    const styles = {
        display: visible ? 'flex' : 'none',
        paddingBottom,
        ...style,
    };

    return (
        <div className={`success_message ${addClassName}`} style={holdSpace ? holdSpaceStyles : styles}>
            <img src={successIcon} alt="Success"/>
            <p>{text}</p>
        </div>
    );
}

export default SuccessMessage;