/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { withRouter } from "react-router-dom";
import LikedProductService from "../../Services/LikedProductService";
import ProductSearchService from "../../Services/ProductSearchService";
import { isMobile, isTablet } from "../Media/Media";
import MultipleApplianceModal from './../Modal/DeleteModal';
import SearchResultsList from "./SearchResultsList";
import SearchButton from "./SearchButton";
import FinderInput from "./FinderInput";
import IBasisTablePager from '../Table/IBasisTablePager';
import SelectListStaticWithLabel from "../Form/SelectListStaticWithLabel";
import FeatureFlagContext from "../../Context/FeatureFlagContext";
import { isEmpty } from "../../utils/funcs";


const SELECT_VALUES = [
    { sortDir: "asc", text: "Product Name - A-Z", sortProp: "Description" },
    { sortDir: "desc", text: "Product Name - Z-A", sortProp: "Description" },
];

class SearchByText extends React.Component {
    static contextType = FeatureFlagContext;

    constructor(props) {
        super(props);

        this.state = {
            isFilterActived: false,
            searched: false,
            data: [],
            searchTerm: this.props.isSearchByCode
                ? (new URL(window.location.href).searchParams.get("q") ?? '')
                : '',
            totalRecords: 0,
            skip: 0,
            take: 10,
            appliances: [],
            searchedAppliances: false,
            likedProducts: [],
            appliance: {},
            sortDir: SELECT_VALUES[0].sortDir,
            sortProp: SELECT_VALUES[0].sortProp,
            loadedRecords: false,
            errorSearchTerm: false,
            searchingByAppliance: false,
            searchText: "",
            searchTermError: false,
            loading: false,
            filterManufacturerId: 0,
            filterManufacturerName: "All Manufacturers",
            manufacturers: [],
            multiple_appliance_modal: false,
            pageNumber: 1
        };
    }

    async componentDidMount() {
        await this.getManufacturers();
        await this.getLikedProducts();

        if (this.props.isSearchByCode && new URL(window.location.href).searchParams.get("q")) {
            this.search()
        }

        if (this.props.isSearchByCode) {
            this.unlistenHistoryChange = this.props.history.listen((location, action) => {
                const currentUrl = new URL(window.location.href);
                this.setState({
                    searchTerm: currentUrl.searchParams.get("q") ?? ''
                }, () => {
                    if (currentUrl.searchParams.get("q")) {
                        this.search()
                    }
                });
            });
        }
    }

    componentWillUnmount() {
        this.unlistenHistoryChange && this.unlistenHistoryChange()
    }

    pageChanged = ({ skip, take }) =>
        this.setState({ skip, take }, () => this.search());

    changeSearchTerm = (val) => {
        this.setState({
            searchTermError: false,
            searchTerm: val,
        });
    };

    toggleLike = (item, isLiked = false) => {
        LikedProductService.toggle(item.mpn, item.description, item.img_url, item.manid, item.app, item.appid, item.pgcn, item.appgc, item.price, item.man).then(() => {
            if (!isLiked) {// add to the list
                this.setState(prev => {
                    return {
                        ...prev,
                        data: prev.data.map(product =>
                            product.mpn === item.mpn ? { ...product, liked: !isLiked } : product
                        ),
                        likedProducts: [
                            ...prev.likedProducts,
                            {
                                mpn: item.mpn,
                                img_url: item.img_url,
                                description: item.description,
                            }
                        ]
                    }
                })
            } else {// remove from the list
                this.setState(prev => {
                    return {
                        ...prev,
                        data: prev.data.map(product =>
                            product.mpn === item.mpn ? { ...product, liked: !isLiked } : product
                        ),
                        likedProducts: prev.likedProducts.filter(likedProduct => likedProduct.mpn !== item.mpn)
                    }
                })
            }
        });
    };

    search = () => {
        const {
            searchTerm,
            sortDir,
            sortProp,
            filterManufacturerName,
            filterManufacturerId,
            pageNumber
        } = this.state;
        const { isSearchByCode, basket } = this.props;

        if (searchTerm === '') return this.setState({ searchTermError: true })

        this.setState({
            searched: true,
            loading: true
        });

        if (
            isSearchByCode === true &&
            searchTerm != ""
        ) {
            this.setState({
                data: [],
                loadedRecords: false
            }, () => {
                ProductSearchService.searchPartsByFilter(searchTerm, filterManufacturerName, filterManufacturerId, pageNumber, sortDir, sortProp)
                    .then((response) => {
                        let items = response.data.map((product) => {
                            product.qty = basket.lines.find((line) => line.manufacturerCode === product.mpn)?.qty ?? 0
                            product.liked =
                                this.state.likedProducts.findIndex(
                                    (p) => p.mpn == (product.manufacturerCode ?? product.mpn)
                                ) !== -1;
                            return product;
                        });
                        this.setState({
                            data: items || [],
                            totalRecords: response.totalCount,
                            searched: true,
                            loadedRecords: true,
                            loading: false
                        });
                    });
            });
        } else if (
            isSearchByCode === true &&
            (searchTerm === "")
        ) {
            this.setState({ loadedRecords: false, searched: false, items: [], loading: false });
        } else if (isSearchByCode !== true) {
            this.searchAppliances();
        }
    };

    searchAppliances = () => {
        const { searchTerm } = this.state;
        this.setState({ searched: true, searchedAppliances: true, loading: true });

        ProductSearchService.searchAppliances(searchTerm).then((response) => {
            this.setState({ appliances: response || [], data: [], loading: false })

            if (response?.length === 1) {
                this.onSelectAppliance(response[0])
            } else if (response?.length > 1) {
                this.setState({ multiple_appliance_modal: true });
            }
        });
    };

    onSelectAppliance = async (appliance) => {
        await this.setState({ appliance, multiple_appliance_modal: false });
        this.searchPartsByAppliance();
    };

    onSelectApplianceOrPartCodeKeywordOnPaging = async (pageNumber) => {
        await this.setState({ pageNumber: pageNumber })
        const { isSearchByCode } = this.props;
        if (isSearchByCode) {
            this.search();
        }
        else {
            this.searchPartsByAppliance();
        }
    }

    searchPartsByAppliance = () => {
        const { basket } = this.props;
        const { sortDir, sortProp, pageNumber, appliance } = this.state;
        this.setState({
            data: [],
            searched: true,
            searchedAppliances: false,
            searchingByAppliance: true,
            loadedRecords: false,
            loading: true
        });

        ProductSearchService.partsByAppliance(appliance.manid, appliance.appid, pageNumber, sortDir, sortProp).then((response) => {
            let items = response.data.map((product) => {
                product.qty = basket.lines.find((line) => line.manufacturerCode === product.mpn)?.qty ?? 0
                product.liked =
                    this.state.likedProducts.findIndex(
                        (p) => p.mpn == (product.manufacturerCode ?? product.mpn)
                    ) !== -1;
                return product;
            });
            this.setState({ data: items || [], totalRecords: response.totalCount, searchingByAppliance: false, loadedRecords: true, loading: false });
        });
    };

    getLikedProducts = async () => {
        await LikedProductService.searchLikedProducts('').then((response) => {
            this.setState({
                likedProducts:
                    response ? response.map((product) => {
                        return {
                            mpn: product.manufacturerCode,
                            img_url: product.imageUrl,
                            description: product.description,
                        };
                    }) : [],
            })
        });
    };

    changeSort = (option) => {
        let index = option.selected;
        const { isSearchByCode } = this.props;
        let requestOnSearch;
        if (isSearchByCode) {
            requestOnSearch = () => this.search();
        } else {
            requestOnSearch = () => this.searchPartsByAppliance();
        }
        this.setState(
            {
                sortDir: SELECT_VALUES[index].sortDir,
                sortProp: SELECT_VALUES[index].sortProp,
            },
            requestOnSearch
        );
    };

    getManufacturers = async () => {
        this.setState({ loading: true });
        await ProductSearchService.manufacturer().then(async (data) => {
            const sortedData = (data || []).sort((a, b) => {
                if (a.man < b.man) {
                    return -1;
                }
                if (a.man > b.man) {
                    return 1;
                }
                return 0;
            });
            sortedData.unshift({ manid: 0, man: 'All Manufacturers' })
            await this.setState({ manufacturers: sortedData, loading: false });
        });
    };

    setManufacturer = ({ value, text }) => {
        this.setState({
            filterManufacturerName: text,
            filterManufacturerId: value,
            data: [],
            appliances: [],
            appliance: {},
            totalRecords: 0
        });
    };

    searchAmongResults = (e) => {
        this.setState({ searchText: e.target.value });
    };

    clearSearchText = () => {
        this.setState({ searchText: "" });
    };

    filterSearchResults = (item) => {
        return this.state.searchText !== ""
            ? item.description
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())
            : true;
    };

    renderMultipleApplianceModal = () => {
        const { appliances } = this.state
        let content = null;

        if (appliances.length > 1) {
            content = (
                <MultipleApplianceModal
                    className={'multiple-appliance'}
                    onClose={() => this.onSelectAppliance(appliances[0])}
                >
                    <div className={'multiple-appliance__content'}>
                        <h1 className={'modal-popup-header'}>We found more than 1 appliance, please select one...</h1>

                        <div className={'multiple-appliance__appliances'}>
                            {appliances.map((appliance, index) => {
                                if (!isMobile()) {
                                    return <div className={"appliance_item"}>
                                        <h4>{appliance.man}</h4>

                                        <div>
                                            <p>{appliance.app}</p>
                                            <button type="button" onClick={() => this.onSelectAppliance(appliance)}>Select</button>
                                        </div>
                                    </div>
                                } else {
                                    return <div className={"appliance_item"}>
                                        <div>
                                            <h4>{appliance.man}</h4>
                                            <p>{appliance.app}</p>
                                        </div>
                                        <button type="button" onClick={() => this.onSelectAppliance(appliance)}>Select</button>
                                    </div>
                                }
                            }
                            )}
                        </div>
                    </div>
                </MultipleApplianceModal>
            );
        }

        return content
    }

    applianceDocumentExists = () => {
        const { appliance } = this.state;

        if (appliance && !isEmpty(appliance)) {
            return appliance.extras !== "" && appliance.extras !== null;
        } else {
            return false;
        }
    }

    onViewManual = () => {
        const { appliance } = this.state;

        if (appliance && !isEmpty(appliance)) {
            ProductSearchService.applianceDocumentInTab(appliance.appid);
        }
    }

    isAllProducts() {
        return this.context.checkFeatures("all-products");
    }

    render() {
        const {
            searched,
            searchTerm,
            data,
            appliances,
            searchedAppliances,
            searchingByAppliance,
            searchText,
            searchTermError,
            loading,
            multiple_appliance_modal,
        } = this.state;
        const { isSearchByCode } = this.props;

        return (
            <div className="spares-finder-recently_wrapper">
                <div className={`search-field search-field-wrapper ${isMobile() ? isTablet() ? "bg-max-width-tablet" : "" : "max-width-1300 bg-gray-container full-width-by_text"}`}
                    style={this.isAllProducts() ? {} : {
                        margin: '0 auto 30px auto',
                        padding: '21px 10px 40px 10px',
                    }}>
                    <div
                        className="finder-inputs-wrapper"
                        data-columns='3'
                    >
                        <>
                            <FinderInput
                                searchTerm={searchTerm}
                                searchTermError={searchTermError}
                                placeholder={isSearchByCode ? "Part Code or Keyword" : "Appliance GC Number (Exclude dashes & spaces)"}
                                onChange={this.changeSearchTerm}
                            />
                            {isSearchByCode && <SelectListStaticWithLabel
                                placeholder="Manufacturer"
                                valueProperty="manid"
                                textProperty="man"
                                collection={this.state.manufacturers}
                                onChange={this.setManufacturer}
                                value={this.state.filterManufacturerId}
                                className="search-field-list"
                                withoutWrapper
                            />}
                            <SearchButton loading={loading} onSearch={this.search} />
                        </>
                    </div>
                    {<div
                        className='selected-appliance-text'
                        style={{
                            display: this.state.filterManufacturerName
                                ? isMobile() ? 'block' : 'flex'
                                : 'none',
                            padding: 0,
                            margin: isMobile() ? '15px 0 5px' : '0 0 0 15px'
                        }}
                    >
                        <p>
                            You have selected: {this.state.appliance.man ?? this.state.filterManufacturerName} {this.state.appliance?.app}
                        </p>
                        {this.applianceDocumentExists() && (
                            <a
                                onClick={this.onViewManual}
                                className='link-like'
                            >
                                View Manual
                            </a>
                        )}
                    </div>}
                </div>
                {data.length > 0 ? <div className='search-by-text-wrapper'>
                    <SearchResultsList
                        results={data.filter(this.filterSearchResults)}
                        loading={loading}
                        noResults={searched && data && !data.length && !loading}
                        skip={this.state.skip}
                        take={this.state.take}
                        onPageChange={this.pageChanged}
                        toggleLike={this.toggleLike}
                        onAddToBasket={this.props.onAddToBasket}
                        changeSort={this.changeSort}
                        sortValues={SELECT_VALUES}
                        showNewSortFilter={false}
                        noItemsText='Enter details above and click search'
                        isAccountCompleted={this.props.isAccountCompleted}
                        children={searchedAppliances && !loading && multiple_appliance_modal
                            && this.renderMultipleApplianceModal()}
                    />
                    {!!this.state.totalRecords &&
                        <div className="pagination-wrapper">
                            <IBasisTablePager
                                onChange={this.onSelectApplianceOrPartCodeKeywordOnPaging}
                                totalRecords={this.state.totalRecords}
                                pageNumber={this.state.pageNumber}
                            /></div>}
                </div> : <p>Your search has found no results. Please re-try using a spares/products search as appropriate, or contact us for assistance during office hours on <a href="tel:01978 666 888">01978 666 888</a> or <a href="mailto:info@buytrade.co.uk">info@buytrade.co.uk</a> .</p>}
            </div>
        );
    }
}

export default withRouter(SearchByText);