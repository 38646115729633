/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import ProductSearchService from "../../Services/ProductSearchService";
import { isMobile } from "../Media/Media";

const useStyles = makeStyles(() => ({
    [`@media (max-width: 760px)`]: {
        modal: {
            overflowY: "auto",
            maxHeight: "90vh",
            width: "90vw",
            left: "calc(50% - 45vw)",
            top: 20,
            padding: "10px 20px;",
        },
        scrollbox: {
            overflowY: "auto",
            position: 'relative',
            padding: "0 10px 10px 10px",
            height: '80vh',
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: '#E6EEF1',
                borderRadius: 6,
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: 'transparent',
            },
            "&::-webkit-scrollbar": {
                backgroundColor: 'transparent',
                width: 9,
            }
        },
        closeModalBtn: {
            position: 'absolute',
            right: 30,
            top: 30,
            cursor: 'pointer',
        },
        modalHeader: {
            margin: 0,
            marginTop: 60,
            textAlign: 'center'
        },
    },
    [`@media (min-width: 760px)`]: {
        modal: {
            overflowY: "hidden",
            maxHeight: "90vh",
            width: "50vw",
            left: "calc(50% - 25vw)",
            top: "5%",
        },
        scrollbox: {
            overflowY: "auto",
            position: 'relative',
            padding: "0 30px 20px 30px",
            marginTop: 30,
            height: '80vh',
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: '#E6EEF1',
                borderRadius: 6,
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: 'transparent',
            },
            "&::-webkit-scrollbar": {
                backgroundColor: 'transparent',
                width: 9,
            }
        },
        closeModalBtn: {
            position: 'absolute',
            right: 30,
            top: 0,
            cursor: 'pointer',
        },
        modalHeader: {
            margin: 0,
            marginTop: 24,
            textAlign: 'left'
        },
    },
    button: {
        paddingBottom: 0,
        borderRadius: "6px",
        marginLeft: "10px",
        color: "#009FDF !important",
        textDecoration: "underline",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        fontSize: "12px",
        width: "100%",
        margin: "0px !important",
    },
    modalHeader: {
        fontSize: '26px',
        lineHeight: '32px',
        color: '#009FDF',
    },
    specificationList: {
        marginTop: 32,
        borderTop: '1px solid #BACAD1'
    },
    specificationListItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '9px 31px',
        color: '#454545',
        borderBottom: '1px solid #BACAD1'
    },
    specificationListKey: {
        fontSize: '14px',
        lineHeight: '17px',
        textTransform: 'uppercase',
        fontWeight: 600
    },
    specificationListValue: {
        fontWeight: 400
    }
}));

const TechnicalSpecs = (props) => {
    const { product, className } = props;
    const classes = useStyles();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [list, setList] = useState([]);

    const onShowModal = () => {
        setIsModalOpen(true);

        ProductSearchService.searchTechnicalSpecifications(product.productId || product.itemID)
            .then((resp) => {
                if (!!resp?.length) {
                    setList(resp);
                }
            });
    };

    const onCloseModal = () => {
        setIsModalOpen(false);
    };

    const renderSpecs = () => {
        return (
            <div className={classes['specificationList']}>
                {list.map((el, index) =>
                    <div className={classes['specificationListItem']} key={index}>
                        <div className={classes['specificationListKey']}>
                            {el.attributeName}
                        </div>
                        <div className={classes['specificationListValue']}>
                            {el.value}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            <div className={className}>
                {isModalOpen && (
                    <>
                        <div
                            className="modal-overlay"
                            style={{ zIndex: 1000, background: "#00000033" }}
                        />
                        <div className={`dropdown_select_bar_modal ${classes.modal}`}>
                            <div className={classes.scrollbox}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg" className={classes.closeModalBtn}
                                    onClick={onCloseModal}>
                                    <path d="M1 19L19 1M19 19L1 1" stroke="#007AAB" />
                                </svg>
                                <p className={classes.modalHeader}>Technical Specification</p>
                                {renderSpecs()}
                            </div>
                        </div>
                    </>
                )}
                <a className={classes.button} onClick={onShowModal}>
                    {"Further info..."}
                </a>
            </div>
        </>
    );
};

export default TechnicalSpecs;
