import http from '../utils/http';

const MarkAsRead = (id) => {
    return http.post(`NotificationTriggerLog/MarkAsRead`, {
        notificationIds: id
    })
}

const RemoveItem = (id) => {
    const params = new URLSearchParams();
    params.append('id', id);
    return http.delete(`NotificationTriggerLog/Delete`, { params });
}

const GetAllNotifications = (skip, take) => {
    const params = new URLSearchParams();
    params.append('skip', skip);
    params.append('take', take);
    return http.get(`NotificationTriggerLog/GetData`, { params });
}

const GetUnreadNotificationsCount = () => {
    return http.get('NotificationTriggerLog/UnreadNotifications');
}

const SendContactUsEmail = (form) => {
    return http.post('NotificationTriggerLog/ContactUsEmail', form);
}

export default {
    MarkAsRead,
    RemoveItem,
    GetAllNotifications,
    GetUnreadNotificationsCount,
    SendContactUsEmail,
}