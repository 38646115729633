/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useCallback, useEffect, useContext} from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ModalWindow from "../../Modal/ModalWindow";
import RecentlyAdded from "../../Shop/RecentlyAdded";
import { isMobile } from "../../Media/Media";
import PartFits from "../PartFits";
import ProductInfoModal from "../../Modal/ProductInfoModal";
import CompleteAccountPopUp from "../../Account/CompleteAccountPopUp";
import NoImageAvailable from "../../../assets/img/no_image_available.png"
import moment from 'moment';
import { trimText } from '../trimtext';
import TechnicalSpecs from "../TechnicalSpecs";
import formatPrice from "../../../Helpers/price/formatPrice"
import InfoIcon from "../../../assets/img/info";
import ProductTag from "./ProductTag/ProductTag";
import Box from '@material-ui/core/Box';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import { CashBackModal } from "../CashBackModal";
import SparesAvailabilityService from "../../../Services/SparesAvailabilityService";
import FeatureFlagContext from "../../../Context/FeatureFlagContext";


const useStyles = makeStyles((theme) => ({
    '@media (max-width: 768px)': {
        productCard: {
            background: 'white',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '24px 5px',
            position: 'relative',
            borderBottom: '1px solid #BACAD1',
            [theme.breakpoints.up('md')]: {
                borderRadius: '0px',
                margin: '0px',
            },
            '& img': {
                height: '101px',
                maxWidth: '192px',
                objectFit: "contain",
                marginBottom: 24
            }
        },
        productInfo: {
            alignSelf: 'self-start',
            color: '#454545',
            fontSize: '16px',
            lineHeight: '16px',
            textAlign: 'start',
        },
        descriptionOld: {
            display: "flex",
            alignSelf: 'self-start',
            flexWrap: 'wrap',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
            color: '#454545',
            textAlign: 'start',
            marginBottom: 13,
            maxHeight: 35
        },
        description: {
            display: "flex",
            alignSelf: 'self-start',
            flexWrap: 'wrap',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
            color: '#454545',
            textAlign: 'start',
            height: 40,
        },
        removeIconWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
                overflow: 'inherit',
            },
        },
        button: {
            // padding: '8px 15px',
            // background: '#009FDF',
            // borderRadius: '4px',
            // color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
            fontWeight: 600,
            width: '100%',
            height: 34,
        },
        qtyWrapper: {
            color: '#ccc',
            borderRadius: 6,
            border: '1px solid #ccc',
            display: 'grid',
            gridTemplateColumns: '35px 15px 20px 15px',
            padding: '0 5px',
            alignItems: 'center',
            justifyContent: 'center',
        },
        applianceInfo: {
            alignSelf: 'self-start',
            color: '#454545',
            fontSize: '14px',
            lineHeight: '17px',
            textAlign: 'left',

            '& span': {
                color: '#009FDF',
                fontSize: '16px',
                lineHeight: '19px',
                fontWeight: 700,
            },
        },
        excVatText: {
            color: '#454545',
            fontWeight: 300,
            fontSize: '10px',
            display: 'inline',
        },
        manufactureCode: {
            fontSize: '14px',
            textAlign: 'left'
        },
        buyTradePriceChip: {
            padding: '4px 7px',
            borderRadius: '30px',
            background: '#E5F6FD',
            fontWeight: 500,
            fontSize: '10px',
            color: '#014360',
            margin: '0 0 13px -2px',

            '& svg': {
                color: '#1AB1F5',
                marginRight: '-2px',
            }
        },
    },
    '@media (min-width: 769px)': {
        productCard: {
            marginBottom: '0',
            width: 'auto',
            background: 'white',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '50px 10px',
            borderBottom: '1px solid #BACAD1',
            marginRight: '0px',
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                borderRadius: '0px',
                margin: '0px',
            },
            '& :hover': {
                '& + svg': {
                    display: 'block',
                }
            },
            '& img': {
                height: '113.32px',
                maxWidth: '165px',
                objectFit: "contain",
                marginBottom: 34
            }
        },
        productInfo: {
            alignSelf: 'self-start',
            color: '#454545',
            fontSize: '16px',
            lineHeight: '19px',
        },
        applianceInfo: {
            alignSelf: 'self-start',
            color: '#454545',
            fontSize: '14px',
            lineHeight: '17px',
            textAlign: 'left',
            textAlign: 'left',

            '& span': {
                color: '#009FDF',
                fontSize: '16px',
                lineHeight: '19px',
                fontWeight: 700,
            },
        },

        excVatText: {
            color: '#454545',
            fontWeight: 300,
            fontSize: '10px',
            display: 'inline',
        },
        manufactureCode: {
            fontSize: '14px',
            textAlign: 'left',
            marginBottom: 4,
        },
        descriptionOld: {
            display: "flex",
            alignSelf: 'self-start',
            flexWrap: 'wrap',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '22px',
            color: '#454545',
            marginBottom: 19,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'left',
            maxHeight: 45
        },
        description: {
            display: "flex",
            alignSelf: 'self-start',
            flexWrap: 'wrap',
            fontWeight: 400,
            fontSize: '15px',
            lineHeight: '22px',
            color: '#454545',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'left',
            height: 46,
        },
        buyTradePriceChip: {
            padding: '4px 7px',
            borderRadius: '30px',
            background: '#E5F6FD',
            fontWeight: 500,
            fontSize: '10px',
            color: '#014360',
            margin: '0 0 13px -2px',

            '& svg': {
                color: '#1AB1F5',
                marginRight: '-2px',
            }
        },
        removeIconWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
        },
        button: {
            // padding: '8px 15px',
            // background: '#009FDF',
            height: 34,
            // borderRadius: '4px',
            // color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
            fontWeight: 600,
            whiteSpace: 'nowrap',
            width: '100%',
        },
    },
    qtyIcon: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '22px',
        lineHeight: '27px',
        height: '100%',
    },
    wrapper: {
        marginTop: '4px',
        textAlign: 'start',
    },
    wrapperWithQty: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        height: 34,
        alignItems: 'center',
    },
    qty: {
        position: 'absolute',
        left: 10,
        color: '#454545',
        opacity: '50%',
        fontSize: '14px',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    like: {
        position: 'absolute',
        top: '35px',
        right: '27px',
    },
    diagramRef: {
        position: 'absolute',
        top: '80px',
        right: '28px',
        width: '24px',
        height: '24px',
        background: '#009FDF',
        borderRadius: '2px',
        padding: '2px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        fontSize: '16px',
        fontWeight: '700'
    },
    addText: {
        paddingLeft: '10px',
        fontWeight: '400',
        display: 'inline-block',
    },
    addToBasketModal: {
        height: 'fit-content',
    },
    popup: {
        position: 'fixed',
        zIndex: 10000,
        top: 16,
        right: 16,
        backgroundColor: '#fff',
        borderRadius: 8,
        border: '2px solid #009fdf',
        boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 16,
        width: 250,
    },
    show: {
        transition: 'opacity 0.5s',
        opacity: 1,
    },
    hide: {
        opacity: 0,
        pointerEvents: 'none',
    },
    partFits: {
        fontWeight: '600',
    },
    magnifyingGlass: {
        position: 'absolute',
        cursor: 'pointer',
        right: 30,
        top: isMobile() ? 120 : 160,
        display: isMobile() ? 'block' : 'none'
    },
    removeText: {
        fontSize: 10,
        fontWeight: 600,
        color: '#828282',
        paddingLeft: 5,
        cursor: 'pointer',
    },
    productOptionsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: 600,
        marginBottom: 7,
        marginTop: 7,
    },
    productTagContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '5px',
        width: '100%',
        justifyContent: 'flex-start',
        gap: 4,
        paddingBottom: 8
    }
}));

export default function ProductCard(props) {
    const {
        product,
        onAddToBasket,
        liked: wasLiked,
        toggleLike,
        desktop,
        onRemove,
        redirectToSearch,
        isAccountCompleted,
        restProp = {},
        quantity,
        showGroupCost = false,
        onRemoveFromBasket,
        diagramRef,
    } = props;
    const { specialButton } = restProp
    const featureFlagContext = useContext(FeatureFlagContext);
    const [qty, setQty] = useState();
    useEffect(()=>{
        setQty(quantity)
    },[quantity])

    const areBadgesEnabled = featureFlagContext.checkFeatures("allproducts-badges");
    
    const [isProductInfoModalOpen, setIsProductInfoModalOpen] = useState(false);
    const [showCompleteAccountPopUp, setShowCompleteAccountPopUp] = useState(false);
    const [cashBackModalOpen, setCashBackModalOpen] = useState(false);
    const [cashBackEmailSent, setCashBackEmailSent] = useState(false);


    const classes = useStyles();
    const modalId = `view-details${product.itemid || product.mpn}${desktop ? "-desk" : "-mobile"
        }`;

    const onToggleLike = useCallback(() => {
        toggleLike && toggleLike(product, wasLiked);
    }, [product, toggleLike]);

    const onAddToBasketItem = (obj) => {
        onAddToBasket && onAddToBasket(obj);
    };

    function addFirstElementToBasket() {
        if (isAccountCompleted) {
            setQty(1);
            onAddToBasketItem({ quantityChildValue: 1, item: product });
        } else {
            setShowCompleteAccountPopUp(true);
            setTimeout(() => {
                setShowCompleteAccountPopUp(false);
            }, 5000);
        }
    }

    function addQuantity() {
        setQty(qty + 1);
        onAddToBasketItem({ quantityChildValue: qty + 1, item: product });
    }

    function minusQuantity() {
        setQty(qty - 1);
        onRemoveFromBasket({ quantityChildValue: qty - 1, item: product });
    }
    function removeManufacturerCode(text) {
        return text?.replace(/\d{5,}$/g, '');
    }

    const onClickProduct = () => {
        setIsProductInfoModalOpen(true);
    }

    const onCloseProductInfoModal = () => {
        setIsProductInfoModalOpen(false);
    }


    const formatDate = (date) => {
        let convertedDate = ''
        if (date) convertedDate = moment(date).format("DD MMMM")

        return convertedDate
    }
    const [currentTextLength, setCurrentTextLength] = useState(1000)

    return (
        <div className={classes.productCard}>
            <img
                width={140}
                src={product.img_url || product.imageUrl ? product.img_url || product.imageUrl : NoImageAvailable}
                alt="product"
                className="product-card__img"
                onClick={product.img_url || product.imageUrl ? onClickProduct : null}
            />
            {product.img_url || product.imageUrl ?
                <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={classes.magnifyingGlass}
                    onClick={onClickProduct}
                >
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.7281 23.3399L19.3533 17.7276C21.0364 15.8482 22.0557 13.3929 22.0557 10.7069C22.0557 4.79314 17.1188 0 11.0278 0C4.93683 0 0 4.79314 0 10.7069C0 16.6206 4.93683 21.4137 11.0278 21.4137C13.6188 21.4137 16.0011 20.5457 17.8833 19.0936L23.2388 24.685C23.621 25.0842 24.2645 25.1071 24.6767 24.736C25.0867 24.3649 25.1103 23.7391 24.7281 23.3399ZM2.03426 10.7069C2.03426 5.88461 6.06103 1.97505 11.0278 1.97505C15.9946 1.97505 20.0214 5.88461 20.0214 10.7069C20.0214 15.5291 15.9946 19.4387 11.0278 19.4387C6.06103 19.4387 2.03426 15.5291 2.03426 10.7069Z" fill="#C4C4C4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9334 10.7072C17.9334 10.1614 17.4784 9.71964 16.9163 9.71964H12.0448V4.88596C12.0448 4.34023 11.5897 3.89844 11.0276 3.89844C10.4655 3.89844 10.0105 4.34023 10.0105 4.88596V9.71964H5.0319C4.4698 9.71964 4.01477 10.1614 4.01477 10.7072C4.01477 11.2529 4.4698 11.6947 5.0319 11.6947H10.0105V16.5284C10.0105 17.0741 10.4655 17.5159 11.0276 17.5159C11.5897 17.5159 12.0448 17.0741 12.0448 16.5284V11.6947H16.9163C17.4784 11.6947 17.9334 11.2529 17.9334 10.7072Z" fill="#C4C4C4" />
                </svg>
                : null}

            {areBadgesEnabled && <Box className={classes.productTagContainer}>
                {product.isCashBack && <ProductTag color="green" text="Cash Back" onClick={() => setCashBackModalOpen(true)} />}
                {product.isSpecialOffer && <ProductTag color="red" text="Offer" icon={ <LocalOfferOutlinedIcon />} />}
                {product.isInStock && <ProductTag color="yellow" text="In Stock" />}
            </Box>}


            <div style={isMobile() ? { alignSelf: "flex-start", width: "100%" } : { width: "100%" }}>
                <div className={classes.productInfo}></div>
                {showGroupCost
                    ? <div className={classes.description}>
                        <span>
                            {trimText(removeManufacturerCode(product.description), currentTextLength, false)}
                        </span>

                        {product.description.length > currentTextLength ? <span className="show-more-text-btn" onClick={() => setCurrentTextLength(prev => prev + 5)}>...</span> : null}
                    </div>
                    :
                    <div className={classes.descriptionOld}>
                        <span>{removeManufacturerCode(product.description)}</span>
                    </div>
                }

                {product.manufactureCode ?
                    <div className={classes.manufactureCode}> {product.manufactureCode}</div> : null
                }


                {showGroupCost ?
                    <div className={classes.applianceInfo}>
                        <span>{formatPrice(product.buyingGroupCost)}</span> <div className={classes.excVatText}>Exc. VAT</div>
                    </div> :
                    <div className={classes.applianceInfo}>
                        MPN:  {product.mpn}
                        <br />
                        {(product.man || product.app) && <>APPLIANCE:  {product.man} {product.app}</>}
                    </div>
                }
                <div
                    className={clsx(
                        ...[
                            classes.wrapper,
                            ...(!desktop ? [classes.wrapperMobile] : []),
                            ...(onRemove ? [classes.wrapperWithRemove] : []),
                        ]
                    )}
                >
                    {showGroupCost &&
                        <span className={classes.buyTradePriceChip}> <InfoIcon /> BuyTrade Price </span>
                    }


                    <div className={classes.productOptionsWrapper}>
                        {showGroupCost ?
                            <TechnicalSpecs product={product} />
                            : <PartFits className={classes.partFits} product={product} />// added onRemove as a prop, because only comes for requestedProducts part
                        }
                        {onRemove && (
                            <div
                                className={classes.removeIconWrapper}
                                onClick={() => onRemove(product)}
                            >
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 11L11 1M11 11L1 1" stroke="#828282" />
                                </svg>
                                <span className={classes.removeText}>Remove</span>
                            </div>
                        )}
                    </div>
                    {!specialButton ? (
                        qty === 0 ? (
                            <button className={`${classes.button} button-medium button-light-blue`} onClick={addFirstElementToBasket}>
                                <svg
                                    width="17"
                                    height="14"
                                    viewBox="0 0 17 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="7.35373" cy="12.8459" r="1.05051" fill="white" />
                                    <ellipse
                                        cx="11.5554"
                                        cy="12.8459"
                                        rx="1.05053"
                                        ry="1.05051"
                                        fill="white"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M0 2.20962H2.16257L4.8964 9.91405C4.98935 10.176 5.2372 10.3511 5.51516 10.3511H13.394C13.6554 10.3511 13.8919 10.196 13.9961 9.95628L16.3936 4.44199H14.9617L12.9635 9.03792H5.97886L3.24504 1.33349C3.15208 1.07153 2.90424 0.896484 2.62627 0.896484H0V2.20962Z"
                                        fill="white"
                                    />
                                </svg>
                                <div className={classes.addText}>Add to order</div>
                            </button>
                        ) : (
                            <div className={classes.wrapperWithQty}>
                                <button
                                    className={`${classes.button} button-medium button-light-blue`}
                                    style={{ marginLeft: 0 }}
                                    onClick={minusQuantity}
                                >
                                    <span className={classes.qtyIcon}>-</span>
                                </button>
                                    <span style={{ textAlign: 'center' }}>{qty}</span>
                                <button
                                    className={`${classes.button} button-medium button-light-blue`}
                                    style={{ marginLeft: 0 }}
                                    onClick={addQuantity}
                                >
                                    <span className={classes.qtyIcon}>+</span>
                                </button>
                            </div>
                        )
                    ) : (
                        <button className={`${classes.button} button-medium button-light-blue`} style={{ cursor: "default" }}>
                            <div className={classes.addText}>{
                                specialButton?.page === 'requestedProducts'
                                    ? specialButton.text + formatDate(product.createdOn)
                                    : specialButton.text || 'Add to order'
                            }</div>
                        </button>
                    )}
                </div>
                {toggleLike && (
                    <button type="button" className={classes.like} onClick={onToggleLike}>
                        {wasLiked ? (
                            <svg
                                width={isMobile() ? "28" : "26"}
                                height={isMobile() ? "27" : "25"}
                                viewBox="0 0 29 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.8274 1.36274C14.1026 0.805303 14.8974 0.805305 15.1726 1.36274L18.8808 8.87654C18.9901 9.09789 19.2013 9.25132 19.4455 9.28682L27.7375 10.4917C28.3527 10.5811 28.5983 11.3371 28.1532 11.771L22.153 17.6197C21.9763 17.792 21.8956 18.0402 21.9373 18.2835L23.3538 26.542C23.4589 27.1546 22.8158 27.6219 22.2656 27.3326L14.849 23.4335C14.6305 23.3186 14.3695 23.3186 14.151 23.4335L6.73443 27.3326C6.18421 27.6219 5.54114 27.1546 5.64622 26.542L7.06266 18.2835C7.10439 18.0402 7.02373 17.792 6.84696 17.6197L0.846835 11.771C0.401697 11.3371 0.647331 10.5811 1.2625 10.4917L9.55446 9.28682C9.79875 9.25132 10.0099 9.09789 10.1192 8.87654L13.8274 1.36274Z"
                                    fill="#00226A"
                                />
                            </svg>
                        ) : (
                            <svg
                                width={isMobile() ? "28" : "26"}
                                height={isMobile() ? "27" : "25"}
                                viewBox="0 0 55 54"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11.8549 53.1701C11.4475 53.1701 11.0446 53.0447 10.6998 52.794C10.091 52.3507 9.79099 51.612 9.91634 50.8733L12.7817 34.1692C12.7907 34.111 12.7728 34.0528 12.7325 34.0125L0.595014 22.184C0.0532851 21.6557 -0.134747 20.8857 0.0980627 20.1693C0.330872 19.453 0.939775 18.9381 1.68297 18.8307L18.4542 16.3951C18.5124 16.3862 18.5616 16.3504 18.584 16.3011L26.0832 1.10137C26.4279 0.420848 27.1039 0 27.8561 0C28.6082 0 29.2843 0.420848 29.6201 1.09689L37.1192 16.2967C37.1461 16.3504 37.1953 16.3862 37.2491 16.3907L54.0203 18.8262C54.768 18.9337 55.3724 19.4485 55.6052 20.1649C55.838 20.8812 55.6455 21.6557 55.1082 22.1796L42.9708 34.0081C42.9305 34.0484 42.9126 34.1066 42.9216 34.1648L45.7869 50.8688C45.9122 51.612 45.6168 52.3463 45.0034 52.7895C44.39 53.2327 43.6021 53.2909 42.935 52.9373L27.9322 45.0486C27.8829 45.0217 27.8203 45.0217 27.771 45.0486L12.7683 52.9373C12.4817 53.0939 12.1683 53.1701 11.8549 53.1701ZM27.8561 43.2443C28.1695 43.2443 28.4829 43.3204 28.7694 43.4682L43.7722 51.3524C43.8349 51.3882 43.8976 51.3792 43.9558 51.3389C44.014 51.2986 44.0363 51.2404 44.0274 51.1688L41.1621 34.4647C41.0546 33.829 41.265 33.1753 41.7262 32.7231L53.8636 20.8946C53.9173 20.8454 53.9307 20.7827 53.9084 20.7156C53.886 20.6484 53.8367 20.6081 53.7651 20.5947L36.9939 18.1591C36.3536 18.0651 35.8029 17.6666 35.5164 17.0846L28.0128 1.88934C27.9501 1.7595 27.7621 1.7595 27.6994 1.88934L20.2003 17.0846C19.9137 17.6666 19.3586 18.0651 18.7183 18.1591L1.94711 20.5947C1.87547 20.6036 1.82622 20.6439 1.80384 20.7156C1.78145 20.7872 1.7949 20.8454 1.84862 20.8946L13.986 32.7231C14.4517 33.1753 14.6621 33.8245 14.5502 34.4647L11.6848 51.1688C11.6714 51.2404 11.6938 51.2986 11.7565 51.3389C11.8147 51.3792 11.8773 51.3837 11.94 51.3524L26.9428 43.4637C27.2293 43.3204 27.5427 43.2443 27.8561 43.2443Z"
                                    fill="#00226A"
                                />
                            </svg>
                        )}
                    </button>
                )}
                {product.diagram_placement_ref && (
                    <div className={classes.diagramRef}>
                        <div>{product.diagram_placement_ref}</div>
                    </div>
                )}

            </div>
            <ModalWindow id={modalId} className={classes.addToBasketModal}>
                <RecentlyAdded
                    product={product}
                    toggleLike={onToggleLike}
                    liked={wasLiked}
                    qty={qty}
                    setQty={setQty}
                    addQuantity={addQuantity}
                    minusQuantity={minusQuantity}
                    addToBasket={() =>
                        onAddToBasketItem({ quantityChildValue: qty, item: product })
                    }
                    redirectToSearch={redirectToSearch}
                />
            </ModalWindow>

            <CashBackModal
                modalOpen={cashBackModalOpen}
                handleClose={() => {
                    setCashBackEmailSent(false);
                    setCashBackModalOpen(false);
                 }}
                onRegister={() => {
                    SparesAvailabilityService.JoinBuyingGroupEmail();
                    setCashBackEmailSent(true);
                }}
                emailSent={cashBackEmailSent}
            />

            {isProductInfoModalOpen && (
                <ProductInfoModal product={product} onClose={onCloseProductInfoModal} />
            )}
            {showCompleteAccountPopUp && <CompleteAccountPopUp />}
        </div>
    );
}
