/**
 * @order {object} value
 * @returns {string}
 * Get total quantity of items in basket
 */
const getTotalQty = (order) => {
  const qty = order
      ? order.lines.filter(l => l.basketLineType.name === "Product" && l.basketLineType.considerForAvailability === true)
          .reduce((acc, curr) => (acc += curr.qty), 0)
    : 0;

  return `${qty} ${qty === 1 ? "item" : "items"}`;
};

export default getTotalQty;
