import React from "react";
import TextBoxWithLabel from "../Form/TextBoxWithLabel";
import SelectListStaticWithLabel from "../Form/SelectListStaticWithLabel";
import { isMobile } from "../Media/Media";
import OrganisationSupplierService from "../../Services/OrganisationSupplierService";
import { MerchantIDs } from "../../constants/MerchantIDs";
import ErrorMessage from "../Info/ErrorMessage";
import Checkbox from "../Form/Checkbox";
import AccountService from "../../Services/AccountService";
import SignatureCanvas from 'react-signature-canvas';
import convertToFormData from "../../Helpers/network/convertToFormData";
const $ = window.jQuery;

const validateSupplierBtnText = {
    wolseley: "Click to Validate Account (takes 2-3 days)",
    cityPlumbing: "Click to Automatically Validate Account",
};

class InviteSupplier extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.getDefaultState();

        this.bindData();
        this.getOrganisation();

    }
    componentDidMount() {
        $('#bg-signature').jSignature();
    }

    getDefaultState() {
        const cityPlumbingId = MerchantIDs.cityPlumbing;
        if (this.props.isEdit && this.props.item) {
            return {
                organisationID: this.props.item.organisationID,
                merchantAccountId: this.props.item.id,
                isEdit: this.props.isEdit,
                merchantId: this.props.item.supplierID,
                accountNumber: this.props.item.accountNumber,
                type: this.props.item.typeID,
                branch: this.props.item.branchID,
                postcode: '',
                phoneNumber: this.props.item.telephoneNumber,
                types: [],
                suppliers: [],
                branches: [],
                cityPlumbingId: cityPlumbingId,
                selectedCityPlumbing: this.props.item.supplierID == cityPlumbingId,
                IsAccountValidated: this.props.item.isValidated,
                isValidateBtnDisabled: !this.areFieldsValidated(
                    this.props.item,
                    this.getRequiredFields.bind(this, this.props.item.supplierID)
                ),
                sparesFinder: this.props.item.sparesFinder,
                confirm: false,
                hasJoinedBuyingGroup: true,
                organisation: {},
                signature: ""
            };
        } else {
            return {
                isEdit: false,
                merchantId: "",
                accountNumber: "",
                type: null,
                branch: "",
                postcode: "",
                phoneNumber: "",
                types: [],
                suppliers: [],
                branches: [],
                cityPlumbingId: cityPlumbingId,
                selectedCityPlumbing: false,
                IsAccountValidated: 0,
                isValidateBtnDisabled: true,
                sparesFinder: false,
                confirm: false,
                hasJoinedBuyingGroup: true,
                organisation: {},
                signature : ""
            };
        }
    }

    bindData() {
        this.hasJoinedBuyingGroup();
        this.getSuppliers();
        this.getTypes();
        this.getBranches();
        
    }

    getTypes() {
        OrganisationSupplierService.listTypes().then((response) => {
            this.setState({
                types: response.collection,
            });
        });
    }

    getSuppliers() {
        OrganisationSupplierService.listSuppliers().then((response) => {
            this.setState({
                suppliers: response
            });
        });
    }

    getBranches() {
        const { merchantId } = this.state;
        if (merchantId !== "" && merchantId !== null) {
            OrganisationSupplierService.listBranches(merchantId).then((response) => {
                this.setState({
                    branches: response.collection,
                });
            });
        }
    }

    changeMerchant(e) {
        const { cityPlumbingId } = this.state;
        const selectedCityPlumbing = e.value == cityPlumbingId;
        this.setState(
            {
                merchantId: e.value,
                phoneNumberError: !selectedCityPlumbing
                    ? false
                    : this.state.phoneNumber == true
                        ? true
                        : false,
                postcodeError: !selectedCityPlumbing
                    ? false
                    : this.state.phoneNumber == true
                        ? true
                        : false,
                selectedCityPlumbing: selectedCityPlumbing,
            },
            () => {
                this.setState(
                    {
                        branch: "",
                    },
                    () => {
                        this.validateInputs();
                        this.getBranches();
                    }
                );
            }
        );
    }

    changeAccountNo(e) {
        this.setState(
            {
                accountNumber: e,
                IsAccountValidated: 0,
                accountNumberError: "",
            },
            () => {
                this.validateInputs();
            }
        );
    }

    changePhoneNumber(e) {
        this.setState(
            {
                phoneNumber: e,
                IsAccountValidated: 0,
                phoneNumberError: "",
            },
            () => {
                if (this.state.selectedCityPlumbing) {
                    this.validateInputs();
                }
            }
        );
    }

    changeType(e) {
        this.setState({
            type: e.value,
        });
    }

    changePostcode(e) {
        this.setState(
            {
                postcode: e,
                IsAccountValidated: 0,
                postcodeError: "",
            },
            () => {
                this.validateInputs();
            }
        );
    }

    changeBranch(e) {
        this.setState(
            {
                branch: e.value,
                branchError: "",
            },
            () => {
                this.validateInputs();
            }
        );
    }

    onCloseModal() {
        this.setState(this.getDefaultState(), () => {
            this.bindData();
        });
    }

    validateInputs() {
        this.setState({
            isValidateBtnDisabled: !this.areStateFieldsValidated(),
        });
    }

    getRequiredFields = (merchantId) => {
        if (merchantId == MerchantIDs.wolseley) {
            return ["branch", "accountNumber"];
        } else {
            return ["branch", "accountNumber", "postcode", "phoneNumber"];
        }
    };

    areStateFieldsValidated = () => {
        return this.areFieldsValidated(
            this.state,
            this.getRequiredFields.bind(this, this.state.merchantId)
        );
    };

    areFieldsValidated = (sourceData, onGetRequiredFields) => {
        const requiredFields = onGetRequiredFields();

        var toJoingBGValidity = true;

        if (!this.state?.isEdit) {
            toJoingBGValidity = (!this.state?.hasJoinedBuyingGroup ? (this.state?.confirm == true && this.state?.signature != '') : true);
        }

        const isValidated = requiredFields.every((item) => sourceData[item] != "") && toJoingBGValidity;

        return isValidated;
    };

    validationFields = () => {
        const requiredFields = this.getRequiredFields(this.state.merchantId);
        let errorFields = {};

        requiredFields.forEach((item) => {
            errorFields[`${item}Error`] = !this.state[item];
        });
        return {
            isValidated: !Object.values(errorFields).some((item) => Boolean(item)),
            errorFields,
        };
    };

    changeTermsConditions(e) {
        this.setState({
            confirm: e.value,
        },
        () => {
            this.validateInputs();
        })
    }

    hasJoinedBuyingGroup() {
        AccountService.getOrganisation().then((response) =>
        {
            this.setState({
                hasJoinedBuyingGroup: response.buyingGroupStatusID > 1
            });
        })
    }

    getOrganisation = () => {
        AccountService.getOrganisation().then((response) => {
            const res = {
                postcode: response.postCode,
                address1: response.address1,
                address2: response.address2,
                address3: response.address3,
                town: response.townCity,
                fullName: response.computedContactUser || ""
            };
            this.setState(
                {
                    ...res, organisation: res
                }
            );
        });
    };

    joinBuyingGroup = () => {
        const data = {
            FullName: this.state.organisation.fullName,
            SignatureImage: this.state.signature,
            Address3: this.state.organisation.address3,
            Address1: this.state.organisation.address1,
            Address2: this.state.organisation.address2,
            Town: this.state.organisation.town,
            Postcode: this.state.organisation.postcode,
            SendBuyingGroupEmail: true,
            SignupFormTrigger: 'BuyTrade Form'
        };

        AccountService.joinBuyingGroup(convertToFormData(data)).then((response) => {
            this.showActionResult(response, this.props.onClose);
        });
    }

    onClear = () => {
        this.sigCanvas.clear();
        this.setState({ signature: '' });
        this.validateInputs();
    };

    onSignatureEnd(d) {
        this.setState({
            signature: this.sigCanvas.toDataURL('image/jpeg', 100),
        },
        () => 
        {
            this.validateInputs();
        });
    }

    render() {
        const {
            accountNumberError,
            merchantId,
            postcodeError,
            branchError,
            phoneNumberError,
            isEdit,
            sparesFinder,
            selectedCityPlumbing,
            isValidateBtnDisabled
        } = this.state;
        const disabled = isEdit;
        return (
            <div style={isMobile() ? { paddingBottom: 140 } : {}}>
                <h1 className="create-account-header">
                    {" "}
                    {!isEdit ? "Add New" : "Edit"} Credit Account
                </h1>
                <br />
                <div className="row">
                    <div className="col-md-6 padding-right-8">
                        <SelectListStaticWithLabel
                            valueProperty="id"
                            textProperty="name"
                            collection={this.state.suppliers}
                            onChange={this.changeMerchant.bind(this)}
                            value={this.state.merchantId}
                            className={
                                isMobile()
                                    ? "select-list-account margin-bottom-10"
                                    : "select-list-account"
                            }
                            disabled={disabled}
                            placeholder="- Select Merchant"
                            newStyle
                        />
                    </div>
                    <div className="col-md-6 padding-left-8">
                        <SelectListStaticWithLabel
                            valueProperty="id"
                            textProperty={["name", "supplierCode"]}
                            collection={this.state.branches}
                            onChange={this.changeBranch.bind(this)}
                            value={this.state.branch}
                            className={
                                isMobile()
                                    ? "select-list-account margin-bottom-10"
                                    : "select-list-account"
                            }
                            placeholder="- Select Branch"
                            newStyle
                            shouldSelect={branchError}
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-md-6 padding-right-8">
                        <TextBoxWithLabel
                            labelClassName={`customer-label ${accountNumberError ? "customer-label-error" : ""
                                }`}
                            addCssClass={`group__row merchant-account ${isMobile() ? "margin-top-30" : ""
                                }  ${accountNumberError ? "group__row__error error-icon" : ""}`}
                            label="ACCOUNT NUMBER"
                            placeholder="eg 1234X56"
                            value={this.state.accountNumber}
                            onChange={this.changeAccountNo.bind(this)}
                            newStyle
                            thinPlaceholder
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-md-6 padding-left-8"></div>
                    <div className="col-md-6 padding-right-8">
                        <span
                            className="with-tooltip"
                            data-tooltip="This will help us to vaildate your account"
                        >
                            <TextBoxWithLabel
                                labelClassName={`customer-label ${postcodeError ? "customer-label-error" : ""
                                    }`}
                                addCssClass={`group__row merchant-account ${isMobile() ? "margin-top-30" : ""
                                    }  ${postcodeError ? "group__row__error" : ""}`}
                                label="ACCOUNT POSTCODE"
                                placeholder="Enter postcode"
                                value={this.state.postcode}
                                onChange={this.changePostcode.bind(this)}
                                newStyle
                                thinPlaceholder
                                disabled={disabled}
                            />
                        </span>
                    </div>
                    <div className="col-md-6 padding-left-8">
                        <span
                            className="with-tooltip"
                            data-tooltip="This will help us to vaildate your account"
                        >
                            <TextBoxWithLabel
                                labelClassName={`customer-label ${phoneNumberError ? "customer-label-error" : ""
                                    }`}
                                addCssClass={`group__row merchant-account ${isMobile() ? "margin-top-30" : ""
                                    }  ${phoneNumberError ? "group__row__error" : ""}`}
                                placeholder="Enter phone number"
                                label="ACCOUNT PHONE NUMBER"
                                value={this.state.phoneNumber}
                                onChange={this.changePhoneNumber.bind(this)}
                                type="number"
                                newStyle
                                thinPlaceholder
                                disabled={disabled}
                            />
                        </span>
                    </div>
                    {(!this.state.isEdit && !this.state.hasJoinedBuyingGroup)&& (
                        <div className="col-md-12">
                            <label className="checkbox">
                                <Checkbox
                                    additionalStyle="checkbox-clear-wrapper"
                                    onChange={this.changeTermsConditions.bind(this)}
                                    titlePlacement="aside"
                                    filled
                                    value={this.state.confirm}
                                    title={
                                        <p className="checkbox__name">
                                            I wish to link my credit account to BuyTrade to take advantage of the benefits of the TradeHelp Buying Group,
                                            and I agree to the terms contained on this
                                            <a href="https://buytrade.co.uk/buying-group-terms-and-conditions/" target="_blank"> link </a>.
                                        </p>
                                    }
                                />
                            </label>

                           <div className="signatureWrapper p-3 showSig">
                                <div className="sigToolbar">
                                    <h3 style={{ fontSize: 16 }}>Signature</h3>
                                    <button
                                        type="button"
                                        onClick={() => { this.onClear(false); }}
                                        className="btn-grey"
                                    >
                                        Clear Signature
                                    </button>
                                </div>

                                <SignatureCanvas
                                    ref={(ref) => {
                                        this.sigCanvas = ref;
                                    }}
                                    canvasProps={{ height: 130, className: 'sigCanvas' }}
                                    onEnd={this.onSignatureEnd.bind(this)}
                                    backgroundColor={'#ffffff'}
                                />

                            </div>
                        </div>


                    )}
                </div>
                <div className='col-md-12 merchant-account-validation-error-wrapper'>
                    <ErrorMessage text='Account number not recognised' visible={accountNumberError} paddingBottom={10} />
                </div>
                <div className="col-md-12">
                    {this.state.IsAccountValidated === 1 && (
                        <p className="validate-success">
                            {"Your account has been validated" +
                                (isMobile() ? "" : " with the supplier") +
                                "."}
                        </p>
                    )}
                </div>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: isMobile() ? "column" : "row",
                        justifyContent: "space-between",
                        margin: isMobile() ? "30px 0 0 0" : "10px 0",
                    }}
                >
                    {merchantId && !disabled && (
                        <button
                            className={"btn btn-primary button-large button-light-blue"}
                            style={{
                                width: isMobile() ? "100%" : "100%",
                                margin: 0,
                                fontSize: 14,
                            }}
                            onClick={this.onValidateAccount.bind(this)}
                            disabled={isValidateBtnDisabled}
                        >
                            {selectedCityPlumbing
                                ? validateSupplierBtnText.cityPlumbing
                                : validateSupplierBtnText.wolseley}
                        </button>
                    )}
                </div>
            </div>
        );
    }

    onValidateAccount() {
        const {
            isEdit,
            merchantAccountId,
            merchantId,
            accountNumber,
            phoneNumber,
            branch,
            type,
            postcode,
            hasJoinedBuyingGroup,
            confirm,
            signature
        } = this.state;
        const { onClose } = this.props;

        if (!accountNumber) {
            return this.setState({ accountNumberError: true })
        }

        var toJoingBGValidity = true;

        if (!isEdit) {
            toJoingBGValidity = !hasJoinedBuyingGroup ? (confirm == true && signature != '') : true;
        }

        const validationResult = this.validationFields();
        if (validationResult.isValidated && toJoingBGValidity) {
            const params = {
                accountNumber: accountNumber,
                supplierID: merchantId,
                typeID: type,
                postcode,
                branchID: branch,
                telephoneNumber: phoneNumber,
            };
            if (isEdit) {
                this.onEditMerchantAccount({ id: merchantAccountId });
            } else {
                this.onCreateMerchantAccount(params);
            }
        } else {
            this.setState({ ...validationResult.errorFields });
            this.props.setValidationError(["Please complete all required fields", "Error"]);
        }
    }

  onCreateMerchantAccount(item) {
      OrganisationSupplierService.create(item).then((res) => {
          if (!res.success || this.state.hasJoinedBuyingGroup) {
              this.showActionResult(res, this.props.onClose)
          } else {
              this.joinBuyingGroup();
          }

      });
  }

  onEditMerchantAccount(item) {
    OrganisationSupplierService.update(item).then((res) =>
      this.showActionResult(res, this.props.onClose)
    );
  }

    showActionResult = (res, callback) => {
        if (res.success) { } else {
            this.props.setValidationError([res.message, "Error"]);
        }
        callback();
    }
}

export default InviteSupplier;