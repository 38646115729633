import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function QtyPlus(props) {
  return (
    <SvgIcon {...props}>
          <ellipse cx="10.3175" cy="9.77529" rx="9.90244" ry="9.64736" fill="currentColour" />
          <rect x="9.49219" y="4.14746" width="1.65041" height="11.2553" rx="0.825204" fill="white" />
          <rect x="16.0938" y="8.97119" width="1.60789" height="11.5529" rx="0.803947" transform="rotate(90 16.0938 8.97119)" fill="white" />
    </SvgIcon>
  );
}

