import { makeStyles } from "@material-ui/styles";
import Portal from "../../Helpers/modal/Portal";

const useStyles = makeStyles(() => ({
  modalWrapper: {
    "&::before": {
      content: '""',
      display: "inline-block",
      height: "100%",
      verticalAlign: "middle",
      marginRight: "-0.05em",
    },
    position: "fixed",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    width: "100%",
    height: "100%",
    overflow: "auto",
    zIndex: "1000",
    padding: "20px",
    boxSizing: "border-box",
    backgroundColor: "#4e4e4e77",
    textAlign: "center",
  },
  "": {},
  modalMain: {
    display: "inline-block",
    verticalAlign: "middle",
    position: "relative",
    zIndex: "2000",
    maxWidth: "569px",
    boxSizing: "border-box",
    width: "90%",
    background: "#fff",
    padding: "60px 40px",
    "-webkit-border-radius": "8px",
    "-moz-border-radius": "8px",
    "-o-border-radius": "8px",
    "-ms-border-radius": "8px",
    borderRadius: "31px !important",
    "-webkit-box-shadow": "0 0 25px rgba(0, 0, 0, 0.14)",
    "-moz-box-shadow": "0 0 25px rgba(0, 0, 0, 0.14)",
    "-o-box-shadow": "0 0 25px rgba(0, 0, 0, 0.14)",
    "-ms-box-shadow": "0 0 25px rgba(0, 0, 0, 0.14)",
    boxShadow: "0 0 25px rgba(0, 0, 0, 0.14)",
    textAlign: "left",
    paddingBottom: "50px",
  },
  modalClose: {
    position: "absolute",
    top: "40px",
    right: "30px",
  },
}));

const ModalComp = ({ children, onClose, onCloseOnBackground, className, styles }) => {
  const classes = useStyles();

  return (
    <>
      <Portal>
        <div className={classes.modalWrapper} onClick={onCloseOnBackground}>
          <div style={styles} className={`${classes.modalMain} ${className}`}>
            <a onClick={onClose} className={classes.modalClose}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 19L19 1M19 19L1 1" stroke="#007AAB"/>
              </svg>
            </a>
            {children}
          </div>
        </div>
      </Portal>
    </>
  );
};

export default ModalComp;
