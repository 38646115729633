import React, {useState} from "react";
import NoImage from "../../../assets/img/no_image_available.png";
import ButtonsQuantity from "../../Button/ButtonsQuantity";
import QuantityButtons from "./QuantityButtons/QuantityButtons";
import DeleteButton from "./DeleteButton/DeleteButton";
import ExclamationRed from "../../../assets/img/exclamation-red.svg";


function BasketRow({
    imageUrl,
    code,
    description,
    qty,
    saveForLaterOnClick,
    saveForLaterError,
    onDelete,
    increaseQty,
    decreaseQty,
    featureFlagContext
}) {

    return (

        <div className="newBasketGridRow">

            <div className="basketImage">
                <img src={imageUrl ? imageUrl : NoImage} alt={description} />
            </div>

            <div className="basketCode">
                {code}
            </div>

            <div className="basketDescription">
                {description}
            </div>

            <div className="basketDelete">
                <DeleteButton onDelete={onDelete} />
            </div>

            {featureFlagContext.checkFeatures("saveforlater-search") && <div className="basketSaveForLater" >
                {!saveForLaterError ?
                    <div className="saveForLater" onClick={saveForLaterOnClick} >
                        Save for later
                    </div>
                    :
                    <div className="saveForLaterError">
                        <img src={ExclamationRed} alt="" />
                        <span>There was a problem</span>
                    </div>
                }
            </div>}


            <div className="basketQty">
                <QuantityButtons qty={qty} increaseQty={increaseQty} decreaseQty={decreaseQty} />
            </div>


        </div>

    )
}




export default BasketRow