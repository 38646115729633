/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useRef, useState, useContext } from "react";
import Wrapper from "./Components/Layout/Wrapper";
import { useTheme } from '@mui/material/styles';
import * as signalR from "@aspnet/signalr";
import markGreen from './assets/img/mark-green.png';
import markGrey from './assets/img/mark-grey.png';
import markYellow from './assets/img/orange_circle.png';
import BasketService from "./Services/BasketService";
import { isMobile } from "./Components/Media/Media";
import { useHistory } from "react-router-dom";
import Loader from "./Components/Layout/Loader";
import AvailabilityService from "./Services/AvailabilityService";
import Location from "./Components/Availability/Location";
import ApiAddressianService from "./Services/ApiAddressianService";
import AccountService from "./Services/AccountService";
import SortDropDown from "./Components/Layout/SortDropDown";
import getBasketCount from "./Helpers/common/getBasketCount";
import RequestedProductsService from "./Services/RequestedProductsService";
import Header from "./Components/Header/Header";
import moment from "moment";
import BranchDetailModal from './Components/Modal/DeleteModal';
import NewConfirmModal from "./Components/Modal/NewConfirmModal";
import buttonArrowIcon from "./assets/img/button-arrow.svg";
import arrowDetailsIcon from "./assets/img/arrow-details.svg";
import binIcon from "./assets/img/bin.svg";
import infoIcon from "./assets/img/info.svg";
import SubscribeForAlternativeModal from './Components/Modal/SubscribeForAlternativeModal';
import DeliveryQuoteModal from "./Components/Checkout/DeliveryQuoteModal";
import ByBoxModal from "./Components/Checkout/ByBoxModal";
import BasketTimedOutModal from "./Components/Modal/BasketTimedOutModal";
import FeatureFlagContext from "./Context/FeatureFlagContext";
import OnePriceBranchRow from "./Components/Availability/OnePriceBranchRow/OnePriceBranchRow";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import ReactGA from "react-ga4"
import configurationService from './Services/ConfigurationService'

const SELECT_VALUES = [
    { id: 0, text: "Closest" },
    { id: 1, text: "Product availability" },
    { id: 2, text: "Price (low to high)" },
    { id: 3, text: "Price (high to low)" },
    { id: 4, text: "Merchant (City)" },
    { id: 5, text: "Merchant (Wolseley)" },
    { id: 6, text: "Merchant (Other)" }
];

const DEFAULT_GEOLOCATION = {
    Longitude: -3.01903,
    Latitude: 51.66476
};

const TIMER_DURATION_IN_MS = 300000;

const ITEM_AVAILABILITY = ['sameDayAvailability', 'nextDayAvailability', 'nextDayDelivery', 'futureDateAvailability', 'sameDayAvailability', 'nextDayDelivery'];

const FULFILMENT_OPTION_TYPES = [
    {
        id: 0,
        description: 'to collect from ',
        description_saturday_collection: 'to collect before noon ' // The Saturday collection options
    },
    {
        id: 1,
        description: 'to collect from ',
        description_saturday_collection: 'to collect before noon ' // The Saturday collection options
    },
    {
        id: 2,
        description: 'for delivery from '
    },
    {
        id: 3,
        description: 'to collect from '
    },
    {
        id: 4,
        description: 'EXPRESS SAME-DAY DELIVERY '
    },
    {
        id: 5,
        description: 'ByBox Delivery'
    }
]

const Availability = (props) => {
    const history = useHistory();
    const timer = useRef(null);

    const theme = useTheme();

    const featureFlagContext = useContext(FeatureFlagContext);
    const isAllProducts = featureFlagContext.checkFeatures("all-products");
    const [availability, setAvailability] = useState([]);
    const [manufacturerCode, setManufacturerCode] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [sortedAvailability, setSortedAvailability] = useState([]);
    const [selected, setSelected] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [sortState, setSortState] = useState({ id: 0 });
    const [detailsRowId, setDetailsRowId] = useState(-1);
    const [basket, setBasket] = useState({ lines: [] });
    const [allItemsCount, setAllItemsCount] = useState(0);
    const [showProceedToCheckout, setShowProceedToCheckout] = useState(false);
    const [proceedPopupData, setProceedPopupData] = useState(null);
    const [geolocationInfo, setGeolocationInfo] = useState({
        Longitude: 0,
        Latitude: 0,
        getGeolocationEffected: false
    });
    const [showByBoxModal, setShowByBoxModal] = useState(false)
    const [showDeliveryQuoteModal, setShowDeliveryQuoteModal] = useState(false);
    const [deliveryQuoteBranch, setDeliveryQuoteBranch] = useState({});
    const [byBoxBranch, setByBoxBranch] = useState({});
    const [selectedDeliveryQuoteIndex, setSelectedDeliveryQuoteIndex] = useState(0);
    const [noResults, setNoResults] = useState(false);
    const [loading, setLoading] = useState(false);
    const [byBoxEnabled, setByBoxEnabled] = useState(false)
    const [postcode, setPostcode] = useState('');
    const [subscribeForAlternativeModal, setSubscribeForAlternativeModal] = useState(false);
    const [requestedProduct, setRequestedProduct] = useState(null);
    const [requestedProductsList, setRequestedProductsList] = useState([]);
    const [requestedProductBranchId, setRequestedProductBranchId] = useState(null);
    const [branchOpenDate, setBranchOpenDate] = useState(null);
    const [branchCloseDate, setBranchCloseDate] = useState(null);
    const [isExpired, setIsExpired] = useState(false)
    const expiryTimer = useRef(null)
    const countdownIntervalId = useRef(null)
    const [countDown, setCountDownText] = useState(null)
    const [expirationTime, setExpirationTime] = useState(null)

    useEffect(() => {
        getBasket()
    }, []);

    useEffect(() => {
        if (availability.length > 0) {

            expiryTimer.current = setTimeout(() => {
                setIsExpired(true)
            }, TIMER_DURATION_IN_MS)
            return () => clearInterval(expiryTimer.current)
        }

        let start = new Date();
        start.setSeconds(start.getSeconds() + TIMER_DURATION_IN_MS / 1000)
        setExpirationTime(TIMER_DURATION_IN_MS / 1000)
        countdownIntervalId.current = setInterval(() => {
            let deltaInMs = start - Date.now();
            const seconds = parseInt(deltaInMs / 1000) % 60
            const diff = parseInt(deltaInMs / 1000 / 60) + ((seconds?.toString().length ?? 0) == 1 ? ":0" : ":") + seconds
            if (deltaInMs >= 0)
                setCountDownText(diff)
            else clearInterval(countdownIntervalId.current)
        }, 1000);
    }, [availability])

    useEffect(() => {
        setSortedAvailability(sortAvailabiity(availability));
    }, [sortState.id]);

    useEffect(() => {
        setSortedAvailability(sortAvailabiity(availability));
    }, [availability.length]);

    useEffect(() => {
        const selectedItems = sortedAvailability.map(({
            futureDateAvailableQty,
            futureDayAvailableQty,
            nextDayAvailableQty,
            nextDayDeliveryAvailableQty,
            sameDayAvailableQty,
            branch,
            sameDayDateTime
        }) => {
            const weekDayName = moment().format('dddd')
            var openDate = new Date(), closeDate = new Date(), today = new Date();
            openDate.setHours(branch[`open${weekDayName}`].hours);
            openDate.setMinutes(branch[`open${weekDayName}`].minutes);
            openDate.setSeconds(branch[`open${weekDayName}`].seconds);
            closeDate.setHours(branch[`close${weekDayName}`].hours);
            closeDate.setMinutes(branch[`close${weekDayName}`].minutes);
            closeDate.setSeconds(branch[`close${weekDayName}`].seconds);

            if (openDate.getHours() === 0) {
                if (today.getDay() == 6 || today.getDay() == 0)
                    openDate.setHours(11);
                else
                    openDate.setHours(8);
            }

            if (closeDate.getHours() === 0)
                closeDate.setHours(20);

            var openDiff = moment(today).diff(openDate, 'hours');
            var closeDiff = moment(closeDate).diff(moment(today), 'hours');

            if (branch.branchSameDayDeliveryTypes?.length > 0 && openDiff >= 0 && closeDiff > 0 && sameDayAvailableQty && moment(sameDayDateTime).isSame(moment(), 'day')) return 4;
            else if (sameDayAvailableQty) return 0;
            if (nextDayAvailableQty) return 1;
            if (nextDayDeliveryAvailableQty) return 2;
            if (futureDateAvailableQty) return 3;
            if (futureDayAvailableQty) return 3;
        });
        setSelected(selectedItems);
    }, [sortedAvailability]);

    useEffect(() => {
        if (geolocationInfo.getGeolocationEffected) {
            listenForAvailability();
        }
    }, [geolocationInfo]);

    const showGophrDialogue = (item, selectedTypeId, openDate, closeDate) => {
        setDeliveryQuoteBranch(item);
        setSelectedDeliveryQuoteIndex(selectedTypeId);
        setShowDeliveryQuoteModal(true);
        setBranchOpenDate(openDate);
        setBranchCloseDate(closeDate);
    }

    const showByBoxDialogue = (item, selectedTypeId) => {
        setByBoxBranch(item);
        setSelectedDeliveryQuoteIndex(selectedTypeId);
        setShowByBoxModal(true);
    }

    const sortAvailabiity = (availability) => {
        let sortedAvailability = [...availability];
        if (sortState.id == 0) {
            const sortByDistanceAndAvailabilityArr = sortByDistanceAndAvailability(sortedAvailability);
            const sortedOutOfStockArr = sortOutOfStock(sortByDistanceAndAvailabilityArr)
            sortedAvailability = sortedOutOfStockArr
        } else if (sortState.id == 1) {
            sortedAvailability = sortedAvailability.sort(sortByAvailabilityAndDistance);
            sortedAvailability = sortedAvailability.filter(s => s.futureDateAvailableQty > 0 || s.nextDayAvailableQty > 0 || s.nextDayDeliveryAvailableQty > 0 || s.sameDayAvailableQty > 0)
        } else if (sortState.id == 2) {
            sortedAvailability.sort((a, b) => {
                const firstPrice = a.mostAvailable.products.filter(p => p.available).reduce((acc, current) => acc + current.displayedPrice * current.qty, 0);
                const secondPrice = b.mostAvailable.products.filter(p => p.available).reduce((acc, current) => acc + current.displayedPrice * current.qty, 0);
                if (firstPrice > secondPrice) {
                    return 1;
                }
                if (firstPrice < secondPrice) {
                    return -1;
                }
                return sortByAvailabilityAndDistance(a, b);
            });
            sortedAvailability = sortedAvailability.filter(filterAvailabilityByPrice);
        } else if (sortState.id == 3) {
            sortedAvailability.sort((a, b) => {
                const firstPrice = a.mostAvailable.products.filter(p => p.available).reduce((acc, current) => acc + current.displayedPrice * current.qty, 0);
                const secondPrice = b.mostAvailable.products.filter(p => p.available).reduce((acc, current) => acc + current.displayedPrice * current.qty, 0);
                if (firstPrice > secondPrice) {
                    return -1;
                }
                if (firstPrice < secondPrice) {
                    return 1;
                }
                return sortByAvailabilityAndDistance(a, b);
            });
            sortedAvailability = sortedAvailability.filter(filterAvailabilityByPrice);
        } else if (sortState.id == 4) {
            sortedAvailability = sortedAvailability.sort((a, b) => {
                if (a.branch.supplierID > b.branch.supplierID) {
                    return -1;
                }
                if (a.branch.supplierID < b.branch.supplierID) {
                    return 1;
                }
                return sortByAvailabilityAndDistance(a, b);
            }).filter(s => s.branch.supplierID === 2);
        } else if (sortState.id == 5) {
            sortedAvailability = sortedAvailability.sort((a, b) => {
                if (a.branch.supplierID > b.branch.supplierID) {
                    return 1;
                }
                if (a.branch.supplierID < b.branch.supplierID) {
                    return -1;
                }
                return sortByAvailabilityAndDistance(a, b);
            }).filter(s => s.branch.supplierID === 1);
        } else if (sortState.id == 6) {
            sortedAvailability = sortedAvailability.sort((a, b) => {
                if (a.branch.supplierID > b.branch.supplierID) {
                    return 1;
                }
                if (a.branch.supplierID < b.branch.supplierID) {
                    return -1;
                }
                return sortByAvailabilityAndDistance(a, b);
            }).filter(s => s.branch.supplierID === 25);
        }
        return sortedAvailability;
    }

    const onCloseDeliveryQuoteModal = () => {
        setShowDeliveryQuoteModal(false);
    }

    const onCloseByBoxQuoteModal = () => {
        setShowByBoxModal(false);
    }

    const sortOutOfStock = (items) => {
        if (items.length > 0) {
            let availableItems = []
            let outOfStockItems = []

            items.map(item => {
                if (noAvailable(item)) {
                    outOfStockItems = [...outOfStockItems, item]
                } else {
                    availableItems = [...availableItems, item]
                }
            })

            return [...availableItems, ...outOfStockItems]
        } else {
            return [];
        }
    }

    const sortByAvailabilityAndDistance = (item1, item2) => {
        return sortByAvailability(item1, item2, sortByDistance);
    }

    const sortByDistanceAndAvailability = (items) => {
        if (items.length > 0) {
            items.sort(sortByAvailabilityAndDistance)

            return [items.shift(), ...items.sort(sortByDistance)];
        } else {
            return [];
        }
    }

    const sortByDistance = (item1, item2) => {
        if (item1.branch.distanceMiles > item2.branch.distanceMiles) {
            return 1;
        }
        if (item1.branch.distanceMiles < item2.branch.distanceMiles) {
            return -1;
        }
        return 0;
    }

    const sortByAvailability = (item1, item2, onEqual = () => (0)) => {
        if (item1.mostAvailable.availableQty < item2.mostAvailable.availableQty) {
            return 1;
        }
        if (item1.mostAvailable.availableQty > item2.mostAvailable.availableQty) {
            return -1;
        }
        return onEqual(item1, item2);
    }

    const filterAvailabilityByPrice = ({ mostAvailable }) => {
        return mostAvailable.products.reduce((acc, curr) => acc += curr.displayedPrice * curr.availableQty, 0).toFixed(2) > 0
    }

    const getLocation = () => {
        AccountService.getOrganisation().then((response) => {
            setByBoxEnabled(response.byBoxEnabled)
            setPostcode(response.postCode)
            return response.postCode;
        }).then((postcode) => {
            ApiAddressianService.getPostCodeDetails(postcode).then((postcodeDetails) => {
                setGeolocationInfo({
                    Longitude: postcodeDetails.longitude,
                    Latitude: postcodeDetails.latitude,
                    getGeolocationEffected: true
                });
            })
        })
    }

    const getBasket = () => {
        if (countdownIntervalId.current)
            clearInterval(countdownIntervalId.current)


        return BasketService.getBasketForUser().then((response) => {
            if (response.lines == null) response.lines = [];
            setAllItemsCount(getBasketCount(response));
            setBasket(response);
            setPostcode(response.fulfillmentPostcode)
            getLocation(response.fulfillmentPostcode)
        });
    };

    const handleChangeLocation = async (latitude, longitude, postcode) => {
        if (countdownIntervalId.current)
            clearInterval(countdownIntervalId.current)
        if (timer.current)
            clearInterval(timer.current)


        const basketToUpdate = { ...basket, fulfillmentPostcode: postcode }
        await BasketService.updateBasketForUser(basketToUpdate)
        setBasket(basketToUpdate)
        setPostcode(postcode);
        setAvailability([]);
        setLoading(true);
        setGeolocationInfo({
            Longitude: longitude,
            Latitude: latitude,
            getGeolocationEffected: true
        });

    }

    const mapAvailabilityItem = (availability) => {
        let resultSet = []

        for (const basketLine of basket.lines) {
            if (basketLine.basketLineType.name == "Product") {
                const item = availability?.find(item => basketLine.manufacturerCode == item.manufacturerNumber);

                resultSet.push({
                    ...basketLine,
                    manufacturerNumber: basketLine.manufacturerCode,
                    price: item?.price || 0,
                    displayedPrice: item?.displayedPrice || 0,
                    available: (item?.available && item?.inStock) || false,
                    availableQty: item?.availableQty || 0,
                    properties: item?.properties || null
                });
            }
        }
        return resultSet;
    }

    const mapAvailability = (newAvailability) => {
        const availability = {
            ...newAvailability,
            sameDayAvailableQty: 0,
            nextDayAvailableQty: 0,
            nextDayDeliveryAvailableQty: 0,
            futureDateAvailableQty: 0,
            mostAvailable: []
        };

        availability.sameDayAvailability = mapAvailabilityItem(availability.sameDayAvailability);
        availability.nextDayAvailability = mapAvailabilityItem(availability.nextDayAvailability);
        availability.nextDayDelivery = mapAvailabilityItem(availability.nextDayDelivery);
        availability.futureDateAvailability = mapAvailabilityItem(availability.futureDateAvailability)

        availability.sameDayAvailableQty = calcMaxAvailableItems(availability.sameDayAvailability);
        availability.nextDayAvailableQty = calcMaxAvailableItems(availability.nextDayAvailability);
        availability.nextDayDeliveryAvailableQty = calcMaxAvailableItems(availability.nextDayDelivery);
        availability.futureDayAvailableQty = calcMaxAvailableItems(availability.futureDateAvailability)

        availability.mostAvailable = getMostAvailable(availability);

        return availability;
    }

    const calcMaxAvailableItems = (item) => {
        const availableItems = item.reduce((accumulator, curr) => accumulator + (curr.availableQty > curr.qty ? curr.qty : curr.availableQty), 0);
        return availableItems;
    }

    const listenForAvailability = () => {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl("/availabilityHub")
            .configureLogging(signalR.LogLevel.Debug)
            .build();

        hubConnection.start().then(a => {
            setLoading(true);
            hubConnection.invoke("GetAvailability", {
                Latitude: geolocationInfo.Latitude,
                Longitude: geolocationInfo.Longitude,
                PostCode: postcode
            });
        });

        hubConnection.on("onStartedListener", (availabilityRequestId) => {
            /*
            This is the request id that is assigned to your request
            if the page is loaded twice, you will have a new one each time.
            This IS NOT your basket id :)
             */
            console.log("requestId", availabilityRequestId);
        });

        hubConnection.on("onAvailabilityReceived", (newAvailability) => {
            console.log("onAvailabilityReceived", newAvailability);
            setLoading(preState => {
                if (preState) {
                    return false
                }
            });

            setAvailability(preState => {
                let preList = preState ?? [];

                if (!(preState?.find(item => item?.branch.id === newAvailability.branch.id))) {
                    return [...preList, mapAvailability(newAvailability)];
                } else {
                    return [...preList]
                }
            });
        })
    }

    const handleChange = (e, index) => {
        setSelected(prev => {
            const newArray = prev;
            newArray.splice(index, 1, parseInt(e.target.value, 10));

            return [...newArray];
        });
    }

    const handleShowDetailsClick = (e, branch) => {
        setDetailsRowId(detailsRowId === branch.id ? -1 : branch.id);
    };

    const handleContinueBrowsingClick = (e) => {
        e.preventDefault();
        history.push(isAllProducts ? '/' : '/Shop');
    };

    const viewBranchDetails = (branch) => {
        setSelectedBranch(branch);
    };

    const redirectToCheckout = () => {
        history.push(`/Basket/Checkout/${basket.basketKey}`);
    };

    const onSelectBranchCollect = (selectedAvailableBranch) => {
        AvailabilityService.select(selectedAvailableBranch).then((response) => {
            if (response === true) {
                redirectToCheckout();
            }
        });
    };

    const onSelectBranchDelivery = (selectedAvailableBranch) => {
        AvailabilityService.selectDelivery(selectedAvailableBranch).then((response) => {
            if (response === true) {
                redirectToCheckout();
            }
        });
    };

    const isAllItemsAvailable = (branch, selectedTypeId) => {
        let isAllAvailable = false;
        switch (selectedTypeId) {
            case 0: {
                isAllAvailable = branch.sameDayAvailableQty === allItemsCount;
                break;
            }
            case 1: {
                isAllAvailable = branch.nextDayAvailableQty === allItemsCount;
                break;
            }
            case 2: {
                isAllAvailable = branch.nextDayDeliveryAvailableQty === allItemsCount;
                break;
            }
            case 3: {
                isAllAvailable = branch.futureDayAvailableQty === allItemsCount;
                break;
            }
            case 4: {
                isAllAvailable = branch.sameDayAvailableQty === allItemsCount;
                break;
            }
            case 5: {
                isAllAvailable = branch.nextDayDeliveryAvailableQty === allItemsCount;
                break;
            }
            default:
                isAllAvailable = false;
        }
        if (isAllAvailable) {
            handleSelectBranch(branch, selectedTypeId, false);
        } else {
            setShowProceedToCheckout(true);
            setProceedPopupData({ branch, selectedTypeId });
        }
    };


    const handleSelectBranch = async (branch, selectedTypeId, courierSelected) => {
        if (selectedTypeId !== -1) {
            let selectedAvailableBranch = {
                availabilityLines: null,
                availableDate: null,
                branchId: branch.branch.id,
                supplierSelectedOptionId: null
            };

            if (!courierSelected) {
                let basketClone = await BasketService.getBasketForUser()
                let basketLines = basketClone.lines.filter(line => line.manufacturerCode != "GOPHR" && line.manufacturerCode != "STUART")
                basketClone.lines = basketLines;
                basketClone.quoteId = null;
                await BasketService.updateBasketForUser(basketClone)
            }

            switch (selectedTypeId) {
                case 0:
                    selectedAvailableBranch.availableDate = branch.sameDayDateTime
                    selectedAvailableBranch.availabilityLines = branch.sameDayAvailability
                    selectedAvailableBranch.supplierSelectedOptionId = branch.sameDayAvailability.filter(s => s.properties != null) ? branch.sameDayAvailability.find(s => s.properties != null).properties.fulfilmentOptionId : null
                    onSelectBranchCollect(selectedAvailableBranch);
                    break;
                case 1:
                    selectedAvailableBranch.availableDate = branch.nextDayDateTime
                    selectedAvailableBranch.availabilityLines = branch.nextDayAvailability
                    selectedAvailableBranch.supplierSelectedOptionId = branch.nextDayAvailability.filter(s => s.properties != null) ? branch.nextDayAvailability.find(s => s.properties != null).properties.fulfilmentOptionId : null
                    onSelectBranchCollect(selectedAvailableBranch);
                    break;
                case 2:
                    selectedAvailableBranch.availableDate = branch.nextDayDeliveryDateTime
                    selectedAvailableBranch.availabilityLines = branch.nextDayDelivery
                    selectedAvailableBranch.supplierSelectedOptionId = branch.nextDayDelivery.filter(s => s.properties != null) ? branch.nextDayDelivery.find(s => s.properties != null).properties.fulfilmentOptionId : null
                    onSelectBranchDelivery(selectedAvailableBranch);
                    break;
                case 3:
                    selectedAvailableBranch.availableDate = branch.futureDateDateTime;
                    selectedAvailableBranch.availabilityLines = branch.futureDateAvailability;
                    selectedAvailableBranch.supplierSelectedOptionId = branch.futureDateAvailability.filter(s => s.properties != null) ? branch.futureDateAvailability.find(s => s.properties != null).properties.fulfilmentOptionId : null
                    onSelectBranchCollect(selectedAvailableBranch);
                    break;
                case 4:
                    selectedAvailableBranch.availableDate = branch.sameDayDateTime
                    selectedAvailableBranch.availabilityLines = branch.sameDayAvailability
                    selectedAvailableBranch.supplierSelectedOptionId = branch.sameDayAvailability.filter(s => s.properties != null) ? branch.sameDayAvailability.find(s => s.properties != null).properties.fulfilmentOptionId : null
                    onSelectBranchCollect(selectedAvailableBranch);
                    break;
                case 5:
                    selectedAvailableBranch.availableDate = branch.nextDayDeliveryDateTime
                    selectedAvailableBranch.availabilityLines = branch.nextDayDelivery
                    selectedAvailableBranch.supplierSelectedOptionId = branch.nextDayDelivery.filter(s => s.properties != null) ? branch.nextDayDelivery.find(s => s.properties != null).properties.fulfilmentOptionId : null
                    onSelectBranchDelivery(selectedAvailableBranch);
                    break;
                default:
                    return;
            }
        }
    }

    const addToRequestedProducts = (item, selectedBranchId) => {
        RequestedProductsService.addProductToRequestedProducts({
            manufacturerPartNumber: item.manufacturerCode,
            description: item.description,
            imageUrl: item.imageUrl,
            selectedBranchId: selectedBranchId,
            iBasisAppliance: item.iBasisAppliance,
            iBasisManId: item.iBasisManId,
            iBasisApplianceId: item.iBasisApplianceId,
            iBasisApplianceGC: item.iBasisApplianceGC,
            iBasisGCN: item.iBasisGCN
        }).then(() => {
        }, () => {
        });
    };

    const isInRequestedList = (item, branchId) => requestedProductsList.find(reqItem => reqItem.manufacturerCode === item.manufacturerCode && reqItem.branchId === branchId)

    const onAddToRequestedProducts = (item, selectedBranchId) => {
        addToRequestedProducts(item, selectedBranchId);
    };

    const onRemoveItem = async () => {
        const basketCopy = Object.assign(basket);
        const itemIndex = basketCopy.lines.findIndex((item) => {
            return item.manufacturerCode == manufacturerCode || item.id == manufacturerCode
        })
        basketCopy.lines.splice(itemIndex, 1);
        await updateBasket(basketCopy);
        closeProductDeleteModal();
    };

    const updateBasket = async (basket) => {
        await BasketService.updateBasketForUser(basket).then((basket) => {
            if (basket.lines == null || basket.lines.length == 0) {
                history.push('/shop');
            } else {
                setBasket(basket);
                window.location.reload();
            }
        });
    };

    const closeProductDeleteModal = () => {
        setShowDeleteModal(false)
        setManufacturerCode(null)
    }

    const getMostAvailable = (availability) => {
        let response = { products: [], availableQty: 0 };

        if (
            availability.sameDayAvailableQty >= availability.nextDayAvailableQty &&
            availability.sameDayAvailableQty >= availability.nextDayDeliveryAvailableQty
        ) {
            response.products = availability.sameDayAvailability;
            response.availableQty = availability.sameDayAvailableQty;
        } else if (
            availability.nextDayAvailableQty >= availability.sameDayAvailableQty &&
            availability.nextDayAvailableQty >= availability.nextDayDeliveryAvailableQty
        ) {
            response.products = availability.nextDayAvailability;
            response.availableQty = availability.nextDayAvailableQty;
        } else if (
            availability.nextDayDeliveryAvailableQty >= availability.nextDayAvailableQty &&
            availability.nextDayDeliveryAvailableQty >= availability.sameDayAvailableQty
        ) {
            response.products = availability.nextDayDelivery;
            response.availableQty = availability.nextDayDeliveryAvailableQty;
        } else {
            response.products = availability.sameDayAvailability;
            response.availableQty = availability.sameDayAvailableQty;
        }
        return response;
    }

    const renderBranchDistance = (branch) => {
        if (branch.supplier.name == "HRP Trade" || branch.supplier.name == "Partners") return;
        return (
            <>
                <span style={{ display: 'inline-block' }}>
                    {/* ({`${branch?.distanceMiles !== null ? Math.round(branch.distanceMiles) : 0} miles`}) */}
                </span>
            </>
        )
    }

    const calcTotalCostForAvailableItems = (products) => {
        return products
            .reduce((acc, curr) => acc += curr.displayedPrice * (curr.availableQty > curr.qty ? curr.qty : curr.availableQty), 0)
            .toFixed(2)
    }

    const renderItemDisplayedPrice = (displayedPrice) => {
        const formattedPrice = displayedPrice.toFixed(2);
        return formattedPrice > 0.00 ? `£${formattedPrice}` : '-';
    }

    const getAvailableQtyForItem = (item) => {
        return item.availableQty > item.qty ? item.qty : item.availableQty;
    }

    const noAvailable = (item) => {
        const sameDayAvailable = item.sameDayAvailableQty > 0;
        const nextDayAvailable = item.nextDayAvailableQty > 0;
        const nextDayDeliveryAvailable = item.nextDayDeliveryAvailableQty > 0;
        const futureDayAvailable = item.futureDayAvailableQty > 0;

        return !sameDayAvailable && !nextDayAvailable && !nextDayDeliveryAvailable && !futureDayAvailable
    };

    const checkAvailableItem = (item, selectedIndex) => {
        const { sameDayAvailability, nextDayAvailability, nextDayDelivery, futureDateAvailability } = item;
        return selectedIndex !== undefined ? item[ITEM_AVAILABILITY[selectedIndex]] : sameDayAvailability || nextDayAvailability || nextDayDelivery || futureDateAvailability
    };
    const processTime = (todaysDate, timeString) => {
        return moment(`${todaysDate.toLocaleDateString()} ${timeString}`)
    }

    const availabilityItem = (item, index) => {
        item && clearInterval(timer.current);

        const {
            branch,
            mostAvailable,
            sameDayAvailability,
            nextDayAvailability,
            nextDayDelivery,
            futureDateAvailability
        } = item;

        const selectedAvailability = () => {
            switch (selected[index]) {
                case 0:
                    return sameDayAvailability;
                case 1:
                    return nextDayAvailability;
                case 2:
                    return nextDayDelivery;
                case 3:
                    return futureDateAvailability;
                case 4:
                    return sameDayAvailability;
                case 5:
                    return nextDayDelivery;
                default:
                    return sameDayAvailability;
            }
        };

        var openDate = new Date(), closeDate = new Date(), today = new Date();
        switch (today.getDay()) {
            case 1: {
                openDate.setHours(processTime(today, branch.openMonday).hours());
                openDate.setMinutes(processTime(today, branch.openMonday).minutes());
                openDate.setSeconds(processTime(today, branch.openMonday).seconds());
                closeDate.setHours(processTime(today, branch.closeMonday).hours());
                closeDate.setMinutes(processTime(today, branch.closeMonday).minutes());
                closeDate.setSeconds(processTime(today, branch.closeMonday).seconds());
                break;
            }
            case 2: {
                openDate.setHours(processTime(today, branch.openTuesday).hours());
                openDate.setMinutes(processTime(today, branch.openTuesday).minutes());
                openDate.setSeconds(processTime(today, branch.openTuesday).seconds());
                closeDate.setHours(processTime(today, branch.closeTuesday).hours());
                closeDate.setMinutes(processTime(today, branch.closeTuesday).minutes());
                closeDate.setSeconds(processTime(today, branch.closeTuesday).seconds());
                break;
            }
            case 3: {
                openDate.setHours(processTime(today, branch.openWednesday).hours());
                openDate.setMinutes(processTime(today, branch.openWednesday).minutes());
                openDate.setSeconds(processTime(today, branch.openWednesday).seconds());
                closeDate.setHours(processTime(today, branch.closeWednesday).hours());
                closeDate.setMinutes(processTime(today, branch.closeWednesday).minutes());
                closeDate.setSeconds(processTime(today, branch.closeWednesday).seconds());
                break;
            }
            case 4: {
                openDate.setHours(processTime(today, branch.openThursday).hours());
                openDate.setMinutes(processTime(today, branch.openThursday).minutes());
                openDate.setSeconds(processTime(today, branch.openThursday).seconds());
                closeDate.setHours(processTime(today, branch.closeThursday).hours());
                closeDate.setMinutes(processTime(today, branch.closeThursday).minutes());
                closeDate.setSeconds(processTime(today, branch.closeThursday).seconds());
                break;
            }
            case 5: {
                openDate.setHours(processTime(today, branch.openFriday).hours());
                openDate.setMinutes(processTime(today, branch.openFriday).minutes());
                openDate.setSeconds(processTime(today, branch.openFriday).seconds());
                closeDate.setHours(processTime(today, branch.closeFriday).hours());
                closeDate.setMinutes(processTime(today, branch.closeFriday).minutes());
                closeDate.setSeconds(processTime(today, branch.closeFriday).seconds());
                break;
            }
            case 6: {
                openDate.setHours(processTime(today, branch.openSaturday).hours());
                openDate.setMinutes(processTime(today, branch.openSaturday).minutes());
                openDate.setSeconds(processTime(today, branch.openSaturday).seconds());
                closeDate.setHours(processTime(today, branch.closeSaturday).hours());
                closeDate.setMinutes(processTime(today, branch.closeSaturday).minutes());
                closeDate.setSeconds(processTime(today, branch.closeSaturday).seconds());
                break;
            }
            case 7: {
                openDate.setHours(processTime(today, branch.openSunday).hours());
                openDate.setMinutes(processTime(today, branch.openSunday).minutes());
                openDate.setSeconds(processTime(today, branch.openSunday).seconds());
                closeDate.setHours(processTime(today, branch.closeSunday).hours());
                closeDate.setMinutes(processTime(today, branch.closeSunday).minutes());
                closeDate.setSeconds(processTime(today, branch.closeSunday).seconds());
                break;
            }
            default: {
            }
        }

        if (openDate.getHours() === 0) {
            if (today.getDay() == 6 || today.getDay() == 0)
                openDate.setHours(11);
            else
                openDate.setHours(8);
        }

        if (closeDate.getHours() === 0)
            closeDate.setHours(20);

        var openDiff = moment(today).diff(openDate, 'hours');
        var closeDiff = moment(closeDate).diff(moment(today), 'hours');

        return isMobile() ? (

            featureFlagContext.checkFeatures("one-card-pricing") ?
                <>
                    <OnePriceBranchRow
                        key={branch.id}
                        branchRowStyles={detailsRowId === branch.id ? 'expanded' : ''}
                        branchName={branch?.name}
                        branchDistance={renderBranchDistance(branch)}
                        orderDetailsOnClick={(e) => handleShowDetailsClick(e, branch)}
                        fulfilledByLogo={branch?.supplier?.imageUrl}
                        branch={branch}
                        onViewBranchDetails={viewBranchDetails}
                        calcTotalCostForAvailableItems={calcTotalCostForAvailableItems}
                        price={calcTotalCostForAvailableItems(selectedAvailability())}
                        buttonOnClick={
                            (newTypeId) => {
                                if (newTypeId === 5) showByBoxDialogue(item, newTypeId)
                                else if (newTypeId === 4) showGophrDialogue(item, newTypeId, openDate, closeDate)
                                else isAllItemsAvailable(item, newTypeId)
                            }
                        }
                        buttonDisabled={noAvailable(item)}
                        orderDetailsClass={detailsRowId === branch.id ? 'expand' : ''}
                        item={item}
                        indexIdentifier={index}
                        fulfilmentOptionTypes={FULFILMENT_OPTION_TYPES}
                        allItemsCount={allItemsCount}
                        selectedFulfilmentRows={selected}
                        requestedProductsList={requestedProductsList}
                        openSubscribeForAlternativeModal={openSubscribeForAlternativeModal}
                        setManufacturerCode={setManufacturerCode}
                        onRemoveItem={onRemoveItem}
                        setShowDeleteModal={setShowDeleteModal}
                        featureFlagContext={featureFlagContext}
                        byBoxEnabled={byBoxEnabled}
                    />
                </>
                :


                <div key={branch.id} className={detailsRowId === branch.id ? 'details-wrapper-mobile' : 'wrapper-mobile'}>
                    <div
                        className='basket-grid-row-mobile row-border-mobile'
                    >
                        <img src={branch?.supplier?.imageUrl} alt="supplier" />
                        <div>
                            <p className='branch-name'>
                                {branch?.name} {renderBranchDistance(branch)} {branch.supplier.name == "HRP Trade" &&
                                    <span className="hrp-fee-text">(£8.50 pre-noon delivery)</span>}
                            </p>
                            {branch?.supplier?.name != "HRP Trade" && <a className='view-branch-details-button-mobile'
                                onClick={viewBranchDetails.bind(this, branch)}>Branch
                                Details</a>}
                        </div>
                        <div className='availability-price'>
                            <p>£{calcTotalCostForAvailableItems(selectedAvailability())}</p>
                            <p className='plus-vat-text'>(plus VAT)</p>
                            <div className='show-details-button'>
                                <a className="show-details-button-link" onClick={(e) => handleShowDetailsClick(e, branch)}>
                                    Expand order details
                                    <img
                                        src={arrowDetailsIcon}
                                        style={{ transform: `rotate(${detailsRowId === branch.id ? 180 : 0}deg)` }}
                                        className='arrow-details-icon'
                                        alt=""
                                    />
                                </a>
                            </div>

                        </div>
                        <div onChange={(e) => handleChange(e, index)} className='fullfillment-options-wrapper'>
                            {renderFulfilmentOptions(item, index, openDiff, closeDiff, branch.branchSameDayDeliveryTypes)}
                        </div>
                    </div>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <button
                                onClick={() => {
                                    if (selected[index] === 5) showByBoxDialogue(item, selected[index])
                                    else if (selected[index] === 4) showGophrDialogue(item, selected[index], openDate, closeDate)
                                    else isAllItemsAvailable(item, selected[index])
                                }}
                                className="btn group__btn btn_width-fixed btn-svg-arrow button-large button-light-blue select-branch-button-mobile"
                                disabled={noAvailable(item)}
                            >
                                Proceed
                                <img src={buttonArrowIcon} alt="" className='button-arrow-icon' />
                            </button>
                        </div>
                    </div>
                    <div style={{ display: detailsRowId === branch.id ? 'block' : 'none' }}
                        className='availability-item-rows-mobile'>
                        {checkAvailableItem(item, selected[index])?.map((item) => (
                            <div className='availability-item-row-mobile'>
                                <img src={item.imageUrl} alt="product" className='product-image' />
                                <div>
                                    <div>
                                        {item.available ? (
                                            <div className='availability-mark-wrapper'>
                                                <img src={markGreen} alt="available" className='availability-green-mark' />
                                                <p className='item-text'>Available</p>
                                            </div>
                                        ) : (
                                            <div className='availability-mark-wrapper'>
                                                <img src={item.availableQty > 0 ? markYellow : markGrey} alt="not-available"
                                                    className='no-availability-gray-mark' />
                                                <p className='not-available-mobile'>{item.availableQty > 0 ? "Limited stock" : "Out of stock"}</p>
                                            </div>
                                        )}
                                    </div>
                                    <p className='item-text item-text-bold'>{item.description}</p>
                                    <p className='item-text item-text-bold'>{item.manufacturerCode}</p>
                                    <div className='footer-availability-item-mobile'>
                                        <div className='qty-and-price'>
                                            <p><span>Qty: {item.qty}</span>Available: {getAvailableQtyForItem(item)}</p>
                                            <p className='price'>{renderItemDisplayedPrice(item.displayedPrice)}</p>
                                        </div>
                                        {item.available
                                            ? (<></>)
                                            : !isInRequestedList(item, branch.id)
                                                ? <button
                                                    className='add-to-requested-products-btn'
                                                    onClick={() => openSubscribeForAlternativeModal(item, branch.id)}
                                                >
                                                    <p className='item-text'>Request Product</p>
                                                </button>
                                                : <button
                                                    className='add-to-requested-products-btn added-to-requested-products-btn'
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <p className='item-text'>Product Requested</p>
                                                </button>
                                        }
                                    </div>
                                    <div
                                        className="remove-icon-wrapper remove-available-product"
                                        onClick={() => {
                                            setShowDeleteModal(true)
                                            setManufacturerCode(item.manufacturerCode)
                                        }}
                                    >
                                        <img src={binIcon} alt="remove" />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
        ) : (


            featureFlagContext.checkFeatures("one-card-pricing")
                ?
                <>
                    <OnePriceBranchRow
                        key={branch.id}
                        branchRowStyles={detailsRowId === branch.id ? 'expanded' : ''}
                        branchName={branch?.name}
                        branchDistance={renderBranchDistance(branch)}
                        orderDetailsOnClick={(e) => handleShowDetailsClick(e, branch)}
                        fulfilledByLogo={branch?.supplier?.imageUrl}
                        branch={branch}
                        onViewBranchDetails={viewBranchDetails}
                        calcTotalCostForAvailableItems={calcTotalCostForAvailableItems}
                        price={calcTotalCostForAvailableItems(selectedAvailability())}
                        buttonOnClick={
                            (newTypeId) => {
                                if (newTypeId === 5) showByBoxDialogue(item, newTypeId)
                                else if (newTypeId === 4) showGophrDialogue(item, newTypeId, openDate, closeDate)
                                else isAllItemsAvailable(item, newTypeId)
                            }
                        }
                        buttonDisabled={noAvailable(item)}
                        orderDetailsClass={detailsRowId === branch.id ? 'expand' : ''}
                        item={item}
                        indexIdentifier={index}
                        fulfilmentOptionTypes={FULFILMENT_OPTION_TYPES}
                        allItemsCount={allItemsCount}
                        selectedFulfilmentRows={selected}
                        requestedProductsList={requestedProductsList}
                        openSubscribeForAlternativeModal={openSubscribeForAlternativeModal}
                        setManufacturerCode={setManufacturerCode}
                        onRemoveItem={onRemoveItem}
                        setShowDeleteModal={setShowDeleteModal}
                        featureFlagContext={featureFlagContext}
                        byBoxEnabled={byBoxEnabled}
                    />
                </>


                :
                <div key={branch.id} className={detailsRowId === branch.id ? 'details-wrapper' : ''}>
                    <div className={detailsRowId === branch.id ? 'basket-grid-row' : 'basket-grid-row row-border'}>
                        <img src={branch?.supplier?.imageUrl} alt="supplier" />
                        <div onChange={(e) => handleChange(e, index)}>
                            <p className='branch-name'>{branch?.name} {renderBranchDistance(branch)} {branch.supplier.name == "HRP Trade" &&
                                <span className="hrp-fee-text">(£8.50+vat pre-noon delivery charge per order)</span>}</p>
                            {branch?.supplier?.name != "HRP Trade" && branch?.supplier?.name != "Partners" &&
                                <a className='view-branch-details-button' onClick={viewBranchDetails.bind(this, branch)}>View
                                    Branch Details</a>}
                            {renderFulfilmentOptions(item, index, openDiff, closeDiff, branch.branchSameDayDeliveryTypes)}
                        </div>
                        <div className='availability-price'>
                            <p>£{calcTotalCostForAvailableItems(selectedAvailability())}</p>
                            <p className='plus-vat-text' style={{ paddingBottom: 0 }}>(plus VAT)</p>
                            <div className='show-details-button' onClick={(e) => handleShowDetailsClick(e, branch)}>
                                <a className="show-details-button-link">
                                    Expand order details
                                    <img
                                        src={arrowDetailsIcon}
                                        style={{ transform: `rotate(${detailsRowId === branch.id ? 180 : 0}deg)` }}
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div>
                                    <button onClick={() => {
                                        if (selected[index] === 5) showByBoxDialogue(item, selected[index])
                                        else if (selected[index] === 4) showGophrDialogue(item, selected[index], openDate, closeDate)
                                        else isAllItemsAvailable(item, selected[index])
                                    }
                                    }
                                        className="btn group__btn btn_width-fixed button-large button-light-blue select-branch-button-desktop"
                                        disabled={noAvailable(item)}>
                                        Proceed
                                        <img src={buttonArrowIcon} alt="" className='button-arrow-icon' />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: detailsRowId === branch.id ? 'block' : 'none' }}>
                        <div className='availability-item-row-header'>
                            <span />
                            <p>MPN</p>
                            <p>Description</p>
                            <p>Qty (Available)</p>
                            <p>Price</p>
                            <p>Status</p>
                            <span />
                            <span />
                        </div>
                        {checkAvailableItem(item, selected[index])?.map((item) => (
                            <div className='availability-item-row'>
                                <img src={item.imageUrl} alt="product" />
                                <p>{item.manufacturerCode}</p>
                                <p>{item.description}</p>
                                <p style={{ fontWeight: 400 }}>{item.qty}&nbsp;&nbsp;(&nbsp;{getAvailableQtyForItem(item)}&nbsp;)</p>
                                <p className='price'>{renderItemDisplayedPrice(item.displayedPrice)}</p>
                                <div className='available-item-data'>
                                    {item.available ? (
                                        <>
                                            <img src={markGreen} alt="available" />
                                            <p>Available</p><span></span>
                                        </>
                                    ) : (
                                        <>
                                            <img src={item.availableQty > 0 ? markYellow : markGrey} alt="not-available" />
                                            <p className='not-available'>{item.availableQty > 0 ? "Limited stock" : "Out of stock"}</p>
                                        </>
                                    )}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {!item.available
                                        ? !isInRequestedList(item, branch.id)
                                            ? <button
                                                style={{ color: "#009FDF", textDecoration: "underline" }}
                                                onClick={() => openSubscribeForAlternativeModal(item, branch.id)}
                                            >
                                                <p className='add-to-requested-products'>Request Product</p>
                                            </button>
                                            : <p className='added-to-requested-products'>Product Requested</p>
                                        : null
                                    }
                                </div>
                                <div
                                    className="remove-icon-wrapper"
                                    onClick={() => {
                                        setShowDeleteModal(true)
                                        setManufacturerCode(item.manufacturerCode)
                                    }}
                                >
                                    <img src={binIcon} alt="remove" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>



        )
    };

    const isSaturdayCollection = (day) => day.includes('Sat')

    const renderFulfilmentOptions = (item, index, openDiff, closeDiff, branchSameDayDeliveryTypes) => {
        const sameDayAvailable = item.sameDayAvailableQty > 0;
        const nextDayAvailable = item.nextDayAvailableQty > 0;
        const nextDayDeliveryAvailable = item.nextDayDeliveryAvailableQty > 0;
        const futureDayAvailable = item.futureDayAvailableQty > 0;

        if (noAvailable(item)) {
            return (<div>
                <label style={isMobile() ? { fontSize: 12 } : {}}>
                    No items available
                </label>
            </div>);
        }

        return (
            <>
                {openDiff >= 0 && closeDiff > 0 && branchSameDayDeliveryTypes?.length > 0 && sameDayAvailable && moment(item.sameDayDateTime).isSame(moment(), 'day') &&
                    calcExpressDelivery(item.sameDayAvailability, item.sameDayAvailableQty, FULFILMENT_OPTION_TYPES[4], index, item.branch.id)}
                {sameDayAvailable &&
                    calcAvailability(item.sameDayAvailability, item.sameDayAvailableQty, FULFILMENT_OPTION_TYPES[0], index, item.branch.id, moment(item.sameDayDateTime).format("ddd Do MMM"))}
                {nextDayAvailable &&
                    calcAvailability(item.nextDayAvailability, item.nextDayAvailableQty, FULFILMENT_OPTION_TYPES[1], index, item.branch.id, moment(item.nextDayDateTime).format("ddd Do MMM"))}
                {nextDayDeliveryAvailable &&
                    calcAvailability(item.nextDayDelivery, item.nextDayDeliveryAvailableQty, FULFILMENT_OPTION_TYPES[2], index, item.branch.id, moment(item.nextDayDeliveryDateTime).format("ddd Do MMM"))}
                {item.branch.supplier.name == "HRP Trade" && nextDayDeliveryAvailable && byBoxEnabled && featureFlagContext.checkFeatures("buy-box") &&
                    calcAvailability(item.nextDayDelivery, item.nextDayDeliveryAvailableQty, FULFILMENT_OPTION_TYPES[5], index, item.branch.id, moment(item.nextDayDeliveryDateTime).format("ddd Do MMM"))}
                {futureDayAvailable &&
                    calcAvailability(item.futureDateAvailability, item.futureDayAvailableQty, FULFILMENT_OPTION_TYPES[3], index, item.branch.id, moment(item.futureDateDateTime).format("ddd Do MMM"))}
            </>
        );
    };

    const calcAvailability = (data, availableQty, fulfilmentOptionType, index, branchId, additionalText) => {

        const handleAvailableQtyClick = (e, branchId) => {
            setDetailsRowId(branchId);
        }
        if (data?.length) {
            const text = (fulfilmentOptionType.id === 0 || fulfilmentOptionType.id === 1)
                ? isSaturdayCollection(additionalText) ? fulfilmentOptionType.description_saturday_collection : fulfilmentOptionType.description
                : fulfilmentOptionType.description;

            if (availableQty >= allItemsCount) {
                return (
                    <div className="select-container">
                        <input
                            checked={selected[index] === fulfilmentOptionType.id}
                            value={fulfilmentOptionType.id}
                            type="radio"
                            name={'availability-item' + index}
                            id={'availability-item' + fulfilmentOptionType.id + text + branchId}
                            className='available-data'
                        />
                        <label htmlFor={'availability-item' + fulfilmentOptionType.id + text + branchId}>
                            &nbsp;
                            <span className={'available-qty-text'}
                                onClick={(e) => handleAvailableQtyClick(e, branchId)}>   All items </span>  &nbsp; available <span> {text} {additionalText ? additionalText : ""}</span>
                        </label>
                    </div>
                );
            }

            return (
                <div className="select-container">
                    {!!availableQty &&
                        <input
                            checked={selected[index] === fulfilmentOptionType.id}
                            value={fulfilmentOptionType.id}
                            type="radio"
                            name={'availability-item' + index}
                            id={'availability-item' + fulfilmentOptionType.id + text + branchId}
                            className='available-data'
                        />}
                    <label htmlFor={'availability-item' + fulfilmentOptionType.id + text + branchId}
                        style={isMobile() ? { fontSize: 12 } : {}}>
                        <span className='item-text-regular'>
                            {!!availableQty ?
                                <>
                                    <span onClick={(e) => handleAvailableQtyClick(e, branchId)}
                                        className={"available-qty-text"}>
                                        {availableQty} of {allItemsCount}
                                    </span>
                                    &nbsp; items available
                                </>
                                : 'No items available'}
                        </span>
                        <span className='item-text-bold'> {text} {additionalText ? additionalText : ""}</span>
                    </label>
                </div>
            );
        }
    };

    const calcExpressDelivery = (data, availableQty, fulfilmentOptionType, index, branchId) => {
        const handleAvailableQtyClick = (e, branchId) => {
            setDetailsRowId(branchId);
        }
        if (data?.length) {
            const text = fulfilmentOptionType.description;


            if (availableQty >= allItemsCount) {
                return (
                    <div className={['select-container', 'express-delivery-box'].join(' ')}>
                        <input
                            checked={selected[index] === fulfilmentOptionType.id}
                            value={fulfilmentOptionType.id}
                            type="radio"
                            name={'availability-item' + index}
                            id={'availability-item' + fulfilmentOptionType.id + text + branchId}
                            className='express-data'
                        />
                        <label htmlFor={'availability-item' + fulfilmentOptionType.id + text + branchId}>
                            <span><span className="item-text-bold">{text}</span> OF ALL ITEMS</span>
                        </label>
                    </div>
                );
            }

            return (
                <div className={['select-container', 'express-delivery-box'].join(' ')}>
                    {!!availableQty &&
                        <input
                            checked={selected[index] === fulfilmentOptionType.id}
                            value={fulfilmentOptionType.id}
                            type="radio"
                            name={'availability-item' + index}
                            id={'availability-item' + fulfilmentOptionType.id + text + branchId}
                            className='express-data'
                        />}
                    <label htmlFor={'availability-item' + fulfilmentOptionType.id + text + branchId}
                        style={isMobile() ? { fontSize: 12 } : {}}>
                        {!!availableQty ? <span><span className="item-text-bold">{text}</span>
                            <span className={"available-qty-text"}
                                onClick={(e) => handleAvailableQtyClick(e, branchId)}>
                                {availableQty} of {allItemsCount}
                            </span>

                            &nbsp; items
                        </span> : <span>No items available</span>}
                    </label>
                </div>
            );
        }
    };

    const handleCloseModal = () => {
        setSelectedBranch(null)
    };

    const handleSort = (option) => {
        let index = option.selected;
        if (index != sortState.id) {
            setSortState({
                id: SELECT_VALUES[index].id,
            });
        }
    };

    const openSubscribeForAlternativeModal = (item, branch_id) => {
        setRequestedProduct(item)
        setRequestedProductBranchId(branch_id)
        setSubscribeForAlternativeModal(true);
    }

    const closeSubscribeForAlternativeModal = () => {
        setRequestedProduct(null)
        setRequestedProductBranchId(null)
        setSubscribeForAlternativeModal(false);
    }

    const onRequestedSuccessOutOfStock = () => {
        setRequestedProductsList(prev => [
            ...prev,
            {
                branchId: requestedProductBranchId,
                manufacturerCode: requestedProduct.manufacturerCode
            }
        ])

        onAddToRequestedProducts(requestedProduct, requestedProductBranchId)
    }

    const selectGophrBranch = (selectedAvailableBranch) => {
        AvailabilityService.select(selectedAvailableBranch).then((response) => {
            if (response === true) {
                redirectToCheckout();
            }
        });
    }

    const renderHeaderLocation = () => (
        <div style={{
            display: 'flex',
            alignItems: isMobile() ? 'flex-end' : 'start',
            flexDirection: isMobile() ? 'column' : 'row'
        }}>
            <div className={`${isMobile() ? 'availability-header-location-mobile' : 'availability-header-location'}`}>
                <Location postcode={postcode} onChange={handleChangeLocation}
                    onGetDefaultLocation={() => getLocation(postcode)}
                />
            </div>
            <div className={`header-sort-by ${isMobile() ? 'availability-header-mobile' : 'availability-header'}`}>
                <SortDropDown
                    sortItems={SELECT_VALUES}
                    onSelectSort={handleSort}
                    customClassName={`sort-basket-dropdown header-alignment ${isMobile() ? 'header-sort-mobile' : ''}`}
                />
            </div>
        </div>
    );

    return (
        <Wrapper wrapperClass="" selected="Home" count={getBasketCount(basket)}>
            <Header
                body='Basket'
                backBody={isMobile() ? 'Back' : 'Continue Browsing'}
                onBack={handleContinueBrowsingClick}
                rightSide={renderHeaderLocation()}
            />
            <div className="expiry-wrapper-box">
                <div className="expiry-wrapper">
                    <img src={infoIcon} />
                    <p>Please note, your order and payment must be completed within 5 minutes. After 5 minutes, your
                        stock availability options will automatically refresh.</p>
                </div>
                <CountdownCircleTimer
                    isPlaying
                    rotation="counterclockwise"
                    trailColor="#D9D9D9"
                    duration={expirationTime}
                    colors={['#009FDF', '#F7B801', '#ED1F45', '#A30000']}
                    colorsTime={[7, 5, 2, 0]}
                    size={52}
                    strokeWidth={5}
                    key={countdownIntervalId.current}
                >
                    {({ _, color }) => (
                        <p style={{ color: '#014360', fontSize: '14px', fontWeight: 500, marginTop: 10 }}>
                            {countDown}
                        </p>
                    )}
                </CountdownCircleTimer>
            </div>


            <div>
                {isExpired && <BasketTimedOutModal
                    onConfirm={() => window.location.reload(false)}
                    onClose={() => {
                        history.push(isAllProducts ? "/homepage-2" : "/shop")
                    }} />}
            </div>
            {noResults && <p className='no-items'>No items are available!</p>}
            {loading && !noResults ? (
                <div className="loader-wrapper">
                    <Loader />
                </div>
            ) : (
                <div className={isMobile() ? 'basket-grid-mobile' : 'basket-grid'}>
                    {sortedAvailability?.map(availabilityItem)}
                    {showProceedToCheckout && (
                        <NewConfirmModal
                            title='Proceed to checkout?'
                            description='Please note, any unavailable items will be added to your save for later list.'
                            theme='blue'
                            okText='Continue'
                            onClose={() => {
                                setShowProceedToCheckout(false);
                                setProceedPopupData(null);
                            }}
                            onConfirm={() => handleSelectBranch(proceedPopupData.branch, proceedPopupData.selectedTypeId, false)}
                        />
                    )}
                </div>
            )}
            {selectedBranch &&
                <BranchDetailModal
                    className={'branch-detail'}
                    onClose={handleCloseModal}
                >
                    <div className={'branch-detail__content'}>
                        <div className={'branch-detail__info'}>
                            <h1>{selectedBranch.name}</h1>
                            <div className='branch-detail__address'>
                                <p>{selectedBranch.address1}, {selectedBranch.address2}</p>
                                <p> {selectedBranch.address3}, {selectedBranch.address4}</p>
                                <p>{selectedBranch.postcode}</p>
                            </div>
                            <div className='branch-detail__contact'>
                                {selectedBranch.telephone
                                    ? <a href={`tel:${selectedBranch.telephone}`}>{selectedBranch.telephone}</a>
                                    : null
                                }
                                {selectedBranch.email
                                    ? <a href={`mailto:${selectedBranch.email}`}>{selectedBranch.email}</a>
                                    : null
                                }
                            </div>
                        </div>
                        <div className={'branch-detail__map'}>
                            <iframe
                                src={`https://maps.google.com/maps?q=${selectedBranch.supplier.name}, ${selectedBranch.postcode},&t=&z=16&ie=UTF8&iwloc=&output=embed`}
                                width={isMobile() ? "230" : "384"}
                                height={isMobile() ? "145" : "384"}
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                            ></iframe>
                        </div>
                    </div>
                </BranchDetailModal>
            }
            {showDeleteModal &&
                <NewConfirmModal
                    title="Delete item?"
                    description='Please note, deleting this item will remove it from your basket.'
                    theme='blue'
                    okText='Continue'
                    onClose={closeProductDeleteModal}
                    onConfirm={onRemoveItem}
                />
            }
            {subscribeForAlternativeModal && (
                <SubscribeForAlternativeModal product={{
                    ...requestedProduct,
                    man: requestedProduct.manufacturer,
                    mpn: requestedProduct.manufacturerCode
                }} onClose={closeSubscribeForAlternativeModal} scenario="Part Availability Request"
                    onSuccessCallback={onRequestedSuccessOutOfStock} />
            )}

            {showDeliveryQuoteModal && <DeliveryQuoteModal openDate={branchOpenDate} closeDate={branchCloseDate}
                onQuoteAccepted={() => handleSelectBranch(deliveryQuoteBranch, selectedDeliveryQuoteIndex, true)}
                onCloseModal={onCloseDeliveryQuoteModal}
                selectedBranch={deliveryQuoteBranch} />}

            {showByBoxModal && <ByBoxModal
                onByBoxAccepted={() => {
                    handleSelectBranch(byBoxBranch, selectedDeliveryQuoteIndex, true)
                }}
                onCloseModal={onCloseByBoxQuoteModal} />
            }
        </Wrapper>
    );
}

export default Availability;