import React from 'react';
import { withStyles } from "@material-ui/styles";
import moment from "moment";
import deleteNotificationIcon from "../../assets/img/delete-notification.svg";

const styles = (theme) => ({
    flex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    headerTitle: {
        color: '#454545',
        fontSize: '12px',
        fontWeight: 500,
        "& p": {
            color: '#828282',
            textTransform: 'uppercase',
            fontSize: 14,
            fontWeight: 500,
            margin: 0,
            padding: 0,
            display: 'inline'
        },
        "& span": {
            color: '#828282',
            textTransform: 'none',
            fontSize: 12,
            fontWeight: 500,
            margin: 0,
            padding: 0,
            display: 'inline',
            justifySelf: 'flex-end'
        },
    },
    title: {
        marginLeft: '15px',
        color: '#454545',
        fontSize: '12px',
        fontWeight: 400,
    },
    mobileLogo: {
        paddingRight: '5px',
        display: 'inline-block',
        width: 31,
        height: 26,
        marginLeft: -4,
    },
    notificationTitle: {
        fontSize: '32px',
        fontWeight: 400,
        color: '#00226A',
        display: 'flex',
        alignItems: 'center'
    },
    closeWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: 10,
    },
    readText: {
        fontWeight: 400,
        color: '#454545',
    },
    notReadText: {
        fontWeight: '600 !important',
        color: '#000 !important',
    },
    "@media (min-width: 769px)": {
        headerTitle: {
            "& p": {
                color: '#828282',
                fontSize: 16,
                fontWeight: 300,
            }
        },
        notificationWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: -15,
        },
        notificationDate: {
            fontSize: 16,
            fontWeight: 300,
            color: '#828282',
            marginBottom: -48,
        },
        notificationTitle: {
            fontSize: '20px',
        },
        notification: {
            background: 'white',
            borderRadius: '10px',
            margin: '15px 0',
            padding: '25px',
            boxShadow: '0px 4px 25px rgba(71, 153, 182, 0.15)'
        },
        notReadNotification: {
            background: '#F5F7F9',
            borderRadius: '10px',
            margin: '15px 0',
            padding: '25px',
            boxShadow: 'none'
        },
        notificationText: {
            color: '#454545',
            fontSize: '16px',
            fontWeight: 400,
            paddingTop: 7,
            paddingRight: 70,
        },
    },
    "@media (max-width: 768px)": {
        notificationWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        notificationTitle: {
            fontSize: '20px',
        },
        notification: {
            background: 'white',
            borderRadius: '10px',
            margin: '15px',
            padding: '25px',
            boxShadow: '0px 4px 25px rgba(71, 153, 182, 0.15)'
        },
        notReadNotification: {
            background: '#F5F7F9',
            borderRadius: '10px',
            margin: '15px',
            padding: '25px',
            boxShadow: 'none'
        },
        notificationText: {
            color: '#454545',
            fontSize: '14px',
            fontWeight: 400,
            paddingTop: 20,
        },
        notificationDate: {
            color: '#828282',
            fontSize: 12,
            minWidth: 50,
            marginLeft: 13
        },
    },
});
function NotificationItem({ notification, handleDelete, classes }) {
    const { hasRead, notificationTitle, logDateTime, computedNotificationText } = notification;
    const passedTime = moment(logDateTime).fromNow()
    const passedTimeText = passedTime === 'a few seconds ago' ? "just now" : passedTime

    return (
        <div className={!hasRead ? classes.notification : classes.notReadNotification}>
            <div className={classes.closeWrapper}>
                <img src={deleteNotificationIcon} onClick={() => handleDelete(notification)} />
            </div>
            <div className={classes.notificationWrapper}>
                <div className={classes.flex}>
                    <img className={classes.mobileLogo} src="/img/mobile_icon_bt.png" />
                    <div className={classes.headerTitle}>
                        <p className={hasRead ? classes.readText : classes.notReadText}>{notificationTitle}</p>
                    </div>
                </div>
                <div className={classes.notificationDate}>
                    <span>{passedTimeText}</span>
                </div>
            </div>
            <div className={classes.notificationText}
                style={{ fontWeight: hasRead ? 400 : 600 }}>{computedNotificationText}</div>
        </div>
    );
}

export default withStyles(styles)(NotificationItem);