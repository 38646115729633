import React, {useEffect, useState} from "react";
import Wrapper from "./Components/Layout/Wrapper";
import {isMobile} from "./Components/Media/Media";
import {Grid} from "@mui/material";
import ProductSearchService from "./Services/ProductSearchService";
import SearchResultsList from "./Components/Shop/SearchResultsList";
import AllProductsIndexedResults from "./Components/Shop/AllProductsIndexedResults/AllProductsIndexedResults";

const AllProducts = () =>{
    const [attributeGroupings, setAttributeGroups]=useState([]);
    const [products, setProducts]=useState([]);
    
    const getAllProducts = async () => {
        const products = await ProductSearchService.getIndexedSearch();
        
        setProducts(products.collection);
    }
    
    useEffect(()=> {
        getAllProducts();
    }, []);
    
    return (<Wrapper text="SparesFinder">
        <div className='shop-categories-wrapper' style={{top:"40px"}}>
            <div className='wrapper-content'>
                {isMobile() ? null : <div className={'max-width-1300'}>
                    <div className={'search-header'}>
                        What are you looking for?
                    </div>
                </div>}
                <div className={'search-form-container max-width-1300'}>
                    <div className='search-form-content max-width-1300'>
                        Filter bars
                    </div>
                </div>
            </div>

            <div className="max-width-1300 shop-categories-content">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                        <div className="sub-categories-list">
                            asdadsadsasdads
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} lg={10}>
                        <div className="products-container">
                            {<AllProductsIndexedResults products={products} />}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    </Wrapper>)
}

export default AllProducts;