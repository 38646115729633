import React from 'react';
import {withStyles} from '@material-ui/styles';
import Wrapper from './Components/Layout/Wrapper';
import TablePager from './Components/Table/TablePager';
import Loader from './Components/Layout/Loader';
import NotificationService from './Services/NotificationService';
import NotificationDelete from './Components/Notification/NotificationDelete';
import Header from "./Components/Header/Header";
import NotificationItem from "./Components/Notification/NotificationItem";

const styles = (theme) => ({
    mobileNotificationsWrapper: {
        padding: '77px 0 40px 0',
        backgroundColor: '#fff'
    },
    notificationTableHeader: {
        fontWeight: 'normal !important'
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    "@media (min-width: 769px)": {
        notificationDate: {
            marginBottom: -30,
        },
        tablePagerWrapper: {
            paddingLeft: 0
        },
        notificationTitle: {
            fontSize: '20px',
        },
        notification: {
            background: 'white',
            borderRadius: '10px',
            margin: '15px 0',
            padding: '25px',
            boxShadow: '0px 4px 25px rgba(71, 153, 182, 0.15)'
        },
        notReadNotification: {
            background: '#F5F7F9',
            borderRadius: '10px',
            margin: '15px 0',
            padding: '25px',
            boxShadow: 'none'
        },
        notificationText: {
            color: '#454545',
            fontSize: '14px',
            fontWeight: 500,
            paddingTop: 20,
            paddingRight: 70,
        },
    },
    "@media (max-width: 768px)": {
        tablePagerWrapper: {
            paddingLeft: 16
        },
        notificationTitle: {
            fontSize: '20px',
        },
        notification: {
            background: 'white',
            borderRadius: '10px',
            margin: '15px',
            padding: '25px',
            boxShadow: '0px 4px 25px rgba(71, 153, 182, 0.15)'
        },
        notReadNotification: {
            background: '#F5F7F9',
            borderRadius: '10px',
            margin: '15px',
            padding: '25px',
            boxShadow: 'none'
        },
        notificationText: {
            color: '#454545',
            fontSize: '14px',
            fontWeight: 500,
            paddingTop: 20,
        },
    },
    headerTitle: {
        color: '#454545',
        fontSize: '12px',
        fontWeight: 500,
        "& p": {
            color: '#828282',
            textTransform: 'capitalize',
            fontSize: 12,
            fontWeight: 500,
            margin: 0,
            padding: 0,
            display: 'inline'
        },
        "& span": {
            color: '#828282',
            textTransform: 'none',
            fontSize: 12,
            fontWeight: 500,
            margin: 0,
            padding: 0,
            display: 'inline',
            justifySelf: 'flex-end'
        },
    },
    title: {
        marginLeft: '15px',
        color: '#454545',
        fontSize: '12px',
        fontWeight: 400,
    },
    mobileLogo: {
        paddingRight: '5px',
        display: 'inline-block',
        width: 31,
        height: 26,
        marginLeft: -4,
    },
    notificationTitle: {
        fontSize: '32px',
        fontWeight: 400,
        color: '#00226A',
        display: 'flex',
        alignItems: 'center'
    },
    closeWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: 10,
    },
    goBackArrow: {
        width: 17,
        height: 14,
        margin: '0 16px',
    },
    goBackWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: -7,
        '& p': {
            margin: 0,
            padding: 0,
            color: '#00226A',
            fontSize: 18,
            fontWeight: 400,
        },
    },
    noNotificationsText: {
        textAlign: 'center',
        paddingTop: 70,
    },
});


function Notifications(props) {
    const {classes} = props;
    return (<NotificationsPage classes={classes}/>);
};

export default withStyles(styles)(Notifications);


class NotificationsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            skip: 0,
            take: 10,
            totalRecords: 0,
            loading: true,
            showDeleteNotificationModal: false,
            deleteItem: null,
            unreadNotificationCount: 0
        };
    }

    componentDidMount() {
        this.fetchAllData();
    }

    fetchAllData = async () => {
        await this.getAllNotification();
        this.setState({loading: false});
    };

    getAllNotification = () =>
        NotificationService.GetAllNotifications(this.state.skip, this.state.take).then(response => {
            if (response) {
                const notRead = response.collection.filter(({ hasRead }) => !hasRead)
                if (notRead.length) {
                    this.markAsRead(notRead.map(({notificationTriggerLogID}) => notificationTriggerLogID))
                }
                this.setState({data: response.collection, totalRecords: response.count});
            } else {
                this.setState({data: [], totalRecords: 0});
            }
        })

    goBack = () => window.history.back();

    markAsRead = async (ids) => {
        this.setState({loading: true});
        await NotificationService.MarkAsRead(ids);
        this.getUnreadNotificationsCount();
    };

    handleDeleteNotification = (notification) => {
        this.setState({showDeleteNotificationModal: true, deleteItem: notification})
    }

    renderNotificationList = () => {
        const {classes} = this.props;
        const {data, totalRecords, loading, skip, take} = this.state;

        if (loading) return <Loader/>;
        else return (
            <>
                <div>
                    {data.map((notification) => {
                        return <NotificationItem notification={notification} handleDelete={this.handleDeleteNotification} />;
                    })}
                </div>
                {!data?.length && (
                    <div className={classes.noNotificationsText}>
                        No notifications
                    </div>
                )}
                {!!totalRecords && <div className={classes.tablePagerWrapper}>
                    <TablePager
                        skip={skip}
                        take={take}
                        pageSize="10"
                        onChange={this.pageChanged.bind(this)}
                        totalRecords={totalRecords}
                    />
                </div>}
            </>
        )
    };

    render() {
        const {classes} = this.props;
        const {unreadNotificationCount, showDeleteNotificationModal} = this.state;

        return (
            <Wrapper
                selected="Notifications"
                text="NOTIFICATIONS"
                unreadNotificationCount={unreadNotificationCount}
            >
                <div className="sp-desktop-notifications">
                    <Header body='Notifications' backBody='Back' onBack={this.goBack}/>
                    {this.renderNotificationList()}
                    {showDeleteNotificationModal && (
                        <NotificationDelete
                            onClose={() => this.setState({showDeleteNotificationModal: false, deleteItem: null})}
                            onDelete={this.removeItem.bind(this)}
                        />
                    )}
                </div>
                <div className={`sp-mobile-notifications ${classes.mobileNotificationsWrapper}`}>
                    {this.renderNotificationList()}
                </div>
            </Wrapper>
        );
    }

    pageChanged = (e) => {
        this.setState(
            {
                skip: e.skip,
                take: e.take,
            },
            this.fetchAllData
        );
    };

    removeItem = () => {
        this.setState({loading: true, showDeleteNotificationModal: false});
        NotificationService.RemoveItem(this.state.deleteItem.notificationTriggerLogID).then(response => {
            if (response === true) {
                this.setState({loading: false, deleteItem: null});
                this.fetchAllData();
                this.getUnreadNotificationsCount();
            }
        });
    }

    getUnreadNotificationsCount = () => {
        NotificationService.GetUnreadNotificationsCount().then(response => {
            if (response) {
                this.setState({unreadNotificationCount: response.count});
            }
        });
    }
}