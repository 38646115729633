import React, {useContext, useState} from "react";
import ProductSearchService from "../../../Services/ProductSearchService";
import ClearButton from "../../Shop/ClearButton";
import magnifyingGlassIcon from "../../../assets/img/magnifying-glass.svg";
import { useHistory } from "react-router-dom";
import FeatureFlagContext from "../../../Context/FeatureFlagContext";

const AutoCompleteSearchBar = () => {
    const history=useHistory();
    const query = new URLSearchParams(window.location.search);
    const [searchText, setSearchText] = useState('');
    const [options, setOptions]=useState([]);
    const [changedAuto, setChangedAuto]=useState(false);
    const featureFlagContext = useContext(FeatureFlagContext);
    const isAllProducts = featureFlagContext.checkFeatures("all-products");
    const isNewSpares = featureFlagContext.checkFeatures("new-spares");
    const onChangeSearchText = ({ target: { value } }) => {
        setSearchText(value);
        onChangeAutoComplete(value);
        setChangedAuto(true);
    }

    const clearingSearch = () => {
        setSearchText('');
        setChangedAuto(false);
    }

    const onKeyPress = (event) => {
        if (event.key === "Enter") {
            onSearch();
        }
    };

    const onSearch = () => {
        if (isAllProducts){
            window.location = `/products?searchTerm=${searchText}`;
        } else {
            onSearchSpares();
        }
    };

    const onSearchProducts = (value) => {
        window.location = `/products?searchTerm=${value || searchText}`;
    };
    
    const onSearchSpares = () => {
        if (isNewSpares)
            window.location=`/Spares?tab=searchByKeyword&q=${searchText}`
        else 
            window.location=`/Shop-2?tab=searchByKeyword&q=${searchText}`;
    }

    const onChangeAutoComplete = (term)=>{
        if (term.length>3 && isAllProducts)
        {
            ProductSearchService.getAutoComplete(term).then((response)=>{
                if (response.length>0)
                {
                    let newList = [...response];
                    setOptions(newList);
                }
            })
        }
    }
    
    return (<>
        <input
            type="text"
            placeholder="Part Code or Keyword"
            value={searchText}
            onChange={onChangeSearchText}
            onKeyPress={onKeyPress}
            style={{ width: "100%" }}
        />
        {searchText.length > 0 && <ClearButton onClear={clearingSearch} />}
        <img onClick={onSearch} src={magnifyingGlassIcon} alt="search" />
        { changedAuto===true && searchText.length>0 ? 
            <ul className="autocomplete-dropdown">
                <li><a onClick={onSearchSpares}>{searchText} <span className="search-location">Search in Spares...</span> </a></li>
                {isAllProducts && <li><a onClick={() => { onSearchProducts(searchText) }}>{searchText} <span className="search-location">Search in Products...</span></a></li>}
                {isAllProducts && options.map((term)=> {
                    return <li><a onClick={()=>{
                        onSearchProducts(term.name);
                    }}>{term.name}</a></li>
                })}
            </ul> : <></>
        }
    </>)
}

export default AutoCompleteSearchBar;