import http from '../utils/http';

const previousOrders = (searchText, sortDir, sortProp, pageNumber, pageSize) => {
    return http.post(`Orders/PreviousOrders`, { searchText, sortDir, sortProp, pageNumber, pageSize })
}

const recentlyOrderedLines = (searchText, sortDir, sortProp) => {
    const params = new URLSearchParams();
    if (searchText) {
        params.append('searchText', searchText);

    }
    if (sortDir) {
        params.append('sortDir', sortDir);
    }
    if (sortProp) {
        params.append('sortProp', sortProp);
    }


    return http.get(`Orders/RecentlyOrderedLines`, { params })
}

const checkRestriction = () => {
    return http.get('Orders/CheckRestriction')
}

const placeOrder = (order) => {
    return http.post(`Orders/Order`, order);
}

const updateOrderOnBasket = (order) => {
    return http.post('Orders/UpdateOrderForCardPayment', order);
}

const placeOrderForBasket = (basketKey) => {
    return http.post(`/orders/PlaceOrderForCurrentBasket?basketKey=${basketKey}`);
}

const paymentLink = (basketKey, tokenId) => {
    let paymentUrl = `Orders/GetPaymentLink?basketKey=${basketKey}`
    if (tokenId) {
        paymentUrl += `&tokenId=${tokenId}`
    }
    return http.post(paymentUrl);
}

const returnProducts = (request) => {
    return http.post(`Orders/ReturnProducts`, request);
}

export default {
    previousOrders,
    recentlyOrderedLines,
    checkRestriction,
    placeOrder,
    paymentLink,
    updateOrderOnBasket,
    placeOrderForBasket,
    returnProducts
}