import React from 'react';
import { isMobile } from "../Media/Media";
import spinnerIcon from "../../assets/img/spinner.svg";

function SearchButton({ loading, onSearch, disabled = false }) {
    return (
        <button
            type="button"
            className={`button-large button-light-blue width-full search-button ${isMobile() ? '' : 'col-md-3'}`}
            disabled={disabled}
            onClick={onSearch}
        >
            {!loading ? 'Search' : <img src={spinnerIcon} alt="Loading" />}
        </button>
    );
}

export default SearchButton;