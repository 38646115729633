import React from "react";
import FooterBar from "./FooterBar";
import HeaderBar from "./HeaderBarDesktop";
import clsx from "clsx";
import getBasketCount from "../../Helpers/common/getBasketCount";
import BasketService from "../../Services/BasketService";
import NotificationService from "../../Services/NotificationService";
import FeatureFlagContext from "../../Context/FeatureFlagContext";
const $ = window.jQuery;

class Wrapper extends React.Component {
  static contextType = FeatureFlagContext;
  constructor(props) {
    super(props);

    this.state = {
      subscriptionName: "",
      daysTrial: 0,
      show: false,
      expiresOn: "",
      freeTier: false,
      basket: null,
      unreadNotificationCount: 0
    };
  }

  isAllProducts(){
    return this.context.checkFeatures("all-products");
  }

  componentDidMount() {
    this.fetchSubscription();
    //this.loadBasket();
  }

  getCount = (basketCount, basket) => {
    if (basketCount !== undefined && basketCount !== null) {
      return basketCount;
    }
    return basket ? getBasketCount(basket) : 0;
  };

  getUnreadNotificationCount = () => {
    NotificationService.GetUnreadNotificationsCount().then(response => {
      if (response) {
        this.setState({ unreadNotificationCount: response.count })
      }
    })
  };

  fetchSubscription = () => {
    $.ajax({
      url: "/home/currentsubscription",
      data: {},
      success: (subscription) => {
        let showBanner = subscription.showBanner;

        if (this.props.hideBanner) {
          showBanner = false;
        }

        this.setState({
          subscriptionName: subscription ? subscription.name : "",
          daysTrial: subscription ? subscription.daysTrial : "",
          show: showBanner,
          expiresOn: subscription.expiresOnDisplayString,
          freeTier: subscription ? subscription.freeTier : false,
        });
      },
    });
  };

  loadBasket = () => {
    BasketService.getBasketForUser().then((response) => {
      if (response.lines === null) response.lines = [];
      this.setState({ basket: response });
    });
    };

  render() {
    const { headerBarAdd, wrapperClass, children, selected, basketCount, newComponent } =
      this.props;
    const { basket } = this.state;
    return (
      <div>
        <HeaderBar
          headerBarAdd={headerBarAdd}
          basketCount={this.getCount(basketCount, basket)}
          unreadNotificationCount={this.props.unreadNotificationCount || this.state.unreadNotificationCount}
          selected={selected}
          newComponent={newComponent}
          disableBuyTradeCheckout={this.props.disableBuyTradeCheckout}
        />

        <main
          className={clsx(
            "main",
            "template",
            "sp-desktop-wrapper",
            wrapperClass
          )}
          style={{ paddingTop: this.isAllProducts() ? 295 : 205 }}
        >
          <div className="container page-container">
            <div className="template__wrapper">{children}</div>
          </div>
        </main>
        <FooterBar disableBuyTradeCheckout={this.props.disableBuyTradeCheckout}></FooterBar>
      </div>
    );
  }
}

export default Wrapper;
