import React, { useState } from "react";

const AvailabilityFulilmentOptionLine = ({
    isExpress,
    isByBox,
    availableQtyText,
    fulfilmentOptionDescription,
    orderDetailsOnClick,
    availableFromText,
    isSelected,
    onSelected
}) => {
    if (isExpress)
        return (
            <label className="express" >
                <span className="availableFromText">{fulfilmentOptionDescription}</span>{" "}
                on <span className="availableQtyLink" onClick={orderDetailsOnClick}>{availableQtyText} </span>items
                <input type="radio" checked={isSelected} onChange={onSelected} />
                <span className="checkmark"></span>
            </label>)

    if (isByBox)
        return (
            <label >
                <span className="availableFromText">{fulfilmentOptionDescription}</span>{" "}
                on <span className="availableQtyLink" onClick={orderDetailsOnClick}>{availableQtyText} </span>items
                <input type="radio" checked={isSelected} onChange={onSelected} />
                <span className="checkmark"></span>
            </label>)

    return (
        <label>
            <span className="availableQtyLink" onClick={orderDetailsOnClick}>{availableQtyText}</span> items available {fulfilmentOptionDescription}<span className="availableFromText">{availableFromText}</span>
            <input type="radio" checked={isSelected} onChange={onSelected} />
            <span className="checkmark"></span>
        </label>)
}

export default AvailabilityFulilmentOptionLine;