import React from 'react';
import * as ReactDOM from 'react-dom';

const modalRoot = document.getElementById('root');

class Portal extends React.Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
    }
    componentDidMount() {
        modalRoot.appendChild(this.el);
        if (typeof window != 'undefined' && window.document) {
            document.body.style.overflow = 'hidden';
        }
    }
    componentWillUnmount() {
        modalRoot.removeChild(this.el);
        document.body.style.overflow = 'unset';
    }
    render() {
        return ReactDOM.createPortal(this.props.children, this.el);
    }
}

export default Portal;
