export function capitalizeWords(text) {
    // Split the input text into an array of words
    const words = text.split(" ");

    // Iterate over the array of words and capitalize the first letter of each word
    const capitalizedWords = words.map(word => {
        const lowerCaseWord = word.toLowerCase();
        return lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.slice(1);
    });

    // Join the array of capitalized words into a single string with spaces
    const capitalizedText = capitalizedWords.join(" ");

    // Return the capitalized text
    return capitalizedText;
}