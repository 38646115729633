import React from "react";
import DeleteModal from "../Modal/DeleteModal";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    content: {
        padding: '44px 10px'
    },
    title: {
        color: "#00226A",
        margin: 0,
        marginBottom: 8,
        padding: 0,
        fontWeight: '500 !important'
    },

    description: {
        fontSize: 16,
        lineHeight: '19px',
        color: "#828282",
        margin: 0,
        padding: 0
    },
    buttons: {
        display: "flex",
        width: "100%",
        marginTop: 26,
    },
    button: {
        padding: '13px 20px',
        color: "#FFFFFF",
        fontSize: 14,
        lineHeight: ' 17px',
        borderRadius: '4px',
        fontWeight: 600,
        width: "50%",
    },
    cancelButtonGrey: {
        background: '#CDCDCD',
        marginRight: 12
    },
    cancelButtonBlueBordered: {
        background: '#FFFFFF',
        border: '1px solid #009FDF',
        color: '#009FDF',
        marginRight: 12
    },
    okButtonRed: {
        background: '#ED1F45',
    },
    okButtonBlue: {
        background: '#009FDF',
    },
    [`@media (max-width:768px)`]: {
        Content: {
            padding: '28px 0',
            margin: '0 -10px'
        },
        title: {
            fontSize: 20,
            lineHeight: '24px',
            marginBottom: 4,
        },
        buttons: {
            marginTop: 15,
        },
        cancelButton: {
            marginRight: 8
        },
    },
}));

const THEMES = {
    RED: 'red',
    BLUE: 'blue'
};

const NewConfirmModal = ({ onClose, onConfirm, title, description, okText, cancelText, theme, withoutCancelButton, withoutOkButton }) => {
    const classes = useStyles();

    return (
        <DeleteModal onClose={onClose}>
            <div className={classes.content}>
                <h1 className={`${classes.title} modal-popup-header`}>{title || 'Are you sure?'}</h1>
                {description && <p className={classes.description}>{description}</p>}
                    <div className={classes.buttons}>
                    {!withoutCancelButton && (<button
                            className={`${classes.button} ${theme === THEMES.BLUE ? classes.cancelButtonBlueBordered : classes.cancelButtonGrey}`}
                            onClick={onClose}
                        >
                            {cancelText || 'Cancel'}
                        </button>)}
                    {!withoutOkButton && (
                        <button
                            className={`${classes.button} ${theme === THEMES.BLUE ? classes.okButtonBlue : classes.okButtonRed}`}
                            onClick={onConfirm}
                        >
                            {okText || 'Ok'}
                        </button>)}
                    </div>
            </div>
        </DeleteModal>
    )
};

export default NewConfirmModal;