import { create } from 'zustand';
import { DEFAULT_SEARCH } from "../Helpers/PartsIdentificationMode";
import SearchByApplianceIcon from "../../../assets/img/shop-page/search-appliance";
import SearchByNumberIcon from "../../../assets/img/shop-page/search-number";
import SearchByKeywordIcon from "../../../assets/img/shop-page/search-keyword";
import React from "react";

const usePartsIdentificationStore = create((set) => ({
    modes:[
        {
            id: 1,
            icon: <SearchByApplianceIcon sx={{ fontSize: 30 }} />,
            text: "Select Appliance",
            viewKey: "newSearch",
        },
        {
            id: 2,
            icon: <SearchByNumberIcon sx={{ fontSize: 30 }} />,
            text: "Search by Appliance GC Number",
            viewKey: "searchByNumber",
        },
        {
            id: 3,
            icon: <SearchByKeywordIcon sx={{ fontSize: 30 }} />,
            text: "Search by Part Code or Keyword",
            viewKey: "searchByKeyword",
        }
    ],
    selectedMode:{
        id: 1,
        icon: <SearchByApplianceIcon sx={{ fontSize: 30 }} />,
        text: "Select Appliance",
        viewKey: "newSearch",
    },
    setSelectedMode: (modeToSelect) => set((state)=> ({selectedMode:modeToSelect})),
    manufacturers:[],
    setManufacturers: (manufacturerList) => set((state)=>({manufacturers:manufacturerList})),
    selectedManufacturer:null,
    setSelectedManufacturer:(manufacturerId)=> set((state)=> ({selectedManufacturer:manufacturerId})),
    appliances: [],
    setAppliances: (applianceList) => set((state)=>({appliances:applianceList})),
    selectedAppliance:null,
    setSelectedAppliance:(applianceId)=> set((state)=> ({selectedAppliance:applianceId})),
    partTypes:[],
    setPartTypes:(partTypeList) => set((state)=>({partTypes:partTypeList})),
    selectedPartType:null,
    setSelectedPartType:(partId)=> set((state)=>({selectedPartType:partId})),
    searchText:"",
    setSearchText: (newSearchText) => set((state)=>({searchText:newSearchText})),
    parts:[],
    setParts:(partsList)=> set((state)=>({ parts: partsList})),
    diagrams:[], 
    setDiagrams:(diagramList)=> set((state)=> ({ diagrams: diagramList })),
    viewDiagrams:false,
    setViewDiagrams:(showDiagrams)=> set((state)=> ({viewDiagrams:showDiagrams})),
    selectedDiagram:null,
    setSelectedDiagram: (diagramToSelect)=>set((state)=>({selectedDiagram:diagramToSelect})),
    selectedCategory:null,
    setSelectedCategory: (categoryToSelect) => set((state)=>({selectedCategory:categoryToSelect})),
    diagramData:[],
    setDiagramData: (diagramDataList) => set((state)=>({diagramData:diagramDataList})),
    likedProducts:[],
    setLikedProducts: (likedProducts)=> set( (state)=> ({likedProducts:likedProducts})),
    newBasketItem:null,
    setNewBasketItem: (basketItem) => set ((state)=>({newBasketItem:basketItem})),
    isLoading:true,
    setIsLoading: (isLoading)=> set((state)=>({ isLoading:isLoading})),
    hasSearched:false,
    setHasSearched:(hasSearched)=>set((state)=>({hasSearched:hasSearched})),
    autoLoad:true,
    setAutoLoad:(autoLoad)=>set((state)=>({autoLoad:autoLoad})),
    sortOrder:"asc",
    setSortOrder: (sortOrder) => set((state) => ({ sortOrder: sortOrder })),
    searchRefresh: true,
    setSearchRefresh: (searchRefresh) => set((state) => ({ searchRefresh: searchRefresh }))
}));

export default usePartsIdentificationStore;