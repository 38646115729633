import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "#1A438F",
    height: "66px",
    position: "fixed",
    bottom: 0,
  },
  bottomNavigationIcon: {
    maxWidth: "unset",
    color: "#3EC4F4",
  },
  selected: {
    color: "#3EC4F4",
  },
});

export default function Footer(props) {
  const { value = -1 } = props;
  const classes = useStyles();
  // const [value, setValue] = React.useState(0);

  const goToNewSearch = () => (window.location = "/sparesFinder");
  const goToRecentlyOrdered = () =>
    (window.location = "/sparesFinder/recentlyOrdered");
  const goToFavourites = () => (window.location = "/sparesFinder/favourites");

  return null;
  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {}}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        classes={{ selected: classes.selected }}
        className={classes.bottomNavigationIcon}
        label="New search"
        onClick={goToNewSearch}
        icon={
          <div>
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.53125 0C10.7939 0 12.9638 0.898825 14.5638 2.49874C16.1637 4.09866 17.0625 6.26862 17.0625 8.53125C17.0625 10.6444 16.2881 12.5869 15.015 14.0831L15.3694 14.4375H16.4062L21 19.0312L19.0312 21L14.4375 16.4062V15.3694L14.0831 15.015C12.5869 16.2881 10.6444 17.0625 8.53125 17.0625C6.26862 17.0625 4.09866 16.1637 2.49874 14.5638C0.898825 12.9638 0 10.7939 0 8.53125C0 6.26862 0.898825 4.09866 2.49874 2.49874C4.09866 0.898825 6.26862 0 8.53125 0ZM8.53125 2.625C5.25 2.625 2.625 5.25 2.625 8.53125C2.625 11.8125 5.25 14.4375 8.53125 14.4375C11.8125 14.4375 14.4375 11.8125 14.4375 8.53125C14.4375 5.25 11.8125 2.625 8.53125 2.625Z"
                fill="#3EC4F4"
              />
            </svg>
          </div>
        }
      />
      <BottomNavigationAction
        classes={{ selected: classes.selected }}
        className={classes.bottomNavigationIcon}
        onClick={goToRecentlyOrdered}
        label="Recently ordered"
        icon={
          <div>
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 9.48205C19.0097 14.7163 14.7411 18.9963 9.50689 19C7.24623 19.0016 5.16972 18.2136 3.5379 16.8965C3.11358 16.5541 3.08187 15.9182 3.46746 15.5326L3.89906 15.101C4.22884 14.7712 4.75532 14.7351 5.12069 15.025C6.32293 15.979 7.84443 16.5484 9.49999 16.5484C13.3959 16.5484 16.5484 13.3954 16.5484 9.50002C16.5484 5.60405 13.3953 2.45162 9.49999 2.45162C7.6301 2.45162 5.93179 3.17825 4.67078 4.36434L6.61498 6.30855C7.00111 6.69468 6.72764 7.35485 6.18162 7.35485H0.612903C0.274389 7.35485 0 7.08046 0 6.74195V1.17321C0 0.627193 0.660173 0.353722 1.0463 0.739814L2.93757 2.63108C4.64308 1.00118 6.95461 0 9.49999 0C14.7407 0 18.9903 4.2436 19 9.48205ZM12.0699 12.5L12.4462 12.0162C12.7579 11.6154 12.6857 11.0378 12.2849 10.7261L10.7258 9.51339V5.51614C10.7258 5.00839 10.3142 4.59678 9.80644 4.59678H9.19354C8.68579 4.59678 8.27419 5.00839 8.27419 5.51614V10.7125L10.7798 12.6613C11.1806 12.973 11.7582 12.9008 12.0699 12.5Z"
                fill="#3EC4F4"
              />
            </svg>
          </div>
        }
      />
      <BottomNavigationAction
        classes={{ selected: classes.selected }}
        className={classes.bottomNavigationIcon}
        label="Favourites"
        onClick={goToFavourites}
        icon={
          <div>
            <svg
              width="21"
              height="19"
              viewBox="0 0 21 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.0109 2.22617C15.866 0.382473 12.5521 0.659406 10.5 2.79521C8.44785 0.659406 5.13399 0.378679 2.98907 2.22617C0.198442 4.62752 0.606645 8.54253 2.59571 10.6176L9.10469 17.3968C9.47578 17.7838 9.97305 18 10.5 18C11.0307 18 11.5242 17.7876 11.8953 17.4006L18.4043 10.6214C20.3896 8.54632 20.8053 4.63132 18.0109 2.22617ZM17.1351 9.33919L10.6262 16.1184C10.5371 16.2094 10.4629 16.2094 10.3738 16.1184L3.86485 9.33919C2.51035 7.92796 2.23575 5.25726 4.13574 3.62222C5.5793 2.38171 7.80586 2.56759 9.20117 4.02055L10.5 5.37486L11.7988 4.02055C13.2016 2.56001 15.4281 2.38171 16.8643 3.61842C18.7605 5.25347 18.4785 7.93935 17.1351 9.33919Z"
                fill="#3EC4F4"
                stroke="#3EC4F4"
                strokeWidth="0.6"
              />
            </svg>
          </div>
        }
      />
    </BottomNavigation>
  );
}
