/* eslint-disable no-useless-computed-key */
import React, { useState, useEffect, useRef, useContext } from "react";
import Wrapper from "./Components/Layout/Wrapper";
import ShortOrderCard from "./Components/Order/ShortOrderCard";
import SortDropDown from "./Components/Layout/SortDropDown";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CalendarIcon from "../src/assets/img/calendar-icon";
import ModalBranchDetails from "./Components/Modal/ModalBranchDetails";
import ModalWindow from "./Components/Modal/ModalWindow";
import moment from "moment";
import getTotalQty from "./Helpers/common/getTotalQty";
import formatPrice from "./Helpers/price/formatPrice";
import BasketService from "./Services/BasketService";
import OrdersService from "./Services/OrdersService";
import CreditNoteService from "./Services/CreditNoteService";
import { useHistory } from "react-router-dom";
import { isMobile, isTablet } from "./Components/Media/Media";
import Header from "./Components/Header/Header";
import exportSvg from "./assets/img/export.svg"
import { Pagination, PaginationItem } from "@mui/material";
import NewConfirmModal from "./Components/Modal/NewConfirmModal";
import ModalComp from "./Components/Modal/ModalComp";
import SelectListStaticWithLabel from "./Components/Form/SelectListStaticWithLabel";
import toastr from 'toastr';
import InReturnImage from "../src/assets/img/in-return.svg";
import DatePicker from "react-date-picker";
import FeatureFlagContext from "./Context/FeatureFlagContext";
import FinderInput from "./Components/Shop/FinderInput";
import ReactGA from "react-ga4";
import configurationService from './Services/ConfigurationService'

const deliveryOptions = [
    {
        name: "Collect from branch",
        icon: (
            <svg
                width="23"
                height="26"
                viewBox="0 0 30 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.44336 21.8981C8.71406 22.2501 9.01641 22.5688 9.375 22.8046V24.3279C5.47734 24.9482 2.8125 26.1906 2.8125 27.625C2.8125 29.6789 8.26875 31.3438 15 31.3438C21.7312 31.3438 27.1875 29.6789 27.1875 27.625C27.1875 26.1906 24.5227 24.9488 20.625 24.3279V22.8046C20.9836 22.5688 21.2859 22.2501 21.5566 21.8981C26.5506 22.9327 30 25.1049 30 27.625C30 31.1459 23.284 34 15 34C6.71601 34 0 31.1459 0 27.625C0 25.1049 3.44941 22.9327 8.44336 21.8981ZM15 8.5C12.9287 8.5 11.25 6.59746 11.25 4.25C11.25 1.90254 12.9287 0 15 0C17.0713 0 18.75 1.90254 18.75 4.25C18.75 6.59746 17.0713 8.5 15 8.5ZM18.75 21.25V27.625C18.75 28.7984 17.9104 29.75 16.875 29.75H13.125C12.0896 29.75 11.25 28.7984 11.25 27.625V21.25C10.2146 21.25 9.375 20.2984 9.375 19.125V12.75C9.375 10.9896 10.6342 9.5625 12.1875 9.5625H12.8789C13.5275 9.89652 14.2418 10.0938 15 10.0938C15.7582 10.0938 16.4725 9.89652 17.1211 9.5625H17.8125C19.3658 9.5625 20.625 10.9896 20.625 12.75V19.125C20.625 20.2984 19.7854 21.25 18.75 21.25Z"
                    fill="#007AAB"
                />
            </svg>
        ),
    },
    {
        name: "Delivery to branch (Next day)",
        icon: (
            <svg
                width="30"
                height="25"
                viewBox="0 0 30 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14.999 18.75H5.99817V10.9375H2.99788V23.4375C2.99788 24.3018 3.66826 25 4.49803 25H16.4992C17.3289 25 17.9993 24.3018 17.9993 23.4375V10.9375H14.999V18.75ZM29.7473 6.94336L25.7485 0.693359C25.4672 0.258789 24.9984 0 24.4968 0H5.50125C4.99964 0 4.53084 0.258789 4.25425 0.693359L0.255437 6.94336C-0.410251 7.9834 0.302316 9.375 1.50243 9.375H28.5003C29.6957 9.375 30.4083 7.9834 29.7473 6.94336ZM23.9999 24.2188C23.9999 24.6484 24.3374 25 24.7499 25H26.2501C26.6626 25 27.0002 24.6484 27.0002 24.2188V10.9375H23.9999V24.2188Z"
                    fill="#3EC4F4"
                />
            </svg>
        ),
    },
    {
        name: "Delivery to 3rd party address",
        icon: (
            <svg
                width="32"
                height="26"
                viewBox="0 0 32 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M31.2 17.875H30.4V12.3855C30.4 11.7406 30.145 11.1211 29.695 10.6641L24.7 5.59102C24.25 5.13398 23.64 4.875 23.005 4.875H20.8V2.4375C20.8 1.0918 19.725 0 18.4 0H2.4C1.075 0 0 1.0918 0 2.4375V18.6875C0 20.0332 1.075 21.125 2.4 21.125H3.2C3.2 23.8164 5.35 26 8 26C10.65 26 12.8 23.8164 12.8 21.125H19.2C19.2 23.8164 21.35 26 24 26C26.65 26 28.8 23.8164 28.8 21.125H31.2C31.64 21.125 32 20.7594 32 20.3125V18.6875C32 18.2406 31.64 17.875 31.2 17.875ZM8 23.5625C6.675 23.5625 5.6 22.4707 5.6 21.125C5.6 19.7793 6.675 18.6875 8 18.6875C9.325 18.6875 10.4 19.7793 10.4 21.125C10.4 22.4707 9.325 23.5625 8 23.5625ZM24 23.5625C22.675 23.5625 21.6 22.4707 21.6 21.125C21.6 19.7793 22.675 18.6875 24 18.6875C25.325 18.6875 26.4 19.7793 26.4 21.125C26.4 22.4707 25.325 23.5625 24 23.5625ZM28 13H20.8V7.3125H23.005L28 12.3855V13Z"
                    fill="#3EC4F4"
                />
            </svg>
        ),
    },
];

const statusOptions = [
    { id: 0, name: "In Progress", color: "#FEAB4A" },
    { id: 1, name: "Pending", color: "#C4C4C4" },
    { id: 2, name: "Received", color: "#81C454" },
    { id: 3, name: "Returned", color: "#ED1F45" },
    { id: 4, name: "Partially Returned", color: "#ED1F45" },
];

const useStyles = makeStyles((theme) => ({
    description: {
        alignSelf: "self-start",
    },
    qty: {
        position: "absolute",
        left: "10px",
        color: "#454545",
        opacity: "50%",
        fontSize: "14px",
        top: "50%",
        transform: "translateY(-50%)",
    },
    qtyWrapper: {
        position: "relative",
        display: "flex",
    },
    productRoot: {
        display: "flex",
        padding: "10px 0",
    },
    wrapper: {
        flex: 1,
    },
    title: {
        textAlign: "center",
        color: "#3EC4F4",
        fontSize: "16px",
        fontWeight: 600,
        margin: "25px",
    },
    order: {
        border: "1px",
        borderRadius: "6px",
        background: "white",
        padding: "10px",
        margin: "10px",
    },
    downloadInvoice: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 30px",
        gap: "10px",
        width: "209px",
        height: "39px",
        background: "#009FDF",
        borderWidth: 0,
        borderRadius: "4px",
        cursor: "pointer"
    },
    downloadModalTitle: {
        color: "#00226A",
        margin: 0,
        marginBottom: 8,
        padding: 0,
        fontWeight: '500 !important'
    },
    downloadModalDesc: {
        fontSize: 16,
        lineHeight: '19px',
        color: "#828282",
        margin: 0,
        padding: 0
    },
    productFooter: {
        display: "flex",
        justifyContent: "space-between",
    },
    price: {
        color: "#81C454",
        fontWeight: 700,
        fontSize: "18px",
    },
    numberWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },
    deliveryWrapper: {
        display: "flex",
        alignItems: "center",
        paddingTop: "15px",
        paddingBottom: "5px",
    },
    deliveryTitle: {
        fontSize: "16px",
        fontWeight: 700,
    },
    deliveryTitleWrapper: {
        paddingLeft: "20px",
    },
    deliveryCost: {
        color: "#454545",
        opacity: "50%",
        fontSize: "18px",
        fontWeight: 700,
    },
    totalDisplayedCost: {
        color: "#007AAB",
        fontSize: "18px",
        fontWeight: 700,
        textAlign: "right",
    },
    totalVatDisplayedCost: {
        color: "#007AAB",
        fontSize: "24px",
        fontWeight: 700,
    },
    main: {
        display: "flex",
        flexDirection: "column",
    },
    buttons: {
        justifySelf: "",
    },
    vatCost: {
        color: "#454545",
        opacity: "50%",
        fontSize: "12px",
        fontWeight: 500,
    },
    productImg: {
        height: "unset",
    },
    deliveryDesktopWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginBottom: 25,
    },
    costDesktopWrapper: {
        width: "100%",
    },
    returnItemRow: {
        display: "grid",
        gridTemplateColumns: "10px 64px 120px 150px 74px 57px 57px",
        alignItems: "center",
        gridGap: 32,
        borderBottom: "1px solid #bacad1",
        "&:last-child": {
            borderBottom: "1px solid transparent",
        },
        "& div:last-child": {
            textAlign: "end",
        },
    },

    priceDesk: {
        fontWeight: 500,
        fontSize: 16,
        color: "#454545",
    },
    ["@media (max-width: 768px)"]: {
        totalVatDisplayedCostWrapper: {
            padding: "10px",
            marginTop: 10,
            background: "#F0F5F8",
        },
        priceWrapper: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
        },
        orderHeader: {
            padding: 20,
            display: "flex",
            justifyContent: "space-between",
            color: "#00226A",
            backgroundColor: "#fff",
        },
        tableWrapper: {
            padding: "15px 20px",
            marginBottom: 30,
        },
        goBackWrapper: {
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginTop: 8,
        },
        itemRow: {
            display: "grid",
            gridTemplateColumns: "64px 1fr",
            alignItems: "center",
            gridGap: 16,
            borderBottom: "1px solid #bacad1",
            paddingBottom: 15,
            paddingTop: 15,

            "&:first-child": {
                borderTop: "1px solid #bacad1",
            }
        },
        returnItemRow: {
            display: "grid",
            gridTemplateColumns: "64px 1fr",
            alignItems: "center",
            gridGap: 16,
            borderBottom: "1px solid #bacad1",
            paddingBottom: 15,
            paddingTop: 15,

            "&:first-child": {
                borderTop: "1px solid #bacad1",
            },
            "& div:last-child": {
                textAlign: "start",
            },
        },
        infoWrapper: {
            display: "flex",
            flexDirection: "column-reverse",
            padding: 20,
        },
        collectInfoWrapper: {
            border: "1px solid #bacad1",
            borderRadius: 33,
            padding: "15px 30px",
            marginTop: 60,

            "& p": {
                marginBottom: 25,
                lineHeight: 1.2,
            },

            "& a": {
                color: "#000",
            },
        },
        IHUBNum: {
            display: "none",
        },
        reOrderBtn: {
            minWidth: "105px",
            width: "100%",
            height: "35px !important",
            fontSize: 14,
            borderRadius: "3px !important",
        },
        goBackWrapperMobile: {
            display: "flex",
            alignItems: "end",
            cursor: "pointer",
            marginTop: 8,
            fontSize: 16,
            color: "#00226A",
            borderBottom: "none",
        },
    },
    ["@media (min-width: 769px)"]: {
        totalVatDisplayedCostWrapper: {
            padding: "10px 55px 10px 10px",
            marginTop: 10,
            background: "#F0F5F8",
        },
        priceWrapper: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 55px 10px 10px",
        },
        orderHeader: {
            padding: "25px 35px 25px 0",
            display: "flex",
            justifyContent: "space-between",
            color: "#00226A",
        },
        tableWrapper: {
            padding: "35px 55px",
            border: "1px solid #bacad1",
            borderRadius: 33,
            marginBottom: 55,
        },
        goBackWrapper: {
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginTop: 8,
            fontSize: 16,
            color: "#00226A",
        },
        itemRow: {
            display: "grid",
            gridTemplateColumns: "64px 180px 1fr 74px 127px",
            alignItems: "center",
            gridGap: 32,
            borderBottom: "1px solid #bacad1",
            "&:last-child": {
                borderBottom: "1px solid transparent",
            },
            "& div:last-child": {
                textAlign: "end",
            },
        },
        infoWrapper: {
            display: "flex",
            flexDirection: "row",
        },
        collectInfoWrapper: {
            border: "1px solid #bacad1",
            borderRadius: 33,
            padding: 30,
            marginRight: 56,
            fontSize: 14,
            "& p": {
                marginBottom: 25,
                lineHeight: 1.2,
            },
            "& a": {
                color: "#454545",
            },
        },
        IHUBNum: {
            display: "block",
            fontWeight: 400,
            fontSize: 16,
            color: "##00226A",
        },
        reOrderBtn: {
            minWidth: "105px",
            width: "100%",
            height: "48px !important",
        },
    },
    orderNum: {
        fontWeight: 600,
        fontSize: 16,
    },
    orderDate: {
        fontWeight: 400,
        fontSize: 16,
    },
    statusSelect: {
        minWidth: "250px",
    },
    creditNoteWrapper: {
        marginTop: "30px",
        padding: "10px"
    },
    available: {
        fontSize: 16,
        "& p": {
            margin: 0,
            padding: 0,
        },
    },
    backIcon: {
        position: "inherit !important",
        width: 18,
        height: 14,
        display: "inline",
        marginRight: 9,
        marginLeft: 3,
    },
    historySearchInput: {
        position: "relative",
        marginTop: 12,
        "& input": {
            height: 46,
            width: "100%",
            border: "1px solid #bacad1",
            borderRadius: 10,
            "&::placeholder": {
                fontSize: 12,
                fontWeight: 600,
                color: "#454545",
                lineHeight: 4.5,
            },
        },
        "& img": {
            position: "absolute",
            width: 14,
            height: 14,
            right: 17,
            top: 17,
        },
    },
    historySearchDesktop: {
        position: "relative",
        display: "grid",
        gridTemplateColumns: "auto 280px",
        marginTop: 12,
        "& input": {
            height: 54,
            width: "-webkit-fill-available",
            border: "1px solid #bacad1",
            borderRadius: 10,
            "&::placeholder": {
                fontSize: 16,
                fontWeight: 400,
                color: "#828282",
                lineHeight: 19.5,
            },
        },
        "& img": {
            position: "absolute",
            width: 18,
            height: 18,
            right: 17,
            top: 17,
        },
        "& .btn": {
            margin: 0,
            justifySelf: "end",
        },
    },
    btnStyleOutlined: {
        width: "35%",
        fontWeight: "600",
        fontSize: "16px",
        color: "#009FDF",
    },

    '@media (min-width: 640px) and (max-width: 768px)': {
        orderHeader: {
            padding: "25px 35px",
        },
        orderNum: {
            fontSize: 20,
            lineHeight: '24px',
            fontWeight: 400
        },
        IHUBNum: {
            display: "block",
        },
        deliveryWrapper: {
            paddingBottom: 8
        },
        collectInfoWrapper: {
            "& p": {
                fontSize: '14px',
                lineHeight: '23px',
            },
        },
        priceWrapper: {
            padding: "18px 27px"
        }
    }
}));

const DesktopDetailsView = ({ desktopItem }) => {
    const classes = useStyles();
    const itemsCount = getTotalQty(desktopItem);
    const [creditNotes, setCreditNotes] = useState([]);

    useEffect(() => {
        getCreditNote(desktopItem.id)
    }, [desktopItem])
    const formatDate = (date) => {
        return moment(date).format("DD MMMM YYYY [at] HH:mm");
    };

    const isJuneOrJuly = (date) => {
        return moment(date).month() == 5 || moment(date).month() == 6
    }
    const getCreditNote = (basketId) => {
        CreditNoteService.getCreditNoteByBasketId(basketId).then(response =>
            setCreditNotes(response));
        setTimeout(() => console.log(creditNotes), 100);
    }

    const formatOpeningTime = (open, close) => {
        let timeFormat = "HH:mm";
        let openTime, closeTime;
        open = moment(open, [timeFormat]);
        close = moment(close, [timeFormat]);

        if (moment(open, "HH:mm").isValid() && moment(close, "HH:mm").isValid()) {
            openTime = moment(open).format(timeFormat);
            closeTime = moment(close).format(timeFormat);
            return `${openTime} - ${closeTime}`;
        }
        return "Closed";
    };

    const getDeliveryAddress = () => {
        return [
            desktopItem.address1,
            desktopItem.address2,
            desktopItem.town,
            desktopItem.postCode,
        ]
            .filter((x) => x !== null && x !== "")
            .join(", ");
    };

    const renderWolseleyText = () => {
        return (
            <div>
                <div className={classes.collectInfoWrapper}>
                    <div className={classes.deliveryDesktopWrapper}>
                        <div className={classes.deliveryWrapper}>
                            {deliveryOptions[0].icon}
                            <div className={classes.deliveryTitleWrapper}>
                                <div className={classes.deliveryTitle}>
                                    {desktopItem.isDelivery
                                        ? "Delivery" +
                                        (desktopItem.collectFromBranchOn
                                            ? " on " +
                                            moment(desktopItem.collectFromBranchOn).format(
                                                isJuneOrJuly(desktopItem.collectFromBranchOn) ?
                                                    "dddd Do MMMM" : "dddd Do MMM"
                                            )
                                            : "")
                                        : deliveryOptions[0].name +
                                        (desktopItem.collectFromBranchOn
                                            ? " from " +
                                            moment(desktopItem.collectFromBranchOn).format(
                                                isJuneOrJuly(desktopItem.collectFromBranchOn) ?
                                                    "dddd Do MMMM" : "dddd Do MMM") + (new Date(desktopItem.collectFromBranchOn).getDay() != 6 ? "" : " (until midday)")
                                            : "")}
                                </div>
                                <div className="branch-wrapper">
                                    <ModalWindow
                                        id={`branch-modal${desktopItem.selectedBranch.id}${desktopItem.selectedBranch.supplier.name}`}
                                        className={
                                            "das-modal-change-location-container das-modal-basket-container"
                                        }
                                    >
                                        <ModalBranchDetails
                                            branchId={desktopItem.selectedBranch.id}
                                            supplierName={desktopItem.selectedBranch.supplier.name}
                                            formatOpeningTime={formatOpeningTime}
                                        />
                                    </ModalWindow>
                                </div>
                            </div>
                        </div>
                        <div>
                            {desktopItem.selectedBranch.supplier.name},{" "}
                            {desktopItem.selectedBranch.name}
                        </div>
                    </div>
                    <div>Contact name: {desktopItem.contactName}</div>
                    <div>Contact number: {desktopItem.mobileNumber}</div>
                    {desktopItem.isDelivery && (
                        <div>Dellivery address: {getDeliveryAddress()}</div>
                    )}
                    <div>Method of payment: {desktopItem.paymentMethodType.name}</div>
                    <div>Order number: {desktopItem.orderId}</div>
                    {desktopItem.paymentMethodTypeId != 1 && <div>Account number: {desktopItem.accountNumber}</div>}
                    {desktopItem.customerOrderNo && <div>Your reference: {desktopItem.customerOrderNo}</div>}
                    <br />{" "}
                    {desktopItem.paymentMethodTypeId != 1 && <p>
                        To track your order status please go to{" "}
                        <a
                            className="link-like-order"
                            target="_blank"
                            href="https://www.wolseley.co.uk/myaccount?section=orderStatus"
                            style={{ color: "#3EC4F4", textDecoration: "underline" }}
                        >
                            Order Status
                        </a>{" "}
                        and Advance Search “All” orders by typing your Order Number into
                        the "Customer Reference" field (please be aware that the search is
                        case sensitive and that it may take up to 60 minutes from checkout for
                        your order status to be updated online.)
                    </p>}
                    <p>
                        {" "}
                        For collections and delivery please have your Order Number to hand,
                        plus a valid form of ID. Next day collections will be ready by 9:30am
                        on the day of collection. For orders delivered by branch, please
                        contact your local branch on the day of delivery for your expected
                        delivery time slot.
                    </p>
                    {desktopItem.paymentMethodTypeId === 1 ?
                        <p>
                            If you wish to return an item please
                            click 'Return' on the Order History page and follow the instructions.
                            You will be required to provide images of the collection/delivery note,
                            the item you wish to return and the document provided by the merchant when your item has
                            been successfully returned.
                            Further returns information can be found{" "}
                            <a
                                className="link-like-order"
                                target="_blank"
                                href="https://buytrade.co.uk/returns-information/"
                                style={{ color: "#3EC4F4", textDecoration: "underline" }}
                            >
                                here
                            </a>
                            .
                        </p>
                        :
                        <p>
                            To return any item please first email{" "}
                            <a
                                className="link-like-order"
                                href="mailto:online.support@wolseley.co.uk"
                                style={{ color: "#3EC4F4", textDecoration: "underline" }}
                            >
                                {" "}
                                online.support@wolseley.co.uk
                            </a>
                            , telephone{" "}
                            <a className="link-like-order" href="tel:03448910196">
                                0344 8910196
                            </a>
                            , or telephone the branch from which the item was collected or
                            delivered. The full returns policy can be found{" "}
                            <a
                                className="link-like-order"
                                target="_blank"
                                href="https://www.wolseley.co.uk/returns-policy/"
                                style={{ color: "#3EC4F4", textDecoration: "underline" }}
                            >
                                here
                            </a>
                            .
                        </p>
                    }
                    {desktopItem.paymentMethodTypeId === 1 ?
                        <p>
                            Please note that this order is subject to the <a class="link-like-order" style={{
                                color: "#3EC4F4",
                                textDecoration: "underline"
                            }} target="_blank" href="https://buytrade.co.uk/terms-and-conditions-of-use/">terms of
                                use</a> of BuyTrade and the BuyTrade Electronic Payment Order
                            {" "}<a class="link-like-order" style={{ color: "#3EC4F4", textDecoration: "underline" }}
                                target="_blank"
                                href="https://buytrade.co.uk/electronic-payment-order-terms-and-conditions/">terms
                                and conditions</a>, as accepted at checkout.
                        </p>
                        :
                        <p>
                            Please note that this order is subject to the terms and conditions of
                            use of BuyTrade and the standard Wolseley sales{" "}
                            <a
                                className="link-like-order"
                                style={{ color: "#3EC4F4", textDecoration: "underline" }}
                                target="_blank"
                                href="https://www.wolseley.co.uk/terms-and-conditions/"
                            >
                                terms
                            </a>
                            , as accepted at checkout.
                        </p>}
                </div>
            </div>
        );
    };

    const renderHRPTradeText = () => {
        return (
            <div>
                <div className={classes.collectInfoWrapper}>
                    <div className={classes.deliveryDesktopWrapper}>
                        <div className={classes.deliveryWrapper}>
                            {deliveryOptions[0].icon}
                            <div className={classes.deliveryTitleWrapper}>
                                <div className={classes.deliveryTitle}>
                                    {"Delivery" +
                                        (desktopItem.collectFromBranchOn
                                            ? " on " +
                                            moment(desktopItem.collectFromBranchOn).format(
                                                isJuneOrJuly(desktopItem.collectFromBranchOn) ?
                                                    "dddd Do MMMM" : "dddd Do MMM"
                                            )
                                            : "")}
                                </div>
                                <div className="branch-wrapper">
                                    <ModalWindow
                                        id={`branch-modal${desktopItem.selectedBranch.id}${desktopItem.selectedBranch.supplier.name}`}
                                        className={
                                            "das-modal-change-location-container das-modal-basket-container"
                                        }
                                    >
                                        <ModalBranchDetails
                                            branchId={desktopItem.selectedBranch.id}
                                            supplierName={desktopItem.selectedBranch.supplier.name}
                                            formatOpeningTime={formatOpeningTime}
                                        />
                                    </ModalWindow>
                                </div>
                            </div>
                        </div>
                        <div>
                            {desktopItem.selectedBranch.supplier.name}
                        </div>
                    </div>
                    <div>Contact name: {desktopItem.contactName}</div>
                    <div>Contact number: {desktopItem.mobileNumber}</div>
                    <div>Delivery address: {getDeliveryAddress()}</div>
                    <div>Method of payment: {desktopItem.paymentMethodType.name}</div>
                    <div>Order number: {desktopItem.orderId}</div>
                    {desktopItem.customerOrderNo && <div>Your reference: {desktopItem.customerOrderNo}</div>}
                    <br />{" "}
                    <p>
                        Please check your emails and messages for courier updates.
                    </p>
                    <p>
                        If you wish to return an item please click 'Return' on the Order History page and follow the
                        instructions. You will be required to provide information about the products you need to return
                        and the reasons for return. We will then provide you with a returns reference and instructions.
                        Further returns information can be found{" "}
                        <a class="link-like-order" style={{ color: "#3EC4F4", textDecoration: "underline" }}
                            target="_blank" href="https://buytrade.co.uk/returns-information/">here</a>.
                    </p>
                    <p>
                        Please note that this order is subject to the <a class="link-like-order" style={{
                            color: "#3EC4F4",
                            textDecoration: "underline"
                        }} target="_blank" href="https://buytrade.co.uk/terms-and-conditions-of-use/">terms of use</a> of
                        BuyTrade and the BuyTrade Electronic Payment Order{" "}
                        <a class="link-like-order" style={{ color: "#3EC4F4", textDecoration: "underline" }}
                            target="_blank" href="https://buytrade.co.uk/electronic-payment-order-terms-and-conditions/">terms
                            and conditions</a>, as accepted at checkout.
                    </p>
                </div>
            </div>
        );
    };

    const renderCityPlumbingText = () => {
        return (
            <div>
                <div className={classes.collectInfoWrapper}>
                    <div className={classes.deliveryDesktopWrapper}>
                        <div className={classes.deliveryWrapper}>
                            {deliveryOptions[0].icon}
                            <div className={classes.deliveryTitleWrapper}>
                                <div className={classes.deliveryTitle}>
                                    {desktopItem.isDelivery
                                        ? "Delivery" +
                                        (desktopItem.collectFromBranchOn
                                            ? " on " +
                                            moment(desktopItem.collectFromBranchOn).format(
                                                isJuneOrJuly(desktopItem.collectFromBranchOn) ?
                                                    "dddd Do MMMM" : "dddd Do MMM"
                                            )
                                            : "")
                                        : deliveryOptions[0].name +
                                        (desktopItem.collectFromBranchOn
                                            ? " from " +
                                            moment(desktopItem.collectFromBranchOn).format(isJuneOrJuly(desktopItem.collectFromBranchOn) ?
                                                "dddd Do MMMM" : "dddd Do MMM") + (new Date(desktopItem.collectFromBranchOn).getDay() != 6 ? "" : " (until midday)")
                                            : "")}
                                </div>
                                <div className="branch-wrapper">
                                    <ModalWindow
                                        id={`branch-modal${desktopItem.selectedBranch.id}${desktopItem.selectedBranch.supplier.name}`}
                                        className={
                                            "das-modal-change-location-container das-modal-basket-container"
                                        }
                                    >
                                        <ModalBranchDetails
                                            branchId={desktopItem.selectedBranch.id}
                                            supplierName={desktopItem.selectedBranch.supplier.name}
                                            formatOpeningTime={formatOpeningTime}
                                        />
                                    </ModalWindow>
                                </div>
                            </div>
                        </div>
                        <div>
                            {desktopItem.selectedBranch.supplier.name},{" "}
                            {desktopItem.selectedBranch.name}
                        </div>
                    </div>
                    <div>Contact name: {desktopItem.contactName}</div>
                    <div>Contact number: {desktopItem.mobileNumber}</div>
                    {desktopItem.isDelivery && (
                        <div>Delivery address: {getDeliveryAddress()}</div>
                    )}
                    <div>Method of payment: {desktopItem.paymentMethodType.name}</div>
                    <div>Order number: {desktopItem.orderId}</div>
                    {desktopItem.paymentMethodTypeId != 1 && <div>Account number: {desktopItem.accountNumber}</div>}
                    {desktopItem.customerOrderNo && <div>Your reference: {desktopItem.customerOrderNo}</div>}
                    <br />{" "}
                    <p>
                        Please check your emails and notifications for order status updates.
                    </p>
                    <p>
                        {" "}
                        For collections and delivery please have your Order Number to hand,
                        plus a valid form of ID. Next day collections will be ready by 9:30am
                        on the day of collection. For orders delivered by branch, please
                        contact your local branch on the day of delivery for your expected
                        delivery time slot.
                    </p>
                    {desktopItem.paymentMethodTypeId === 1 ?
                        <p>
                            If you wish to return an item please
                            click 'Return' on the Order History page and follow the instructions.
                            You will be required to provide images of the collection/delivery note,
                            the item you wish to return and the document provided by the merchant when your item has
                            been successfully returned.
                            Further returns information can be found{" "}
                            <a
                                className="link-like-order"
                                target="_blank"
                                href="https://buytrade.co.uk/returns-information/"
                                style={{ color: "#3EC4F4", textDecoration: "underline" }}
                            >
                                here
                            </a>
                            .
                        </p>
                        :
                        <p>
                            If you wish to return an item please first email
                            {" "}
                            <a
                                className="link-like-order"
                                href="mailto:customerservice@cityplumbing.co.uk"
                                style={{ color: "#3EC4F4", textDecoration: "underline" }}
                            >
                                {" "}
                                customerservice@cityplumbing.co.uk
                            </a>, or telephone 03306780267 with your
                            Order Number, postcode and telephone number. The full returns
                            policy can be found{" "}
                            <a
                                className="link-like-order"
                                target="_blank"
                                href="https://www.cityplumbing.co.uk/TermsandConditionsOfSale"
                                style={{ color: "#007AAB", fontWeight: "bold" }}
                            >
                                here
                            </a>
                            .
                        </p>}
                    {desktopItem.paymentMethodTypeId === 1 ?
                        <p>
                            Please note that this order is subject to the <a class="link-like-order" style={{
                                color: "#3EC4F4",
                                textDecoration: "underline"
                            }} target="_blank" href="https://buytrade.co.uk/terms-and-conditions-of-use/">terms of
                                use</a> of BuyTrade and the BuyTrade Electronic Payment Order
                            {" "}<a class="link-like-order" style={{ color: "#3EC4F4", textDecoration: "underline" }}
                                target="_blank"
                                href="https://buytrade.co.uk/electronic-payment-order-terms-and-conditions/">terms
                                and conditions</a>, as accepted at checkout.
                        </p>
                        :
                        <p>
                            Please note that this order is subject to the terms and conditions of
                            use of BuyTrade and the standard City Plumbing sales{" "}
                            <a
                                className="link-like-order"
                                target="_blank"
                                style={{ color: "#3EC4F4", textDecoration: "underline" }}
                                href="https://www.cityplumbing.co.uk/TermsandConditionsOfSale"
                            >
                                terms
                            </a>
                            , as accepted at checkout.
                        </p>}
                </div>
            </div>
        );
    };

    const renderExpressDeliveryText = () => {
        return (
            <div>
                <div className={classes.collectInfoWrapper}>
                    <div className={classes.deliveryDesktopWrapper}>
                        <div className={classes.deliveryWrapper}>
                            {deliveryOptions[0].icon}
                            <div className={classes.deliveryTitleWrapper}>
                                <div className={classes.deliveryTitle}>
                                    {"Express Same-Day Delivery" +
                                        (desktopItem.collectFromBranchOn
                                            ? " from " +
                                            moment(desktopItem.collectFromBranchOn).format(isJuneOrJuly(desktopItem.collectFromBranchOn) ?
                                                "dddd Do MMMM" : "dddd Do MMM"
                                            )
                                            : "")}
                                </div>
                                <div className="branch-wrapper">
                                    <ModalWindow
                                        id={`branch-modal${desktopItem.selectedBranch.id}${desktopItem.selectedBranch.supplier.name}`}
                                        className={
                                            "das-modal-change-location-container das-modal-basket-container"
                                        }
                                    >
                                        <ModalBranchDetails
                                            branchId={desktopItem.selectedBranch.id}
                                            supplierName={desktopItem.selectedBranch.supplier.name}
                                            formatOpeningTime={formatOpeningTime}
                                        />
                                    </ModalWindow>
                                </div>
                            </div>
                        </div>
                        <div>
                            {desktopItem.selectedBranch.supplier.name},{" "}
                            {desktopItem.selectedBranch.name}
                        </div>
                    </div>
                    <div>Contact name: {desktopItem.contactName}</div>
                    <div>Contact number: {desktopItem.mobileNumber}</div>
                    <div>Method of payment: {desktopItem.paymentMethodType.name}</div>
                    <div>Order number: {desktopItem.orderId}</div>
                    {desktopItem.customerOrderNo && <div>Your reference: {desktopItem.customerOrderNo}</div>}
                    <br />{" "}
                    <p>
                        Please check your emails and notifications for order status updates.
                    </p>
                    <p>
                        You can track progress <a
                            className="link-like-order"
                            target="_blank"
                            href={desktopItem.deliveryQuote.publicTrackerUrl}
                            style={{ color: "#3EC4F4", textDecoration: "underline" }}
                        >here</a>.
                    </p>
                    <p>
                        If you wish to return an item please
                        click 'Return' on the Order History page and follow the instructions.
                        You will be required to provide images of the collection/delivery note,
                        the item you wish to return and the document provided by the merchant when your item has been
                        successfully returned.
                        Further returns information can be found{" "}
                        <a
                            className="link-like-order"
                            target="_blank"
                            href="https://buytrade.co.uk/returns-information/"
                            style={{ color: "#3EC4F4", textDecoration: "underline" }}
                        >
                            here
                        </a>
                        .
                    </p>
                    <p>
                        Please note that this order is subject to the <a class="link-like-order" style={{
                            color: "#3EC4F4",
                            textDecoration: "underline"
                        }} target="_blank" href="https://buytrade.co.uk/terms-and-conditions-of-use/">terms of use</a> of
                        BuyTrade and the BuyTrade Electronic Payment Order
                        {" "}<a class="link-like-order" style={{ color: "#3EC4F4", textDecoration: "underline" }}
                            target="_blank"
                            href="https://buytrade.co.uk/electronic-payment-order-terms-and-conditions/">terms and
                            conditions</a>, as accepted at checkout.
                    </p>
                </div>
            </div>
        );
    };

    const renderBuyTradePartnersText = () => {
        return (
            <div>
                <div className={classes.collectInfoWrapper}>
                    <div className={classes.deliveryDesktopWrapper}>
                        <div className={classes.deliveryWrapper}>
                            {deliveryOptions[0].icon}
                            <div className={classes.deliveryTitleWrapper}>
                                <div className={classes.deliveryTitle}>
                                    {desktopItem.isDelivery ? "Delivery" : "Collection" +
                                        (desktopItem.collectFromBranchOn
                                            ? " on " +
                                            moment(desktopItem.collectFromBranchOn).format(
                                                isJuneOrJuly(desktopItem.collectFromBranchOn) ?
                                                    "dddd Do MMMM" : "dddd Do MMM"
                                            )
                                            : "")}
                                </div>
                                <div className="branch-wrapper">
                                    <ModalWindow
                                        id={`branch-modal${desktopItem.selectedBranch.id}${desktopItem.selectedBranch.supplier.name}`}
                                        className={
                                            "das-modal-change-location-container das-modal-basket-container"
                                        }
                                    >
                                        <ModalBranchDetails
                                            branchId={desktopItem.selectedBranch.id}
                                            supplierName={desktopItem.selectedBranch.supplier.name}
                                            formatOpeningTime={formatOpeningTime}
                                        />
                                    </ModalWindow>
                                </div>
                            </div>
                        </div>
                        <div>
                            {desktopItem.selectedBranch.supplier.name}
                        </div>
                    </div>
                    <div>Contact name: {desktopItem.contactName}</div>
                    <div>Contact number: {desktopItem.mobileNumber}</div>
                    {desktopItem.isDelivery && <div>Delivery address: {getDeliveryAddress()}</div>}
                    <div>Method of payment: {desktopItem.paymentMethodType.name}</div>
                    <div>Order number: {desktopItem.orderId}</div>
                    {desktopItem.customerOrderNo && <div>Your reference: {desktopItem.customerOrderNo}</div>}
                    <br />{" "}
                    <p>
                        {desktopItem.isDelivery === false && <>Please check your emails for updates.</>}
                        {desktopItem.isDelivery && <>Please check your emails and messages for delivery updates.</>}
                    </p>
                    <p>
                        If you wish to return an item please click 'Return' on the Order History page and follow the
                        instructions. You will be required to provide information about the products you need to return
                        and the reasons for return. We will then provide you with a returns reference and instructions.
                        Further returns information can be found{" "}
                        <a class="link-like-order" style={{ color: "#3EC4F4", textDecoration: "underline" }}
                            target="_blank" href="https://buytrade.co.uk/returns-information/">here</a>.
                    </p>
                    <p>
                        Please note that this order is subject to the <a class="link-like-order" style={{
                            color: "#3EC4F4",
                            textDecoration: "underline"
                        }} target="_blank" href="https://buytrade.co.uk/terms-and-conditions-of-use/">terms of use</a> of
                        BuyTrade and the BuyTrade Electronic Payment Order{" "}
                        <a class="link-like-order" style={{ color: "#3EC4F4", textDecoration: "underline" }}
                            target="_blank" href="https://buytrade.co.uk/electronic-payment-order-terms-and-conditions/">terms
                            and conditions</a>, as accepted at checkout.
                    </p>
                </div>
            </div>
        );
    };

    const renderSupplierText = () => {
        if (desktopItem.quoteId > 0) {
            return renderExpressDeliveryText();
        } else if (desktopItem.selectedBranch) {
            if (desktopItem.selectedBranch.supplier.name.includes("City Plumbing")) {
                return renderCityPlumbingText();
            } else if (desktopItem.selectedBranch.supplier.name.includes("Wolseley")) {
                return renderWolseleyText();
            } else if (desktopItem.selectedBranch.supplier.name.includes("HRP Trade")) {
                return renderHRPTradeText();
            } else if (desktopItem.selectedBranch.supplier.name.includes("Partners") || desktopItem.selectedBranch.supplier.name.includes("Toolstation"))
                return renderBuyTradePartnersText();
        }
    }

    return (
        <div className="sp-desktop-order-inform">
            <div className={classes.orderHeader}>
                <div>
                    <div className={classes.orderNum}>
                        <span>Order Number: {desktopItem.orderId ?? "-"}</span>
                        <span> ({itemsCount})</span>
                    </div>
                    <div className={classes.IHUBNum}>
                        {desktopItem.supplierOrderNumber ?? "-"}
                    </div>
                    <div className={classes.orderDate}>
                        Purchased on {formatDate(desktopItem.orderDate)}
                    </div>
                </div>
                {desktopItem.paymentMethodTypeId === 1 &&
                    <div>
                        <button onClick={() => downloadInvoice(desktopItem)} className={classes.downloadInvoice}>
                            <svg width="13" height="14" viewBox="0 0 13 14" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12.0423 5.125H8.87565V0.375H4.12565V5.125H0.958984L6.50065 10.6667L12.0423 5.125ZM0.958984 12.25V13.8333H12.0423V12.25H0.958984Z"
                                    fill="white" />
                            </svg>
                            <span style={{ color: "white" }}>Download Invoice</span>
                        </button>
                    </div>}
            </div>
            <div style={isMobile() ? { backgroundColor: "#fff" } : {}}>
                <div className={classes.tableWrapper}>
                    {desktopItem.lines.map((item) =>
                        isMobile() ? (
                            <div className={classes.itemRow}>
                                {item.imageUrl ?
                                    <img style={{ maxWidth: 64 }} src={item.imageUrl} alt="alt" />
                                    : <div style={{ minHeight: 20 }}>&nbsp;</div>
                                }
                                <div>
                                    <div className={classes.available}>
                                        <p>{item.description}</p>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            paddingTop: 5,
                                            paddingRight: 10,
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>Qty: {`${item.qty}`}</div>
                                        <div style={{ fontWeight: 600, marginLeft: 10 }}>
                                            {formatPrice(item.displayedCost * item.qty)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={classes.itemRow}>
                                {item.imageUrl ?
                                    <img style={{ maxWidth: 64 }} src={item.imageUrl} alt="alt" />
                                    : <div style={{ minHeight: 50 }}>&nbsp;</div>
                                }
                                <div>{item.manufacturerCode}</div>
                                <div className={classes.available}>
                                    <p>{item.description}</p>
                                </div>
                                <div>Qty: {`${item.qty}`}</div>
                                <div style={{ fontWeight: 600 }}>
                                    {formatPrice(item.displayedCost * item.qty)}
                                </div>
                            </div>
                        )
                    )}
                </div>

                <div className={classes.infoWrapper}>
                    {renderSupplierText()}
                    <div className={classes.costDesktopWrapper}>
                        <div className={classes.priceWrapper}>
                            <span className={classes.priceDesk}>Delivery:</span>
                            <div>
                                <div className={classes.deliveryCost}>£0.00</div>
                            </div>
                        </div>
                        <div className={classes.priceWrapper}>
                            <span className={classes.priceDesk}>Total:</span>
                            <div>
                                <div className={classes.totalDisplayedCost}>
                                    {formatPrice(desktopItem.totalDisplayedCost)}
                                </div>
                            </div>
                        </div>
                        <div
                            className={clsx(
                                classes.priceWrapper,
                                classes.totalVatDisplayedCostWrapper
                            )}
                        >
                            <span className={classes.priceDesk}>Total inc. VAT:</span>
                            <span className={classes.totalVatDisplayedCost}>
                                {formatPrice(desktopItem.totalDisplayedCostWithVat)}
                            </span>
                        </div>

                        {!!creditNotes.length && <div className={classes.creditNoteWrapper}>
                            <h3 style={{ textAlign: "center" }}> Credit notes.</h3>
                            <div className={"table"}>
                                <div className={"row justify-content-between"}
                                    style={{ "border-bottom": "1px solid #BACAD1" }}>
                                    <div>Created on</div>
                                    <div> #</div>
                                </div>
                                {
                                    creditNotes.map(item => {
                                        return (
                                            <div className={"row justify-content-between align-items-center"}>
                                                <div>{item.creditDate?.substring(0, 16)}</div>
                                                <div>
                                                    <a className={"link-like"}
                                                        onClick={() => downloadCreditNote(item.identifier)}>
                                                        Download </a>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>}

                    </div>
                </div>
            </div>
        </div>
    );
};

const SELECT_VALUES = [
    {
        sortDir: "desc",
        text: "Purchase Date (Newest to Oldest)",
        sortProp: "CreatedOn",
    },
    {
        sortDir: "asc",
        text: "Purchase Date (Oldest to Newest)",
        sortProp: "CreatedOn",
    },
    {
        sortDir: "asc",
        text: "Merchant (A to Z)",
        sortProp: "SupplierName",
    },
    {
        sortDir: "desc",
        text: "Merchant (Z to A)",
        sortProp: "SupplierName",
    },
];

const downloadInvoice = (order) => {
    window.open(`/Basket/downloadInvoice?basketKey=${order.basketKey}`, '_blank')
}
const downloadCreditNote = (identifier) => {
    window.open(`/PaymentInvoiceCreditNote/GetPdfByIdentifier?identifier=${identifier}`, '_blank')
}

export default function OrderHistory() {
    const featureFlagContext = useContext(FeatureFlagContext);
    const PAGE_SIZE = 12;
    const invoiceTypes = [
        {
            invoiceTypeText: 'PDF Export (Zip)',
            invoiceTypeValue: 'CC5BB086-64FA-40F6-BB0D-0B57F16F0BE5'
        }
    ]
    const classes = useStyles();
    const history = useHistory();
    const [data, setData] = useState([]);
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [invoiceType, setInvoiceType] = useState({})
    const [showExport, setShowExport] = useState(false)
    const [selectedBasketKey, setSelectedBasketKey] = useState(
        new URL(window.location.href).searchParams.get("basketKey") ?? ""
    );
    const [tableState, setTableState] = useState({
        pageNumber: 1,
        pageSize: PAGE_SIZE,
    });
    const [sortState, setSortState] = useState({
        sortDir: SELECT_VALUES[0].sortDir,
        sortProp: SELECT_VALUES[0].sortProp,
    });
    const [desktopItem, setDesktopItem] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [totalRecords, setTotalRecords] = useState(0);
    const [itemForReturn, setItemForReturn] = useState(null);
    const [itemForReorder, setItemForReorder] = useState(null);

    const [basket, setBasket] = useState({
        basketKey: "",
        lines: [],
    });

    const getBasket = () => {
        return BasketService.getBasketForUser().then((response) => {
            if (response.lines == null) response.lines = [];
            setBasket(response);
        });
    };

    window.addEventListener("popstate", (event) => {
        const basketKey =
            new URL(window.location.href).searchParams.get("basketKey") ?? "";
        changeSelectedItem(basketKey);
    });

    useEffect(() => {
        getPreviousOrders(searchText).then(resetTableState).then(getBasket);
    }, [searchText]);

    const mounted = useRef();
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true
        } else {
            getPreviousOrders(searchText).then(resetTableState);
        }
    }, [sortState]);

    const resetTableState = () => {
        setTableState({
            pageNumber: 1,
            pageSize: PAGE_SIZE,
        });
    };

    const getPreviousOrders = (
        searchText = "",
        pageNumber = 1,
        pageSize = PAGE_SIZE,
        resetData = true
    ) => {
        return OrdersService.previousOrders(
            searchText,
            sortState.sortDir,
            sortState.sortProp,
            pageNumber,
            pageSize
        ).then((response) => {
            if (selectedBasketKey !== "") {
                const desktopItem = response.collection.find(
                    (item) => item.basketKey == selectedBasketKey
                );
                setDesktopItem(desktopItem);
            }
            if (resetData) {
                setData(response.collection || []);
                setTotalRecords(response.totalRecords);
            } else {
                const result = [...data, ...response.collection];
                setData(result);
                setTotalRecords(response.totalRecords);
            }
        });
    };

    const isJuneOrJuly = (date) => {
        return moment(date).month() == 5 || moment(date).month() == 6
    }

    const formatDate = (date) => {
        if (isJuneOrJuly(date))
            return moment(date).format("DD MMMM YYYY [at] HH:mm");
        else
            return moment(date).format("DD MMM YYYY [at] HH:mm");
    };

    const formatPrice = (value) => {
        return `£${value.toFixed(2)}`;
    };

    const updateBasket = () => {
        BasketService.updateBasketForUser(basket).then((basket) => {
            if (basket.lines == null) basket.lines = [];
            setBasket(basket);
            history.push("/Basket");
        });
    };

    const addToBasket = (basketItem) => {
        let basketCopy = Object.assign(basket);
        let newItem = Object.assign(basketItem);

        const currentIndex = basketCopy.lines
            .map((item) => item.manufacturerCode)
            .indexOf(newItem.manufacturerCode);

        if (currentIndex > -1) {
            basket.lines.splice(currentIndex, 1);
        }

        basketCopy.lines.push(newItem);

        basketCopy.lines = basketCopy.lines.filter(({ qty }) => qty !== 0);

        setBasket(basketCopy);

        configurationService.getConfigurationVariables().then(res => {
            if (res?.enableGoogleAnalytics) {
                //GA add item to basket event
                ReactGA.event("add_to_cart", {
                    currency: "GBP",
                    value: basketItem.cost * basketItem.qty,
                    items: [
                        {
                            //GA parameters
                            item_id: basketItem.manufacturerCode,
                            item_name: basketItem.description,
                            index: currentIndex > -1 ? currentIndex : basketCopy.lines.length - 1,
                            price: basketItem.cost,
                            quantity: basketItem.qty,

                            //custom parameters
                            manufacturer: basketItem.manufacturer,
                            manufacturer_id: basketItem.iBasisManId,
                            location: "Order History",
                            is_liked: undefined
                        }
                    ],

                    //custom parameters
                    basket_key: basketCopy.basketKey,
                    organisation_id: basketCopy.organisationId
                })
            }
        });
    };

    const handleSelected = () => {
        itemForReorder.lines.forEach(item => {
            if (item.basketLineTypeId == 1)
                addToBasket(item);
        });
        updateBasket();
    };

    const handleSort = (option) => {
        let index = option.selected;
        setSortState({
            sortDir: SELECT_VALUES[index].sortDir,
            sortProp: SELECT_VALUES[index].sortProp,
        });
    };

    const onSelectItem = (item) => {
        setDesktopItem(item);
        const newUrl = `${window.location.origin}${window.location.pathname}?basketKey=${item.basketKey}`;
        window.history.pushState(
            {
                path: newUrl,
            },
            "",
            newUrl
        );
    };

    const changeSelectedItem = (basketKey) => {
        if (basketKey === "") {
            setDesktopItem(null);
        } else {
            const selectedItem = data.find((item) => item.basketKey == basketKey);
            setDesktopItem(selectedItem);
        }
    };

    const renderCell = (item) => {
        const { selectedBranch, orderId, orderDate, totalDisplayedCostWithVat, paymentMethodType } = item
        const itemsCount = getTotalQty(item);
        return (
            <tr className="order-history-table-row">
                <td>
                    <img
                        src={item.selectedBranch?.supplier.imageUrl ?? "N/A"}
                        alt="supplier"
                    />
                </td>
                <td className="petux">{selectedBranch.name}</td>
                <td><a> Order: <span className="link-like"
                    onClick={() => onSelectItem(item)}>{orderId}</span></a> ({itemsCount})
                    <br />
                    <span>Ref: {item.customerOrderNo}</span>
                </td>
                <td>{formatDate(orderDate)}</td>
                <td className="order-history-table-row-price"> {formatPrice(totalDisplayedCostWithVat)}</td>
                <td>
                    <a className="link-like"

                        onClick={() => {
                            item.basketLineTypeId = 1
                            setItemForReorder(item)
                        }}
                    >
                        Reorder
                    </a>
                </td>
                <td>
                    <a
                        className="link-like"
                        onClick={() => setItemForReturn(item)}
                    >
                        Return
                    </a>
                </td>
                <td>
                    {
                        paymentMethodType.id === 1 ?
                            <a className="link-like" onClick={() => downloadInvoice(item)}>
                                Download Invoice
                            </a>
                            :
                            <a className="link-like">
                                -
                            </a>
                    }


                </td>
            </tr>
        );
    };

    const onSearchChange = (value) => setSearchText(value);

    const clearingSearch = () => {
        setSearchText("");
        getPreviousOrders(searchText).then(resetTableState);
    };

    const onSearch = () => {
        getPreviousOrders(searchText).then(resetTableState);
    };

    const showExportModal = () => {
        setShowExport(true)
    };

    const startDownload = () => {
        BasketService.batchDownloadInvoice({
            from: fromDate,
            to: toDate,
            taskId: invoiceType.value
        }).then(response => {
            if (response) {
                toastr.success('Thank you. You will shortly receive a link via email to download your documents.')
                setShowExport(false)
            }
        })
    }

    const goToOrderHistory = (e) => {
        window.location = "/OrderHistory";
    };

    const onChangePage = (event, value) => {
        setTableState({
            pageNumber: value,
            pageSize: PAGE_SIZE,
        });
        getPreviousOrders(searchText, value, PAGE_SIZE);
    };

    const renderSearchInput = () =>
        isMobile() ? (
            <div className={classes.historySearchDesktop}>
                <FinderInput
                    searchTerm={searchText}
                    placeholder="Search Order History"
                    onChange={onSearchChange}
                    onClear={() => { setSearchText('') }}
                    style={{ '&::placeholder': { color: '#454545' } }}
                />
            </div>
        ) : (
            <div className={classes.historySearchDesktop}>
                <div style={{ position: "relative" }}>
                    <FinderInput
                        searchTerm={searchText}
                        placeholder="Search Order History"
                        onChange={onSearchChange}
                        style={{ '&::placeholder': { color: '#454545' } }}
                        onClear={() => { setSearchText('') }}
                    />
                </div>
                <button
                    className={"btn button-large button-light-blue"}
                    style={{ maxWidth: 278, marginLeft: 16 }}
                    onClick={onSearch}
                >
                    Search
                </button>
            </div>
        );
    const onShowMore = () => {
        setTableState({
            pageNumber: tableState.pageNumber + 1,
            pageSize: tableState.pageSize,
        });
        getPreviousOrders(
            searchText,
            tableState.pageNumber + 1,
            tableState.pageSize,
            false
        );
    };


    const onCloseModal = () => {
        setItemForReturn(null);
    }

    const onCloseExportModal = () => {
        setShowExport(false)
    }

    return (
        <>
            <Wrapper
                wrapperClass=""
                text={desktopItem ? "ORDER INFORMATION" : "ORDER HISTORY"}
                selected="Order History"
            >
                <Header
                    body={!desktopItem ? "Order History" : "Order Details"}
                    backBody="Back to Order History"
                    onBack={goToOrderHistory}
                    children={!desktopItem ? renderSearchInput() : null}
                />
                <div>
                    {isMobile() && !!desktopItem && (
                        <DesktopDetailsView desktopItem={desktopItem} />
                    )}
                    {!desktopItem && (
                        <>
                            <div className='order-history-sort-wrapper'>
                                <SortDropDown
                                    sortItems={SELECT_VALUES}
                                    onSelectSort={handleSort}
                                    customClassName={isMobile() ? 'order-history-sort-mobile' : ''}
                                />
                                {featureFlagContext.checkFeatures("export-bulk-invoices") && <button
                                    className={"btn button-light-blue order-history-export-btn desktop-btn"}
                                    style={{ maxWidth: 150, marginLeft: 16 }}
                                    onClick={showExportModal}
                                >
                                    <img src={exportSvg}
                                    />
                                    Export
                                </button>}
                            </div>
                            <div
                                className={`${isTablet() ? "spares-finder-tablet" : ""} sp-mobile-spares-finder`}
                                style={{ paddingBottom: 20 }}
                            >
                                {data?.map((order) => (
                                    <ShortOrderCard
                                        order={order}
                                        setItemForReturn={setItemForReturn}
                                        setDesktopItem={setDesktopItem}
                                        onReorder={() => setItemForReorder(order)}
                                        downloadInvoice={() => downloadInvoice(order)}
                                    />
                                ))}

                                {isMobile() && totalRecords > 0 && totalRecords != data.length && (
                                    <button
                                        className={`btn button-large ${isTablet() ? "button-white" : "button-light-blue"}`}
                                        onClick={onShowMore}
                                        style={isTablet()
                                            ? {
                                                margin: '10px auto',
                                                maxWidth: 135,
                                                border: "1px solid #009FDF",
                                                color: "#009FDF",
                                                fontWeight: 600
                                            }
                                            : {
                                                marginLeft: 30,
                                                width: 'calc(100vw - 60px)'
                                            }
                                        }
                                    >
                                        Show more
                                    </button>
                                )}
                            </div>
                        </>
                    )}

                    <div className={"das_searchTable sp-desktop-spares-finder"}>
                        {desktopItem ? (
                            <DesktopDetailsView desktopItem={desktopItem} />
                        ) : (
                            <>
                                <table className="order-history-table">
                                    <thead className="order-history-table-head">
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Branch</th>
                                            <th scope="col">Order Reference</th>
                                            <th scope="col">Order Date/Time</th>
                                            <th scope="col">Total</th>
                                            <th scope="col">Reorder</th>
                                            <th scope="col">Return</th>
                                            <th scope="col">Download invoice</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map(renderCell)}
                                    </tbody>
                                </table>


                                <div className='order-history-grid-wrapper'>
                                    {totalRecords > 0 && (
                                        <Pagination
                                            count={Math.floor(totalRecords / tableState.pageSize) + 1}
                                            defaultPage={1}
                                            page={tableState.pageNumber}
                                            siblingCount={1}
                                            onChange={onChangePage}
                                            boundaryCount={1}
                                            size="large"
                                            renderItem={(item) => (
                                                <PaginationItem
                                                    {...item}
                                                    sx={{
                                                        fontSize: "16px",
                                                        fontWeight: "600",
                                                        color: "#007AAB",
                                                        backgroundColor: "transparent !important",
                                                        borderRadius: "0px !important",
                                                        "&.Mui-selected": {
                                                            borderBottom: "2px solid #007AAB",
                                                        },
                                                        "& .MuiPaginationItem-icon": {
                                                            fontSize: "2rem !important",
                                                        },
                                                        height: "20px",
                                                        paddingBottom: "4px",
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {itemForReorder && (
                    <NewConfirmModal
                        title='Add items to basket?'
                        description={`Would you like to add ${getTotalQty(itemForReorder)} from this order to your basket?`}
                        okText='Continue'
                        theme='blue'
                        onClose={() => setItemForReorder(null)}
                        onConfirm={handleSelected}
                    />
                )}

                {itemForReturn && (
                    <ModalComp styles={{ maxWidth: 580 }} onClose={onCloseModal} onCloseOnBackground={onCloseModal}>
                        <div className="return-modal-container">
                            <div className="return-modal-header">
                                <img src={InReturnImage} alt="in return" />
                            </div>
                        </div>
                        {itemForReturn.paymentMethodTypeId === 1 ?
                            <p className="return-description">Please contact us on <a href="tel:01978 666888">01978
                                666888</a> or <a href="mailto: info@buytrade.co.uk">info@buytrade.co.uk</a> and one of
                                our team will be happy to support you in processing your return (our office hours are
                                Monday - Friday, 8.30am - 5pm). Please ensure you have your Order Number to hand. </p>
                            :
                            <p className="return-description">This order was placed on your credit account directly with
                                the merchant. Please click into your Order Number and check your order confirmation
                                details, which set out the returns process direct with your merchant.</p>
                        }
                        <div className="return-info">
                            <h1>Order Number:
                                <span className="link-like" onClick={() => onSelectItem(itemForReturn)}>
                                    {itemForReturn.orderId ?? "-"}
                                </span>
                                ({getTotalQty(itemForReturn)})</h1>
                            <p>{itemForReturn.selectedBranch?.supplier?.name ?? "N/A"}</p>
                            <p>Puchased on {formatDate(itemForReturn.orderDate)}</p>
                        </div>
                    </ModalComp>
                )}

                {showExport && (
                    <ModalComp styles={{ maxWidth: 580 }} onClose={onCloseExportModal}>


                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>

                                <Grid item xs={12}>
                                    <h1 className={`${classes.downloadModalTitle} modal-popup-header`}>Export</h1>
                                    <p className={classes.downloadModalDesc}>Select PDF Export below to download invoice
                                        PDFs within a chosen date range.</p>
                                </Grid>

                                <Grid item xs={6} className={classes.modalInputContainer}>
                                    <SelectListStaticWithLabel
                                        placeholder="Please Select"
                                        valueProperty="invoiceTypeValue"
                                        textProperty="invoiceTypeText"
                                        collection={invoiceTypes}
                                        value={invoiceType.value}
                                        onChange={type => setInvoiceType(type)}
                                        className="dropdown-width"
                                        styles={{ margin: '0 !important' }}
                                    />
                                </Grid>

                                <Grid item xs={6} className={classes.modalInputContainer}>

                                </Grid>

                                <Grid item xs={6} className={classes.modalInputContainer}>
                                    <div>
                                        <label>From</label>
                                        <br />
                                        <DatePicker
                                            value={fromDate}
                                            format="dd/MM/yyyy"
                                            onChange={date => setFromDate(date)}
                                            calendarIcon={<CalendarIcon fontSize='inherit' />}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={6} className={classes.modalInputContainer}>
                                    <div>
                                        <label>To</label>
                                        <br />
                                        <DatePicker
                                            value={toDate}
                                            format="dd/MM/yyyy"
                                            onChange={date => setToDate(date)}
                                            calendarIcon={<CalendarIcon fontSize='inherit' />}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <button
                                        className={"btn button-light-blue order-history-startdownload-btn desktop-btn"}
                                        onClick={startDownload}
                                    >
                                        Start Download
                                    </button>
                                </Grid>

                            </Grid>
                        </Box>

                    </ModalComp>
                )}
            </Wrapper>
        </>
    );
}  