/**
 * @param {number} value
 * @param {string} [currencySymbol=£] - Currency symbol like '£', '$', '€', etc...
 * @returns {string}
 * Format price to X0.00 where X is currency symbol
 */
function formatPrice(value, currencySymbol = "£") {
  return `${currencySymbol}${value.toFixed(2)}`;
}

export default formatPrice;
