import React, { useState } from "react";
import DeleteModal from "../Modal/DeleteModal";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile, isTablet } from "../Media/Media";
import TextBoxWithLabel from "../Form/TextBoxWithLabel";
import AccountService from "../../Services/AccountService";
import toastr from "toastr";

const useStyles = makeStyles(() => ({
    deleteModal: {},
    Content: {
        padding: '44px 10px'
    },
    deleteTitle: {
        color: "#00226A",
        margin: 0,
        marginBottom: 8,
        padding: 0,
        fontWeight: '500 !important'
    },

    deleteDesc: {
        fontSize: 16,
        lineHeight: '19px',
        color: "#828282",
        margin: 0,
        padding: 0
    },
    buttons: {
        display: "flex",
        width: "100%",
        marginTop: 26,
    },
    button: {
        padding: '13px 20px',
        color: "#FFFFFF",
        fontSize: 14,
        lineHeight: ' 17px',
        borderRadius: '4px',
        fontWeight: 600,
        width: "50%",
    },
    CancelBTN: {
        background: '#CDCDCD',
        marginRight: 12
    },
    DeleteBTN: {
        background: '#ED1F45',
    },
    [`@media (max-width:768px)`]: {
        Content: {
            padding: '28px 0',
            margin: '0 -10px'
        },
        deleteTitle: {
            fontSize: 20,
            lineHeight: '24px',
            marginBottom: 4,
        },
        buttons: {
            marginTop: 15,
        },
        CancelBTN: {
            marginRight: 8
        },
    },
}));

const InviteUserModal = ({ onClose }) => {
    const [firstName, setFirstName] = useState(null)
    const [firstNameError, setFirstNameError] = useState(null)
    const [surname, setSurname] = useState(null)
    const [surnameError, setSurnameError] = useState(null)
    const [email, setEmail] = useState(null)
    const [emailError, setEmailError] = useState(null)
    const [telephone, setTelephone] = useState(null)
    const [telephoneError, setTelephoneError] = useState(null)

    const classes = useStyles();

    const changeFirstName = e => {
        setFirstName(e)
        setFirstNameError("")
    }

    const changeSurname = e => {
        setSurname(e)
        setSurnameError("")
    }

    const changeEmail = e => {
        setEmail(e)
        setEmailError("")
    }

    const changeTelephone = e => {
        setTelephone(e)
        setTelephoneError("")
    }

    const save = () => {
        if (firstName && surname && email && telephone) {
            AccountService.inviteUser({
                firstName,
                surname,
                email,
                telephone
            }).then(response => {
                onClose()
                if (response) {
                    toastr.success('User created successfully.')
                }
            }).catch(err => {
                toastr.error(err.response.data)
            })
        }
    }


    return (
        <DeleteModal
            className={classes.deleteModal}
            onClose={onClose}
        >
            <div className={classes.Content}>
                <div className="row">
                    <div className="col-md-12">
                        <TextBoxWithLabel
                            labelClassName={isMobile() ? 'customer-label account-label' : 'customer-label'}
                            addCssClass={`group__row ${firstNameError ? "group__row__error" : ""}`}
                            label="FIRST NAME"
                            placeholder="Enter first name"
                            value={firstName}
                            onChange={changeFirstName}
                            required
                            hasErr={firstNameError}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <TextBoxWithLabel
                            labelClassName={isMobile() ? 'customer-label account-label' : 'customer-label'}
                            addCssClass={`group__row ${surnameError ? "group__row__error" : ""}`}
                            label="SURNAME"
                            placeholder="Enter surname"
                            value={surname}
                            onChange={changeSurname}
                            required
                            hasErr={surnameError}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <TextBoxWithLabel
                            labelClassName={isMobile() ? 'customer-label account-label' : 'customer-label'}
                            addCssClass={`group__row ${emailError ? "group__row__error" : ""}`}
                            label="EMAIL"
                            placeholder="Enter email"
                            value={email}
                            onChange={changeEmail}
                            required
                            hasErr={emailError}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <TextBoxWithLabel
                            labelClassName={isMobile() ? 'customer-label account-label' : 'customer-label'}
                            addCssClass={`group__row ${telephoneError ? "group__row__error" : ""}`}
                            label="TELEPHONE"
                            placeholder="Enter telephone"
                            value={telephone}
                            onChange={changeTelephone}
                            required
                            hasErr={telephoneError}
                            type="number"
                        />
                    </div>
                </div>

                <div className="row">
                    <button
                        type="button"
                        className="btn group__btn btn_width-fixed button-large button-light-blue"
                        onClick={save}
                        style={isMobile() ? isTablet() ? {
                            marginLeft: "auto", marginRight: "auto", marginBottom: 26
                        } : { margin: "0 0 40px 0", width: "100%" } : {}}
                    >
                        Save
                    </button>
                </div>
            </div>
        </DeleteModal>)
};

export default InviteUserModal;