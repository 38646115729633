import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    productTag: {
        display: 'flex',
        gap: 3,
        alignItems: 'center',
        fontSize: 12,
        fontWeight: 600,
        padding: '0px 6px',
        borderRadius: '5px',
        color: '#454545',
        minHeight: 22
    },
    green: {
        backgroundColor: '#B2ECE1'
    },
    red: {
        backgroundColor: '#FE5F55',
        color: '#fff',
    },
    yellow: {
        backgroundColor: '#FFEC40',
    },
    iconWrapper: {
        fontSize: 15,
        '& .MuiSvgIcon-root': {
            fontSize: 'inherit'
        }
    },
    clickable: {
        cursor: 'pointer'
    }
});



const ProductTag = ({ color, text, icon, onClick }) => {
    const classes = useStyles();

    const getColor = () => {
        if (color === "green") {
            return classes.green
        } else if (color === "red") {
            return classes.red
        } else if (color === "yellow") {
            return classes.yellow
        } else {
            return null
        }
    }

    const getPointerStyles = (color) => {
        if (onClick !== undefined) {
            return classes.clickable
        } else {
            return null
        }
    }

    return (
        <Box className={`${classes.productTag} ${getColor()} ${getPointerStyles()}`} onClick={onClick}>
            <Box className={classes.iconWrapper}>
                {icon}
            </Box>
            {text}
        </Box>
    )
}

export default ProductTag;