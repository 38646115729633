import React from "react";
import NewConfirmModal from "./NewConfirmModal";

const BasketTimedOutModal = (props) => {
    const { onClose, onConfirm } = props;
    return (<NewConfirmModal
        title='Please refresh'
        description='Your basket has timed out. To continue, please refresh your fulfilment options for up-to-date availability.'
        theme='blue'
        okText='Refresh'
        onClose={onClose}
        onConfirm={onConfirm}
    />);   
}

export default BasketTimedOutModal;