import React, {useState, useEffect} from "react";
import featuresService from "../Services/FeaturesService";
import FeatureFlagContext from "./FeatureFlagContext";

export default function FeatureFlagProvider({children}){
    const [state, setState] = useState({
        features:[]
    })
    
    
    const checkFeatures = (featureKey)=>{
        return state.features[featureKey] ?? false;
    }
    const init = async () =>{
        featuresService.getAllFeatures().then((response)=>{
            setState({
                features:response
            })
        });
    }
    
    useEffect(async ()=>{
        await init();
    },[])
    
    const context = {
        checkFeatures
    }
    
    return <FeatureFlagContext.Provider value={context}>{children}</FeatureFlagContext.Provider>
}