import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  modal: {
    overflowY: "auto",
    maxHeight: "90vh",
    width: "40vw",
    left: "calc(50% - 20vw)",
    top: "25%",
    padding: "5px 40px;",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: 16,
  },

  ["@media (max-width: 768px)"]: {
    modal: {
      width: "90vw",
      left: "calc(50% - 45vw)",
    },
  },
  closeModalBtnWrapper: {
    top: "20px",
    width: "24px",
    height: "24px",
    right: "20px",
    position: "absolute",
  },
  closeModalBtn: {
    position: "fixed",
  },
  productImg: {
    height: "400px !important",
    width: "400px !important",
    maxWidth: "100% !important"
  },
  manufacturerNo: {
    display: "flex",
    justifyContent: "center",
  },
}));

const ProductInfoModal = ({ product, onClose }) => {
  const classes = useStyles();

  const onCloseModal = () => {
    onClose && onClose();
  };
  return (
    <>
      <div
        className="modal-overlay"
        style={{ zIndex: 1000, background: "#00000033" }}
      />
      <div className={`dropdown_select_bar_modal ${classes.modal}`}>
        <img
          width={140}
          src={product.img_url || product.imageUrl}
          alt="product"
          className={clsx("product-card__img", classes.productImg)}
        />
        <div>
          <div className={classes.closeModalBtnWrapper}>
            <a className={classes.closeModalBtn} onClick={onCloseModal}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.46 12L19 17.54V19H17.54L12 13.46L6.46 19H5V17.54L10.54 12L5 6.46V5H6.46L12 10.54L17.54 5H19V6.46L13.46 12Z"
                  fill="#AEAEAE"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductInfoModal;
