/* eslint-disable no-useless-computed-key */
import React from "react";
import InputBase from "./InputBase";
import clsx from "clsx";
import get from "lodash/get";
export class Checkbox extends InputBase {
  // This component inherits all methods from InputBase component as well

  render() {
    const {
      id,
      title,
      titlePlacement,
      filled,
      additionalStyle,
      value,
      noError,
      classes = {},
    } = this.props;
    const { error, required } = this.state;
    const root = get(classes, "root", "");
    const titleTop = get(classes, "titleTop", "");
    const titleAside = get(classes, "titleAside", "");
    const input = get(classes, "input", "");
    const wrapper = get(classes, "wrapper", "");
    const adornment = get(classes, "adornment", "");

    return (
      <div
        className={clsx("checkbox", root, {
          ["checkbox--filled"]: filled,
          ["checkbox--error"]: noError ? false : error,
        })}
      >
        {title && titlePlacement === "top" && (
          <div className={clsx("input-field__label", titleTop)}>{title}</div>
        )}
        <label
          htmlFor={id}
          className={clsx("checkbox__wrapper", wrapper, additionalStyle)}
        >
          <input
            id={id}
            type="checkbox"
            className={clsx("checkbox__input", input)}
            onChange={this.onCheck}
            checked={value}
            required={required}
          />
          <span className={clsx(`checkbox__adornment ${value ? "adornment_blue_checked" : "adornment_blue"}`, adornment)} />
          {title && titlePlacement === "aside" && (
            <p className={clsx("checkbox__name", titleAside)}>{title}</p>
          )}
        </label>
      </div>
    );
  }
}

export default Checkbox;
