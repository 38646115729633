import React, {useState, useEffect, useContext} from "react";
import {isMobile, isTablet} from "./Components/Media/Media";
import RecentlyOrdered from "./Components/Order/RecentlyOrdered";
import LikedProductService from "./Services/LikedProductService";
import spinnerIcon from "./assets/img/spinner.svg";
import SearchButton from "./Components/Shop/SearchButton";
import ClearButton from "./Components/Shop/ClearButton";
import FinderInput from "./Components/Shop/FinderInput";
import Header from "./Components/Header/Header";
import FeatureFlagContext from "./Context/FeatureFlagContext";
import Wrapper from "./Components/Layout/Wrapper";



export default function RecentlyOrderd({onAddToBasket, isAccountCompleted, basket}) {
    const [likedProducts, setLikedProducts] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);

    const featureFlagContext = useContext(FeatureFlagContext);
    const isAllProducts=featureFlagContext.checkFeatures("all-products");
    
    const getLikedProducts = () => {
        setLoading(true);
        LikedProductService.searchLikedProducts().then((response) => {
            if (!response) response = [];
            setLikedProducts(response);
            setLoading(false);
        });
    };

    useEffect(() => {
        getLikedProducts();
    }, []);

    const onSearchChange = value => setSearchText(value);

    const clearingSearch = () => {
        setSearchText("");
    };

    const onSearch = () => {
        if (!searchText) return setSearchTerm("");
        setSearchTerm(searchText);
    };

    useEffect(onSearch, [searchText]);

    return (
        <>
            <Wrapper
                wrapperClass=""
                text="RecentlyOrdered"
                selected="Recently Ordered"
                basket={basket}
            >
            {console.log(isAllProducts, isMobile())}


            {!isAllProducts ?
                <>
                    {isMobile() ?
                        <div className="order_history_search order_history_search_mobile">
                            <div className={`order_history_search_box ${isTablet() ? "bg-max-width-tablet" : ""}`}>
                                <div className="finder-inputs-wrapper">
                                    <FinderInput
                                        searchTerm={searchText}
                                        placeholder="Search Recently Ordered"
                                        onChange={onSearchChange}
                                        onClear={clearingSearch}
                                    />
                                    <SearchButton loading={loading} onSearch={onSearch} />
                                </div>
                            </div>
                        </div>
                        :
                        <div className={"sp-desktop-spares-finder"}>
                            <div className={"max-width-1300 bg-gray-container full-width-fav"}>
                                <div className="finder-inputs-wrapper no-margin-left" data-columns='2'>
                                    <FinderInput
                                        searchTerm={searchText}
                                        placeholder="Search Recently Ordered"
                                        onChange={onSearchChange}
                                        onClear={clearingSearch}
                                    />
                                    <SearchButton loading={loading} onSearch={onSearch} />
                                </div>
                            </div>
                        </div>
                    }
                </>

                :


                <>
                    {isMobile() ?
                        <div style={{ marginTop: -50 }}>
                            <Header
                                body="Recently Ordered"
                            />
                            <div style={{
                                padding: 20,
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: '#fff',
                            }}>
                                <FinderInput
                                    searchTerm={searchText}
                                    placeholder="Search Recently Ordered"
                                    onChange={onSearchChange}
                                    onClear={clearingSearch}
                                />
                                <SearchButton loading={loading} onSearch={onSearch} />
                            </div>
                        </div>
                        :
                        <>
                            <Header
                                body="Recently Ordered"
                            />
                            <div className="finder-inputs-wrapper no-margin-left" data-columns='2'>
                                <FinderInput
                                    searchTerm={searchText}
                                    placeholder="Search Recently Ordered"
                                    onChange={onSearchChange}
                                    onClear={clearingSearch}
                                />
                                <SearchButton loading={loading} onSearch={onSearch} />
                            </div>
                        </>
                    }
                </>

            }



            <RecentlyOrdered
                searchTerm={searchTerm}
                onAddToBasket={onAddToBasket}
                basket={basket}
                likedProducts={likedProducts}
                isAccountCompleted={isAccountCompleted}
            />
            </Wrapper>
        </>

    );
}