import http from "../utils/http";

const createMultipleSaveForLater = async multipleSaveForLater => {
    return await http.post("/SaveForLater/CreateMultipleSaveForLater", multipleSaveForLater);
}

const createSaveForLater = async saveForLaterObjectToSave => {
    return await http.post("/SaveForLater/CreateSaveForLater", saveForLaterObjectToSave);
}

const deleteSaveForLater = async id => {
    return await http.delete(`/SaveForLater/DeleteSaveForLater?ID=${id}`);
}

const bulkDeleteSaveForLater = async orderReference => {
    return await http.get(`/SaveForLater/BulkDeleteSaveForLater?orderReference=${orderReference}`);
}

const getSaveForLaters = async () => {
    return await http.get('/SaveForLater/GetSaveForLaters')
}

const getSavedParts = async orderReference => {
    let url = '/SaveForLater/GetSavedParts'
    if (orderReference)
        url += `?orderReference=${orderReference}`
    return await http.get(url)
}

export default {
    createMultipleSaveForLater,
    createSaveForLater,
    deleteSaveForLater,
    bulkDeleteSaveForLater,
    getSaveForLaters,
    getSavedParts
}