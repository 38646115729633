import React, { useEffect, useState } from "react";
import Wrapper from "./Components/Layout/Wrapper";
import Header from "./Components/Header/Header";
import Iframe from "./Components/IFrame/iframe";
import OrdersService from "./Services/OrdersService";
import toastr from "toastr";
import BasketService from "./Services/BasketService";
import NewConfirmModal from "./Components/Modal/NewConfirmModal";
import { useHistory } from "react-router-dom";
import BasketTimedOutModal from "./Components/Modal/BasketTimedOutModal";
import ReactGA from "react-ga4";
import configurationService from './Services/ConfigurationService'

export default function PaymentComplete(props) {
    const [paymentLinkUrl, setPaymentLinkUrl] = useState("");
    const [paymentResult, setPaymentResult] = useState(null);
    const basketKey = props.match.params.basketKey;
    const searchParams = new URLSearchParams(props.location.search);
    const [timerExpired, setTimerExpired] = useState(false)
    const [timer, setTimer] = useState(null)
    const history = useHistory();

    const getPaymentDetailsIframe = async () => {
        if (paymentLinkUrl !== "")
            return;

        const tokenId = searchParams.get("tokenId")
        const response = await OrdersService.paymentLink(basketKey, tokenId)

        if (response?.linkUrl) {
            setPaymentLinkUrl(response?.linkUrl)
        } else {
            toastr.error("Failed to load payment link");
        }
    };

    const handlePaymentCompleteTask = (e) => {
        setPaymentResult(e)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        const startTimer = async () => {
            await BasketService.getBasketExpiry(basketKey).then(response => {
                if (response.expiryDate) {
                    let expiryDate = Date.parse(response.expiryDate + 'Z')
                    let timeLeft = expiryDate - new Date().getTime()
                    if (timeLeft > 0) {
                        let timer = setTimeout(() => {
                            setTimerExpired(true)
                        }, timeLeft
                        );
                        setTimer(timer)
                    } else {
                        setTimerExpired(true)
                    }
                }
            })
        }
        startTimer().catch(console.error);
        if (timer != null) {
            return () => { clearInterval(timer) };
        }
    }, [])

    useEffect(() => {
        if (!paymentResult) return;

        if (paymentResult.data == "completed purchase") {
            OrdersService.placeOrderForBasket(basketKey).then(response => {
                if (response.success) {
                    registerGAPurchaseEvent();
                    props.history.replace(`/Basket/Confirmation/${basketKey}`);
                } else {
                    toastr.error(response.message);
                }
            });
        } else if (paymentResult.data == "aborted purchase") {
            props.history.replace(`/Basket/Checkout/${basketKey}`);
        } else if (paymentResult.data == "failed purchase") {
            props.history.replace(`/payment-failed`);
        }
    }, [paymentResult])

    window.addEventListener("message", handlePaymentCompleteTask);
    useEffect(async () => {

        await getPaymentDetailsIframe();
    }, [])

    const registerGAPurchaseEvent = async () => {
        configurationService.getConfigurationVariables().then(async (res) => {
            if (res?.enableGoogleAnalytics) {
                await BasketService.getBasketByKey(basketKey).then(async (basket) => {
                    //GA begin checkout event
                    var basketItemDetails = [];
                    for (const basketItem of basket.lines.filter(bl => bl.basketLineTypeId === 1)) {
                        const currentIndex = basket.lines
                            .map((item) => item.manufacturerCode)
                            .indexOf(basketItem.manufacturerCode);

                        basketItemDetails.push({
                            //GA parameters
                            item_id: basketItem.manufacturerCode,
                            item_name: basketItem.description,
                            index: currentIndex,
                            price: basketItem.cost,
                            quantity: basketItem.qty,

                            //custom parameters
                            manufacturer: basketItem.manufacturer,
                            manufacturer_id: basketItem.iBasisManId,
                            internal_id: basketItem.id
                        })
                    }

                    let totalShipping = 0;
                    for (const shippingItem of basket.lines.filter(sh => sh.basketLineTypeId === 2 || sh.basketLineTypeId === 3)) {
                        totalShipping = shippingItem.qty * shippingItem.cost;
                    }

                    let totalDiscount = 0;
                    for (const discountItem of basket.lines.filter(sh => sh.basketLineTypeId === 4)) {
                        totalDiscount = discountItem.qty * discountItem.cost;
                    }

                    ReactGA.event("purchase", {
                        //GA parameters
                        currency: "GBP",
                        transaction_id: basket.basketKey,
                        value: basket.totalCost,
                        tax: basket.totalCostWithVat - basket.totalCost,
                        shipping: totalShipping,
                        coupon: basket.discount?.friendlyName ?? undefined,
                        items: basketItemDetails,

                        //custom parameters
                        key: basket.basketKey,
                        organisation_id: basket.organisationId,
                        value_with_vat: basket.totalCostWithVat,
                        collect_date: basket.collectFromBranchOn,
                        order_date: basket.orderDate,
                        branch_id: basket.selectedBranchId,
                        supplier_id: basket.selectedBranch.supplierID,
                        supplier: basket.selectedBranch.supplier.name,
                        discount: -totalDiscount
                    })
                })
            }
        });
    };

    return (
        <Wrapper>
            <Header body='Make Payment' />
            {timerExpired && <BasketTimedOutModal
                onConfirm={() => history.push("/Basket/Availability")}
                onClose={() => {
                    history.push("/homepage-2")
                }} />}
            <div>
                <Iframe source={paymentLinkUrl} />
            </div>
        </Wrapper>);
}