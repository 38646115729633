/* eslint-disable no-useless-computed-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { makeStyles } from "@material-ui/styles";
import React from 'react';
import SparesAvailabilityService from "../../Services/SparesAvailabilityService";
import NewConfirmModal from "./NewConfirmModal";

const $ = window.jQuery;
const useStyles = makeStyles(() => ({
    modal: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "auto",
        maxHeight: "90vh",
        maxWidth: "464px",
        left: "50%",
        right: "50%",
        transform: "translateX(-50%)",
        top: "10%",
        padding: "48px",
        fontSize: 16,
    },
    Content: {
        // border: "1px solid red",
        padding: 10,
    },
    Heading: {
        fontSize: '24px',
        lineHeight: '28px',
        color: '#00226A',
        textAlign: "start",
        marginBottom: 10
    },
    Description: {
        fontSize: '16px',
        lineHeight: '19px',
        color: '#828282',
        textAlign: "start",

        "& a": {
            color: "#009FDF",
            fontWeight: 600,

            "&:hover": {
                textDecoration: "none"
            }
        }
    },
    SendEmailForm: {
        marginTop: 10,
        width: "100%",
        // border: "1px solid red",

        "& input": {
            border: "none",
            borderBottom: '1px solid #E9E9E9',
            fontSize: '16px',
            lineHeight: '19px',
            color: '#828282 !important',
            padding: "9px 2px",
            width: "100%",

            "&::placeholder": {
                color: '#C4C4C4 !important',
            }
        },
        "& button": {
            marginTop: 26,

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: "#009FDF",
            padding: "15px 20px",
            width: "100%",
            color: "white",
            borderRadius: 4,

            "&:active, &:focus": {
                outline: "none",
            },
        }
    },
    SuccessMessage: {
        display: "flex",
        alignItems: "center",

        "& svg": {
            marginRight: 20
        },
        "& h4": {
            fontSize: '24px',
            lineHeight: '28px',
            color: '#00226A',
            textAlign: "start",
            margin: 0,
            padding: 0,
        },
    },
    errMessage: {
        color: '#ED1F45',
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '17px',
        textAlign: "start",
        margin: "15px 0"
    },
    closeModalBtnWrapper: {
        top: "30px",
        right: "55px",
        position: "absolute",

        "& svg": {
            width: "18px",
            height: "18px",
        }
    },
    closeModalBtn: {
        position: "fixed",
    },

    ["@media (max-width: 768px)"]: {
        modal: {
            width: "90vw",
            left: "calc(50% - 45vw)",
            top: "9%",
            padding: "27px",
            transform: "none"
        },
        Heading: {
            fontSize: '20px',
            lineHeight: '24px',
            marginBottom: 10
        },
        Description: {
            "& a": {
                textDecoration: "underline"
            }
        },
        SendEmailForm: {
            "& button": {
                marginTop: 23,
                padding: "10px 20px",
            }
        },
        SuccessMessage: {
            "& svg": {
                marginRight: 10
            },
            "& h4": {
                fontSize: '20px',
                lineHeight: '24px',
                width: "80%"
            },
        },
        errMessage: {
            fontSize: 12,
            lineHeight: '14px',
            margin: "10px 0"
        },
        closeModalBtnWrapper: {
            top: "20px",
            right: "45px",
        }
    },

    '@media (min-width: 640px) and (max-width: 768px)': {
        modal: {
            top: "25%",
            left: "50%",
            right: "50%",
            transform: "translateX(-50%)",
        },
    }
}));

const SubscribeForAlternativeModal = (props) => {
    const classes = useStyles();
    const [emailVal, setEmailVal] = React.useState('')
    const [messageSent, setMessageSent] = React.useState(false)
    const [isDisabled, setIsDisabled] = React.useState(false)
    const [errMessage, setErrMessage] = React.useState('')
    let productInfo = props.product;
    const onCloseModal = () => {
        props.onClose && props.onClose();
    };

    const SendEmail = (e) => {
        setIsDisabled(true);
        $.extend(productInfo, props.man);
        SparesAvailabilityService.ObsoleteProductEmail(props.product, props.scenario).then((response) => {
            setMessageSent(true);
            setIsDisabled(false);
            if (props.onSuccessCallback) props.onSuccessCallback()
        }, (error) => {
            setIsDisabled(false);
            setErrMessage(error.message);
        });
    };

    return (
        <>
            <div
                className="modal-overlay"
                style={{ zIndex: 1000, background: "#00000033" }}
            />
            {messageSent ? (
                <NewConfirmModal
                    title='Thank you.'
                    description='Your request has been sent and we will be in touch shortly.'
                    withoutOkButton
                    withoutCancelButton
                    onClose={onCloseModal}
                />
            ) : (
                <div className={`dropdown_select_bar_modal ${classes.modal}`}>
                    <div className={classes.Content}>
                        <>
                            <h2 className={classes.Heading}>
                                {props.scenario
                                    ? props.scenario === 'obsolete'
                                        ? 'Want us to source a suitable alternative product for you?'
                                        : 'Want us to source this part for you?'
                                    : 'We may be able to source a suitable alternative product for you!'
                                }
                            </h2><br />
                            <p className={classes.Description}>
                                We can source most products within 48 hours.
                                <br /><br />
                                Simply click below and a member of the team will come back to you within 60 mins (during office opening hours 8.30am-5.00pm, Monday-Friday). We will provide a cost (including delivery) and arrange payment if you decide to order the product.
                                <br /><br />
                                Alternatively you can contact us on{" "} <a href="tel:01978 666888">01978 666  888</a>{" "} or {" "} <a href="mailto:info@buytrade.co.uk">info@buytrade.co.uk</a>.
                            </p>
                            <br /><br />
                            <button className={`button-large button-blue`} style={{ width: "100%" }} onClick={SendEmail} disabled={isDisabled}>
                                Request Product
                            </button>
                        </>
                    </div>
                    <div>
                        <div className={classes.closeModalBtnWrapper}>
                            <a className={classes.closeModalBtn} onClick={onCloseModal}>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.46 12L19 17.54V19H17.54L12 13.46L6.46 19H5V17.54L10.54 12L5 6.46V5H6.46L12 10.54L17.54 5H19V6.46L13.46 12Z"
                                        fill="#AEAEAE"
                                    />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SubscribeForAlternativeModal;