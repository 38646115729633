import React from "./Components/Layout/Wrapper";
import Loader from "./Components/Layout/Loader";
import { useEffect, useState } from "react";
import usePartsIdentificationStore from "./Components/PartsIdentification/Store/PartsIdentificationStore";
import PartsIdentificationSearchFilters
    from "./Components/PartsIdentification/SearchFilters/PartsIdentificationSearchFilters";
import BasketService from "./Services/BasketService";
import Wrapper from "./Components/Layout/Wrapper";
import Grid from "@mui/material/Grid";
import ProductSearchService from "./Services/ProductSearchService";
import { isTablet } from "./Components/Media/Media";
import SearchResultsList from "./Components/Shop/SearchResultsList";
import { isEmpty } from "./utils/funcs";
import SearchByDiagram from "./Components/SearchByDiagram/SearchByDiagram";
import DiagramResultsList from "./Components/SearchByDiagram/DiagramResultsList/DiagramResultsList";
import LikedProductService from "./Services/LikedProductService";
import _ from "lodash";
import ReactGA from 'react-ga4';
import configurationService from './Services/ConfigurationService'

const PartsIdentification = ({ }) => {
    const SELECT_VALUES = [
        { sortDir: "asc", text: "Product Name - A-Z", sortProp: "Description" },
        { sortDir: "desc", text: "Product Name - Z-A", sortProp: "Description" },
    ];

    const {
        modes,
        selectedMode,
        setSelectedMode,
        manufacturers,
        setManufacturers,
        selectedManufacturer,
        setSelectedManufacturer,
        appliances,
        setAppliances,
        selectedAppliance,
        setSelectedAppliance,
        partTypes,
        setPartTypes,
        setSelectedPartType,
        selectedPartType,
        searchText,
        setSearchText,
        parts,
        setParts,
        diagrams,
        setDiagrams,
        viewDiagrams,
        setViewDiagrams,
        selectedDiagram,
        setSelectedDiagram,
        selectedCategory,
        setSelectedCategory,
        diagramData,
        setDiagramData,
        likedProducts,
        setLikedProducts,
        newBasketItem,
        setNewBasketItem,
        isLoading,
        setIsLoading,
        hasSearched,
        setHasSearched,
        autoLoad,
        setAutoLoad,
        sortOrder,
        setSortOrder,
        searchRefresh,
        setSearchRefresh
    } = usePartsIdentificationStore();
    const [basket, setBasket] = useState(null); //todo introduce this into store
    const getBasket = () => {
        return BasketService.getBasketForUser().then((response) => {
            if (response.lines == null) response.lines = [];
            setBasket(response);
        });
    };

    const addToBasket = (basketItem) => {
        let basketCopy = _.cloneDeep(basket);
        const interval = setInterval(() => {
            setNewBasketItem(null);
            clearInterval(interval);
        }, 3000);

        setNewBasketItem(basketItem);

        const newItem = {
            qty: basketItem.quantityChildValue,
            manufacturerCode: basketItem.item.mpn,
            description: basketItem.item.description,
            imageUrl: basketItem.item.img_url,
            iBasisManId: basketItem.item.manid,
            iBasisAppliance: basketItem.item.app || basketItem.item.iBasisAppliance,
            iBasisApplianceId:
                basketItem.item.appid || basketItem.item.iBasisApplianceId,
            iBasisGCN: basketItem.item.pgcn || basketItem.item.iBasisGCN,
            IBasisApplianceGC:
                basketItem.item.appgc || basketItem.item.IBasisApplianceGC,
            cost: basketItem.item.price,
            manufacturer: basketItem.item.man,
            basketLineTypeId: 1
        };

        const currentIndex = basketCopy.lines
            .map((item) => item.manufacturerCode)
            .indexOf(newItem.manufacturerCode);

        if (currentIndex > -1) {
            basketCopy.lines.splice(currentIndex, 1);
        }

        basketCopy.lines.push(newItem);

        basketCopy.lines = basketCopy.lines.filter(({ qty }) => qty !== 0);

        updateBasket(basketCopy);

        configurationService.getConfigurationVariables().then(res => {
            if (res?.enableGoogleAnalytics) {
                //GA add item to basket event
                ReactGA.event("add_to_cart", {
                    currency: "GBP",
                    value: basketItem.item.price * basketItem.quantityChildValue,
                    items: [
                        {
                            //GA parameters
                            item_id: basketItem.item.mpn || basketItem.item.manufacturerCode,
                            item_name: basketItem.item.description,
                            index: currentIndex > -1 ? currentIndex : basketCopy.lines.length - 1,
                            price: basketItem.item.price,
                            quantity: basketItem.quantityChildValue,

                            //custom parameters
                            manufacturer: basketItem.item.man,
                            manufacturer_id: basketItem.item.manid,
                            location: "Parts",
                            is_liked: basketItem.item.liked
                        }
                    ],

                    //custom parameters
                    basket_key: basketCopy.basketKey,
                    organisation_id: basketCopy.organisationId
                })
            }
        });
    };

    const removeFromBasket = (basketItem) => {
        let basketCopy = _.cloneDeep(basket);

        const newItem = {
            qty: basketItem.quantityChildValue,
            manufacturerCode: basketItem.item.mpn,
            description: basketItem.item.description,
            imageUrl: basketItem.item.img_url,
            iBasisManId: basketItem.item.manid,
            iBasisAppliance: basketItem.item.app || basketItem.item.iBasisAppliance,
            iBasisApplianceId:
                basketItem.item.appid || basketItem.item.iBasisApplianceId,
            iBasisGCN: basketItem.item.pgcn || basketItem.item.iBasisGCN,
            IBasisApplianceGC:
                basketItem.item.appgc || basketItem.item.IBasisApplianceGC,
            cost: basketItem.item.price,
            manufacturer: basketItem.item.man,
            basketLineTypeId: 1
        };

        const currentIndex = basketCopy.lines
            .map((item) => item.manufacturerCode)
            .indexOf(newItem.manufacturerCode);

        if (currentIndex > -1) {
            basketCopy.lines.splice(currentIndex, 1);
        }

        basketCopy.lines.push(newItem);

        basketCopy.lines = basketCopy.lines.filter(({ qty }) => qty !== 0);

        updateBasket(basketCopy);
    };

    const updateBasket = (basketCopy) => {
        BasketService.updateBasketForUser(basketCopy).then((basket) => {
            if (basket.lines == null) basket.lines = [];
            setBasket(basket);
        });
    };

    const getManufacturers = () => {
        ProductSearchService.manufacturer().then((data) => {
            const sortedData = (data || []).sort((a, b) => {
                if (a.man < b.man) {
                    return -1;
                }
                if (a.man > b.man) {
                    return 1;
                }
                return 0;
            });
            if (autoLoad === true) {
                sortedData.unshift({ manid: 0, man: 'All Manufacturers' });
                setManufacturers(sortedData);
            }
        });
    }
    const loadAppliances = () => {
        ProductSearchService.appliances(selectedManufacturer?.value).then((response) => {
            const sortedData = (response.data || []).sort((a, b) => {
                if (a.app < b.app) {
                    return -1;
                }
                if (a.app > b.app) {
                    return 1;
                }
                return 0;
            });
            setAppliances(sortedData)
        });
    }
    const loadPartTypes = () => {
        ProductSearchService.partTypes(selectedAppliance.value).then((response) => {
            const sortedData = (response || []).sort((a, b) => {
                if (a.part_type < b.part_type) {
                    return -1;
                }
                if (a.part_type > b.part_type) {
                    return 1;
                }
                return 0;
            });
            setPartTypes(sortedData);
        });
    };

    const searchParts = () => {
        clearDiagrams();
        setIsLoading(true);
        switch (selectedMode.id) {
            case 2:
                searchPartsByGcNo();
                break;
            case 3:
                searchPartsByPartCode();
                break;
            default:
                searchPartsByType();
        }
    }

    const searchPartsByType = () => {
        if (selectedAppliance !== null && selectedPartType !== null && selectedManufacturer !== null) {
            ProductSearchService.partsByType(selectedAppliance?.value, selectedPartType?.value, selectedManufacturer?.value, selectedManufacturer?.text, selectedAppliance?.text, selectedPartType?.text, sortOrder, "Description")
                .then((response) => {
                    let items = response.map((product) => {
                        product.qty = basket?.lines.find((line) => line.manufacturerCode === product.mpn)?.qty ?? 0
                        product.liked =
                            likedProducts?.findIndex(
                                (p) => p.mpn == (product.manufacturerCode ?? product.mpn)
                            ) !== -1;
                        return product;
                    });
                    setParts(items);
                    setIsLoading(false);
                });
        }
    }

    const searchPartsByGcNo = () => {
        if (searchText !== null && searchText !== '') {
            ProductSearchService.searchAppliances(searchText)
                .then((response) => {
                    if (response?.length === 1) {
                        const firstAppliance = response[0];
                        setSelectedManufacturer({
                            text: firstAppliance.man,
                            value: firstAppliance.manid
                        });
                        setSelectedAppliance({
                            text: firstAppliance.app,
                            value: firstAppliance.appid
                        });
                        ProductSearchService.partsByAppliance(firstAppliance.manid, firstAppliance.appid, 1, null, null, null).then((response) => {
                            let items = response.data.map((product) => {
                                product.qty = basket?.lines.find((line) => line.manufacturerCode === product.mpn)?.qty ?? 0
                                product.liked = likedProducts.findIndex(
                                    (p) => p.mpn == (product.manufacturerCode ?? product.mpn)
                                ) !== -1;
                                return product;
                            });

                            setParts(items);
                            setIsLoading(false);
                        });
                    } else if (response?.length > 1) {
                        //TODO: Set multiple modal window here.
                    }
                });
        }
    }

    const searchPartsByPartCode = () => {
        if (selectedManufacturer !== null && searchText !== null && searchText !== '') {
            ProductSearchService.searchPartsByFilter(searchText, selectedManufacturer?.text, selectedManufacturer?.value, 1, "asc", "Description")
                .then((response) => {
                    let items = response.data.map((product) => {
                        product.qty = basket?.lines.find((line) => line.manufacturerCode === product.mpn)?.qty ?? 0
                        product.liked = likedProducts.findIndex(
                            (p) => p.mpn == (product.manufacturerCode ?? product.mpn)
                        ) !== -1;
                        return product;
                    });
                    setParts(items);
                    setIsLoading(false);
                });
        }
    }

    const getDiagrams = () => {
        ProductSearchService.getDiagrams(selectedAppliance.value).then((response) => {
            if (response.length > 0) {
                setDiagrams(response);
                setSelectedDiagram(response[0]);
            }
        })
    }

    const getLikedProducts = () => {
        LikedProductService.searchLikedProducts('').then((response) => {
            setLikedProducts(response ? response.map((product) => {
                return {
                    mpn: product.manufacturerCode,
                    img_url: product.imageUrl,
                    description: product.description,
                };
            }) : []);
        });
    };

    const getDiagramData = () => {
        return ProductSearchService.getDiagramData(
            selectedManufacturer?.value,
            selectedAppliance?.value,
            selectedDiagram?.diagramid,
        ).then((response) => {
            response.data = response.data.map((product) => {
                product.qty = basket.lines.find((line) => line.manufacturerCode === product.mpn)?.qty ?? 0
                product.liked =
                    likedProducts?.findIndex(
                        (p) => p.mpn == (product.manufacturerCode ?? product.mpn)
                    ) !== -1;
                return product;
            });

            setDiagramData(response);
        })
    }

    const viewDiagram = () => {
        setViewDiagrams(!viewDiagrams);
        getDiagrams();
    }

    const viewManual = () => {
        if (selectedAppliance && !isEmpty(selectedAppliance)) {
            ProductSearchService.applianceDocumentInTab(selectedAppliance.value);
        }
    }

    const checkQuery = () => {

        const tab = new URL(window.location.href).searchParams.get("tab");
        const query = new URL(window.location.href).searchParams.get("q");
        switch (tab) {
            case "searchByNumber":
                changeMode(modes[1]);
                break;
            case "searchByKeyword": {
                setAutoLoad(true);
                changeMode(modes[2]);

                if (query !== null || query != '') {
                    setSearchText(query);
                    setupAllManufacturers(false);
                    ProductSearchService.searchPartsByFilter(query, selectedManufacturer?.text, selectedManufacturer?.value, 1, "asc", "Description")
                        .then((response) => {
                            let items = response.data.map((product) => {
                                product.qty = basket?.lines.find((line) => line.manufacturerCode === product.mpn)?.qty ?? 0
                                product.liked = likedProducts.findIndex(
                                    (p) => p.mpn == (product.manufacturerCode ?? product.mpn)
                                ) !== -1;
                                return product;
                            });
                            setParts(items);
                            setIsLoading(false);
                        });
                }
                break;
            }

        }
    }

    const changeMode = (newMode) => {
        setSelectedMode(newMode);
    }

    const setupAllManufacturers = (removeManufacturers = true) => {
        if (removeManufacturers && manufacturers.filter(manufacturer => manufacturer.id == 0)?.length > 0) {
            setManufacturers(manufacturers.filter((row) => row.id !== 0));
        }
        else {
            let manufacturersClone = [...manufacturers];
            manufacturersClone.unshift({ value: 0, text: 'All Manufacturers' })
            setManufacturers(manufacturersClone);
            setSelectedManufacturer(manufacturersClone[0]);
        }
    }

    const toggleLike = (item, isLiked = false) => {
        LikedProductService.toggle(item.mpn, item.description, item.img_url, item.manid, item.app, item.appid, item.pgcn, item.appgc, item.price, item.man).then(() => {


            if (parts != null && parts.length > 0) {
                var partsUpdated = [];
                partsUpdated = parts?.map(product =>
                    product.mpn === item.mpn ? { ...product, liked: !isLiked } : product
                );

                setParts(partsUpdated);
            }


            if (isLiked) {
                var likedProductsCopy = [];

                likedProductsCopy = likedProducts.push({
                    mpn: item.mpn,
                    img_url: item.img_url,
                    description: item.description,
                });

                setLikedProducts(likedProducts);
            } else {
                setLikedProducts(likedProducts.filter(likedProduct => likedProduct.mpn !== item.mpn));
            }
        });
    };

    const clearSearch = () => {
        setSelectedManufacturer(null);
        setSelectedAppliance(null);
        setSelectedPartType(null);
        setSearchText('');
        clearDiagramsAndParts();
    }

    const clearDiagrams = () => {
        setDiagrams([]);
        setDiagramData([]);
        setSelectedDiagram(null);
        setViewDiagrams(false);
    }

    const clearDiagramsAndParts = () => {
        clearDiagrams();
        setParts(false);
        setHasSearched(false);
        setSearchRefresh(false);
    }

    useEffect(() => {
        getBasket();
        getManufacturers();
        getLikedProducts();
        checkQuery();
    }, []);

    useEffect(() => {
        if (selectedMode.id === 1) {
            setSelectedAppliance(null);
            loadAppliances();
            clearDiagramsAndParts();
        }
    }, [selectedManufacturer]);

    useEffect(() => {
        setSelectedPartType(null);
        if (selectedAppliance != null && selectedMode.id === 1) {
            loadPartTypes();
            clearDiagramsAndParts();
        }
    }, [selectedAppliance]);

    useEffect(() => {
        diagrams?.length > 0 && selectedCategory > 0 && getDiagramData(
            selectedManufacturer?.value,
            selectedAppliance?.value,
            selectedDiagram?.diagramid,
        );
    }, [selectedCategory]);

    const changeSort = (order) => {
        switch (order.selected) {
            case 1:
                setSortOrder("desc");
                break;
            default:
                setSortOrder("asc");
                break;
        }
    }

    useEffect(() => {
        searchParts();
    }, [sortOrder])

    const renderNoResultsMessage = (searchTerm) => {
        let content =
            <>
                <div className="search-term-container">
                    Search Results for <span className="searched-term">'{searchTerm}'</span> <span className="product-count">(0 products)</span>
                </div>
                <p>Your search has found no results. Please re-try using a spares/products search as appropriate, or contact us for assistance during office hours on <a href="tel:01978 666 888">01978 666 888</a> or <a href="mailto:info@buytrade.co.uk">info@buytrade.co.uk</a> .</p>
            </>
        return searchTerm && content;
    }

    return (<Wrapper text="SparesFinder" selected="New Search" newComponent={true} basket={basket}>
        <div className="shop-wrapper">
            <div className='wrapper-content'>
                <PartsIdentificationSearchFilters
                    modes={modes}
                    selectedMode={selectedMode}
                    onChangeMode={(mode) => {
                        changeMode(mode);
                        clearSearch();
                    }}
                    manufacturers={manufacturers}
                    selectedManufacturer={selectedManufacturer}
                    onManufacturerChanged={(newManufacturer) => {
                        setSelectedManufacturer(newManufacturer);
                    }}
                    appliances={appliances}
                    onApplianceChanged={(newAppliance) => {
                        setSelectedAppliance(newAppliance)
                    }}
                    selectedAppliance={selectedAppliance}
                    partTypes={partTypes}
                    onPartTypeChanged={(newPartType) => {
                        setSelectedPartType(newPartType);
                    }}
                    selectedPartType={selectedPartType}
                    searchText={searchText}
                    setSearchText={(newText) => {
                        setSearchText(newText);
                        setSearchRefresh(true);
                    }}
                    onSearch={() => {
                        setHasSearched(true);
                        setSearchRefresh(false);
                        searchParts();
                    }}
                    onDiagramSelected={viewDiagram}
                    onViewManual={viewManual}
                    hasSearched={hasSearched}
                />
            </div>

            {isLoading && <Loader />}

            <div className="max-width-1300" style={{ width: "100vw" }}>
                <div className={isTablet() ? 'results-wrapper-tablet' : ''}>
                    {viewDiagrams == false && !isLoading && <SearchResultsList
                        results={parts || []}
                        loading={false}
                        noResults={parts.length}
                        toggleLike={toggleLike}
                        onAddToBasket={addToBasket}
                        changeSort={(item) => { changeSort(item) }}
                        sortValues={SELECT_VALUES}
                        noItemsText='Select an appliance above and click search'
                        isAccountCompleted={true}
                        showNewSortFilter={false}
                        onRemoveFromBasket={removeFromBasket}
                        noItemsHTML={
                            !isLoading
                            && parts?.length === 0
                            && !searchRefresh
                            && renderNoResultsMessage(searchText)
                        }
                    />}
                    {viewDiagrams === true && diagrams.length > 0 &&
                        <SearchByDiagram
                            diagrams={diagrams}
                            manufacturer={selectedPartType?.value}
                            appliance={selectedManufacturer?.text}
                            selectedCategory={selectedCategory}
                            selectedDiagram={selectedDiagram}
                            handleSelectCategory={(item) => {
                                setSelectedCategory(item.pnum);
                                setSelectedDiagram(item);
                            }}
                            handleDeselectCategory={() => {
                                setSelectedCategory(null)
                                setDiagramData([]);
                                setSelectedDiagram(diagrams[0]);
                            }}
                            results={!diagramData.totalCount > 0}
                            searchResultsList={diagramData.totalCount > 0 &&
                                <DiagramResultsList results={diagramData.data} onAddToBasket={addToBasket} toggleLike={toggleLike} />
                            }
                        />}
                </div>
            </div>
        </div>
    </Wrapper>);
};

export default PartsIdentification;