import React, { useCallback } from "react";
import clsx from "clsx";
import DropDown from "./../DropDown";
const $ = window.jQuery;
export const SelectListStaticWithLabel = ({
  valueProperty,
  textProperty,
  collection,
  label,
  value,
  width,
  onChange,
  shouldSelect,
  className,
  selectBarClass = '',
  popUpStyle = '',
  placeholder,
  searchIgnoresWhiteSpace,
  outlined = false,
  disabled = false,
  newStyle = false,
  selectOnly = false,
  withoutWrapper,
}) => {
  const dropDown = () => (
    <DropDown
      placeholder={placeholder}
      valueProperty={valueProperty}
      textProperty={textProperty}
      collection={collection}
      onChange={onChange}
      selectedVal={value}
      error={shouldSelect}
      width={width}
      searchIgnoresWhiteSpace={searchIgnoresWhiteSpace ?? true}
      outlined={outlined}
      disabled={disabled}
      newStyle={newStyle}
      selectOnly={selectOnly}
      selectBarClass={selectBarClass}
      popUpStyle={popUpStyle}
    />
  );

  return withoutWrapper ? dropDown() : (
    <div className={clsx("filter__item", className)}>
      {label && <label className="filter__label">{label}</label>}
      <div className="select filter__select">
        {dropDown()}
      </div>
    </div>
  );
};

export default SelectListStaticWithLabel;
