import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "../Media/Media";
import Portal from "../../Helpers/modal/Portal";
import React, {useEffect, useState} from "react";
import { useHistory } from "react-router";
import boundToElement from "../../Helpers/modal/boundToElement";
import exclamationMarkIcon from "../../assets/img/exclamation-mark-icon.svg";
import closePopupIcon from "../../assets/img/close-popup-icon.svg";

const useStyles = makeStyles({
    "@media (max-width: 768px)": {
        completeAccountPopUp: {
            position: "fixed",
            top: 60,
            right: 20,
            width: "calc(100vw - 40px)",
            zIndex: 9999,
            backgroundColor: "#fff",
            borderRadius: "31px",
            boxShadow: "0px 0px 25px rgb(0 0 0 / 14%)",
        },
        completeAccountButton: {
            color: "#FFF",
            backgroundColor: "#009FDF",
            width: "-webkit-fill-available",
            height: "40px",
            borderRadius: "3px !important",
            margin: "0px 20px 60px 20px",
        },
        completeAccountHeaderWrapper: {
            display: "flex",
            flex: "1 1",
            padding: "65px 20px 10px 20px",
        },
        completeAccountDescription: {
            fontWeight: 400,
            fontSize: "16px",
            padding: "0px 20px 17px 20px",
            color: "#828282",
        },
        completeAccountDivider: {
            margin: "0px 20px 30px 20px",
        },
    },
    "@media (min-width: 769px)": {
        completeAccountPopUp: {
            position: "relative",
            backgroundColor: "#fff",
            borderRadius: "31px",
            boxShadow: "0px 0px 25px rgb(0 0 0 / 14%)",
            width: "438px",
            zIndex: 9999
        },
        completeAccountButton: {
            color: "#FFF",
            backgroundColor: "#009FDF",
            width: "345px",
            height: "43px",
            margin: "0px 49px 40px 45px",
        },
        completeAccountHeaderWrapper: {
            display: "flex",
            flex: "1 1",
            padding: "45px 50px 10px 35px",
        },
        completeAccountDescription: {
            fontWeight: 400,
            fontSize: "16px",
            padding: "0px 50px 17px 45px",
            color: "#828282",
        },
        completeAccountDivider: {
            margin: "0px 50px 30px 45px",
        },
    },
    completeAccountHeaderText: {
        fontSize: "24px",
        fontWeight: "normal",
        lineHeight: "28px",
        display: "flex",
        alignItems: "center",
        margin: "0 0 0 15px",
        color: "#00226A",
    },
    notificationArrow: {
        width: "20px",
        borderWidth: "20px",
        borderStyle: "solid",
        borderColor: "transparent transparent #fff transparent",
        position: "absolute",
        top: "-40px",
        left: "300px",
    },
    closeModalBtn: {
        position: "absolute",
        right: 30,
        top: 35,
    }
});

const CompleteAccountPopUp = ({ onClose }) => {
    const classes = useStyles();
    const history = useHistory();
    const [absoluteStyles, setAbsoluteStyles] = useState({})
    const [fixedStyles, setFixedStyles] = useState({})
    const [windowScrollY, setWindowScrollY] = useState(window.scrollY);

    useEffect(() => setPopupPosition(), []);

    useEffect(() => {
        window.addEventListener("scroll", onScrollHandler);
        window.addEventListener("resize", onResizeHandler);
        return () => {
            window.removeEventListener("scroll", onScrollHandler);
            window.removeEventListener("resize", onResizeHandler);
        };
    }, []);

    const onScrollHandler = () => setWindowScrollY(window.scrollY);

    const onResizeHandler = () => setPopupPosition();

    const setPopupPosition = () => {
        const checkoutButtonRef = document.getElementById('checkout-button');
        if (checkoutButtonRef) {
            const [absolute, fixed] = boundToElement(checkoutButtonRef);
            setAbsoluteStyles(absolute);
            setFixedStyles(fixed);
        }
    };

    const goToOMyAccount = () => {
        history.push("/settings");
    };

    const onClosePopup = () => {
        onClose && onClose()
    }

    const renderPopupBody = () => (
        <>
            <a className={classes.closeModalBtn} onClick={onClosePopup}>
                <img src={closePopupIcon} alt="Close"/>
            </a>
            <div className={classes.completeAccountHeaderWrapper}>
                <img src={exclamationMarkIcon} alt="Warning"/>
                <p className={classes.completeAccountHeaderText}>
                    Complete your Account to start shopping
                </p>
            </div>
            <div className={classes.completeAccountDescription}>
                Please go to your Account settings and enter your address.
            </div>
            <hr className={classes.completeAccountDivider} />
            <button
                className={classes.completeAccountButton}
                onClick={goToOMyAccount}
            >
                My Account
            </button>
        </>
    )

    return isMobile() ? (
        <Portal>
            <div className={`${classes.completeAccountPopUp} swing-in-top-fwd`}>
                {renderPopupBody()}
            </div>
        </Portal>
    ) : (
        <Portal>
            <div
                className={`${classes.completeAccountPopUp} swing-in-top-fwd`}
                style={windowScrollY > 200 ? fixedStyles : absoluteStyles}
            >
                {!(windowScrollY > 200) && (
                    <span className={classes.notificationArrow} />
                )}
                {renderPopupBody()}
            </div>
        </Portal>
    );
};

export default CompleteAccountPopUp;
