import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import NoImageAvailable from "../../assets/img/no_image_available.png"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import 'swiper/swiper.min.css';
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { isMobile } from "../Media/Media";
import { trimText } from "../Shop/trimtext";


function removeManufacturerCode(text) {
    return text.replace(/\d{5,}$/g, '');
}

function RelatedProductCard(props) {
    const { product, onAddToBasket } = props;
    const { description, buyTradePrice, imageUrlDisplayString, manufactureCode } = product.childItem;

    const [qty, setQty] = useState(null);
    const increaseQuantity = () => {
        setQty((prevState) => (prevState + 1))
    }
    const decreaseQuantity = () => {
        setQty((prevState) => {
            if (prevState === 0) return 0;
            return prevState - 1;
        })
    }


    useEffect(() => {

        if (!qty) return;
        //Do this because all other functions expect imageUrl not imageUrlDisplayString.
        product.childItem.imageUrl = product.childItem.imageUrlDisplayString
        product.childItem.itemID = product.childItem.id
        onAddToBasket({ quantityChildValue: qty, item: product.childItem })
    }, [qty])
    const buttonDiv = () => {
        if (!qty) return (
            <div className={"related-product-btn-container"}>
                <button className={`button-medium button-light-blue related-product-button`} onClick={() => increaseQuantity()}>
                    <svg
                        width="17"
                        height="14"
                        viewBox="0 0 17 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="7.35373" cy="12.8459" r="1.05051" fill="white" />
                        <ellipse
                            cx="11.5554"
                            cy="12.8459"
                            rx="1.05053"
                            ry="1.05051"
                            fill="white"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 2.20962H2.16257L4.8964 9.91405C4.98935 10.176 5.2372 10.3511 5.51516 10.3511H13.394C13.6554 10.3511 13.8919 10.196 13.9961 9.95628L16.3936 4.44199H14.9617L12.9635 9.03792H5.97886L3.24504 1.33349C3.15208 1.07153 2.90424 0.896484 2.62627 0.896484H0V2.20962Z"
                            fill="white"
                        />
                    </svg>
                    <div>
                        Add to order
                    </div>
                </button>
            </div>)
        return (

            <div className="relatedProductCardQtyButtons">
                <button onClick={() => decreaseQuantity()}> <RemoveIcon /> </button>
                <input  readOnly={true} value={qty} />
                <button onClick={() => increaseQuantity()}> <AddIcon /> </button>
            </div>

        )
    };


    return (

        <div className={"related-product-card"}>
            <div className="related-product-img-container">
                <img
                    src={imageUrlDisplayString ? imageUrlDisplayString : NoImageAvailable}
                    alt="product"
                    className="related-product-img"
                />
            </div>

            <div style={isMobile() ? { alignSelf: "flex-start", width: "100%" } : { width: "100%" }}>
                <div className={"related-product-description"}>
                    <div>
                        {trimText(removeManufacturerCode(description ?? ""), 70, true)}
                    </div>

                    {manufactureCode ? <div className={"related-product-mpn"}>
                        {manufactureCode}
                    </div> : <div className={"related-product-mpn"}>

                        </div>}
                    <div className={"related-product-price"}>
                        From <span>£{buyTradePrice}</span> Exc. VAT
                    </div>
                    {buttonDiv()}
                </div>
            </div>
        </div>


    )

}

function RelatedProductSlider(props) {
    const [swiper, setSwiper] = useState(null);
    const { products, onAddToBasket, slidesPerView, allowTouchMove } = props


    return (<div className="related-product-slider-container">
        <div className="related-product-slider-navigation-container">
            <div className="related-product-title">{props.sliderTitle} </div>
            {isMobile() ? null : <div className="related-product-navigation">

                <button className="button" onClick={() => swiper.slidePrev()}>
                    <svg width="18" height="8" viewBox="0 0 18 8" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M17 4.5C17.2761 4.5 17.5 4.27614 17.5 4C17.5 3.72386 17.2761 3.5 17 3.5V4.5ZM0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM17 3.5H1V4.5H17V3.5Z"
                            fill="#009FDF" />
                    </svg>
                </button>
                <button className="button" onClick={() => swiper.slideNext()}>
                    <svg width="18" height="8" viewBox="0 0 18 8" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM17.3536 4.35355C17.5488 4.15829 17.5488 3.84171 17.3536 3.64645L14.1716 0.464466C13.9763 0.269204 13.6597 0.269204 13.4645 0.464466C13.2692 0.659728 13.2692 0.97631 13.4645 1.17157L16.2929 4L13.4645 6.82843C13.2692 7.02369 13.2692 7.34027 13.4645 7.53553C13.6597 7.7308 13.9763 7.7308 14.1716 7.53553L17.3536 4.35355ZM1 4.5L17 4.5L17 3.5L1 3.5L1 4.5Z"
                            fill="#009FDF" />
                    </svg>
                </button>
            </div>}

        </div>
        <div className="related-product-slider-content">
            <Swiper onSwiper={setSwiper}
                slidesPerGroup={1}
                loop={false}
                loopFillGroupWithBlank={true}
                className="mySwiper"
                allowTouchMove={true}
                slidesPerView={1.3}
                spaceBetween={20}
                breakpoints={{
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                        allowTouchMove: false
                    }
                }}
            >
                {products?.map((product) => {
                    return (<SwiperSlide key={product.id}>
                        <RelatedProductCard product={product} onAddToBasket={onAddToBasket} key={product.id} />
                    </SwiperSlide>

                    )
                })}
            </Swiper>
    </div>
    </div >)
}

export default RelatedProductSlider;