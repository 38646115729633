import { isMobile } from "../Media/Media";
import clsx from "clsx";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useProductStyles = makeStyles({
    productCard: {
        width: "45%",
        background: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "10px",
        padding: "10px",
        margin: "10px",
        marginRight: "0px",
        position: "relative",
    },
    number: {
        color: "#454545",
        opacity: "50%",
        fontSize: "16px",
        alignSelf: "self-start",
    },
    button: {
        backgroundColor: "#3EC4F4",
        padding: "15px 20px",
        width: "calc(100% - 20px)",
        color: "white",
        margin: "10px",
    },
    transparentButton: {
        border: "1px solid #3EC4F4",
        padding: "15px 20px",
        width: "calc(100% - 20px)",
        color: "#3EC4F4",
        margin: "10px",
    },
    input: {
        width: "80px",
        textAlign: "right",
        paddingLeft: "40px",
        height: 36,
        borderRadius: 4,
    },
    description: {
        alignSelf: "self-start",
    },
    qty: {
        position: "absolute",
        left: "10px",
        color: "#454545",
        opacity: "50%",
        fontSize: "14px",
        top: "50%",
        transform: "translateY(-50%)",
    },
    qtyWrapper: {
        position: "relative",
        display: "flex",
    },
    productRoot: {
        display: "flex",
        padding: "10px 0",
    },
    wrapper: {
        flex: 1,
    },
    wrapperMobile: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start"
    },
    title: {
        textAlign: "center",
        color: "#3EC4F4",
        fontSize: "16px",
        fontWeight: 600,
        margin: "25px",
    },
    order: {
        border: "1px",
        borderRadius: "6px",
        background: "white",
        padding: "10px",
        margin: "10px",
    },
    productFooter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    price: {
        color: "#81C454",
        fontWeight: 700,
        fontSize: "18px",
    },
    numberWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },
    deliveryWrapper: {
        display: "flex",
        alignItems: "center",
        paddingTop: "15px",
    },
    deliveryTitle: {
        fontSize: "16px",
        fontWeight: 700,
    },
    deliveryTitleWrapper: {
        paddingLeft: "20px",
    },
    priceWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
    },
    deliveryCost: {
        color: "#454545",
        opacity: "50%",
        fontSize: "18px",
        fontWeight: 700,
    },
    totalDisplayedCost: {
        color: "#81C454",
        fontSize: "18px",
        fontWeight: 700,
        textAlign: "right",
    },
    totalVatCost: {
        color: "#81C454",
        fontSize: "24px",
        fontWeight: 700,
    },
    totalVatCostWrapper: {
        padding: "10px",
        background: "#EBFFDD",
    },
    main: {
        display: "flex",
        flexDirection: "column",
    },
    buttons: {
        justifySelf: "",
    },
    vatCost: {
        color: "#454545",
        opacity: "50%",
        fontSize: "12px",
        fontWeight: 500,
    },
    productImg: {
        height: 100,
        width: 100,
        padding: "10px",
    },
    orderNum: {
        fontWeight: 600,
        fontSize: 16,
        color: "#454545",
    },
    orderDate: {
        fontWeight: 500,
        fontSize: 16,
        color: "gray",
    },
    orderHeader: {
        padding: "12px 17px 20px 17px",
    },
    itemName: {
        padding: "10px 0",
    },
    itemNameMobile: {
        fontSize: 12,
    },
    subHeadType: {
        fontWeight: 500,
        fontSize: 16,
        color: "#454545",
    },
});

const DeliveryInformMobile = ({ isDelivery, totalDisplayedCost, totalDisplayedCostWithVat, costWithVatBox = false }) => {
    const classes = useProductStyles();
    return (
        <>
            <br />
            {costWithVatBox ?
                <div
                    className={clsx(classes.priceWrapper, classes.totalVatCostWrapper)}
                    style={isMobile() ? { backgroundColor: "#F0F5F8" } : {}}
                >
                    <span className={classes.subHeadType}>Total inc. VAT:</span>
                    <span
                        className={classes.totalVatCost}
                        style={isMobile() ? { color: "#007AAB" } : {}}
                    >
                        £ {totalDisplayedCostWithVat?.toFixed(2)}
                    </span>
                </div>
                : <div className={classes.priceWrapper}>
                    <span className={classes.subHeadType}>Total:</span>
                    <div>
                        <div
                            className={classes.totalDisplayedCost}
                            style={isMobile() ? { color: "#007AAB" } : {}}
                        >
                            £ {totalDisplayedCost?.toFixed(2)}
                        </div>
                        {/*<div className={classes.vatCost}>
                                  with VAT of £{order.price - order.totalDisplayedCost}
                              </div>*/}
                    </div>
                </div>}
        </>
    );
};

export default DeliveryInformMobile;