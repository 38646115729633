import React from "react";

class TablePager extends React.Component {
  constructor(props) {
    super(props);

    this.state = { skip: 0, take: parseInt(this.props.pageSize) };
  }

  render() {
    const displayPaging =
      this.props.totalRecords > 0 && this.props.totalRecords > this.props.take;
    const upperBoundCount =
      parseInt(this.props.skip) + parseInt(this.props.take);
    return (
      <div className="section template__section">
        {displayPaging == true ? (
          this.props.skip == 0 ? null : (
            <a onClick={this.onPrevious.bind(this)} className="pager-link">
              Previous
            </a>
          )
        ) : null}
        {displayPaging == true ? (
          this.props.totalRecords <=
          this.props.skip + this.props.take ? null : (
            <a onClick={this.onNext.bind(this)} className="pager-link">
              Next
            </a>
          )
        ) : null}
        Showing {this.props.skip + 1} to{' '}
        {this.props.totalRecords > upperBoundCount
            ? upperBoundCount
            : this.props.totalRecords}{' '}
        of {this.props.totalRecords}
      </div>
    );
  }

  getPageSize() {
    return parseInt(this.props.pageSize);
  }

  onNext() {
    var skip = this.props.skip + this.getPageSize();
    var take = this.getPageSize();

    this.props.onChange({
      skip: skip,
      take: take,
    });
  }

  onPrevious() {
    var skip = this.props.skip - this.getPageSize();
    var take = this.getPageSize();
    this.props.onChange({
      skip: skip,
      take: take,
    });
  }
}

export default TablePager;
