import React from 'react';
import { useState } from 'react';
import ApiAddressianService from '../../Services/ApiAddressianService';
import AddressLine from './AddressLine';
import clsx from "clsx";
import {isMobile} from "../Media/Media";

const AddressAutocomplete = (props) => {
    const [addresses, setAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState('');
    const [showChangeAddressButton, setShowChangeAddressButton] = useState(props.showChangeAddressButton ?? true);
    const [hideForm, setHideForm] = useState(false);

    const addressSelected = (obj) => {
        let addresses = '';
        for (let i = 0; i < obj.address.length; i++) {
            addresses += obj.address[i] + ', ';
        }
        addresses = addresses + obj.city + ', ' + obj.postcode;
        setSelectedAddress(addresses);
        setAddresses([]);
        props.onChange(obj);
    }

    const toggleForm = () => {
        setHideForm(prevState => !prevState)
    }

    const searchAddresses = (e) => {
        props.onChange(null)
        setSelectedAddress(e.target.value)
        if (e.target.value !== '') {
            ApiAddressianService.searchAddresses(e.target.value).then((response) => {
                setAddresses(
                    response.sort((a, b) => {
                        const aPostNum = a.address[0].split(' ')[0];
                        const bPostNum = b.address[0].split(' ')[0];
                        return aPostNum - bPostNum;
                    })
                );
            });
        } else {
            setAddresses([])
        }
    }

    const getAddressLines = () => {
        return addresses.map((data, index) => {
            return (
                <AddressLine
                    key={index}
                    data={data}
                    addressSelected={addressSelected}
                />
            );
        });
    }

    return (
        <div className="template__grid">
            <div className="search-field">
                <div className={clsx(props.className,`search-field__wrapper__town_autocomplete`)}>
                    {hideForm != true || !showChangeAddressButton ? (
                        <>
                            <label className={isMobile() ? "input-field__label account-label" : "input-field__label"}>{props.labelText ?? 'Search address'}</label>
                            <input
                                className={clsx(`input-field__input autocomplete_input-field__input`, props.addressError ? "group__row__error" : "")}
                                value={selectedAddress}
                                onChange={searchAddresses}
                                id="autoComplete"
                                type="text"
                                name="s"
                                placeholder={props.placeholderText ?? "Search address"}
                                autoComplete="new-password"
                            />
                            {props.addressError && <img src="/img/warning.svg" alt="error icon" style={{ position: "absolute", right: 11, bottom: 11 }} />}
                        </>
                    ) : (
                        <input
                            type="button"
                            value="Change Address"
                            onClick={toggleForm}
                            className="btn btn-primary"
                        />
                    )}
                </div>
            </div>
            <div id="address-drop-down" className="address-autocomplete-wrapper">
                {!!addresses.length && <ul>{getAddressLines()}</ul>}
            </div>
        </div>
    );
}

export default AddressAutocomplete;
