import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  wrapper: {
    position: "fixed",
    zIndex: 10000,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00000099",
  },
  container: {
    borderRadius: 16,
    backgroundColor: "#fff",
    width: "80vw",
    padding: 30,
    boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
  },
}));

function ModalWindowMobile({
  setIsMobileModalOpen,
  isMobileModalOpen,
  children,
}) {
  const classes = useStyles();

  const handleClose = (e) => {
    setIsMobileModalOpen(false);
  };

  return (
    <div
      className={classes.wrapper}
      style={{ display: isMobileModalOpen ? "flex" : "none" }}
      // onClick={handleClose}
    >
      <div className={[classes.container, "slide-in-top"].join(" ")}>
        <a onClick={handleClose} className="modal-close">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.46 12L19 17.54V19H17.54L12 13.46L6.46 19H5V17.54L10.54 12L5 6.46V5H6.46L12 10.54L17.54 5H19V6.46L13.46 12Z"
              fill="#AEAEAE"
            />
          </svg>
        </a>
        {children}
      </div>
    </div>
  );
}

export default ModalWindowMobile;
