import React from "react";

export default class ModalWindow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children, id, className = "", styles } = this.props;

    return (
      <div id={id} className={`modal ${className}`} style={styles}>
        {children}
      </div>
    );
  }
}
