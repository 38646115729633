import http from '../utils/http';

const getBasketForUser = () => {
    return http.get(`Basket/GetBasketForUser`);
}

const getBasketByKey =async  (basketKey) => {
    const params = new URLSearchParams();
    params.append('basketKey', basketKey);

    return await http.get(`Basket/GetBasketByKey`, { params });
}

const updateBasketForUser = (basket) => {
    return http.post(`Basket/UpdateBasketForUser`, basket)
}
const getRelatedItems = async (itemId,skip,pageSize) => {
    const params = new URLSearchParams();
    params.append('id', itemId);
    params.append('pageSize', pageSize);
    params.append('skip', skip);
    
    return await http.get(`Basket/GetRelatedProducts/`, {params})
}
const getBasketExpiry =   async (basketKey) =>{
    const params = new URLSearchParams();
    params.append('basketKey', basketKey);
    return await http.get(`Basket/GetBasketExpiry`, { params })
}

const getDeliveryQuote = async (deliveryQuote)=> {
    return await http.post('Basket/DeliveryQuote', deliveryQuote);
}

const addDeliveryQuote=(deliveryQuote)=> {
    return http.post('Basket/AddDeliveryQuoteToBasket', deliveryQuote);
}

const getSavedCards = () => {
    return http.get('Basket/getSavedCards');
}

const deleteSavedCard = (tokenID) => {
    const params = new URLSearchParams();
    params.append('tokenID', tokenID);
    return http.delete('Basket/deleteSavedCard', { params })
}

const getSavedAddresses = (isBillingDetails) => {
    const params = new URLSearchParams();
    params.append('isBillingDetails', isBillingDetails);
    return http.get('Basket/getAddressesForBasket', { params })
}

const deleteAddress = addressId => {
    const params = new URLSearchParams();
    params.append('addressId', addressId);
    return http.delete('Basket/deleteAddress', { params })
}

const batchDownloadInvoice = (exportPDFRequest) => {
    return http.post('Basket/BatchDownloadInvoice', exportPDFRequest);
}

const isValidDiscountCode = (isValidDiscountCodeRequest) =>{
    const params = new URLSearchParams();
    params.append('basketKey', isValidDiscountCodeRequest.basketKey);
    params.append('discountCode',isValidDiscountCodeRequest.discountCode );
    params.append('supplier', isValidDiscountCodeRequest.supplier);
    return http.get('Basket/IsValidDiscountCode', {params});
}
const applyDiscount = (applyDiscountCodeRequest) =>{
    return http.post('Basket/applyDiscountCode', applyDiscountCodeRequest);
}

const addByBoxToBasket = byBoxRequest => {
    return http.post('Basket/addByBoxToBasket', byBoxRequest);
}

export default {
    getBasketForUser,
    getBasketByKey,
    updateBasketForUser,
    getDeliveryQuote,
    addDeliveryQuote,
    getSavedCards,
    deleteSavedCard,
    getSavedAddresses,
    deleteAddress,
    getBasketExpiry,
    batchDownloadInvoice,
    getRelatedItems,
    isValidDiscountCode,
    applyDiscount,
    addByBoxToBasket
}
