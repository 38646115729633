import React, { useEffect } from "react";
import Wrapper from "./Components/Layout/Wrapper";
import Header from "./Components/Header/Header";

export default function PaymentFailed(props) {
    useEffect(() => {
       window.parent.postMessage("failed purchase"); 
    });

    const goBack = () => props.history.goBack();
    
    return (
        <Wrapper text="FAILED">
            <Header backBody='Go back' onBack={goBack}></Header>
            <p>Payment has failed</p>
        </Wrapper>
        )
}