import React, {useContext, useEffect} from "react";
import Wrapper from "./Components/Layout/Wrapper";
import NavigationComp from "./Components/General/NavigationComp";
import NewSearchCont from "./Components/Shop/NewSearchCont";
import SearchByText from "./Components/Shop/SearchByText";
import getBasketCount from "./Helpers/common/getBasketCount";
import NavigationComp1 from "./Components/General/NavigationComp1";
import RecentlyOrdered from "./RecentlyOrdered";
import RequestedProducts from "./RequestedProducts";
import SavedFavouritesCont from "./Components/Shop/SavedFavouritesCont";
import BasketService from "./Services/BasketService";
import Portal from "./Helpers/modal/Portal";
import { isMobile, isTablet } from "./Components/Media/Media";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import AccountService from "./Services/AccountService";
import _ from "lodash";
import FeatureFlagContext from "./Context/FeatureFlagContext";
import ReactGA from 'react-ga4'
import configurationService from './Services/ConfigurationService'

const DEFAULT_DATA = [
    {
        id: 1,
        viewKey: "newSearch",
        btnText: "Select Appliance",
    },
    {
        id: 2,
        viewKey: "recentlyOrdered",
        btnText: "Search by Appliance GC Number",
    },
    {
        id: 3,
        viewKey: "savedFavorites",
        btnText: "Search by Part Code or Keyword",
    },
    {
        id: 4,
        viewKey: "favourites",
        btnText: "Favourites List",
    },
    {
        id: 5,
        viewKey: "recently",
        btnText: "Recently Ordered",
    },
    {
        id: 6,
        viewKey: "requestedProducts",
        btnText: "Requested Products",
    },
];

const SparesFinder = (props) => {
    const history = useHistory();

    const [onShowTabView, setOnShowTabView] = useState();
    const [basket, setBasket] = useState({
        basketKey: "",
        lines: [],
    });
    const [showFilterHeader, setShowFilterHeader] = useState(true);
    const [basketIconPosition, setBasketIconPosition] = useState(null);
    const [windowScrollY, setWindowScrollY] = useState(0);
    const [newBasketItem, setNewBasketItem] = useState(null);
    const [isAccountCompleted, setIsAccountCompleted] = useState(false);

    const featureFlagContext = useContext(FeatureFlagContext);
    const isAllProducts=featureFlagContext.checkFeatures("all-products");

    useEffect(() => {
        let unregisterHistoryListener;
        checkAccount()
            .then(getBasket)
            .then(() => {
                window.addEventListener("popstate", (event) => {
                    const tab =
                        new URL(window.location.href).searchParams.get("tab") ??
                        "newSearch";
                    changeTabsView(tab);
                });

                unregisterHistoryListener = history.listen((location, action) => {
                    const tab =
                        new URL(window.location.href).searchParams.get("tab") ??
                        "newSearch";
                    changeTabsView(tab);
                });

                const tab = new URL(window.location.href).searchParams.get("tab");
                setOnShowTabView(tab ?? "newSearch");

                const basketIcon = document.getElementById("basket-icon");
                basketIcon &&
                    setBasketIconPosition(basketIcon?.getBoundingClientRect());
                window.addEventListener("scroll", () => {
                    setWindowScrollY(window.scrollY);
                });
            });

        return () => {
            window.removeEventListener("popstate", () => {
            });
            unregisterHistoryListener && unregisterHistoryListener();
            window.removeEventListener("scroll", () => {
            });
        };
    }, []);

    const newItemPopup = () => {
        if (!basketIconPosition && !isMobile()) return null;

        const posX = isMobile() ? 0 : basketIconPosition.x - 184.5;
        const posY = isMobile()
            ? 0
            : basketIconPosition.y + basketIconPosition.height + 20;
        const fixedStyles = { top: 16, right: 16, position: "fixed", zIndex: 9999 };
        const absoluteStyles = { top: posY, left: posX + 27, position: "absolute", zIndex: 9999 };

        const goToBasket = () => {
            history.push("/Basket");
        };
        return isMobile() ? (
            <div className="header-new-basket-item-notification-mobile swing-in-top-fwd">
                <p>Item(s) added to basket</p>
                <div className="new-basket-item-description">
                    <div>
                        <p>{newBasketItem?.item.description}</p>
                    </div>
                    <img src={newBasketItem?.item.img_url} alt="product" />
                </div>
                <button className={`button-large button-white`} onClick={goToBasket}>Go to Basket</button>
            </div>
        ) : (
            <Portal>
                <div
                    className="header-new-basket-item-notification swing-in-top-fwd"
                    style={windowScrollY > 200 ? fixedStyles : absoluteStyles}
                >
                    {!(windowScrollY > 200) && <span className="notification-arrow" />}
                    <p>Item(s) added to basket</p>
                    <div className="new-basket-item-description">
                        <div>
                            <p>MPN: {newBasketItem?.item.mpn}</p>
                            <p>{newBasketItem?.item.description}</p>
                        </div>
                        <img src={newBasketItem?.item.img_url} alt="product" />
                    </div>
                    <button className={`button-large button-white`} onClick={goToBasket}>Go to Basket</button>
                </div>
            </Portal>
        );
    };

    const addToBasket = (basketItem) => {
        let basketCopy = _.cloneDeep(basket);
        const interval = setInterval(() => {
            setNewBasketItem(null);
            clearInterval(interval);
        }, 3000);

        setNewBasketItem(basketItem);

        const newItem = {
            qty: basketItem.quantityChildValue,
            manufacturerCode: basketItem.item.mpn,
            description: basketItem.item.description,
            imageUrl: basketItem.item.img_url,
            iBasisManId: basketItem.item.manid,
            iBasisAppliance: basketItem.item.app || basketItem.item.iBasisAppliance,
            iBasisApplianceId:
                basketItem.item.appid || basketItem.item.iBasisApplianceId,
            iBasisGCN: basketItem.item.pgcn || basketItem.item.iBasisGCN,
            IBasisApplianceGC:
                basketItem.item.appgc || basketItem.item.IBasisApplianceGC,
            cost: basketItem.item.price,
            manufacturer: basketItem.item.man,
            basketLineTypeId: 1
        };

        const currentIndex = basketCopy.lines
            .map((item) => item.manufacturerCode)
            .indexOf(newItem.manufacturerCode);

        if (currentIndex > -1) {
            basketCopy.lines.splice(currentIndex, 1);
        }

        basketCopy.lines.push(newItem);

        basketCopy.lines = basketCopy.lines.filter(({ qty }) => qty !== 0);

        updateBasket(basketCopy);

        configurationService.getConfigurationVariables().then(res => {
            if (res?.enableGoogleAnalytics) {
                //GA add item to basket event
                ReactGA.event("add_to_cart", {
                    currency: "GBP",
                    value: basketItem.item.price * basketItem.quantityChildValue,
                    items: [
                        {
                            //GA parameters
                            item_id: basketItem.item.mpn || basketItem.item.manufacturerCode,
                            item_name: basketItem.item.description,
                            index: currentIndex > -1 ? currentIndex : basketCopy.lines.length - 1,
                            price: basketItem.item.price,
                            quantity: basketItem.quantityChildValue,

                            //custom parameters
                            manufacturer: basketItem.item.man,
                            manufacturer_id: basketItem.item.manid,
                            location: "Spares Finder",
                            is_liked: basketItem.item.liked
                        }
                    ],

                    //custom parameters
                    basket_key: basketCopy.basketKey,
                    organisation_id: basketCopy.organisationId
                })
            }
        });
    };

    const removeFromBasket = (basketItem) => {
        let basketCopy = _.cloneDeep(basket);

        const newItem = {
            qty: basketItem.quantityChildValue,
            manufacturerCode: basketItem.item.mpn,
            description: basketItem.item.description,
            imageUrl: basketItem.item.img_url,
            iBasisManId: basketItem.item.manid,
            iBasisAppliance: basketItem.item.app || basketItem.item.iBasisAppliance,
            iBasisApplianceId:
                basketItem.item.appid || basketItem.item.iBasisApplianceId,
            iBasisGCN: basketItem.item.pgcn || basketItem.item.iBasisGCN,
            IBasisApplianceGC:
                basketItem.item.appgc || basketItem.item.IBasisApplianceGC,
            cost: basketItem.item.price,
            manufacturer: basketItem.item.man,
            basketLineTypeId: 1
        };

        const currentIndex = basketCopy.lines
            .map((item) => item.manufacturerCode)
            .indexOf(newItem.manufacturerCode);

        if (currentIndex > -1) {
            basketCopy.lines.splice(currentIndex, 1);
        }

        basketCopy.lines.push(newItem);

        basketCopy.lines = basketCopy.lines.filter(({ qty }) => qty !== 0);

        updateBasket(basketCopy);
    };

    const updateBasket = (basketCopy) => {
        BasketService.updateBasketForUser(basketCopy).then((basket) => {
            if (basket.lines == null) basket.lines = [];
            setBasket(basket);
        });
    };
    const getBasket = () => {
        return BasketService.getBasketForUser().then((response) => {
            if (response.lines == null) response.lines = [];
            setBasket(response);
        });
    };

    const checkAccount = () => {
        return AccountService.isAccountCompleted().then((response) => {
            setIsAccountCompleted(response);
        });
    };
    /**
     * @desc SparesFinderNavigationComp has multi view navigation button these buttons returns with their name
     * and SpareFinders triggers re-render the prefered view
     */
    const handleSparesFinderNavigationCompChange = (clickItemName, evt) => {
        changeTabsViewAndUrl(clickItemName);
    };
    /**
     * @desc sets selected view name  by SparesFinderNavigationComp onClick event return value assigned as a param
     */
    const changeTabsViewAndUrl = (tabViewName) => {
        changeTabInUrl(tabViewName);
        changeTabsView(tabViewName);
    };

    const changeTabsView = (tabViewName) => {
        setOnShowTabView(tabViewName);
    };

    const changeTabInUrl = (tabViewName) => {
        const newUrl = `/Shop?tab=${tabViewName}`;
        history.push(newUrl);
    };

    const viewTabs = {
        components: {
            newSearch: (
                <NewSearchCont
                    basket={basket}
                    onAddToBasket={addToBasket}
                    isAccountCompleted={isAccountCompleted}
                />
            ),
            recentlyOrdered: (
                <SearchByText
                    key={1}
                    basket={basket}
                    onAddToBasket={addToBasket}
                    setShowFilterHeader={setShowFilterHeader}
                    isAccountCompleted={isAccountCompleted}
                />
            ),
            savedFavorites: (
                <SearchByText
                    key={2}
                    basket={basket}
                    onAddToBasket={addToBasket}
                    isAccountCompleted={isAccountCompleted}
                    isSearchByCode
                />
            ),
            recently: (
                <RecentlyOrdered
                    basket={basket}
                    onAddToBasket={addToBasket}
                    isAccountCompleted={isAccountCompleted}
                />
            ),
            requestedProducts: (
                <RequestedProducts
                    basket={basket}
                    onAddToBasket={addToBasket}
                    isAccountCompleted={isAccountCompleted}
                />
            ),
            favourites: (
                <SavedFavouritesCont
                    basket={basket}
                    onAddToBasket={addToBasket}
                    onRemoveFromBasket={removeFromBasket}
                    isAccountCompleted={isAccountCompleted}
                />
            ),
        },
    };

    return (
        <>
                <Wrapper
                    wrapperClass=""
                    text="SparesFinder"
                    selected="New search"
                    basket={basket}
            >


                {!isTablet() && !isMobile() ?
                   <div className="template__wrapper sp-desktop-spares-finder max-width-1300">

                    {newBasketItem?.quantityChildValue ? newItemPopup() : null}

                        {!isAllProducts ?
                        <>
                            <div className='nav-buttons-container'>
                                <NavigationComp
                                    dataViews={DEFAULT_DATA}
                                    hightligntActiveButton={onShowTabView}
                                    handlerOnClick={handleSparesFinderNavigationCompChange}
                                />
                            </div>
                            <div className='absolute-gray-bg' style={isAllProducts ? {} : { top: 213 }} />
                        </>
                        : null
                        }



                    <>{viewTabs.components[onShowTabView]}</>


                    </div>
                    :

                    <div className={"sp-mobile-spares-finder"} style={{ paddingTop: 77 }}>


                        <div style={{ backgroundColor: "#F3F9FC" }}>
                            {!isAllProducts ?
                                <div
                                    style={isTablet()
                                        ? {
                                            margin: "0 auto 20px auto",
                                            display: showFilterHeader ? "block" : "none",
                                            maxWidth: 320,
                                        }
                                        : {
                                            margin: "0 -10px 20px -10px",
                                            display: showFilterHeader ? "block" : "none",
                                        }}
                                >
                                    <NavigationComp1
                                        dataViews={DEFAULT_DATA}
                                        hightligntActiveButton={onShowTabView}
                                        handlerOnClick={handleSparesFinderNavigationCompChange}
                                        navigation={props.navigation}
                                    />
                                </div>
                                : null}
                                {newBasketItem?.quantityChildValue ? newItemPopup() : null}
                                {viewTabs.components[onShowTabView]}
                            </div>

                    </div>
                }
                </Wrapper>


        </>
    );
    return (
                <Wrapper
                    wrapperClass=""
                    text="SparesFinder"
                    selected="New search"
                    count={getBasketCount(basket)}
                >
            {/*
                    <div className="template__wrapper sp-desktop-spares-finder max-width-1300">
                        {newBasketItem?.quantityChildValue ? newItemPopup() : null}
                        <div className='nav-buttons-container'>
                            <NavigationComp
                                dataViews={DEFAULT_DATA}
                                hightligntActiveButton={onShowTabView}
                                handlerOnClick={handleSparesFinderNavigationCompChange}
                            />
                        </div>
                        <div className='absolute-gray-bg' />

                        <>{viewTabs.components[onShowTabView]}</>
                    </div>
                    <div className="sp-mobile-spares-finder">
                        <div className='light-gray-background'>
                            <div className='finder-navigation-wrapper'
                                style={{ display: showFilterHeader ? "block" : "none" }}>
                                <NavigationComp1
                                    dataViews={DEFAULT_DATA}
                                    hightligntActiveButton={onShowTabView}
                                    handlerOnClick={handleSparesFinderNavigationCompChange}
                                    navigation={props.navigation}
                                />
                            </div>
                            {newBasketItem?.quantityChildValue ? newItemPopup() : null}
                            {viewTabs.components[onShowTabView]}
                        </div>
            </div>
                    */}
                </Wrapper>
    );
};

export default SparesFinder;
