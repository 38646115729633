import http from '../utils/http';

const userDetails = () => {
    return http.get(`Account/UserDetails`)
}

const getOrganisation = () => {
    return http.get(`Account/GetOrganisation`)
}

const updateAccountInfo = (request) => {
    return http.post(`Account/UpdateAccountInfo`, request, {
        headers : {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    });
}

const listUsers = () => {
    return http.get('Account/ListUsers');
}

const createAccount = (request) => {
    return http.post(`Account/CreateAccount`, request)
}

const checkUserExists = (request) => {
    return http.post(`Account/CheckUserExists`, request)
}
const isAccountCompleted = () => {
    return http.get(`Account/IsAccountCompleted`)
}

const joinBuyingGroup = (request) => {
    return http.post(`Account/JoinBuyingGroup`, request);
}

const getTrades = () => {
    return http.get('Account/ListTrades');
}

const lockUser = (id) => {
    const formData = new FormData();
    formData.append('id', id);
    return http.post('Account/LockUser', formData);
}

const unLockUser = (id) => {
    const formData = new FormData();
    formData.append('id', id);
    return http.post('Account/UnLockUser', formData);
}

const inviteUser = request => {
    return http.post('Account/InviteUser', request);
}

export default {
    userDetails,
    getOrganisation,
    updateAccountInfo,
    createAccount,
    checkUserExists,
    isAccountCompleted,
    joinBuyingGroup,
    getTrades,
    listUsers,
    lockUser,
    unLockUser,
    inviteUser
}
