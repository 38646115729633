import React from "react";

const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingTop: 50,
        width: "100%",
      }}
    >
      <img src="/img/loader.gif" alt="Loading..." width={30} />
    </div>
  );
}

export default Loader;
