import React, { useState, useEffect } from "react";
import markGreen from '../../../assets/img/mark-green.png';
import binIcon from "../../../assets/img/bin.svg";
import buttonArrowIcon from "../../../assets/img/button-arrow.svg";
import arrowDetailsIcon from "../../../assets/img/arrow-details.svg";
import InfoIcon from "../../../assets/img/info";
import AvailabilityFulfilmentOptions from "./AvailabilityFulfilmentOptions/AvailabilityFulfilmentOptions";
import markYellow from "../../../assets/img/orange_circle.png";
import markGrey from "../../../assets/img/mark-grey.png";

const $ = window.jQuery;

const OnePriceBranchRow = (props) => {

    const {
        detailsWrapper,
        branchRowStyles,
        branchName,
        orderDetailsOnClick,
        fulfilledByLogo,
        buttonOnClick,
        buttonDisabled,
        orderDetailsClass,
        branch,
        onViewBranchDetails,
        item,
        allItemsCount,
        selectedFulfilmentRows,
        indexIdentifier,
        requestedProductsList,
        openSubscribeForAlternativeModal,
        setManufacturerCode,
        setShowDeleteModal,
        calcTotalCostForAvailableItems,
        featureFlagContext,
        byBoxEnabled
    } = props;
    
    const [selectedFulfilmentTypeId, setSelectedFulfilmentTypeId]=useState(0);

    const getItems = () => {
        const { sameDayAvailability, nextDayAvailability, nextDayDelivery, futureDateAvailability } = item;

        switch (selectedFulfilmentTypeId){
            case 0:
                return sameDayAvailability;
            case 1:
                return nextDayAvailability;
            case 2:
                return nextDayDelivery;
            case 3:
                return futureDateAvailability;
            case 4:
                return sameDayAvailability;
            case 5:
                return nextDayDelivery;
            default:
                return [];
        }
    }

    const renderItemDisplayedPrice = (displayedPrice) => {
        const formattedPrice = displayedPrice.toFixed(2);
        return formattedPrice > 0.00 ? `£${formattedPrice}` : '-';
    }
    const isInRequestedList = (item, branchId) => requestedProductsList.find(reqItem => reqItem.manufacturerCode === item.manufacturerCode && reqItem.branchId === branchId)

    const getAvailableQtyForItem = (row) => {
        return row.availableQty > row.qty ? row.qty : row.availableQty;
    }

    const sameDayAvailable = item.sameDayAvailableQty > 0;
    const nextDayAvailable = item.nextDayAvailableQty > 0;
    const nextDayDeliveryAvailable = item.nextDayDeliveryAvailableQty > 0;
    const futureDayAvailable = item.futureDayAvailableQty > 0;
    
    return (

        <>


            {/* START DESKTOP COMPONENT - HIDDEN ON MOBILE */}

            <div className={`onePriceBranchRow ${detailsWrapper}`}>
                <div className={`branchRow ${branchRowStyles}`}>
                    
                    <div className="column1">

                        <p className='branchName'> <span className="branchNameTitle">{branch.name}</span> {
                            branch?.supplier?.deliveryChargeText !== null ? <span className="deliveryChargeText">({branch?.supplier?.deliveryChargeText})</span> : (branch?.supplier?.showBranchDistance && `(${branch?.distanceMiles !== null ? Math.round(branch.distanceMiles) : 0} miles)`)
                        }</p>
                        
                        <AvailabilityFulfilmentOptions
                            key={`${branch.id}_options`}
                            item={item}
                            allItemsCount={allItemsCount}
                            selectedFulfilmentRows={selectedFulfilmentRows}
                            indexIdentifier={indexIdentifier}
                            onFulfilmentTypeChanged={(newTypeId) => {
                                calcTotalCostForAvailableItems(getItems());
                                setSelectedFulfilmentTypeId(newTypeId);
                            }}
                            orderDetailsOnClick={orderDetailsOnClick}
                            featureFlagContext={featureFlagContext}
                            byBoxEnabled={byBoxEnabled}
                        />

                        {!sameDayAvailable && !nextDayAvailable && !nextDayDeliveryAvailable && !futureDayAvailable ? null :
                            <div className='expandOrderDetails' onClick={orderDetailsOnClick}>
                                <a>
                                Expand Order Details
                                    <img src={arrowDetailsIcon} alt="" />
                                </a>
                            </div>
                        }

                    </div>

                    <div className="column2">
                        <div className="fulfilledByText">Fulfilled By</div>
                        <img className="fulfilledByLogo" src={fulfilledByLogo} alt="supplier" />
                        {branch?.supplier?.showBranchDetailsLink &&
                            <a className='branchDetailsButton' onClick={()=>{
                                onViewBranchDetails(branch)}}>
                                Branch Details</a>
                        }
                    </div>

                    <div className="column3">

                        {!sameDayAvailable && !nextDayAvailable && !nextDayDeliveryAvailable && !futureDayAvailable ?

                            <button
                                className="proceedButton disabled"
                                disabled
                                Proceed
                            > Proceed
                                <img src={buttonArrowIcon} alt="" />
                            </button>
                            :

                            <div className="priceContainer">
                                {item.isTradeClub==true && <span className="buyTradePriceChip"> <InfoIcon style={{ marginRight: 1 }} /> Trade Club Price </span>}
                                {(branch.supplier.isCreditAccount != true && item.isTradeClub!=true) && <span className="buyTradePriceChip"> <InfoIcon style={{ marginRight: 1 }} /> BuyTrade Price </span>}
                                <p className="price">£{calcTotalCostForAvailableItems(getItems())}</p>
                                <p className='plusVatText' style={{ paddingBottom: 0 }}>(plus VAT)</p>
                                <button onClick={() => {
                                    buttonOnClick(selectedFulfilmentTypeId);
                                }}
                                    className="proceedButton"
                                    disabled={buttonDisabled}>
                                    Proceed
                                <img src={buttonArrowIcon} alt="" />
                                </button>
                            </div>
                            }
                    </div>


                    <div className={`expandOrderDetailsSection ${orderDetailsClass}`}>

                        <div className="orderDetailsHeader">
                            <span />
                            <p>MPN</p>
                            <p>Description</p>
                            <p className="text-center">Qty (Available)</p>
                            <p className="text-center">Price</p>
                            <p>Status</p>
                            <span />
                            <span />
                        </div>



                        {getItems().map((row)=> {
                            return <div className="orderDetailsItemRow">
                                <span><img src={row.imageUrl} alt="product" /></span>
                                <span>{row.manufacturerCode}</span>
                                <span>{row.description}</span>
                                <span className="textCenter">{`${row.qty} (${getAvailableQtyForItem(row)})`}</span>
                                <span className="textCenter fontBold">{renderItemDisplayedPrice(row.displayedPrice)}</span>
                                <div className='availabilityContainer'>
                                    {row.available ? (
                                        <>
                                            <img src={markGreen} alt="available" />
                                            <p>Available</p><span></span>
                                        </>
                                    ) : (
                                        <>
                                            <img src={row.availableQty > 0 ? markYellow : markGrey} alt="not-available" />
                                            <p>{row.availableQty > 0 ? "Limited stock" : "Out of stock"}</p>
                                        </>
                                    )}
                                </div>
                                <span className='addToRequestedProducts'>{row.availableQty > 0 ?
                                    null
                                    :
                                    !isInRequestedList(item, branch.id)
                                        ? <button
                                            className='add-to-requested-products-btn'
                                            onClick={() => openSubscribeForAlternativeModal(row, branch.id)}
                                        >
                                            <p className='item-text'>Request Product</p>
                                        </button>
                                        : <button
                                            className='add-to-requested-products-btn added-to-requested-products-btn'
                                            onClick={(e) => openSubscribeForAlternativeModal(row, branch.id)}
                                        >
                                            <p className='item-text'>Product Requested</p>
                                        </button>
                                }</span>
                                <img src={binIcon} alt="remove" className="removeItemButton" onClick={() => {
                                    setShowDeleteModal(true)
                                    setManufacturerCode(row.id)
                                }} />
                            </div>
                        })}
                    </div>

                </div>
            </div>

            {/* END DESKTOP COMPONENT - HIDDEN ON MOBILE */}




            {/* START MOBILE COMPONENT - HIDDEN ON DESKTOP */}

            <div className={`onePriceBranchRowMobile ${detailsWrapper}`}>
                <div className={`branchRow ${branchRowStyles}`}>

                    <div className="column1">
                        <div className="fulfilledByText">Fulfilled By</div>
                        <img className="fulfilledByLogo" src={fulfilledByLogo} alt="supplier" />
                    </div>


                    <div className="column2">
                        <p className='branchName'>{branchName} {
                            branch?.supplier?.deliveryChargeText !== null ? <span className="deliveryChargeText">({branch?.supplier?.deliveryChargeText})</span> : (branch?.supplier?.showBranchDistance && `(${branch?.distanceMiles !== null ? Math.round(branch.distanceMiles) : 0} miles)`)
                        }</p>
                        {branch?.supplier?.showBranchDetailsLink &&
                            <a className='branchDetailsButton' onClick={()=>{
                                onViewBranchDetails(branch)}}>
                                Branch Details</a>
                        }
                    </div>


                    <div className="column3">
                        {!sameDayAvailable && !nextDayAvailable && !nextDayDeliveryAvailable && !futureDayAvailable ?
                            null
                            :
                            <div className="priceContainer">
                                {branch.supplier.isCreditAccount && branch.supplier.isTradeClub!=true ? null : <span className="buyTradePriceChip"> <InfoIcon style={{ marginRight: 1 }} /> {branch.supplier.isTradeClub ? "Trade Club" : "BuyTrade"} Price</span>}
                                <p className="price">£{calcTotalCostForAvailableItems(getItems())}</p>
                                <p className='plusVatText' style={{ paddingBottom: 0 }}>(plus VAT)</p>
                            </div>
                            }
                    </div>

                    <div className="column4">
                        <AvailabilityFulfilmentOptions
                            key={`options_mobile_${branch.id}`}
                            item={item}
                            allItemsCount={allItemsCount}
                            selectedFulfilmentRows={selectedFulfilmentRows}
                            indexIdentifier={indexIdentifier}
                            onFulfilmentTypeChanged={(newTypeId) => {
                                setSelectedFulfilmentTypeId(newTypeId);
                            }}
                            orderDetailsOnClick={orderDetailsOnClick}
                            featureFlagContext={featureFlagContext}
                            byBoxEnabled={byBoxEnabled}
                        />
                    </div>


                    <div className="column5">
                        {!sameDayAvailable && !nextDayAvailable && !nextDayDeliveryAvailable && !futureDayAvailable ?
                            null
                            :
                            <div className='expandOrderDetails' onClick={orderDetailsOnClick}>
                                <a>
                                    Expand Order Details
                                <img
                                        src={arrowDetailsIcon}
                                        alt=""
                                    />
                                </a>
                            </div>
                            }
                    </div>


                    <div className="column6">

                        {!sameDayAvailable && !nextDayAvailable && !nextDayDeliveryAvailable && !futureDayAvailable ?
                            <button
                                className="proceedButton disabled"
                                disabled
                                Proceed
                            > Proceed
                                <img src={buttonArrowIcon} alt="" />
                            </button>
                            :
                            <button onClick={() => {
                                buttonOnClick(selectedFulfilmentTypeId);
                            }}
                                className="proceedButton"
                                disabled={buttonDisabled}>
                                Proceed
                            <img src={buttonArrowIcon} alt="" />
                            </button>
                            }
                    </div>


                    <div className="column7">
                        <div className={`expandOrderDetailsSection ${orderDetailsClass}`}>


                            {getItems().map((row)=> {
                                return (<div className="orderDetailsItemRow">

                                    <div className="productImageColumn">
                                        <span><img src={row.imageUrl} alt="product" /></span>
                                    </div>

                                    <div className="productDetailsColumn">
                                        <div className='availabilityContainer'>
                                            {row.available ? (
                                                <>
                                                    <img src={markGreen} alt="available" />
                                                    <p>Available</p><span></span>
                                                </>
                                            ) : (
                                                <>
                                                    <img src={row.availableQty > 0 ? markYellow : markGrey} alt="not-available" />
                                                    <p>{row.availableQty > 0 ? "Limited stock" : "Out of stock"}</p>
                                                </>
                                            )}
                                        </div>
                                        <span className="fontBold">{row.description}</span>
                                        <span className="fontBold">{row.manufacturerCode}</span>
                                        <span> Qty: {row.qty} &nbsp; Available: {getAvailableQtyForItem(row)}</span>
                                    </div>

                                    <div className="productPriceColumn">
                                        <img src={binIcon} alt="remove" className="removeItemButton" />
                                        

                                        <span className='addToRequestedProducts'>

                                            {row.availableQty > 0 ?
                                                <span className="textCenter fontBold">{renderItemDisplayedPrice(row.displayedPrice)}</span>
                                                :
                                                !isInRequestedList(item, branch.id)
                                                    ? <button
                                                        className='add-to-requested-products-btn'
                                                        onClick={() => openSubscribeForAlternativeModal(row, branch.id)}
                                                    >
                                                        <p className='item-text'>Request Product</p>
                                                    </button>
                                                    : <button
                                                        className='add-to-requested-products-btn added-to-requested-products-btn'
                                                        onClick={(e) => openSubscribeForAlternativeModal(row, branch.id)}
                                                    >
                                                        <p className='item-text'>Product Requested</p>
                                                    </button>
                                            }
                                        </span>


                                    </div>

                                </div>)
                            })}


                        </div>
                    </div>

                </div>

            </div>

            {/* END MOBILE COMPONENT - HIDDEN ON DESKTOP */}


        </>

    )
};

export default OnePriceBranchRow;
