import http from './http'

const setupRequestInterceptors = () => {
   // http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
}

const setupResponseInterceptors = (history) => {
    http.interceptors.response.use(
        (response) => response.data,
        (error) => {
            switch (error.response?.status) {
                case 401:
                    window.location.href = '/bff/login?returnUrl=/'
                    break;
                default:
                    console.log('Bad request');
            }
            return Promise.reject(error);
        }
    );
}

export default {
    setupRequestInterceptors,
    setupResponseInterceptors
};