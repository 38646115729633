import React from "react";
import FooterShopLinks from "../Footer/FooterShopLinks";

function FooterMobile(props) {
  return (
    <div className="footer_mobile">
      <svg
        width="161"
        height="52"
        viewBox="0 0 161 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.3092 35.0154L27.4416 25.6324C27.1285 24.8522 26.3918 24.345 25.5998 24.345H22.119C21.9901 24.345 21.8796 24.423 21.8059 24.54L19.7984 28.0318C19.6511 28.2854 19.8353 28.617 20.1115 28.617H24.2738L26.3918 33.767H5.28572L7.71679 28.617H10.8293C11.1055 28.617 11.2897 28.2854 11.1424 28.0318L9.13491 24.54C9.06124 24.423 8.95074 24.345 8.82182 24.345H6.46442C5.70932 24.345 5.00947 24.7936 4.65954 25.5349L0.239423 34.9179C0.0920858 35.2105 0.0184172 35.5226 0 35.8737V49.3337C0 50.5041 0.90244 51.46 2.00747 51.46H29.4674C30.5725 51.46 31.4749 50.5041 31.4749 49.3337V35.9323C31.4749 35.9127 31.4749 35.8932 31.4749 35.8737C31.4749 35.5616 31.4197 35.269 31.3092 35.0154ZM27.46 47.1879H4.03336V38.039H27.46V47.1879Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M25.2866 7.0226C25.2682 6.94457 25.2314 6.86654 25.213 6.78851C25.1209 6.55442 25.0472 6.33984 24.9367 6.10576C24.8631 5.9497 24.8078 5.81315 24.7341 5.65709C24.6605 5.52054 24.5868 5.36448 24.5131 5.22793C24.329 4.91582 24.1448 4.6037 23.9422 4.29159C23.8685 4.19405 23.7949 4.09651 23.7212 3.99898C23.6291 3.86243 23.5186 3.74538 23.4265 3.60883C23.3528 3.5113 23.2608 3.41376 23.1871 3.33573C23.1134 3.2382 23.0213 3.16017 22.9477 3.06263C21.1796 1.24846 18.7854 0.0585216 15.9676 0C15.8939 0 15.8202 0 15.7466 0C15.6729 0 15.5992 0 15.5071 0C7.79035 0.156058 3.14923 8.85627 7.05367 15.6838L11.7869 23.9744L14.2364 28.2464L15.5808 30.5873C15.6545 30.7044 15.8202 30.7044 15.8939 30.5873L17.2383 28.2464L19.6878 23.9744L24.421 15.6838C24.6973 15.2156 24.9183 14.7279 25.1209 14.2403C25.1577 14.1232 25.213 14.0257 25.2498 13.9086C25.2866 13.7916 25.3235 13.6941 25.3603 13.577C25.3971 13.46 25.434 13.3624 25.4524 13.2454C25.4892 13.1284 25.5077 13.0308 25.5445 12.9138C26.0233 10.9045 25.8944 8.87578 25.2866 7.0226ZM15.7466 14.7475C13.1682 14.7475 11.087 12.5431 11.087 9.81213C11.087 7.10063 13.1682 4.8768 15.7466 4.8768C18.3065 4.8768 20.4061 7.08112 20.4061 9.81213C20.3877 12.5431 18.3065 14.7475 15.7466 14.7475Z"
          fill="#00226A"
        />
        <path
          d="M44.2197 16.3276H47.4243V24.4231C48.5846 22.7845 50.2974 21.8677 52.1575 21.8677C55.933 21.8677 58.6772 25.0279 58.6772 30.0607C58.6772 35.1911 55.8962 38.2342 52.1575 38.2342C50.2421 38.2342 48.5662 37.2589 47.4243 35.7178V37.8441H44.2197V16.3276ZM51.1998 35.2496C53.7598 35.2496 55.3805 33.1233 55.3805 30.0802C55.3805 27.0371 53.7598 24.8718 51.1998 24.8718C49.708 24.8718 48.1242 25.8472 47.4243 26.9981V33.1624C48.1242 34.3523 49.708 35.2496 51.1998 35.2496Z"
          fill="#00226A"
        />
        <path
          d="M70.7407 35.7958C69.6725 37.0638 67.8492 38.2537 65.5286 38.2537C62.3609 38.2537 60.7954 36.5176 60.7954 33.3184V22.2773H64V32.0895C64 34.5084 65.1603 35.2496 66.9652 35.2496C68.5859 35.2496 69.9856 34.2743 70.7407 33.2209V22.2773H73.9453V37.8831H70.7407V35.7958Z"
          fill="#00226A"
        />
        <path
          d="M78.8437 41.1993C79.8567 41.1993 80.5197 40.8677 80.9433 39.8338L81.68 38.0587L75.6392 22.2773H79.0463L83.3375 33.9622L87.6287 22.2773H91.0727L83.9637 40.6922C82.9508 43.3451 81.1827 44.1839 78.899 44.2035C78.4386 44.2035 77.5545 44.1059 77.0941 43.9694L77.573 40.9262C77.9597 41.1018 78.4754 41.1993 78.8437 41.1993Z"
          fill="#00226A"
        />
        <path
          d="M94.9775 34.2546V25.2423H92.5464V22.2772H94.9775V18.0051H98.182V22.2772H101.166V25.2423H98.182V33.4158C98.182 34.4887 98.6609 35.2495 99.5817 35.2495C100.19 35.2495 100.779 34.9959 101.018 34.7033L101.773 37.2587C101.202 37.844 100.245 38.2536 98.753 38.2536C96.2851 38.2536 94.9775 36.8296 94.9775 34.2546Z"
          fill="#009FDF"
        />
        <path
          d="M103.762 22.2774H106.967V24.5792C108.072 23.0967 109.84 21.9263 111.755 21.9263V25.2815C111.479 25.223 111.147 25.184 110.742 25.184C109.398 25.184 107.611 26.1593 106.967 27.2517V37.8832H103.762V22.2774Z"
          fill="#009FDF"
        />
        <path
          d="M123.026 36.186C121.921 37.5125 120.227 38.2538 118.275 38.2538C115.862 38.2538 113.155 36.5371 113.155 33.0843C113.155 29.495 115.862 28.0125 118.275 28.0125C120.264 28.0125 121.958 28.6952 123.026 30.0217V27.7004C123.026 25.8862 121.627 24.7938 119.582 24.7938C117.943 24.7938 116.507 25.4375 115.217 26.764L113.91 24.4036C115.623 22.687 117.759 21.8872 120.098 21.8872C123.358 21.8872 126.231 23.3307 126.231 27.5443V37.8831H123.026V36.186ZM123.026 31.9529C122.29 30.88 120.927 30.3338 119.527 30.3338C117.722 30.3338 116.396 31.4262 116.396 33.1429C116.396 34.8205 117.741 35.9129 119.527 35.9129C120.927 35.9129 122.308 35.3667 123.026 34.2938V31.9529Z"
          fill="#009FDF"
        />
        <path
          d="M140.063 35.7373C138.939 37.2784 137.226 38.2537 135.329 38.2537C131.554 38.2537 128.773 35.2106 128.773 30.0802C128.773 25.0474 131.517 21.8872 135.329 21.8872C137.153 21.8872 138.866 22.7845 140.063 24.4426V16.3276H143.267V37.8636H140.063V35.7373ZM140.063 26.9786C139.326 25.8081 137.779 24.8718 136.25 24.8718C133.727 24.8718 132.106 27.0371 132.106 30.0802C132.106 33.0843 133.727 35.2496 136.25 35.2496C137.779 35.2496 139.326 34.3523 140.063 33.1819V26.9786Z"
          fill="#009FDF"
        />
        <path
          d="M153.176 21.8872C157.596 21.8872 160.432 25.4765 160.432 30.3728V31.1726H149.069C149.308 33.533 150.984 35.4642 153.765 35.4642C155.202 35.4642 156.914 34.8595 157.946 33.7476L159.401 35.9714C157.946 37.454 155.773 38.2343 153.434 38.2343C149.014 38.2343 145.717 34.9766 145.717 30.0412C145.698 25.5351 148.811 21.8872 153.176 21.8872ZM149.032 28.7928H157.32C157.264 26.9591 156.067 24.6572 153.176 24.6572C150.432 24.6572 149.179 26.8811 149.032 28.7928Z"
          fill="#009FDF"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="30.5117"
            y1="56.1919"
            x2="0.989186"
            y2="27.3787"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.000250764" stop-color="#009FDF" />
            <stop offset="0.4349" stop-color="#009DDD" />
            <stop offset="0.6438" stop-color="#0098D5" />
            <stop offset="0.8045" stop-color="#008FC8" />
            <stop offset="0.9398" stop-color="#0082B6" />
            <stop offset="1" stop-color="#007AAB" />
          </linearGradient>
        </defs>
      </svg>
      <p className="footer_list_title">SHOP</p>
      <ul className="footer_list">
        <FooterShopLinks disableBuyTradeCheckout={props.disableBuyTradeCheckout} wrapperElementName={"li"} />
        <li>
          <a href="http://buytrade.co.uk/delivery-and-returns-information/" target="_blank" rel="noreferrer">
            Delivery and Returns Information
          </a>
        </li>
        <li>
          <a
            href="https://buytrade.co.uk/electronic-payment-order-terms-and-conditions/"
            target="_blank"
            rel="noreferrer"
          >
            Electronic Payment Order Terms
          </a>
        </li>
        <li>
          <a
            href="https://buytrade.co.uk/our-partners/"
            target="_blank"
            rel="noreferrer"
          >
            Our Partners
          </a>
        </li>
      </ul>
      <p className="footer_list_title">CONTACT US</p>
      <ul className="footer_list">
        <li>
          <a href="tel:01978 666 888">01978 666 888</a>
        </li>
        <li>
          <a href="mailto:info@buytrade.co.uk">info@buytrade.co.uk</a>
        </li>
      </ul>

      <p className="mobile_footer_slogan">Right Product, Right Price, </p>
      <p className="mobile_footer_slogan" style={{ margin: "-10px 0 20px 0" }}>
        Right Now.
      </p>

      <p
        className=" mobile_footer_slogan tablet_footer_slogan"
        style={{ margin: "-10px 0 20px 0" }}
      >
        Right Product, Right Price, Right Now.
      </p>

      <div className="footer_bottom">
        <p style={{ marginTop: 18 }}>Copyright © 2023 buytrade.co.uk</p>
        <p style={{ margin: 0 }}>All Rights Reserved</p>

        <p style={{ marginTop: 18 }} className="copyright_tablet">
          Copyright © {new Date().getFullYear()} buytrade.co.uk All Rights
          Reserved
        </p>

        <div className="mobile_footer_bottom_links" style={{ marginTop: 12 }}>
          <a
            href="http://buytrade.co.uk/terms-and-conditions-of-use/"
            rel="noreferrer"
            target="_blank"
          >
            Terms of Use
          </a>
          <span>|</span>
          <a href="http://buytrade.co.uk/privacy-policy/" target="_blank"             rel="noreferrer">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
}

export default FooterMobile;
