/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import ProductSearchService from "../../Services/ProductSearchService";
import { isMobile } from "../Media/Media";

const useStyles = makeStyles(() => ({
    [`@media (max-width: 760px)`]: {
        modal: {
            overflowY: "auto",
            maxHeight: "90vh",
            width: "90vw",
            left: "calc(50% - 45vw)",
            top: 20,
            padding: "10px 20px;",
        },
        scrollbox: {
            overflowY: "auto",
            position: 'relative',
            padding: "0 10px 10px 10px",
            marginTop: 40,
            height: '80vh',
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: '#E6EEF1',
                borderRadius: 6,
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: 'transparent',
            },
            "&::-webkit-scrollbar": {
                backgroundColor: 'transparent',
                width: 9,
            }
        },
        item: {
            display: "grid",
            gridTemplateColumns: "1fr auto",
            padding: 10,
            borderTop: "1px solid #BACAD1",
            fontSize: 14,
        },
        productDescription: {
            display: "grid",
            gridTemplateColumns: "1fr auto",
            backgroundColor: "#F0F5F8",
            marginTop: 25,
            padding: 10,
            fontSize: 12,
            textAlign: "center"
        },
        headerText: {
            fontSize: 20,
            color: "#009FDF",
            padding: 10,
        },
        closeModalBtn: {
            position: 'absolute',
            right: 30,
            top: 30,
            cursor: 'pointer',
        },
    },
    [`@media (min-width: 760px)`]: {

        modal: {
            overflowY: "hidden",
            maxHeight: "90vh",
            width: "50vw",
            left: "calc(50% - 25vw)",
            top: "5%",
        },
        scrollbox: {
            overflowY: "auto",
            position: 'relative',
            padding: "0 30px 20px 30px",
            marginTop: 30,
            height: '80vh',
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: '#E6EEF1',
                borderRadius: 6,
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: 'transparent',
            },
            "&::-webkit-scrollbar": {
                backgroundColor: 'transparent',
                width: 9,
            }
        },
        closeModalBtn: {
            position: 'absolute',
            right: 30,
            top: 0,
            cursor: 'pointer',
        },
        item: {
            display: "grid",
            gridTemplateColumns: "1fr 150px auto",
            padding: "18px 30px",
            borderTop: "1px solid #BACAD1",
            fontSize: 14,
        },
        productDescription: {
            display: "grid",
            gridTemplateColumns: "1fr 150px auto",
            backgroundColor: "#F0F5F8",
            marginTop: 55,
            padding: "18px 30px",
            fontSize: 14,
        },
        headerText: {
            fontSize: 26,
            color: "#009FDF",
            padding: "25px 15px 15px 15px",
        },
    },
    button: {
        paddingBottom: 0,
        borderRadius: "6px",
        marginLeft: "10px",
        color: "#009FDF !important",
        textDecoration: "underline",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        fontSize: "12px",
        width: "100%",
        margin: "0px !important",
    },
    modalHeader: {
        textAlign: "left",
    },
    manualsWrapper: {
        "&:hover": {
            textDecoration: "none",
        },
        color: "#009fdf",
        borderBottom: "1.5px solid #009fdf",
    },
    itemManMobile: {
        textAlign: "left",
        fontWeight: '500',
        fontSize: 12,
        margin: 0,
        padding: 0,
    },
    itemGCMobile: {
        textAlign: "left",
        fontSize: 12,
        margin: 0,
        padding: 0,
    },
    buttonWrapperMobile: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& a": {
            fontSize: 12,
            whiteSpace: "nowrap",
            marginLeft: 10,
        }
    }
}));

const PartFits = (props) => {
    const { product, className } = props;
    const classes = useStyles();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [partFits, setPartFits] = useState([]);
    const [manufacturer, setManufacturer] = useState('');

    const getPartFits = (manid, mpn) => {
        return ProductSearchService.partFits(manid, mpn);
    };

    const onShowModal = () => {
        setIsModalOpen(true);
        getPartFits(product.manid, product.mpn).then((response) => {
            if (response) {
                setPartFits(response);
                setManufacturer(response[0].man);
            }
        });
    };

    const onCloseModal = () => {
        setIsModalOpen(false);
    };

    const renderPopup = () => {
        return (
            <div className="pop-up">
                {partFits.map(({ man, app, appgc, appid }, index) => isMobile() ? (
                    <div key={index} className={classes.item}>
                        <div>
                            <p className={classes.itemManMobile}>{man} {app}</p>
                            <p className={classes.itemGCMobile}>GCN: {appgc}</p>
                        </div>
                        <div className={classes.buttonWrapperMobile}>
                            <a
                                data-action="view-manual"
                                className={classes.manualsWrapper}
                                href={`/ProductSearch/ApplianceDocument?applianceId=${appid}`}
                                target="_blank"
                                style={{ fontWeight: '500' }}
                            >
                                View Manual
                            </a>
                        </div>
                    </div>
                ) : (
                    <div key={index} className={classes.item}>
                        <span style={{ textAlign: "left", fontWeight: '500' }}>{man} {app}</span>
                        <span>GCN: {appgc}</span>
                        <a
                            data-action="view-manual"
                            className={classes.manualsWrapper}
                            href={`/ProductSearch/ApplianceDocument?applianceId=${appid}`}
                            target="_blank"
                            style={{ fontWeight: '500' }}
                        >
                            View Manual
                        </a>
                    </div>
                ))}
            </div>
        );
    };

    const getProductGCN = (product) => {
        return product.appgc
    }

    return (
        <>
            <div className={className}>
                {isModalOpen && (
                    <>
                        <div
                            className="modal-overlay"
                            style={{ zIndex: 1000, background: "#00000033" }}
                        />
                        <div className={`dropdown_select_bar_modal ${classes.modal}`}>
                            <div className={classes.scrollbox}>
                                <p style={{ textAlign: 'left' }}>{product.description}</p>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg" className={classes.closeModalBtn}
                                    onClick={onCloseModal}>
                                    <path d="M1 19L19 1M19 19L1 1" stroke="#007AAB" />
                                </svg>
                                {isMobile() ? (
                                    <div className={classes.modalHeader}>
                                        <div className={classes.productDescription}>
                                            <div>
                                                <p className={classes.itemManMobile}>
                                                    {manufacturer} {product.app}
                                                </p>
                                                <p
                                                    className={classes.itemGCMobile}>{"GCN:"} {getProductGCN(product)}</p>
                                            </div>
                                            <div className={classes.buttonWrapperMobile}>
                                                <a
                                                    data-action="view-manual"
                                                    className={classes.manualsWrapper}
                                                    href={`/ProductSearch/ApplianceDocument?applianceId=${product.appid}`}
                                                    target="_blank"
                                                    style={{ fontWeight: '500' }}
                                                >
                                                    View Manual
                                                </a>
                                            </div>
                                        </div>
                                        <h1 className={classes.headerText}>This part also fits...</h1>
                                    </div>
                                ) : (
                                    <div className={classes.modalHeader}>
                                        <div className={classes.productDescription}>
                                            <span
                                                style={{ fontWeight: '500' }}>{manufacturer} {product.app}{" "}</span>

                                                {!!product.appid &&
                                                    <>
                                                        <span style={{ textAlign: "center" }}>{"GCN:"} {getProductGCN(product)}</span>
                                                        <a data-action="view-manual"
                                                           className={classes.manualsWrapper}
                                                           href={`/ProductSearch/ApplianceDocument?applianceId=${product.appid}`}
                                                           target="_blank"
                                                           style={{ fontWeight: '500' }}>
                                                            View Manual
                                                        </a>
                                                    </>
                                                }
                                            
                                        </div>
                                        <h1 className={classes.headerText}>This part also fits...</h1>
                                    </div>
                                )}
                                {isModalOpen && renderPopup()}
                            </div>
                        </div>
                    </>
                )}
                {(product.mpn && product.manid) && <a className={classes.button} onClick={onShowModal}>
                    {"This parts fits..."}
                </a>}
            </div>
        </>
    );
};

export default PartFits;
