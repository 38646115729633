import http from '../utils/http';

const searchLikedProducts = (searchText, sortDir, sortProp) => {
    const formData = new FormData();
    if(searchText) {
        formData.append('searchText', searchText);
    }
    if(sortDir){
        formData.append('sortDir', sortDir);
    }
    if(sortProp){
        formData.append('sortProp', sortProp)
    }

    return http.post(`LikedProduct/SearchLikedProducts`, formData , { headers: {
        "Content-Type": "multipart/form-data"
    }});
}

const toggle = (manufacturerPartNumber, description, imageUrl, iBasisManId, iBasisAppliance, iBasisApplianceId, iBasisGCN, iBasisApplianceGC, cost, man, itemId = null) => {
    const params = new URLSearchParams();
    params.append('manufacturerPartNumber', manufacturerPartNumber);
    params.append('description', description);
    params.append('imageUrl', imageUrl);
    params.append('iBasisManId', iBasisManId ?? '');
    params.append('iBasisAppliance', iBasisAppliance ?? '');
    params.append('iBasisApplianceId', iBasisApplianceId ?? '');
    params.append('iBasisGCN', iBasisGCN ?? '');
    params.append('iBasisApplianceGC', iBasisApplianceGC ?? '');
    params.append('cost', cost);
    params.append('man', man ?? '');
    params.append('itemId', itemId ?? '');

    return http.get(`LikedProduct/Toggle`, { params })
}


export default {
    searchLikedProducts,
    toggle
}