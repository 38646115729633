import React from "react";
import { Link } from "react-router-dom";
import FooterShopLinks from "../Footer/FooterShopLinks";

class FooterBar extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer_top max-width-1300">
          <div className="footer_first_column">
            <Link to="/">
              <svg
                width="160"
                height="51"
                viewBox="0 0 161 53"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.3092 35.7318L27.4416 26.3489C27.1285 25.5686 26.3918 25.0614 25.5998 25.0614H22.119C21.9901 25.0614 21.8796 25.1394 21.8059 25.2565L19.7984 28.7483C19.6511 29.0019 19.8353 29.3335 20.1115 29.3335H24.2738L26.3918 34.4834H5.28572L7.71679 29.3335H10.8293C11.1055 29.3335 11.2897 29.0019 11.1424 28.7483L9.13491 25.2565C9.06124 25.1394 8.95074 25.0614 8.82182 25.0614H6.46442C5.70932 25.0614 5.00947 25.5101 4.65954 26.2513L0.239423 35.6343C0.0920858 35.9269 0.0184172 36.239 0 36.5902V50.0501C0 51.2206 0.90244 52.1764 2.00747 52.1764H29.4674C30.5725 52.1764 31.4749 51.2206 31.4749 50.0501V36.6487C31.4749 36.6292 31.4749 36.6097 31.4749 36.5902C31.4749 36.278 31.4197 35.9854 31.3092 35.7318ZM27.46 47.9043H4.03336V38.7555H27.46V47.9043Z"
                  fill="url(#paint0_linear)"
                />
                <path
                  d="M25.2866 7.73903C25.2682 7.661 25.2314 7.58297 25.213 7.50494C25.1209 7.27085 25.0472 7.05627 24.9367 6.82219C24.8631 6.66613 24.8078 6.52958 24.7341 6.37352C24.6605 6.23697 24.5868 6.08091 24.5131 5.94436C24.329 5.63225 24.1448 5.32013 23.9422 5.00802C23.8685 4.91048 23.7949 4.81295 23.7212 4.71541C23.6291 4.57886 23.5186 4.46182 23.4265 4.32527C23.3528 4.22773 23.2608 4.13019 23.1871 4.05216C23.1134 3.95463 23.0213 3.8766 22.9477 3.77906C21.1796 1.96489 18.7854 0.774952 15.9676 0.716431C15.8939 0.716431 15.8202 0.716431 15.7466 0.716431C15.6729 0.716431 15.5992 0.716431 15.5071 0.716431C7.79035 0.872488 3.14923 9.57271 7.05367 16.4002L11.7869 24.6908L14.2364 28.9629L15.5808 31.3037C15.6545 31.4208 15.8202 31.4208 15.8939 31.3037L17.2383 28.9629L19.6878 24.6908L24.421 16.4002C24.6973 15.9321 24.9183 15.4444 25.1209 14.9567C25.1577 14.8397 25.213 14.7421 25.2498 14.6251C25.2866 14.508 25.3235 14.4105 25.3603 14.2935C25.3971 14.1764 25.434 14.0789 25.4524 13.9618C25.4892 13.8448 25.5077 13.7472 25.5445 13.6302C26.0233 11.621 25.8944 9.59221 25.2866 7.73903ZM15.7466 15.4639C13.1682 15.4639 11.087 13.2596 11.087 10.5286C11.087 7.81706 13.1682 5.59323 15.7466 5.59323C18.3065 5.59323 20.4061 7.79755 20.4061 10.5286C20.3877 13.2596 18.3065 15.4639 15.7466 15.4639Z"
                  fill="#00226A"
                />
                <path
                  d="M44.2195 17.0439H47.4241V25.1394C48.5843 23.5008 50.2971 22.584 52.1573 22.584C55.9328 22.584 58.677 25.7442 58.677 30.777C58.677 35.9074 55.896 38.9505 52.1573 38.9505C50.2419 38.9505 48.5659 37.9752 47.4241 36.4341V38.5604H44.2195V17.0439ZM51.1996 35.9659C53.7596 35.9659 55.3803 33.8397 55.3803 30.7965C55.3803 27.7534 53.7596 25.5881 51.1996 25.5881C49.7078 25.5881 48.1239 26.5635 47.4241 27.7144V33.8787C48.1239 35.0686 49.7078 35.9659 51.1996 35.9659Z"
                  fill="#00226A"
                />
                <path
                  d="M70.7402 36.5121C69.672 37.7801 67.8487 38.9701 65.5281 38.9701C62.3604 38.9701 60.7949 37.2339 60.7949 34.0347V22.9937H63.9995V32.8058C63.9995 35.2247 65.1598 35.9659 66.9647 35.9659C68.5854 35.9659 69.9851 34.9906 70.7402 33.9372V22.9937H73.9448V38.5994H70.7402V36.5121Z"
                  fill="#00226A"
                />
                <path
                  d="M78.8435 41.9157C79.8564 41.9157 80.5195 41.584 80.9431 40.5501L81.6797 38.775L75.6389 22.9937H79.0461L83.3373 34.6785L87.6285 22.9937H91.0725L83.9635 41.4085C82.9505 44.0614 81.1825 44.9003 78.8988 44.9198C78.4383 44.9198 77.5543 44.8222 77.0939 44.6857L77.5727 41.6426C77.9595 41.8181 78.4752 41.9157 78.8435 41.9157Z"
                  fill="#00226A"
                />
                <path
                  d="M94.9772 34.9711V25.9587H92.5461V22.9936H94.9772V18.7216H98.1818V22.9936H101.165V25.9587H98.1818V34.1323C98.1818 35.2051 98.6606 35.9659 99.5815 35.9659C100.189 35.9659 100.779 35.7123 101.018 35.4197L101.773 37.9752C101.202 38.5604 100.245 38.97 98.7527 38.97C96.2848 38.97 94.9772 37.546 94.9772 34.9711Z"
                  fill="#009FDF"
                />
                <path
                  d="M103.762 22.9937H106.967V25.2956C108.072 23.813 109.84 22.6426 111.755 22.6426V25.9978C111.479 25.9393 111.147 25.9003 110.742 25.9003C109.398 25.9003 107.611 26.8756 106.967 27.968V38.5995H103.762V22.9937Z"
                  fill="#009FDF"
                />
                <path
                  d="M123.026 36.9022C121.921 38.2287 120.227 38.9699 118.275 38.9699C115.862 38.9699 113.155 37.2533 113.155 33.8005C113.155 30.2112 115.862 28.7287 118.275 28.7287C120.264 28.7287 121.958 29.4114 123.026 30.7379V28.4165C123.026 26.6024 121.627 25.51 119.582 25.51C117.943 25.51 116.507 26.1537 115.217 27.4802L113.91 25.1198C115.623 23.4032 117.759 22.6034 120.098 22.6034C123.358 22.6034 126.231 24.0469 126.231 28.2605V38.5993H123.026V36.9022ZM123.026 32.6691C122.29 31.5962 120.927 31.05 119.527 31.05C117.722 31.05 116.396 32.1424 116.396 33.8591C116.396 35.5367 117.741 36.6291 119.527 36.6291C120.927 36.6291 122.308 36.0829 123.026 35.01V32.6691Z"
                  fill="#009FDF"
                />
                <path
                  d="M140.062 36.4536C138.939 37.9947 137.226 38.9701 135.329 38.9701C131.554 38.9701 128.773 35.9269 128.773 30.7965C128.773 25.7637 131.517 22.6035 135.329 22.6035C137.153 22.6035 138.865 23.5008 140.062 25.1589V17.0439H143.267V38.5799H140.062V36.4536ZM140.062 27.6949C139.326 26.5245 137.779 25.5881 136.25 25.5881C133.727 25.5881 132.106 27.7534 132.106 30.7965C132.106 33.8006 133.727 35.9659 136.25 35.9659C137.779 35.9659 139.326 35.0686 140.062 33.8982V27.6949Z"
                  fill="#009FDF"
                />
                <path
                  d="M153.175 22.6034C157.595 22.6034 160.432 26.1927 160.432 31.089V31.8888H149.068C149.308 34.2492 150.984 36.1804 153.765 36.1804C155.201 36.1804 156.914 35.5757 157.945 34.4638L159.4 36.6876C157.945 38.1702 155.772 38.9504 153.433 38.9504C149.013 38.9504 145.716 35.6927 145.716 30.7574C145.698 26.2512 148.81 22.6034 153.175 22.6034ZM149.031 29.509H157.319C157.264 27.6753 156.067 25.3734 153.175 25.3734C150.431 25.3734 149.179 27.5972 149.031 29.509Z"
                  fill="#009FDF"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="30.5117"
                    y1="56.9084"
                    x2="0.989186"
                    y2="28.0951"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.000250764" stopColor="#009FDF" />
                    <stop offset="0.4349" stopColor="#009DDD" />
                    <stop offset="0.6438" stopColor="#0098D5" />
                    <stop offset="0.8045" stopColor="#008FC8" />
                    <stop offset="0.9398" stopColor="#0082B6" />
                    <stop offset="1" stopColor="#007AAB" />
                  </linearGradient>
                </defs>
              </svg>
            </Link>
            <div className="footer_slogan">
              <p className="footer_title">Right Product,</p>
              <p className="footer_title">Right Price,</p>
              <p className="footer_title">Right Now.</p>
            </div>
          </div>
          <div className="footer_second_column">
            <p className="footer_list_title">SHOP</p>
            <ul className="footer_list">
              <FooterShopLinks disableBuyTradeCheckout={this.props.disableBuyTradeCheckout} wrapperElementName={"li"} />
            </ul>
          </div>
          <div className="footer_third_column">
            <p className="footer_list_title">INFORMATION</p>
            <ul className="footer_list">
              <li>
                <a
                  href="https://buytrade.co.uk/buytrade-faqs/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Frequently Asked Questions
                </a>
              </li>
              <li>
                <a
                  href="http://buytrade.co.uk/terms-and-conditions-of-use/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>
              </li>
              <li>
                <a
                  href="https://buytrade.co.uk/electronic-payment-order-terms-and-conditions/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Electronic Payment Order Terms
                </a>
              </li>
              <li>
                <a
                  href="http://buytrade.co.uk/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy and Cookie Policy
                </a>
              </li>
              <li>
                <a
                  href="http://buytrade.co.uk/delivery-and-returns-information/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Delivery and Returns Information
                </a>
              </li>
              <li>
                <a
                  href="https://buytrade.co.uk/our-partners/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Our Partners
                </a>
              </li>
            </ul>
          </div>
          <div>
            <p className="footer_list_title">CONTACT US</p>
            <ul className="footer_list">
              <li>
                <a href="tel:01978 666 888">01978 666 888</a>
              </li>
              <li>
                <a href="mailto:info@buytrade.co.uk">info@buytrade.co.uk</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer_bottom max-width-1300">
          <p>
            Copyright © {new Date().getFullYear()} buytrade.co.uk All Rights
            Reserved{" "}
          </p>
        </div>
      </footer>
    );
  }
}

export default FooterBar;
