import React, { useState } from "react";
import BasketService from "../../Services/BasketService";
import toastr from "toastr";
import ModalComp from '../Modal/ModalComp';
import AddressAutocomplete from "../Form/AddressAutocomplete";
import TextBoxWithLabel from "../Form/TextBoxWithLabel";
import { isMobile, isTablet } from "../Media/Media";
import InDayDeliveryImage from "../../assets/img/in-day-delivery.svg";

function DeliveryQuoteModal({ onCloseModal, selectedBranch, onQuoteAccepted }) {
    const [deliveryAddress1, setDeliveryAddress1] = useState("");
    const [deliveryPersonNameError, setDeliveryPersonNameError] = useState(false);
    const [deliveryMobileNumberError, setDeliveryMobileNumberError] = useState(false);
    const [deliveryAddress2, setDeliveryAddress2] = useState("");
    const [deliveryTown, setDeliveryTown] = useState("");
    const [deliveryPostCode, setDeliveryPostCode] = useState("");
    const [deliveryPersonName, setDeliveryPersonName] = useState("");
    const [deliveryMobileNumber, setDeliveryMobileNumber] = useState("");
    const [addressDetails, setAddressDetails] = useState(true);
    const [selectedDeliveryType, setSelectedDeliveryType] = useState(null);
    const [availableDeliveryType, setAvailableDeliveryType] = useState(false);
    const [deliveries, setDeliveries] = useState([]);
    const [addressError, setAddressError] = useState(false);

    const getDeliveryQuote = async (deliveryDeadline, deliveryProviderTypeId) => {

        return await BasketService.getDeliveryQuote({
            deliveryMinutes: deliveryDeadline,
            branch: selectedBranch.branch,
            deliveryAddress1: deliveryAddress1,
            deliveryAddress2: deliveryAddress2,
            deliveryPostCode: deliveryPostCode,
            deliveryTown: deliveryTown,
            deliveryProviderTypeId: deliveryProviderTypeId
        })
    }

    const addDeliveryQuote = () => {
        if (!deliveryPersonName) setDeliveryPersonNameError(true);
        if (!deliveryMobileNumber) setDeliveryMobileNumberError(true);

        if (!selectedDeliveryType || !deliveryPersonName || !deliveryMobileNumber) return;
        const phoneNumberValidationRule = /^(07[\d]{9}|447[\d]{9})$/

        const isValidPhoneNumber = phoneNumberValidationRule.test(deliveryMobileNumber)
        if (!isValidPhoneNumber) {
            toastr.error('Please enter a valid mobile number.')
            return
        }

        BasketService.addDeliveryQuote({
            ...selectedDeliveryType,
            branch: selectedBranch.branch,
            deliveryAddress1: deliveryAddress1,
            deliveryAddress2: deliveryAddress2,
            deliveryPostCode: deliveryPostCode,
            deliveryTown: deliveryTown,
            deliveryPersonName: deliveryPersonName,
            deliveryMobileNumber: deliveryMobileNumber
        }).then((response) => {
            if (response) {
                onQuoteAccepted()
            }

        }).catch(() => {
            toastr.error('Unable to process your request, please try again later.')
        });
    }

    const handleDeliveryAddressChange = (obj) => {
        setAddressError(false);
        if (obj) {
            setDeliveryAddress1(obj.address[0]);
            if (obj.address.length > 1)
                setDeliveryAddress2(obj.address[1]);
            setDeliveryTown(obj.city);
            setDeliveryPostCode(obj.postcode)
        }
        else {
            setDeliveryAddress1("")
            setDeliveryAddress2("")
            setDeliveryTown("")
            setDeliveryPostCode("")
        }
    }

    const handleDeliveryPersonNameChange = (e) => {
        setDeliveryPersonName(e);
        setDeliveryPersonNameError(false);
    }

    const handleDeliveryMobileNumberChange = (e) => {
        setDeliveryMobileNumber(e);
        setDeliveryMobileNumberError(false);
    }

    const moveNext = async () => {
        if (!deliveryAddress1 ||
            !deliveryPostCode ||
            !deliveryTown)
            setAddressError(true)
        else {
            let deliveryTypes = [], deliveryMinutes = [90, 120, 180]
            for (let branchSameDayDeliveryType of selectedBranch.branch.branchSameDayDeliveryTypes) {
                let priceList = []
                await Promise.all(deliveryMinutes.map(async deliveryMinute =>
                    await getDeliveryQuote(deliveryMinute, branchSameDayDeliveryType.id).then(response =>
                        priceList.push({
                            cost: response.priceNet.toFixed(2),
                            imageUrl: branchSameDayDeliveryType.imageUrl,
                            deliveryMinutes: deliveryMinute,
                            manufacturer: branchSameDayDeliveryType.name,
                            description: branchSameDayDeliveryType.description,
                            iBasisAppliance: 'DELIVERY IN ' + (deliveryMinute % 60 !== 0 ? deliveryMinute + ' MINUTES' : deliveryMinute / 60 + ' HOURS'),
                            deliveryProviderTypeId: branchSameDayDeliveryType.id,
                            index: "id" + Math.random().toString(16).slice(2)
                        })
                    )))
                priceList = priceList.sort((p2, p1) => p2.deliveryMinutes - p1.deliveryMinutes)
                deliveryTypes.push(priceList)
            }
            setDeliveries(deliveryTypes)
            setAddressDetails(false);

            const costInsideLimits = element => {
                return parseFloat(element.cost) <= 30;
            }

            for (var i = 0; i < deliveryTypes.length; i++) {
                if (deliveryTypes[i].length > 0 && deliveryTypes[i].some(costInsideLimits)) {
                    setAvailableDeliveryType(true);
                    break;
                }
            }
        }
    }

    const selectDeliveryType = delivery => {
        if (delivery && delivery.cost > 0)
            setSelectedDeliveryType(delivery);
    }

    const disableButton = !selectedDeliveryType || !availableDeliveryType || !deliveryPersonName || !deliveryMobileNumber;

    return (
        <ModalComp style={{ width: '100%' }} onClose={onCloseModal}>
            <div className="delivery-modal-container">
                <div className="delivery-modal-header">
                    <img src={InDayDeliveryImage} alt="" />
                    <span>EXPRESS SAME-DAY DELIVERY</span>
                </div>
                {addressDetails == true ? <>

                    <p className="delivery-description">Please complete your delivery details below to obtain a same-day delivery quote where available.</p>

                    <div style={{ marginTop: 50 }}>
                        <div className="row">
                            <div className={["col-md-12", 'delivery-address-search'].join(' ')}>
                                <AddressAutocomplete
                                    onChange={handleDeliveryAddressChange}
                                    showChangeAddressButton={false}
                                    labelText="DELIVERY TO:"
                                    placeholderText="Start typing address..."
                                    addressError={addressError}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: 40 }}>
                        <div className="row">
                            <div className="col-md-12">
                                <button
                                    type="button"
                                    className="btn group__btn button-large button-light-blue"
                                    onClick={moveNext}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </> : <>
                    {deliveries.length > 0 && availableDeliveryType ?
                            <>
                            <p style={{ marginTop: 10 }}>Please select your preferred delivery window below:</p>

                            {typeof deliveries[0] !== "undefined" && availableDeliveryType && <img className="delivery-manufacturer-image" src={deliveries[0][0].imageUrl} style={{ width: 30, height: 30 }} alt="" />}
                            <div className="delivery-type-container">
                                {typeof deliveries[0] !== "undefined" && deliveries[0].map(delivery =>
                                (parseFloat(delivery.cost) <= 30 &&
                                    <div onClick={() => selectDeliveryType(delivery)} className={["delivery-manufacturer-box", delivery.index === selectedDeliveryType?.index ? "selected" : ""].join(' ')}>
                                        <p>Delivery {isMobile() && !isTablet() ? null : <br />} in <b>{delivery.deliveryMinutes % 60 !== 0 ? delivery.deliveryMinutes + ' minutes' : delivery.deliveryMinutes / 60 + ' hours'} </b></p>
                                        <p className="delivery-cost">£{delivery.cost} <span style={{ color: delivery.index === selectedDeliveryType?.index ? '#FFF' : '#828282' }}> (plus VAT) </span></p>
                                    </div>
                                )

                                )}
                            </div>

                            {typeof deliveries[1] !== "undefined" && availableDeliveryType && <img className="delivery-manufacturer-image" src={deliveries[1][0].imageUrl} width="50" style={{ width: 100, height: 40 }} alt="" />}
                            <div className="delivery-type-container">
                                {typeof deliveries[1] !== "undefined" && deliveries[1].map(delivery =>
                                (parseFloat(delivery.cost) <= 30 &&
                                    <div onClick={() => selectDeliveryType(delivery)} className={["delivery-manufacturer-box", delivery.index === selectedDeliveryType?.index ? "selected" : ""].join(' ')}>
                                        <p>Delivery {isMobile() && !isTablet() ? null : <br />} in <b>{delivery.deliveryMinutes % 60 !== 0 ? delivery.deliveryMinutes + ' minutes' : delivery.deliveryMinutes / 60 + ' hours'} </b></p>
                                        <p className="delivery-cost">£{delivery.cost} <span style={{ color: delivery.index === selectedDeliveryType?.index ? '#FFF' : '#828282' }}> (plus VAT) </span></p>
                                    </div>
                                )
                                )}
                            </div>

                            {selectedDeliveryType && <div className="row" style={{ marginTop: 30/* , height: 70 */ }}>
                                <div className="col-md-6">
                                    <TextBoxWithLabel
                                        labelClassName={isMobile() ? 'customer-label account-label' : 'customer-label'}
                                        addCssClass={`group__row ${deliveryPersonNameError ? "group__row__error " : ""}`}
                                        label="DELIVERY NAME:"
                                        placeholder="Enter delivery name..."
                                        value={deliveryPersonName}
                                        onChange={handleDeliveryPersonNameChange}
                                        required
                                    />
                                </div>

                                <div className="col-md-6">
                                    <TextBoxWithLabel
                                        labelClassName={isMobile() ? 'customer-label account-label' : 'customer-label'}
                                        addCssClass={`group__row ${deliveryMobileNumberError ? "group__row__error " : ""}`}
                                        label="MOBILE NUMBER:"
                                        placeholder="Enter mobile number..."
                                        value={deliveryMobileNumber}
                                        onChange={handleDeliveryMobileNumberChange}
                                        required
                                        type="tel"
                                    />
                                </div>
                            </div>}

                            <div style={{ marginTop: selectedDeliveryType ? 0 : 30 }}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <button
                                            type="button"
                                            className="btn group__btn button-large button-light-blue"
                                            disabled={disableButton}
                                            onClick={addDeliveryQuote}
                                        >
                                            Continue to checkout
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <p>Please note: we pass on courier delivery charges at cost in order to maintain the competitive price of the products available.</p>
                        </>
                        :
                        <>
                            <p className="delivery-description">No Same-Day Delivery options are available for your chosen delivery address.</p>
                        </>
                    }
                </>
                }
            </div>
        </ModalComp>);
}

export default DeliveryQuoteModal;