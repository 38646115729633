import http from '../utils/http';

const GetAllClassifications = () => {
    const params = new URLSearchParams();
    return http.get(`ItemClassification/GetData`, { params });
}

const GetData = (classificationId = '', skip = 0, take = 50, selectedAttributes = [], sortDir, sortProp) => {
    return http.post(`Item/SearchItems`,
        {
            classificationId,
            skip,
            take,
            selectedAttributes,
            sortDir,
            sortProp
        });
}

export default {
    GetAllClassifications,
    GetData
}