import http from '../utils/http'

const ObsoleteProductEmail = (obsoleteProductEmailRequest, scenario) => {
    return http.post(`SparesAvailability/ObsoleteProductEmail`, {
        ...obsoleteProductEmailRequest,
        type: scenario
    });
}

const JoinBuyingGroupEmail = () => {
    return http.post(`SparesAvailability/BuyingGroupEmail`);
}

export default {
    ObsoleteProductEmail,
    JoinBuyingGroupEmail
};