import http from '../utils/http';


const getCreditNoteByBasketId = (basketId) => {
    return http.get(`PaymentInvoiceCreditNote/GetByBasketId?basketId=${basketId}`);
}


export default {
    getCreditNoteByBasketId
}
