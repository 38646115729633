import React from 'react';
import { Breadcrumbs, Grid, Link, Stack } from '@mui/material';
import Diagram from "./Diagram/Diagram";
import CategoryButton from "./CategoryButton/CategoryButton";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


export default function SearchByDiagram({
    diagrams,
    selectedCategory,
    handleSelectCategory,
    handleDeselectCategory,
    results,
    searchResultsList,
    selectedDiagram,
    toggleLike
}) {

    function getNumber(desc) {
        const splitDescription = desc.split(" ");
        const number = splitDescription.pop();
        return number;
    }

    function getDescription(description) {
        const splitDesc = description.split(" ");
        splitDesc.splice(splitDesc.length - 2, 2);
        const joinDesc = splitDesc.join(" ");
        const words = joinDesc.toLowerCase().split(' ');
        const titleCaseWords = words.map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
        const titleCaseString = titleCaseWords.join(' ');
        return titleCaseString;
    }

    return (
        <Grid container spacing={5} py={8} px={2}>

            <Grid item xs={12} md={6}>
                <Diagram imgUrl={selectedDiagram?.diagram_url} />
            </Grid>
            <Grid item xs={12} md={6}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="medium" />}
                    aria-label="breadcrumb"
                    color="#BACAD1"
                    fontSize={14}
                    mb={2}
                >
                    <Link
                        color="#BACAD1"
                        underline="hover"
                        onClick={handleDeselectCategory}
                    >
                        {selectedDiagram?.man} {selectedDiagram?.app}
                    </Link>
                    {selectedDiagram?.desc &&
                        <Link
                            color="#BACAD1"
                            underline="hover"
                        >
                            {selectedDiagram?.desc}
                        </Link>}
                </Breadcrumbs>

                {results ? <Stack spacing={1.5} direction="column">
                    <CategoryButton
                        title={diagrams[0]?.desc}
                        onSelect={() => { handleSelectCategory(diagrams[0]) }}
                    />
                    {diagrams.slice(1).map((item) => {
                        return (
                            <CategoryButton
                                isActive={item.pnum === selectedCategory}
                                key={item.pnum}
                                title={item.desc}
                                number={''}
                                toggleLike={toggleLike}
                                onSelect={() => { handleSelectCategory(item) }}
                            />
                        )
                    })}
                </Stack> : <>{searchResultsList}</>}

             
            </Grid>
        </Grid>
    );
};