import React from "react";
import FilterCommonFieldComp from "../General/FilterCommonFieldComp";
import ProductCard from "../Shop/ProductCard/ProductCard";
import SortDropDown from "../Layout/SortDropDown";
import LikedProductService from "../../Services/LikedProductService";
import ProductSearchService from "../../Services/ProductSearchService";
import { isMobile, isTablet } from "../Media/Media";
import ObsoleteProduct from "./ObsoleteProduct";
import isPlural from "../../Helpers/common/isPlural";
import SearchResultsHeader from "./SearchResultsHeader";
import SearchResultsList from "./SearchResultsList";

const SELECT_VALUES = [
  { sortDir: "asc", text: "Product Name - A-Z", sortProp: "Description" },
  { sortDir: "desc", text: "Product Name - Z-A", sortProp: "Description" },
];

class NewSearchCont extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilterActived: false,
      searched: false,
      loading: false,
      data: [],
      searchTerm: "",
      totalRecords: 0,
      skip: 0,
      take: 10,
      gcSearchObj: {},
      likedProducts: [],
      sortDir: SELECT_VALUES[0].sortDir,
      sortProp: SELECT_VALUES[0].sortProp,
      manufacturerId: -1,
      applianceId: -1,
      partTypeId: -1,
      manufacturerName: null,
      applianceName: null,
      partTypeName: null
    };
  }

  componentDidMount() {
    this.getLikedProducts();
  }

  onPartsListChanged = (parts) => {
    this.setState({ data: parts, searched: false }, () => { });
  };

   toggleLike = (item, isLiked = false) => {
    LikedProductService.toggle(item.mpn, item.description, item.img_url, item.manid, item.app, item.appid, item.pgcn, item.appgc, item.price, item.man).then(() => {
      if (!isLiked) {// add to the list
        this.setState(prev => {
          return {
            ...prev,
            data: prev.data.map(product =>
              product.mpn === item.mpn ? { ...product, liked: !isLiked } : product
            ),
            likedProducts: [
              ...prev.likedProducts,
              {
                mpn: item.mpn,
                img_url: item.img_url,
                description: item.description,
              }
            ]
          }
        })
      } else {// remove from the list
        this.setState(prev => {
          return {
            ...prev,
            data: prev.data.map(product =>
              product.mpn === item.mpn ? { ...product, liked: !isLiked } : product
            ),
            likedProducts: prev.likedProducts.filter(likedProduct => likedProduct.mpn !== item.mpn)
          }
        })
      }
    });
  };

  getLikedProducts = () => {
    LikedProductService.searchLikedProducts('').then((response) => {
      this.setState({
        likedProducts:
          response ? response.map((product) => {
            return {
              mpn: product.manufacturerCode,
              img_url: product.imageUrl,
              description: product.description,
            };
          }) : [],
      })
    });
  };

  onSearch = (manufacturerId, applianceId, partTypeId, manufacturerName, applianceName, partTypeName) => {
    const { basket } = this.props;
    const { sortDir, sortProp } = this.state;
    this.setState(
      {
        manufacturerId,
        applianceId,
        partTypeId,
        manufacturerName,
        applianceName,
        partTypeName,
        loading: true,
      },
      () => {
        ProductSearchService.partsByType(applianceId, partTypeId, manufacturerId, manufacturerName, applianceName, partTypeName, sortDir, sortProp)
          .then((response) => {
            let items = response.map((product) => {
              product.qty = basket.lines.find((line) => line.manufacturerCode === product.mpn)?.qty ?? 0
              product.liked =
                this.state.likedProducts.findIndex(
                  (p) => p.mpn == (product.manufacturerCode ?? product.mpn)
                ) !== -1;
              return product;
            });
            this.setState({ data: items || [], searched: true, loading: false });
          });
      }
    );
  };

  changeSort = (option) => {
    let index = option.selected;
    const { manufacturerId, applianceId, partTypeId, manufacturerName, applianceName, partTypeName } = this.state;
    this.setState(
      {
        sortDir: SELECT_VALUES[index].sortDir,
        sortProp: SELECT_VALUES[index].sortProp,
      },
      () => this.onSearch(manufacturerId, applianceId, partTypeId, manufacturerName, applianceName, partTypeName)
    );
  };

  render() {
    const { searched, data, loading, gcSearchObj } = this.state;
    const { onAddToBasket, isAccountCompleted } = this.props;

    return (
      <>
        <FilterCommonFieldComp
          style={{ display: !searched ? "flex" : "none" }}
          gcSearchObj={gcSearchObj}
          partsListChanged={this.onPartsListChanged}
          onSearch={this.onSearch}
          isLoading={loading}
        />
        <div className={isTablet() ? 'results-wrapper-tablet' : ''}>
          <SearchResultsList
            results={data}
            loading={loading}
            noResults={searched && data && !data.length}
            toggleLike={this.toggleLike}
            onAddToBasket={onAddToBasket}
            changeSort={this.changeSort}
            sortValues={SELECT_VALUES}
            noItemsText='Select an appliance above and click search'
            isAccountCompleted={isAccountCompleted}
            showNewSortFilter={false}
          />
        </div>
      </>
    );
  }
}

export default NewSearchCont;