import React from "react";
import { isMobile, isTablet } from "../Media/Media";

/**
 * @desc Creates inner navigational buttons and return the state of clicked button
 * @todo This will directly chage the page for MPA approach  (waiting sparesFinder page refactor)
 * */

const image_wrapper = {
  height: 30,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

class NavigationComp1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      highlightActive: this.props.hightligntActiveButton,
      dataViews: this.props.dataViews ? this.props.dataViews : [],
      isBindPathRedirect: this.props.isBindPathRedirect
        ? this.props.isBindPathRedirect
        : false,
    };
  }

  hangleOnClick = (keyName, link) => {
    if (link) {
      return (window.location = link);
    }

    if (this.state.isBindPathRedirect) {
      window.location = `/SparesFinder/${keyName}?selectedBranchId=${this.props.selectedBranchId ?? ""
        }`;
    }
    this.props.handlerOnClick(keyName);
  };

  render() {
    const { hightligntActiveButton } = this.props;
    var elements = this.state.dataViews.map(
      function (item, i) {
        if (this.props.isBasketNav && isMobile())
          return (
            <button
              key={i}
              onClick={this.hangleOnClick.bind(this, item.viewKey, item.link)}
              name={item.viewKey}
              type="button"
              className={
                hightligntActiveButton === item.viewKey
                  ? "basket_mobile_nav_active"
                  : "basket_mobile_nav"
              }
            >
              <span
                style={
                  hightligntActiveButton === item.viewKey
                    ? { color: "#fff" }
                    : { color: "#00226A" }
                }
              >
                {item.btnText}
              </span>
            </button>
          );
        return (
          <button
            key={i}
            onClick={this.hangleOnClick.bind(this, item.viewKey, item.link)}
            name={item.viewKey}
            type="button"
            className={
              hightligntActiveButton === item.viewKey
                ? isMobile()
                  ? "search_button_active_mobile"
                  : "search_button_active_1"
                : isMobile()
                  ? "search_button_mobile"
                  : "search_button_1"
            }
          >
            {item.svgActive
              ? hightligntActiveButton === item.viewKey
                ? item.svgActive.svg
                : item.svgNotActive.svg
              : ""}
            {isMobile() && (
              <div style={image_wrapper}>
                <img
                  src={`/img/searchItems/${hightligntActiveButton === item.viewKey
                    ? item.viewKey + "Selected"
                    : item.viewKey
                    }.png`}
                  alt={item.btnText}
                // style={item.viewKey === 'newSearch' ? {width: 30} : {}}
                />
              </div>
            )}
            <p
              style={
                hightligntActiveButton === item.viewKey
                  ? { color: "#fff" }
                  : { color: "#00226A" }
              }
            >
              {item.btnText}
            </p>
          </button>
        );
      }.bind(this)
    );
    return (
      <>
        <div
          className="template__grid"
          style={
            isMobile() && this.props.isBasketNav ? { background: "#fff" } : {}
          }
        >
          <div
            className={`${isMobile() ? 'mobile_spares_nav' : ''} ${isTablet() ? 'tablet_spares_nav'  : ''} ${!isTablet() && !isMobile() ? 'desktop_spares_nav' : ''}`}
          >
            {elements}
          </div>
        </div>
      </>
    );
  }
}

export default NavigationComp1;
