import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function QtyMinus(props) {
  return (
    <SvgIcon {...props}>
          <ellipse cx="9.90244" cy="9.77554" rx="9.90244" ry="9.64736" fill="currentColour" />
          <rect x="15.6787" y="8.97119" width="1.60789" height="11.5529" rx="0.803947" transform="rotate(90 15.6787 8.97119)" fill="white" />
    </SvgIcon>
  );
}

