import React, { useContext, useEffect, useState } from 'react';
import HeaderBar from './HeaderBar';
import Footer from './Footer';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { EMediaSize, Media } from '../Media/Media';
import WrapperDesktop from './WrapperDesktop';
import theme from '../../theme/theme';
import FooterMobile from './FooterMobile';
import getBasketCount from '../../Helpers/common/getBasketCount';
import { useHistory, useLocation } from 'react-router-dom';
import BasketService from '../../Services/BasketService';
import BannerService from '../../Services/BannerService';
import NotificationService from '../../Services/NotificationService';
import AccountService from '../../Services/AccountService';
import http from '../../utils/http';
import FeatureFlagContext from '../../Context/FeatureFlagContext';
import { PromoAlert } from './PromoAlert/PromoAlert';

const useStyles = makeStyles({
    navMenu: {
        width: '300px',
        padding: '20px',
        backgroundColor: '#FFFFFF',
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
        },
    },
    listItem: {
        padding: '11px 20px',
    },
    listItemIcon: {
        minWidth: 40,
    },
    wrapper: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflowX: 'hidden',
    },
    main: {
        flex: 1,
    },
    '@media (min-width: 640px)': {
        navMenu: {
            width: '320px',
        },
    },
    allCategoriesHeader: {
        width: 'calc(100% + 40px)',
        marginLeft: -20,
        padding: '19px 36px',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#454545',
        background: '#DCEBF1',
        textTransform: 'uppercase',
    },
    allCategoriesList: {
        background: '#F5F7F9',
        padding: 0,
        position: 'relative',

        '&:before': {
            content: '""',
            background: '#F5F7F9',
            height: '100%',
            width: '100vw',
            position: 'absolute',
            top: 0,
            left: '-20px',
        },
    },
    categoryListItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '17px 16px',
        cursor: 'pointer',
        position: 'relative',

        '&:before': {
            content: '""',
            background: '#d3d5d8',
            height: '1px',
            width: '100vw',
            position: 'absolute',
            bottom: 0,
            left: '-20px',
        },

        '& span': {
            fontSize: 16,
            textTransform: 'capitalize',
        },
    },
    categoryListItemAdd: {
        '&:after': {
            content: '""',
            background: '#d3d5d8',
            height: '1px',
            width: '100vw',
            position: 'absolute',
            top: 0,
            left: '-20px',
        },
    },
});

const Wrapper = (props) => {
    const { text, wrapperClass, children, footerValue } = props;
    const history = useHistory();
    const location = useLocation();
    const [showDrawer, setShowDrawer] = useState(false);
    const [bannerText, setBannerText] = useState(undefined);
    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
    const classes = useStyles();
    const [basket, setBasket] = useState({ lines: [] });
    const [disableBuyTradeCheckout, setDisableBuyTradeCheckout] = useState(false);
    const featureFlagContext = useContext(FeatureFlagContext);
    const isAllProducts = featureFlagContext.checkFeatures('all-products');
    const isNewSpares = featureFlagContext.checkFeatures('new-spares');
    const showBanner = featureFlagContext.checkFeatures('banner-display');

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setShowDrawer(open);
    };

    useEffect(() => {
        if (location.search === '?part=3&origin=home') {
            setShowDrawer(props?.showDrawer ?? false);
        }
    }, []);

    const loadBasket = () => {
        BasketService.getBasketForUser().then((response) => {
            if (response.lines == null) response.lines = [];
            setBasket(response);
        });
    };

    const getBannerText = () => {
        BannerService.getBannerText().then((response) => {
            if (response) {
                setBannerText(response);
            }
        })
    };

    useEffect(() => {
        loadBasket();
        getUnreadNotificationsCount();
        getUserDetails();
        getBannerText();
    }, []);

    const getUnreadNotificationsCount = () => {
        NotificationService.GetUnreadNotificationsCount().then((response) => {
            if (response) {
                setUnreadNotificationCount(response.count);
            }
        });
    };

    const goToPageAndCloseMenu = (path) => {
        setShowDrawer(false);
        history.push(path);
    };

    const goToMyAccount = () => {
        goToPageAndCloseMenu('/settings');
    };
    const goToSparesFinder = () => {
        if (isNewSpares) {
            goToPageAndCloseMenu(isAllProducts ? '/Spares' : '/shop');
        } else {
            goToPageAndCloseMenu(isAllProducts ? '/shop-2' : '/shop');
        }
    };

    const goToRecentlyOrdered = () => {
        goToPageAndCloseMenu('/shop?tab=recently');
    };

    const goToFavourites = () => {
        goToPageAndCloseMenu('/shop?tab=favourites');
    };

    const goToOrderHistory = () => {
        goToPageAndCloseMenu('/orderHistory');
    };

    const goToBasket = () => {
        goToPageAndCloseMenu('/Basket');
    };

    const goToNotifications = () => {
        goToPageAndCloseMenu('/notifications');
    };

    const goToRequestedProducts = () => {
        goToPageAndCloseMenu('/shop?tab=requestedProducts');
    };

    const goHome = () => {
        goToPageAndCloseMenu(isAllProducts ? '/homepage-2' : '/');
    };

    const logout = () => {
        window.location = '/account/logout';
    };

    const [allClassifications, setAllClassifications] = useState([]);
    const [selectedParent, setSelectedParent] = useState(null);

    useEffect(() => {
        GetAllClassifications();
    }, []);

    const GetAllClassifications = () => {
        const params = new URLSearchParams();
        params.append('skip', 0);
        params.append('take', 10);
        return http.get(`ItemClassification/GetData`, { params }).then((response) => {
            if (response && response.collection) {
                // console.log(response.collection)
                setAllClassifications(response.collection || []);
            }
        });
    };

    const checkSubMenu = (parentId) => {
        const subCollection = allClassifications.filter((el) => el.parentId === parentId);
        return subCollection || [];
    };

    // const changeUrl = (part = '', subpart = '') => {
    // 	let newUrl = `/products?part=${part}`;

    // 	if (subpart) {
    // 		newUrl = `/products?part=${part}&subpart=${subpart}`;
    // 	}

    // 	goToPageAndCloseMenu(newUrl);
    // };

    const changeNewUrl = (part) => {
        const parentName = allClassifications.find((c) => c.id === part.parentId).name;
        if (parentName === 'SPARES') {
            window.location = part.url;
        } else {
            window.location = `/products?classification=${part.id}`;
        }
    };

    const getParentCollection = () => {
        if (selectedParent?.parentId) {
            const parentCollection = allClassifications.find((el) => el.id === selectedParent.parentId);
            return parentCollection;
        }

        return null;
    };

    const onBackMenu = () => {
        const parentMenu = getParentCollection();
        if (!!parentMenu) {
            setSelectedParent(parentMenu);
            return;
        }

        setSelectedParent(null);
    };

    const getUserDetails = async () => {
        await AccountService.userDetails().then((response) => {
            setDisableBuyTradeCheckout(response.disableBuyTradeCheckout);
        });
    };

    const showNewDesign = isAllProducts;

    return (
        <>
            <Media size={EMediaSize.TABLET_WIDE}>
                {(matched) =>
                    !matched ? (
                        <WrapperDesktop
                            {...props}
                            unreadNotificationCount={props.unreadNotificationCount || unreadNotificationCount}
                            basketCount={props.basket ? getBasketCount(props.basket) : getBasketCount(basket)}
                            disableBuyTradeCheckout={disableBuyTradeCheckout}>
                            {children}
                        </WrapperDesktop>
                    ) : (
                        <MuiThemeProvider theme={theme}>
                            <div className={classes.wrapper}>
                                <HeaderBar
                                    text={text}
                                    onMenuClick={toggleDrawer(true)}
                                    count={props.basket ? getBasketCount(props.basket) : getBasketCount(basket)}
                                    unreadNotificationCount={props.unreadNotificationCount || unreadNotificationCount}
                                    disableBuyTradeCheckout={disableBuyTradeCheckout}
                                />





                                <main className={clsx('main', wrapperClass, classes.main)}>
                                    {
                                        showBanner && bannerText &&
                                        <PromoAlert contents={bannerText} />
                                    }
                                    {children}
                                    <FooterMobile disableBuyTradeCheckout={disableBuyTradeCheckout} />
                                </main>
                                <div className='drawer_wrapper'>
                                    <Drawer
                                        anchor={'left'}
                                        open={showDrawer}
                                        onClose={toggleDrawer(false)}
                                        classes={{
                                            backgroundColor: '#F5F7F9',
                                        }}>
                                        <div className={classes.navMenu} role='presentation'>
                                            <div onClick={toggleDrawer(false)} className='close_mobile_nav' style={{ marginBottom: 25 }}>
                                                <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                    <rect
                                                        x='1.34326'
                                                        y='0.431763'
                                                        width='23.7442'
                                                        height='1.89953'
                                                        transform='rotate(45 1.34326 0.431763)'
                                                        fill='#00C9FF'
                                                    />
                                                    <rect
                                                        x='18.1763'
                                                        y='1.7749'
                                                        width='23.7442'
                                                        height='1.89953'
                                                        transform='rotate(135 18.1763 1.7749)'
                                                        fill='#25D6E4'
                                                    />
                                                </svg>
                                            </div>
                                            {showNewDesign && allClassifications.length ? (
                                                <div className={classes.allCategories}>
                                                    {!!selectedParent?.id && (
                                                        <div
                                                            className={[classes.categoryListItem, classes.categoryListItemAdd].join(' ')}
                                                            style={{ justifyContent: 'flex-start' }}
                                                            onClick={onBackMenu}>
                                                            <svg
                                                                width='11'
                                                                height='20'
                                                                viewBox='0 0 11 20'
                                                                fill='none'
                                                                xmlns='http://www.w3.org/2000/svg'>
                                                                <path
                                                                    d='M10 1L1.5 10L10 19'
                                                                    stroke='url(#paint0_linear_8113_26987)'
                                                                    stroke-width='2'
                                                                    stroke-linecap='round'
                                                                />
                                                                <defs>
                                                                    <linearGradient
                                                                        id='paint0_linear_8113_26987'
                                                                        x1='1.5'
                                                                        y1='10.0744'
                                                                        x2='15.5158'
                                                                        y2='10.0744'
                                                                        gradientUnits='userSpaceOnUse'>
                                                                        <stop stop-color='#00C9FF' />
                                                                        <stop offset='0.421875' stop-color='#25D6E4' />
                                                                        <stop offset='1' stop-color='#92FE93' />
                                                                    </linearGradient>
                                                                </defs>
                                                            </svg>
                                                            <span style={{ marginLeft: 14, fontWeight: 400 }}>
                                                                Back to {getParentCollection()?.name || 'Main Menu'}
                                                            </span>
                                                        </div>
                                                    )}
                                                    <div className={classes.allCategoriesHeader}>
                                                        {selectedParent?.id ? selectedParent?.name : 'ALL CATEGORIES'}
                                                    </div>

                                                    <List className={classes.allCategoriesList}>
                                                        {(!!selectedParent?.id
                                                            ? checkSubMenu(selectedParent?.id)
                                                            : allClassifications.filter((el) => el.parentId === null)
                                                        )
                                                            .slice(0, 8)
                                                            .map((part, index) => {
                                                                if (checkSubMenu(part.id).length) {
                                                                    return (
                                                                        <ListItem
                                                                            button
                                                                            key={index}
                                                                            className={classes.categoryListItem}
                                                                            onClick={() => setSelectedParent(part)}>
                                                                            <ListItemText primary={part.name?.toLowerCase()} />
                                                                            <svg
                                                                                width='9'
                                                                                height='15'
                                                                                viewBox='0 0 9 15'
                                                                                fill='none'
                                                                                xmlns='http://www.w3.org/2000/svg'>
                                                                                <path d='M1 0.5L8 7.5L1 14.5' stroke='#BACAD1' stroke-linecap='round' />
                                                                            </svg>
                                                                        </ListItem>
                                                                    );
                                                                }

                                                                return (
                                                                    <ListItem
                                                                        button
                                                                        key={index}
                                                                        className={classes.categoryListItem}
                                                                        onClick={() => {
                                                                            return changeNewUrl(part);
                                                                        }}>
                                                                        <ListItemText primary={part.name?.toLowerCase()} />
                                                                    </ListItem>
                                                                );
                                                            })}
                                                    </List>
                                                </div>
                                            ) : null}
                                            {showNewDesign && selectedParent?.id ? null : (
                                                <List>
                                                    <ListItem button key={text} className={classes.listItem} onClick={goHome}>
                                                        <ListItemIcon className={classes.listItemIcon}>
                                                            <svg
                                                                width='25'
                                                                height='19'
                                                                viewBox='0 0 20 19'
                                                                fill='none'
                                                                xmlns='http://www.w3.org/2000/svg'>
                                                                <path
                                                                    d='M16.5935 18.9999H3.4064C2.82903 18.9999 2.35559 18.5235 2.35559 17.9424V9.75036C2.35559 9.16932 2.82903 8.69287 3.4064 8.69287C3.98377 8.69287 4.45721 9.16932 4.45721 9.75036V16.8849H15.5427V9.75036C15.5427 9.16932 16.0161 8.69287 16.5935 8.69287C17.1708 8.69287 17.6443 9.16932 17.6443 9.75036V17.9424C17.6443 18.5351 17.1708 18.9999 16.5935 18.9999Z'
                                                                    fill='#007AAB'
                                                                />
                                                                <path
                                                                    d='M18.9488 8.22706C18.741 8.22706 18.5447 8.16896 18.3599 8.04113L9.99962 2.33532L1.63935 8.05275C1.1659 8.37813 0.507706 8.2503 0.18438 7.77385C-0.138945 7.28578 -0.0234717 6.62339 0.461517 6.29801L9.41071 0.185469C9.76867 -0.0585678 10.2306 -0.0585678 10.5885 0.185469L19.5377 6.29801C20.0227 6.62339 20.1382 7.28577 19.8149 7.76223C19.6186 8.06437 19.2837 8.22706 18.9488 8.22706Z'
                                                                    fill='#007AAB'
                                                                />
                                                            </svg>
                                                        </ListItemIcon>
                                                        <ListItemText primary={'Home'} />
                                                    </ListItem>
                                                    {!disableBuyTradeCheckout && (
                                                        <ListItem button className={classes.listItem} key={text} onClick={goToMyAccount}>
                                                            <ListItemIcon className={classes.listItemIcon}>
                                                                <svg
                                                                    width='25'
                                                                    height='25'
                                                                    viewBox='0 0 25 25'
                                                                    fill='none'
                                                                    xmlns='http://www.w3.org/2000/svg'>
                                                                    <path
                                                                        d='M12.6475 3.1333C11.4506 3.1333 10.2654 3.36905 9.1596 3.82708C8.05381 4.28512 7.04906 4.95647 6.20272 5.80281C4.49346 7.51207 3.5332 9.83033 3.5332 12.2476C3.5332 14.6648 4.49346 16.9831 6.20272 18.6924C7.04906 19.5387 8.05381 20.2101 9.1596 20.6681C10.2654 21.1261 11.4506 21.3619 12.6475 21.3619C15.0647 21.3619 17.383 20.4016 19.0923 18.6924C20.8015 16.9831 21.7618 14.6648 21.7618 12.2476C21.7618 11.0507 21.526 9.8655 21.068 8.7597C20.61 7.6539 19.9386 6.64915 19.0923 5.80281C18.2459 4.95647 17.2412 4.28512 16.1354 3.82708C15.0296 3.36905 13.8444 3.1333 12.6475 3.1333ZM8.15415 17.9714C8.54606 17.1511 10.934 16.349 12.6475 16.349C14.361 16.349 16.7489 17.1511 17.1408 17.9714C15.9013 18.9557 14.3427 19.539 12.6475 19.539C10.9522 19.539 9.39369 18.9557 8.15415 17.9714ZM18.4442 16.6498C17.1408 15.0639 13.9782 14.5262 12.6475 14.5262C11.3168 14.5262 8.15415 15.0639 6.8508 16.6498C5.92115 15.4376 5.35606 13.9064 5.35606 12.2476C5.35606 8.22819 8.62809 4.95616 12.6475 4.95616C16.6669 4.95616 19.9389 8.22819 19.9389 12.2476C19.9389 13.9064 19.3738 15.4376 18.4442 16.6498ZM12.6475 6.77902C10.8793 6.77902 9.45749 8.20084 9.45749 9.96902C9.45749 11.7372 10.8793 13.159 12.6475 13.159C14.4157 13.159 15.8375 11.7372 15.8375 9.96902C15.8375 8.20084 14.4157 6.77902 12.6475 6.77902ZM12.6475 11.3362C12.2849 11.3362 11.9372 11.1921 11.6808 10.9357C11.4244 10.6793 11.2803 10.3316 11.2803 9.96902C11.2803 9.60643 11.4244 9.25869 11.6808 9.0023C11.9372 8.74591 12.2849 8.60187 12.6475 8.60187C13.0101 8.60187 13.3578 8.74591 13.6142 9.0023C13.8706 9.25869 14.0146 9.60643 14.0146 9.96902C14.0146 10.3316 13.8706 10.6793 13.6142 10.9357C13.3578 11.1921 13.0101 11.3362 12.6475 11.3362Z'
                                                                        fill='#007AAB'
                                                                        stroke='#007AAB'
                                                                        stroke-width='0.3'
                                                                    />
                                                                </svg>
                                                            </ListItemIcon>
                                                            <ListItemText primary={'My account'} />
                                                        </ListItem>
                                                    )}
                                                    <ListItem button key={text} className={classes.listItem} onClick={goToSparesFinder}>
                                                        <ListItemIcon className={classes.listItemIcon}>
                                                            <svg
                                                                width='25'
                                                                height='25'
                                                                viewBox='0 0 25 25'
                                                                fill='none'
                                                                xmlns='http://www.w3.org/2000/svg'>
                                                                <path
                                                                    d='M11.2139 3.61584C13.261 3.61584 15.2243 4.42907 16.6718 5.87661C18.1194 7.32416 18.9326 9.28746 18.9326 11.3346C18.9326 13.2465 18.232 15.004 17.0801 16.3577L17.4007 16.6783H18.3389L22.4951 20.8346L20.7139 22.6158L16.5576 18.4596V17.5215L16.237 17.2008C14.8832 18.3527 13.1257 19.0533 11.2139 19.0533C9.16673 19.0533 7.20343 18.2401 5.75589 16.7926C4.30834 15.345 3.49512 13.3817 3.49512 11.3346C3.49512 9.28746 4.30834 7.32416 5.75589 5.87661C7.20343 4.42907 9.16673 3.61584 11.2139 3.61584ZM11.2139 5.99084C8.24512 5.99084 5.87012 8.36584 5.87012 11.3346C5.87012 14.3033 8.24512 16.6783 11.2139 16.6783C14.1826 16.6783 16.5576 14.3033 16.5576 11.3346C16.5576 8.36584 14.1826 5.99084 11.2139 5.99084Z'
                                                                    fill='#007AAB'
                                                                />
                                                            </svg>
                                                        </ListItemIcon>
                                                        <ListItemText primary={'New search'} />
                                                    </ListItem>
                                                    {/*
                             <ListItem
                            button
                            key={text}
                            className={classes.listItem}
                            onClick={goToReturns}
                          >
                            <ListItemIcon className={classes.listItemIcon}>
                              <svg
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.2139 3.61584C13.261 3.61584 15.2243 4.42907 16.6718 5.87661C18.1194 7.32416 18.9326 9.28746 18.9326 11.3346C18.9326 13.2465 18.232 15.004 17.0801 16.3577L17.4007 16.6783H18.3389L22.4951 20.8346L20.7139 22.6158L16.5576 18.4596V17.5215L16.237 17.2008C14.8832 18.3527 13.1257 19.0533 11.2139 19.0533C9.16673 19.0533 7.20343 18.2401 5.75589 16.7926C4.30834 15.345 3.49512 13.3817 3.49512 11.3346C3.49512 9.28746 4.30834 7.32416 5.75589 5.87661C7.20343 4.42907 9.16673 3.61584 11.2139 3.61584ZM11.2139 5.99084C8.24512 5.99084 5.87012 8.36584 5.87012 11.3346C5.87012 14.3033 8.24512 16.6783 11.2139 16.6783C14.1826 16.6783 16.5576 14.3033 16.5576 11.3346C16.5576 8.36584 14.1826 5.99084 11.2139 5.99084Z"
                                  fill="#007AAB"
                                />
                              </svg>
                            </ListItemIcon>
                            <ListItemText primary={"Returns"} />
                          </ListItem>
                             
                             */}

                                                    <ListItem button key={text} className={classes.listItem} onClick={goToRecentlyOrdered}>
                                                        <ListItemIcon className={classes.listItemIcon}>
                                                            <svg
                                                                width='25'
                                                                height='25'
                                                                viewBox='0 0 25 25'
                                                                fill='none'
                                                                xmlns='http://www.w3.org/2000/svg'>
                                                                <path
                                                                    d='M21.4951 12.6331C21.5038 17.3163 17.6845 21.1459 13.0013 21.1492C10.9786 21.1506 9.12066 20.4455 7.66061 19.2671C7.28096 18.9607 7.25258 18.3917 7.59758 18.0467L7.98375 17.6606C8.27881 17.3655 8.74988 17.3332 9.07678 17.5926C10.1525 18.4462 11.5138 18.9556 12.9951 18.9556C16.481 18.9556 19.3016 16.1345 19.3016 12.6492C19.3016 9.16332 16.4804 6.34272 12.9951 6.34272C11.3221 6.34272 9.80251 6.99287 8.67423 8.0541L10.4138 9.79366C10.7593 10.1391 10.5146 10.7298 10.026 10.7298H5.0435C4.74062 10.7298 4.49512 10.4843 4.49512 10.1814V5.19889C4.49512 4.71034 5.0858 4.46566 5.43128 4.81111L7.12347 6.5033C8.64945 5.04496 10.7177 4.14917 12.9951 4.14917C17.6842 4.14917 21.4864 7.94607 21.4951 12.6331ZM15.2945 15.3334L15.6312 14.9005C15.9101 14.5419 15.8455 14.0251 15.4869 13.7462L14.0919 12.6611V9.08466C14.0919 8.63036 13.7236 8.26208 13.2693 8.26208H12.7209C12.2666 8.26208 11.8983 8.63036 11.8983 9.08466V13.734L14.1402 15.4777C14.4988 15.7566 15.0156 15.692 15.2945 15.3334Z'
                                                                    fill='#007AAB'
                                                                />
                                                            </svg>
                                                        </ListItemIcon>
                                                        <ListItemText primary={'Recently ordered'} />
                                                    </ListItem>
                                                    {!disableBuyTradeCheckout && (
                                                        <ListItem button key={text} className={classes.listItem} onClick={goToOrderHistory}>
                                                            <ListItemIcon className={classes.listItemIcon}>
                                                                <svg
                                                                    width='25'
                                                                    height='25'
                                                                    viewBox='0 0 25 25'
                                                                    fill='none'
                                                                    xmlns='http://www.w3.org/2000/svg'>
                                                                    <rect
                                                                        x='5.42383'
                                                                        y='6.69531'
                                                                        width='4.42857'
                                                                        height='4.42857'
                                                                        stroke='#007AAB'
                                                                        stroke-width='2'
                                                                    />
                                                                    <rect
                                                                        x='5.42383'
                                                                        y='15.2667'
                                                                        width='4.42857'
                                                                        height='4.42857'
                                                                        stroke='#007AAB'
                                                                        stroke-width='2'
                                                                    />
                                                                    <path d='M12.9951 8.90955H20.4951' stroke='#007AAB' stroke-width='2' />
                                                                    <path d='M12.9951 17.4811H20.4951' stroke='#007AAB' stroke-width='2' />
                                                                </svg>
                                                            </ListItemIcon>
                                                            <ListItemText primary={'Order History'} />
                                                        </ListItem>
                                                    )}
                                                    <ListItem button key={text} className={classes.listItem} onClick={goToFavourites}>
                                                        <ListItemIcon className={classes.listItemIcon}>
                                                            <svg
                                                                width='25'
                                                                height='25'
                                                                viewBox='0 0 25 25'
                                                                fill='none'
                                                                xmlns='http://www.w3.org/2000/svg'>
                                                                <path
                                                                    d='M19.6107 5.38256C17.5787 3.64731 14.4393 3.90796 12.4951 5.91812C10.551 3.90796 7.41152 3.64374 5.37949 5.38256C2.73575 7.64266 3.12247 11.3274 5.00684 13.2804L11.1732 19.6608C11.5248 20.025 11.9959 20.2285 12.4951 20.2285C12.9978 20.2285 13.4654 20.0286 13.817 19.6644L19.9834 13.284C21.8642 11.3309 22.258 7.64623 19.6107 5.38256ZM18.781 12.0772L12.6146 18.4576C12.5303 18.5433 12.46 18.5433 12.3756 18.4576L6.20918 12.0772C4.92598 10.749 4.66582 8.23535 6.46582 6.69648C7.8334 5.52894 9.94277 5.7039 11.2646 7.07138L12.4951 8.34604L13.7256 7.07138C15.0545 5.69676 17.1639 5.52894 18.5244 6.69291C20.3209 8.23178 20.0537 10.7597 18.781 12.0772Z'
                                                                    fill='#007AAB'
                                                                    stroke='#007AAB'
                                                                    stroke-width='0.6'
                                                                />
                                                            </svg>
                                                        </ListItemIcon>
                                                        <ListItemText primary={'Favourites'} />
                                                    </ListItem>
                                                    <ListItem button key={text} className={classes.listItem} onClick={goToBasket}>
                                                        <ListItemIcon className={classes.listItemIcon}>
                                                            <svg
                                                                width='25'
                                                                height='18'
                                                                viewBox='0 0 19 18'
                                                                fill='none'
                                                                xmlns='http://www.w3.org/2000/svg'>
                                                                <path
                                                                    d='M17.8598 6.38078H14.4841L10.5332 0.436769C10.3619 0.183062 10.0732 0.0380859 9.78457 0.0380859C9.49592 0.0380859 9.20728 0.183062 9.03589 0.44583L5.08507 6.38078H1.43522C0.93911 6.38078 0.533203 6.78852 0.533203 7.28687C0.533203 7.36842 0.533203 7.44997 0.569284 7.53152L1.91544 15.9311C2.1229 16.6922 2.81745 17.254 3.64731 17.254H15.6477C16.4775 17.254 17.1721 16.6922 17.3886 15.9311L18.7347 7.53152L18.7618 7.28687C18.7618 6.78852 18.3559 6.38078 17.8598 6.38078ZM9.78457 2.57516L12.3102 6.38078H7.25893L9.78457 2.57516ZM15.6477 15.4418H3.64731L2.61686 8.19297H16.6871L15.6477 15.4418ZM9.78457 10.0052C8.79235 10.0052 7.98054 10.8207 7.98054 11.8174C7.98054 12.8141 8.79235 13.6296 9.78457 13.6296C10.7768 13.6296 11.5886 12.8141 11.5886 11.8174C11.5886 10.8207 10.7768 10.0052 9.78457 10.0052Z'
                                                                    fill='#007AAB'
                                                                />
                                                            </svg>
                                                        </ListItemIcon>
                                                        <ListItemText primary={'Basket'} />
                                                    </ListItem>
                                                    <ListItem button key={text} className={classes.listItem} onClick={goToNotifications}>
                                                        <ListItemIcon className={classes.listItemIcon}>
                                                            <svg
                                                                width='25'
                                                                height='20'
                                                                viewBox='0 0 17 20'
                                                                fill='none'
                                                                xmlns='http://www.w3.org/2000/svg'>
                                                                <path
                                                                    d='M12.9366 14.469H4.35841V8.21563C4.35841 5.81047 6.26467 3.88634 8.64749 3.88634C11.0303 3.88634 12.9366 5.81047 12.9366 8.21563V14.469ZM14.8428 13.507V8.21563C14.8428 5.26209 12.8031 2.78959 10.0772 2.13538V1.48118C10.0772 1.09845 9.92655 0.731392 9.65843 0.460759C9.39031 0.190126 9.02666 0.0380859 8.64749 0.0380859C8.26831 0.0380859 7.90466 0.190126 7.63654 0.460759C7.36842 0.731392 7.21779 1.09845 7.21779 1.48118V2.13538C4.48232 2.78959 2.45215 5.26209 2.45215 8.21563V13.507L0.545898 15.4311V16.3932H16.7491V15.4311L14.8428 13.507ZM8.64749 19.2794C9.15306 19.2794 9.63792 19.0766 9.99541 18.7158C10.3529 18.3549 10.5537 17.8655 10.5537 17.3552H6.74123C6.74123 17.8655 6.94207 18.3549 7.29956 18.7158C7.65705 19.0766 8.14192 19.2794 8.64749 19.2794Z'
                                                                    fill='#007AAB'
                                                                />
                                                            </svg>
                                                        </ListItemIcon>
                                                        <ListItemText primary={'Notifications'} />
                                                    </ListItem>
                                                    <ListItem button key={text} className={classes.listItem} onClick={goToRequestedProducts}>
                                                        <ListItemIcon className={classes.listItemIcon}>
                                                            <svg
                                                                width='25'
                                                                height='26'
                                                                viewBox='0 0 25 26'
                                                                fill='none'
                                                                xmlns='http://www.w3.org/2000/svg'>
                                                                <path
                                                                    d='M14.8754 14.3492H16.1666V16.8156L18.267 18.0488L17.6214 19.1858L14.8754 17.5765V14.3492ZM18.3186 9.97623H6.26749V19.5969H10.2874C9.91725 18.801 9.71066 17.9089 9.71066 16.9731C9.71066 15.3493 10.3455 13.7921 11.4755 12.644C12.6055 11.4958 14.1381 10.8508 15.7362 10.8508C16.6573 10.8508 17.5353 11.0607 18.3186 11.4368V9.97623ZM6.26749 21.3461C5.312 21.3461 4.5459 20.5589 4.5459 19.5969V7.35242C4.5459 6.38161 5.312 5.60321 6.26749 5.60321H7.12828V3.854H8.84987V5.60321H15.7362V3.854H17.4578V5.60321H18.3186C18.7752 5.60321 19.2131 5.7875 19.5359 6.11554C19.8588 6.44358 20.0402 6.8885 20.0402 7.35242V12.6875C21.1076 13.7895 21.7618 15.3026 21.7618 16.9731C21.7618 18.5968 21.1269 20.154 19.9969 21.3021C18.8669 22.4503 17.3343 23.0953 15.7362 23.0953C14.0921 23.0953 12.6029 22.4306 11.5183 21.3461H6.26749ZM15.7362 12.7312C14.629 12.7312 13.5671 13.1781 12.7842 13.9736C12.0012 14.7691 11.5614 15.8481 11.5614 16.9731C11.5614 19.317 13.4293 21.2149 15.7362 21.2149C16.2845 21.2149 16.8273 21.1052 17.3339 20.892C17.8404 20.6788 18.3006 20.3664 18.6883 19.9725C19.076 19.5786 19.3835 19.111 19.5933 18.5963C19.8031 18.0817 19.9111 17.5301 19.9111 16.9731C19.9111 14.6291 18.0431 12.7312 15.7362 12.7312Z'
                                                                    fill='#007AAB'
                                                                />
                                                            </svg>
                                                        </ListItemIcon>
                                                        <ListItemText primary={'Requested Products'} />
                                                    </ListItem>
                                                    <ListItem button key={text} className={classes.listItem} onClick={logout}>
                                                        <ListItemIcon className={classes.listItemIcon}>
                                                            <svg
                                                                width='25'
                                                                height='25'
                                                                viewBox='0 0 25 25'
                                                                fill='none'
                                                                xmlns='http://www.w3.org/2000/svg'>
                                                                <path
                                                                    d='M14.5199 15.7458L16.8513 13.4144H8.1466V11.614H16.8513L14.5199 9.28255L15.7981 8.0133L20.299 12.5142L15.7981 17.0151L14.5199 15.7458ZM18.9487 4.4126C19.4262 4.4126 19.8841 4.60228 20.2218 4.93991C20.5594 5.27754 20.7491 5.73547 20.7491 6.21295V10.4168L18.9487 8.61642V6.21295H6.34625V18.8154H18.9487V16.4119L20.7491 14.6116V18.8154C20.7491 19.2929 20.5594 19.7508 20.2218 20.0885C19.8841 20.4261 19.4262 20.6158 18.9487 20.6158H6.34625C5.34706 20.6158 4.5459 19.8056 4.5459 18.8154V6.21295C4.5459 5.21375 5.34706 4.4126 6.34625 4.4126H18.9487Z'
                                                                    fill='#007AAB'
                                                                    stroke='#007AAB'
                                                                    stroke-width='0.3'
                                                                />
                                                            </svg>
                                                        </ListItemIcon>
                                                        <ListItemText primary={'Logout'} />
                                                    </ListItem>
                                                </List>
                                            )}
                                        </div>
                                    </Drawer>
                                </div>
                                <Footer value={footerValue || -1} />
                            </div>
                        </MuiThemeProvider>
                    )
                }
            </Media>
        </>
    );
};

export default Wrapper;
