import React, { useEffect, useState } from "react";
import http from './utils/http'

export default function ExportedOrder(props) {
    const url = new URLSearchParams(props.location.search);
    const [isDownloadable, setIsDownloadable] = useState(true)
    
    useEffect(() => {
        const params = new URLSearchParams();
        params.append('fileId', url.get('fileId'));
        http.get('Basket/IsExportedOrderValid', { params }).then(response => {
            setIsDownloadable(response)
            if (response) {
                window.open(`Basket/DownloadExportedOrder?fileId=${url.get('fileId')}`, '_blank');
            }
        })
    }, [])

    return (isDownloadable ? <div>Your download will begin shortly. If it doesn't, click <a href={'Basket/DownloadExportedOrder?fileId=' + url.get('fileId')}>here</a></div> : <div>File misses or deactivated.</div>)
}