/* eslint-disable no-useless-computed-key */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "../Media/Media";
const $ = window.jQuery;
const useStyles = makeStyles({
  number: {
    fontSize: "12px",
  },
  ["@media (max-width: 768px)"]: {
    buttons: {
      paddingBottom: "10px",
      display: "grid",
      gridTemplateColumns: "50% 46%",
      justifyContent: "space-between",
    },
    qtyWrapper: {
      position: "relative",
      display: "flex",
      flex: 1,
      width: "100%",
      textAlign: "center",
      border: "1px solid #BACAD1",
      color: "#BACAD1",
      fontSize: "14px",
      borderRadius: "6px",
      alignItems: "center",
      justifyContent: "center",
      height: 32,

      "& input": {
        width: 40,
        border: "none",
        color: "#BACAD1",
        padding: "0 0 2px 0",
        textAlign: "center",
      },
    },
    button: {
      // backgroundColor: "#009FDF",
      // padding: "15px 0",
      // color: "white",
      margin: 0,
      // borderRadius: 6,
      whiteSpace: "nowrap",
      fontSize: 12,
    },
    transparentButton: {
      border: "1px solid #009FDF",
      padding: "15px 0",
      color: "#009FDF",
      margin: 0,
      borderRadius: 6,
      fontSize: 12,
    },
    product: {
      border: "1px",
      borderRadius: "6px",
      background: "white",
      padding: "20px 0",
      margin: "10px",
      position: "relative",
      width: "300px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    optionsButtons: {
      display: "grid",
      gridTemplateColumns: "36% 60%",
      padding: "20px 0",
      margin: "0 -20px",
      width: "100%",
      borderBottom: "1px solid #bacad1",
      alignItems: "center",
      justifyContent: "space-between",
      "& a": {
        height: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 12,
        "& div": {
          paddingLeft: 16,
        },
      },
    },
  },
  ["@media (min-width: 769px)"]: {
    buttons: {
      paddingBottom: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    button: {
      // backgroundColor: "#3EC4F4",
      // padding: "15px 20px",
      width: "300px",
      // color: "white",
      margin: "10px",
    },
    transparentButton: {
      border: "1px solid #3EC4F4",
      padding: "15px 20px",
      width: "300px",
      color: "#3EC4F4",
      margin: "10px",
    },
    product: {
      border: "1px",
      borderRadius: "6px",
      background: "white",
      padding: "20px",
      margin: "10px",
      position: "relative",
      width: "300px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.15)",
    },
    optionsButtons: {
      display: "grid",
      gridTemplateColumns: "35% 65%",
      padding: "20px 0",
      margin: "0 -20px",
      width: "100%",
      borderBottom: "1px solid #bacad1",
      alignItems: "center",
      justifyContent: "space-between",
      "& a": {
        height: 32,
        display: "flex",
        justifyContent: "center",
        borderRadius: 6,
        backgroundColor: "#3EC4F4",
        padding: "15px 20px",
        alignItems: "center",
        color: "white !important",
        margin: "10px",
        fontSize: 12,
        "& div": {
          paddingLeft: 16,
        },
      },
    },
    qtyWrapper: {
      position: "relative",
      display: "grid",
      gridTemplateColumns: "35px 10px 15px 10px",
      flex: 1,
      width: "100%",
      textAlign: "center",
      border: "1px solid #BACAD1",
      color: "#BACAD1",
      fontSize: "14px",
      borderRadius: "6px",
      alignItems: "center",
      justifyContent: "center",
      height: 32,

      "& input": {
        width: 40,
        border: "none",
        color: "#BACAD1",
        padding: "0 0 2px 0",
        textAlign: "center",
      },
    },
  },
  description: {
    fontWeight: 700,
    fontSize: "14px",
  },
  title: {
    textAlign: "center",
    color: "#3EC4F4",
    fontSize: "16px",
    fontWeight: 600,
    margin: "25px",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  headline: {
    fontWeight: 300,
    fontSize: "24px",
  },
  like: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
});

export default function RecentlyAdded(props) {
  const {
    product,
    redirectToSearch,
    toggleLike,
    liked,
    qty
  } = props;
  const classes = useStyles();

  const goToSparesFinder = () => {
    if (redirectToSearch) {
      window.location = "/sparesFinder";
    } else {
      closeModal();
    }
  };

  const closeModal = () => {
    $(".close-modal").trigger("click");
  };

  const goToBasket = () => {
    window.location = "/SparesFinder/Basket";
  };

  if (isMobile())
    return (
      <div className={classes.root}>
        <div className={classes.main}>
          <div className={classes.headline}>{qty} item(s) added to basket</div>
          <div className={classes.product}>
            <img width={200} src={product.img_url} alt="alt" />
            <div className={classes.description}>{product.description}</div>
            <div className={classes.number}>MPN {product.mpn}</div>
            <button type="button" className={classes.like} onClick={toggleLike}>
              {liked ? (
                <svg
                  width="29"
                  height="28"
                  viewBox="0 0 29 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.8274 1.36274C14.1026 0.805303 14.8974 0.805305 15.1726 1.36274L18.8808 8.87654C18.9901 9.09789 19.2013 9.25132 19.4455 9.28682L27.7375 10.4917C28.3527 10.5811 28.5983 11.3371 28.1532 11.771L22.153 17.6197C21.9763 17.792 21.8956 18.0402 21.9373 18.2835L23.3538 26.542C23.4589 27.1546 22.8158 27.6219 22.2656 27.3326L14.849 23.4335C14.6305 23.3186 14.3695 23.3186 14.151 23.4335L6.73443 27.3326C6.18421 27.6219 5.54114 27.1546 5.64622 26.542L7.06266 18.2835C7.10439 18.0402 7.02373 17.792 6.84696 17.6197L0.846835 11.771C0.401697 11.3371 0.647331 10.5811 1.2625 10.4917L9.55446 9.28682C9.79875 9.25132 10.0099 9.09789 10.1192 8.87654L13.8274 1.36274Z"
                    fill="#00226A"
                  />
                </svg>
              ) : (
                <svg
                  width="26"
                  height="25"
                  viewBox="0 0 55 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.8549 53.1701C11.4475 53.1701 11.0446 53.0447 10.6998 52.794C10.091 52.3507 9.79099 51.612 9.91634 50.8733L12.7817 34.1692C12.7907 34.111 12.7728 34.0528 12.7325 34.0125L0.595014 22.184C0.0532851 21.6557 -0.134747 20.8857 0.0980627 20.1693C0.330872 19.453 0.939775 18.9381 1.68297 18.8307L18.4542 16.3951C18.5124 16.3862 18.5616 16.3504 18.584 16.3011L26.0832 1.10137C26.4279 0.420848 27.1039 0 27.8561 0C28.6082 0 29.2843 0.420848 29.6201 1.09689L37.1192 16.2967C37.1461 16.3504 37.1953 16.3862 37.2491 16.3907L54.0203 18.8262C54.768 18.9337 55.3724 19.4485 55.6052 20.1649C55.838 20.8812 55.6455 21.6557 55.1082 22.1796L42.9708 34.0081C42.9305 34.0484 42.9126 34.1066 42.9216 34.1648L45.7869 50.8688C45.9122 51.612 45.6168 52.3463 45.0034 52.7895C44.39 53.2327 43.6021 53.2909 42.935 52.9373L27.9322 45.0486C27.8829 45.0217 27.8203 45.0217 27.771 45.0486L12.7683 52.9373C12.4817 53.0939 12.1683 53.1701 11.8549 53.1701ZM27.8561 43.2443C28.1695 43.2443 28.4829 43.3204 28.7694 43.4682L43.7722 51.3524C43.8349 51.3882 43.8976 51.3792 43.9558 51.3389C44.014 51.2986 44.0363 51.2404 44.0274 51.1688L41.1621 34.4647C41.0546 33.829 41.265 33.1753 41.7262 32.7231L53.8636 20.8946C53.9173 20.8454 53.9307 20.7827 53.9084 20.7156C53.886 20.6484 53.8367 20.6081 53.7651 20.5947L36.9939 18.1591C36.3536 18.0651 35.8029 17.6666 35.5164 17.0846L28.0128 1.88934C27.9501 1.7595 27.7621 1.7595 27.6994 1.88934L20.2003 17.0846C19.9137 17.6666 19.3586 18.0651 18.7183 18.1591L1.94711 20.5947C1.87547 20.6036 1.82622 20.6439 1.80384 20.7156C1.78145 20.7872 1.7949 20.8454 1.84862 20.8946L13.986 32.7231C14.4517 33.1753 14.6621 33.8245 14.5502 34.4647L11.6848 51.1688C11.6714 51.2404 11.6938 51.2986 11.7565 51.3389C11.8147 51.3792 11.8773 51.3837 11.94 51.3524L26.9428 43.4637C27.2293 43.3204 27.5427 43.2443 27.8561 43.2443Z"
                    fill="#00226A"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
        <div className={classes.buttons}>
          <button
            type="button"
            className={`${classes.button} button-large button-light-blue`}
            onClick={() => goToSparesFinder()}
          >
            Continue Shopping
          </button>
          <button
            type="button"
            className={classes.transparentButton}/* TODO: check for transparentButton design */
            onClick={goToBasket}
          >
            Go to Basket
          </button>
        </div>
      </div>
    );
  else
    return (
      <div className={classes.root}>
        <div className={classes.main}>
          <div className={classes.title}>RECENTLY ADDED</div>
          <div className={classes.headline}>Item(s) added to basket</div>
          <div className={classes.product}>
            <img width={200} src={product.img_url} alt="alt" />
            <div className={classes.description}>{product.description}</div>
            <div className={classes.number}>MPN {product.mpn}</div>
            <button type="button" className={classes.like} onClick={toggleLike}>
              {liked ? (
                <svg
                  width="29"
                  height="28"
                  viewBox="0 0 29 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.8274 1.36274C14.1026 0.805303 14.8974 0.805305 15.1726 1.36274L18.8808 8.87654C18.9901 9.09789 19.2013 9.25132 19.4455 9.28682L27.7375 10.4917C28.3527 10.5811 28.5983 11.3371 28.1532 11.771L22.153 17.6197C21.9763 17.792 21.8956 18.0402 21.9373 18.2835L23.3538 26.542C23.4589 27.1546 22.8158 27.6219 22.2656 27.3326L14.849 23.4335C14.6305 23.3186 14.3695 23.3186 14.151 23.4335L6.73443 27.3326C6.18421 27.6219 5.54114 27.1546 5.64622 26.542L7.06266 18.2835C7.10439 18.0402 7.02373 17.792 6.84696 17.6197L0.846835 11.771C0.401697 11.3371 0.647331 10.5811 1.2625 10.4917L9.55446 9.28682C9.79875 9.25132 10.0099 9.09789 10.1192 8.87654L13.8274 1.36274Z"
                    fill="#00226A"
                  />
                </svg>
              ) : (
                <svg
                  width="26"
                  height="25"
                  viewBox="0 0 55 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.8549 53.1701C11.4475 53.1701 11.0446 53.0447 10.6998 52.794C10.091 52.3507 9.79099 51.612 9.91634 50.8733L12.7817 34.1692C12.7907 34.111 12.7728 34.0528 12.7325 34.0125L0.595014 22.184C0.0532851 21.6557 -0.134747 20.8857 0.0980627 20.1693C0.330872 19.453 0.939775 18.9381 1.68297 18.8307L18.4542 16.3951C18.5124 16.3862 18.5616 16.3504 18.584 16.3011L26.0832 1.10137C26.4279 0.420848 27.1039 0 27.8561 0C28.6082 0 29.2843 0.420848 29.6201 1.09689L37.1192 16.2967C37.1461 16.3504 37.1953 16.3862 37.2491 16.3907L54.0203 18.8262C54.768 18.9337 55.3724 19.4485 55.6052 20.1649C55.838 20.8812 55.6455 21.6557 55.1082 22.1796L42.9708 34.0081C42.9305 34.0484 42.9126 34.1066 42.9216 34.1648L45.7869 50.8688C45.9122 51.612 45.6168 52.3463 45.0034 52.7895C44.39 53.2327 43.6021 53.2909 42.935 52.9373L27.9322 45.0486C27.8829 45.0217 27.8203 45.0217 27.771 45.0486L12.7683 52.9373C12.4817 53.0939 12.1683 53.1701 11.8549 53.1701ZM27.8561 43.2443C28.1695 43.2443 28.4829 43.3204 28.7694 43.4682L43.7722 51.3524C43.8349 51.3882 43.8976 51.3792 43.9558 51.3389C44.014 51.2986 44.0363 51.2404 44.0274 51.1688L41.1621 34.4647C41.0546 33.829 41.265 33.1753 41.7262 32.7231L53.8636 20.8946C53.9173 20.8454 53.9307 20.7827 53.9084 20.7156C53.886 20.6484 53.8367 20.6081 53.7651 20.5947L36.9939 18.1591C36.3536 18.0651 35.8029 17.6666 35.5164 17.0846L28.0128 1.88934C27.9501 1.7595 27.7621 1.7595 27.6994 1.88934L20.2003 17.0846C19.9137 17.6666 19.3586 18.0651 18.7183 18.1591L1.94711 20.5947C1.87547 20.6036 1.82622 20.6439 1.80384 20.7156C1.78145 20.7872 1.7949 20.8454 1.84862 20.8946L13.986 32.7231C14.4517 33.1753 14.6621 33.8245 14.5502 34.4647L11.6848 51.1688C11.6714 51.2404 11.6938 51.2986 11.7565 51.3389C11.8147 51.3792 11.8773 51.3837 11.94 51.3524L26.9428 43.4637C27.2293 43.3204 27.5427 43.2443 27.8561 43.2443Z"
                    fill="#00226A"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
        <div className={classes.buttons}>
          <button
            type="button"
            className={`${classes.button} button-large button-light-blue`}
            onClick={() => goToSparesFinder()}
          >
            Continue Shopping
          </button>
          <button
            type="button"
            className={classes.transparentButton}/* TODO: check for transparentButton design */
            onClick={goToBasket}
          >
            Go to Basket
          </button>
        </div>
      </div>
    );
}
