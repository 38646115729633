import Portal from "../../Helpers/modal/Portal";
import RelatedProductSlider from "./RelatedProductSlider";
import { useHistory } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Grid from '@mui/material/Grid';
import NoImageAvailable from "../../assets/img/no_image_available.png"


const RelatedProduct = ({
    relatedProducts,
    newBasketItem,
    minusQuantity,
    addQuantity,
    onClose,
    onAddRelatedProductToBasket
}) => {

    const history = useHistory();
    const qty = newBasketItem.quantityChildValue;
    const goToBasket = () => {
        history.push("/Basket");
    };

    return (

        <Portal>

            <div className="addedToBasketModal">
                <div className="modalOverlay">
                    <div className="modalContainer">

                        <div className="closeButton" onClick={() => onClose()}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 19L19 1M19 19L1 1" stroke="#007AAB" />
                            </svg>
                        </div>


                        <div className="addedToBasketSection">

                            <p className="mainHeading">{qty} x added to basket</p>

                            <div className="productDescriptionSection">
                                <div className="productDescriptionBox">
                                    <p className="code">{newBasketItem?.item.manufactureCode}</p>
                                    <p className="description">{newBasketItem?.item.description}</p>
                                </div>
                                <div className="productImageContainer">
                                    <img
                                        src={newBasketItem.item.imageUrl ? newBasketItem.item.imageUrl : NoImageAvailable}
                                        alt={newBasketItem?.item.description}
                                    />
                                </div>
                            </div>


                        </div>

                        <div className="qtyButtonsBox">
                            <button disabled={qty === 0} onClick={() => minusQuantity(newBasketItem)}> <RemoveIcon /> </button>
                            <input className="new-basket-item-qty-input" readOnly={true} value={qty} />
                            <button onClick={() => addQuantity(newBasketItem)}> <AddIcon /> </button>
                        </div>

                        <Grid className="basketButtonsBox" container spacing={2}>
                            <Grid item xs={6}>
                                <button className="goToBasket" onClick={goToBasket}>Go to Basket</button>
                            </Grid>
                            <Grid item xs={6}>
                                <button className="continueShopping" onClick={() => onClose()}>Continue Shopping</button>
                            </Grid>
                        </Grid>


                        <div className="relatedProductsSection">
                            <RelatedProductSlider slidesPerView={1.35} products={relatedProducts}
                                onAddToBasket={onAddRelatedProductToBasket}
                                allowTouchMove={true}
                                sliderTitle={"You may require ... "}
                            />
                        </div>

                    </div>
                </div>
            </div>

        </Portal>

    );
};

export default RelatedProduct;