import { useEffect, useState } from "react";

export const BREAKPOINT_GAP = 0.02;

export const EMediaSize = {
  MOBILE: 480 - BREAKPOINT_GAP,
  MOBILE_WIDE: 600 - BREAKPOINT_GAP,
  TABLET: 768 - BREAKPOINT_GAP,
  TABLET_WIDE: 1024 - BREAKPOINT_GAP,
  DESKTOP_SMALL: 1317 - BREAKPOINT_GAP,
  DESKTOP: 1365 - BREAKPOINT_GAP,
};

export function matchMedia(size) {
  return window.matchMedia(`(max-width: ${size}px)`);
}

export function useMedia(size) {
  const [matched, setMatched] = useState(false);

  useEffect(() => {
    const mq = matchMedia(size);
    setMatched(Boolean(mq.matches));

    function screenTest(e) {
      setMatched(Boolean(e.matches));
    }
    mq.addEventListener("change", (e) => screenTest(e));
    return () => mq.removeEventListener("change", (e) => screenTest(e));
  }, [size]);

  return matched;
}

export const Media = ({ size, children }) => {
  const matched = useMedia(size);

  return children(matched);
};

export const isMobile = () => {
  const window_width = getWindowWidth();
  const max = 992;
  return window_width <= max;
};

export const isTablet = () => {
  const window_width = getWindowWidth();
  // console.log(window_width);
  const minWidth = 768;
  const maxWidth = 992;

  return window_width >= minWidth && window_width <= maxWidth;
};

const getWindowWidth = () => window ? window.innerWidth : 0;