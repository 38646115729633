import React from 'react';
import {isMobile} from "../Media/Media";
import spinnerIcon from "../../assets/img/spinner.svg";

function ClearButton({onClear}) {
    return (
        <div className={"search-cancel-btn"}>
            <button onClick={onClear}>
                <span className="left-rectangle"></span>
                <span className="right-rectangle"></span>
            </button>
        </div>
    );
}

export default ClearButton;