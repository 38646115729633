import React, { useEffect, useState, useContext } from "react";
import Wrapper from "./Components/Layout/Wrapper";
import getBasketCount from "./Helpers/common/getBasketCount";
import BasketService from "./Services/BasketService";
import Portal from "./Helpers/modal/Portal";
import { isMobile, isTablet } from "./Components/Media/Media";
import { useHistory } from "react-router-dom";
import AccountService from "./Services/AccountService";
import _ from "lodash";
import { Grid, Stack } from "@mui/material";
import SearchByApplianceIcon from "./assets/img/shop-page/search-appliance.jsx";
import SearchByNumberIcon from "./assets/img/shop-page/search-number.jsx";
import SearchByKeywordIcon from "./assets/img/shop-page/search-keyword.jsx";
import SearchByDiagramIcon from "./assets/img/shop-page/search-diagram.jsx";
import FilterCommonFieldComp from "./Components/General/FilterCommonFieldComp";
import DiagramSearchComp from "./Components/General/DiagramSearchComp";
import SearchResultsList from "./Components/Shop/SearchResultsList";
import NoItems from "./Components/Basket/NoItems/NoItems";
import useNewSearch from "./useNewSearch";
import useTextSearch from "./useTextSearch";
import FinderInput from "./Components/Shop/FinderInput";
import SelectListStaticWithLabel from "./Components/Form/SelectListStaticWithLabel";
import SearchButton from "./Components/Shop/SearchButton";
import MultipleApplianceModal from "./Components/Modal/DeleteModal";
import IBasisTablePager from "./Components/Table/IBasisTablePager";
import SearchByDiagram from "./Components/SearchByDiagram/SearchByDiagram.jsx";
import FeatureFlagContext from "./Context/FeatureFlagContext";
import ProductSearchService from "./Services/ProductSearchService";
import LikedProductService from "./Services/LikedProductService";
import DiagramResultsList from "./Components/SearchByDiagram/DiagramResultsList/DiagramResultsList"
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import ReactGA from 'react-ga4';
import configurationService from './Services/ConfigurationService'

const SparesFinder = (props) => {
    const history = useHistory();

    const [onShowTabView, setOnShowTabView] = useState();
    const [basket, setBasket] = useState({
        basketKey: "",
        lines: [],
    });

    const featureFlagContext = useContext(FeatureFlagContext);

    const showSearchByDiagram = featureFlagContext.checkFeatures("ibasis-diagrams");

    const [showFilterHeader, setShowFilterHeader] = useState(true);
    const [basketIconPosition, setBasketIconPosition] = useState(null);
    const [windowScrollY, setWindowScrollY] = useState(0);
    const [newBasketItem, setNewBasketItem] = useState(null);
    const [isAccountCompleted, setIsAccountCompleted] = useState(false);
    const [diagrams, setDiagrams] = useState([]);
    const [diagramData, setDiagramData] = useState([]);
    const [viewDiagrams, setViewDiagrams] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState(0);
    const [likedProducts, setLikedProducts] = useState([]);
    const [selectedDiagram, setSelectedDiagram] = useState(null);

    const {
        state: newSearchState,
        setState: newSearchSetState,
        toggleLike: newSearchToggleLike,
        onPartsListChanged: newSearchOnPartsListChanged,
        SELECT_VALUES,
        onSearch: newSearchOnSearch,
        changeSort: newSearchChangeSort
    } = useNewSearch(basket)
    const {
        searchTerm: byGcNumberSearchStateSearchTerm,
        searchTermError: byGcNumberSearchStateSearchTermError,
        loading: byGcNumberSearchStateLoading,
        data: byGcNumberSearchStateData,
        setData: byGcNumberSearchStateSetData,
        searched: byGcNumberSearchStateSearched,
        skip: byGcNumberSearchStateSkip,
        take: byGcNumberSearchStateTake,
        filterManufacturerName: byGcNumberSearchStateFilterManufacturerName,
        totalRecords: byGcNumberSearchStateTotalRecords,
        searchedAppliances: byGcNumberSearchStateSearchedAppliances,
        appliances: byGcNumberSearchStateAppliances,
        multiple_appliance_modal: byGcNumberSearchStateMultiple_appliance_modal,
        pageChanged: byGcNumberSearchPageChanged,
        changeSearchTerm: byGcNumberSearchChangeSearchTerm,
        toggleLike: byGcNumberSearchToggleLike,
        search: byGcNumberSearchSearch,
        onSelectAppliance: byGcNumberSearchOnSelectAppliance,
        setManufacturer: byGcNumberSearchSetManufacturer,
        changeSort: byGcNumberSearchChangeSort,
        filterSearchResults: byGcNumberSearchFilterSearchResults,
        applianceDocumentExists: byGcNumberSearchApplianceDocumentExists,
        onViewManual: byGcNumberSearchOnViewManual,
        pageNumber: byGcNumberSearchStatePageNumber,
        appliance: byGcNumberSearchStateAppliance,
        onSearchCodeKeywordOnPaging: byGcNumberSearchCodeKeywordOnPaging,
        setTotalRecords: byGcNumberSetTotalRecords
        // eslint-disable-next-line no-use-before-define
    } = useTextSearch(false, basket, setShowFilterHeader)
    const {
        searchTerm: byPartCodeSearchStateSearchTerm,
        searchTermError: byPartCodeSearchStateSearchTermError,
        manufacturers: byPartCodeSearchStateManufacturers,
        filterManufacturerId: byPartCodeSearchStateFilterManufacturerId,
        manufacturerError: byPartCodeSearchStateManufacturerError,
        loading: byPartCodeSearchStateLoading,
        filterManufacturerName: byPartCodeSearchStateFilterManufacturerName,
        appliance: byPartCodeSearchStateAppliance,
        data: byPartCodeSearchStateData,
        setData: byPartCodeSearchStateSetData,
        searched: byPartCodeSearchStateSearched,
        searchedAppliances: byPartCodeSearchStateSearchedAppliances,
        appliances: byPartCodeSearchStateAppliances,
        multiple_appliance_modal: byPartCodeSearchStateMultiple_appliance_modal,
        skip: byPartCodeSearchStateSkip,
        take: byPartCodeSearchStateTake,
        totalRecords: byPartCodeSearchStateTotalRecords,
        pageNumber: byPartCodeSearchStatePageNumber,
        pageChanged: byPartCodeSearchPageChanged,
        changeSearchTerm: byPartCodeSearchChangeSearchTerm,
        toggleLike: byPartCodeSearchToggleLike,
        search: byPartCodeSearchSearch,
        onSelectAppliance: byPartCodeSearchOnSelectAppliance,
        setManufacturer: byPartCodeSearchSetManufacturer,
        changeSort: byPartCodeSearchChangeSort,
        filterSearchResults: byPartCodeSearchFilterSearchResults,
        applianceDocumentExists: byPartCodeSearchApplianceDocumentExists,
        onViewManual: byPartCodeSearchOnViewManual,
        onPartCodeKeywordOnPaging: byPartCodeOnPartCodeKeywordOnPaging,
        setTotalRecords: byPartCodeOnPartCodeKeywordSetTotalRecords,
        refreshSearchResults: byPartCodeSearchRefreshSearchResults
    } = useTextSearch(true, basket, setShowFilterHeader)
    const {
        state: byDiagramSearchState,
        setState: byDiagramSetState,
        toggleLike: byDiagramToggleLike,
        SELECT_VALUES: DIAGRAM_SELECT_VALUES,
        onSearch: byDiagramOnSearch,
        changeSort: byDiagramChangeSort
    } = useNewSearch(basket)

    useEffect(() => {
        let unregisterHistoryListener;
        checkAccount()
            .then(getBasket)
            .then(() => {
                window.addEventListener("popstate", (event) => {
                    const tab =
                        new URL(window.location.href).searchParams.get("tab") ??
                        "newSearch";
                    changeTabsView(tab);
                });

                unregisterHistoryListener = history.listen((location, action) => {
                    const tab =
                        new URL(window.location.href).searchParams.get("tab") ??
                        "newSearch";
                    changeTabsView(tab);
                });

                const tab = new URL(window.location.href).searchParams.get("tab");
                setOnShowTabView(tab ?? "newSearch");

                const basketIcon = document.getElementById("basket-icon");
                basketIcon &&
                    setBasketIconPosition(basketIcon?.getBoundingClientRect());
                window.addEventListener("scroll", () => {
                    setWindowScrollY(window.scrollY);
                });
            });

        return () => {
            window.removeEventListener("popstate", () => {
            });
            unregisterHistoryListener && unregisterHistoryListener();
            window.removeEventListener("scroll", () => {
            });
        };
    }, []);

    const newItemPopup = () => {
        if (!basketIconPosition && !isMobile()) return null;

        const posX = isMobile() ? 0 : basketIconPosition.x - 184.5;
        const posY = isMobile()
            ? 0
            : basketIconPosition.y + basketIconPosition.height + 20;
        const fixedStyles = { top: 16, right: 16, position: "fixed", zIndex: 9999 };
        const absoluteStyles = { top: posY, left: posX + 27, position: "absolute", zIndex: 9999 };

        const goToBasket = () => {
            history.push("/Basket");
        };
        return isMobile() ? (
            <div className="header-new-basket-item-notification-mobile swing-in-top-fwd">
                <p>Item(s) added to basket</p>
                <div className="new-basket-item-description">
                    <div>
                        <p>{newBasketItem?.item.description}</p>
                    </div>
                    <img src={newBasketItem?.item.img_url} alt="product" />
                </div>
                <button className={`button-large button-white`} onClick={goToBasket}>Go to Basket</button>
            </div>
        ) : (
            <Portal>
                <div
                    className="header-new-basket-item-notification swing-in-top-fwd"
                    style={windowScrollY > 200 ? fixedStyles : absoluteStyles}
                >
                    {!(windowScrollY > 200) && <span className="notification-arrow" />}
                    <p>Item(s) added to basket</p>
                    <div className="new-basket-item-description">
                        <div className="basket-description-container">
                            <p>MPN: {newBasketItem?.item.mpn}</p>
                            <p>{newBasketItem?.item.description}</p>
                        </div>
                        <div className="basket-image-container">
                            <img src={newBasketItem?.item.img_url} alt="product" />
                        </div>
                    </div>
                    <button className={`button-large button-white`} onClick={goToBasket}>Go to Basket</button>
                </div>
            </Portal>
        );
    };

    const addToBasket = (basketItem) => {
        let basketCopy = _.cloneDeep(basket);
        const interval = setInterval(() => {
            setNewBasketItem(null);
            clearInterval(interval);
        }, 3000);

        setNewBasketItem(basketItem);

        const newItem = {
            qty: basketItem.quantityChildValue,
            manufacturerCode: basketItem.item.mpn,
            description: basketItem.item.description,
            imageUrl: basketItem.item.img_url,
            iBasisManId: basketItem.item.manid,
            iBasisAppliance: basketItem.item.app || basketItem.item.iBasisAppliance,
            iBasisApplianceId:
                basketItem.item.appid || basketItem.item.iBasisApplianceId,
            iBasisGCN: basketItem.item.pgcn || basketItem.item.iBasisGCN,
            IBasisApplianceGC:
                basketItem.item.appgc || basketItem.item.IBasisApplianceGC,
            cost: basketItem.item.price,
            manufacturer: basketItem.item.man,
            basketLineTypeId: 1
        };

        const currentIndex = basketCopy.lines
            .map((item) => item.manufacturerCode)
            .indexOf(newItem.manufacturerCode);

        if (currentIndex > -1) {
            basketCopy.lines.splice(currentIndex, 1);
        }

        basketCopy.lines.push(newItem);

        basketCopy.lines = basketCopy.lines.filter(({ qty }) => qty !== 0);

        updateBasket(basketCopy);

        configurationService.getConfigurationVariables().then(res => {
            if (res?.enableGoogleAnalytics) {
                //GA add item to basket event
                ReactGA.event("add_to_cart", {
                    currency: "GBP",
                    value: basketItem.item.price * basketItem.quantityChildValue,
                    items: [
                        {
                            //GA parameters
                            item_id: basketItem.item.mpn || basketItem.item.manufacturerCode,
                            item_name: basketItem.item.description,
                            index: currentIndex > -1 ? currentIndex : basketCopy.lines.length - 1,
                            price: basketItem.item.price,
                            quantity: basketItem.quantityChildValue,

                            //custom parameters
                            manufacturer: basketItem.item.man,
                            manufacturer_id: basketItem.item.manid,
                            location: "Spares Finder 2",
                            is_liked: basketItem.item.liked
                        }
                    ],

                    //custom parameters
                    basket_key: basketCopy.basketKey,
                    organisation_id: basketCopy.organisationId
                })
            }
        });
    };

    const updateBasket = (basketCopy) => {
        BasketService.updateBasketForUser(basketCopy).then((basket) => {
            if (basket.lines == null) basket.lines = [];
            setBasket(basket);
        });
    };

    const getBasket = () => {
        return BasketService.getBasketForUser().then((response) => {
            if (response.lines == null) response.lines = [];
            setBasket(response);
        });
    };

    const getDiagrams = () => {
        return ProductSearchService.getDiagrams(newSearchState.applianceId).then((response) => {
            if (response.length > 0) {
                setDiagrams(response);
                setSelectedDiagram(response[0]);
            }
        })
    }

    const getDiagramsGCNumber = () => {
        return ProductSearchService.getDiagrams(byGcNumberSearchStateAppliance.appid).then((response) => {
            if (response.length > 0) {
                setDiagrams(response);
            }
        })
    }

    useEffect(async () => {
        await getLikedProducts()
        await getDiagrams()
    }, [newSearchState])


    function getDiagramData() {
        return ProductSearchService.getDiagramData(
            newSearchState.manufacturerId,
            newSearchState.applianceId,
            selectedDiagram?.diagramid,
        ).then((response) => {
            response.data = response.data.map((product) => {
                product.qty = basket.lines.find((line) => line.manufacturerCode === product.mpn)?.qty ?? 0
                product.liked =
                    likedProducts?.findIndex(
                        (p) => p.mpn == (product.manufacturerCode ?? product.mpn)
                    ) !== -1;
                return product;
            });

            setDiagramData(response);
        })
    };

    useEffect(() => {
        console.log("DIAGRAMS", [{
            diagrams: diagrams,
            diagramData: diagramData,
            newSearchState: newSearchState,
            selectedCategory: selectedCategory,
            viewDiagrams: viewDiagrams,
            showSearchByDiagram: showSearchByDiagram,
        },
        {
            searchTerm: byGcNumberSearchStateSearchTerm,
            searchTermError: byGcNumberSearchStateSearchTermError,
            loading: byGcNumberSearchStateLoading,
            data: byGcNumberSearchStateData,
            setData: byGcNumberSearchStateSetData,
            searched: byGcNumberSearchStateSearched,
            skip: byGcNumberSearchStateSkip,
            take: byGcNumberSearchStateTake,
            filterManufacturerName: byGcNumberSearchStateFilterManufacturerName,
            totalRecords: byGcNumberSearchStateTotalRecords,
            searchedAppliances: byGcNumberSearchStateSearchedAppliances,
            appliances: byGcNumberSearchStateAppliances,
            multiple_appliance_modal: byGcNumberSearchStateMultiple_appliance_modal,
            pageChanged: byGcNumberSearchPageChanged,
            changeSearchTerm: byGcNumberSearchChangeSearchTerm,
            toggleLike: byGcNumberSearchToggleLike,
            search: byGcNumberSearchSearch,
            onSelectAppliance: byGcNumberSearchOnSelectAppliance,
            setManufacturer: byGcNumberSearchSetManufacturer,
            changeSort: byGcNumberSearchChangeSort,
            filterSearchResults: byGcNumberSearchFilterSearchResults,
            applianceDocumentExists: byGcNumberSearchApplianceDocumentExists,
            onViewManual: byGcNumberSearchOnViewManual,
            pageNumber: byGcNumberSearchStatePageNumber,
            appliance: byGcNumberSearchStateAppliance,
            onSearchCodeKeywordOnPaging: byGcNumberSearchCodeKeywordOnPaging,
            setTotalRecords: byGcNumberSetTotalRecords
        }
        ])
    }, [diagrams, diagramData, newSearchState])

    const getLikedProducts = () => {
        LikedProductService.searchLikedProducts('').then((response) => {
            setLikedProducts(
                response ? response.map((product) => {
                    return {
                        mpn: product.manufacturerCode,
                        img_url: product.imageUrl,
                        description: product.description,
                    };
                }) : [],
            )
        });
    };

    const handleSelectCategory = (item) => {
        setSelectedCategory(item.pnum);
        setSelectedDiagram(item);
    };

    const handleViewDiagramsNewSearch = async () => {
        setViewDiagrams(true);
        setSelectedDiagram(diagrams[0]);
        byDiagramOnSearch();
    }

    const handleViewDiagramsGCNumber = async () => {
        await getDiagramsGCNumber();
        setSelectedDiagram(diagrams[0]);
        setViewDiagrams(true);
    }


    useEffect(() => {
        diagrams?.length > 0 && selectedCategory > 0 && getDiagramData(
            newSearchState.manufacturerId,
            newSearchState.applianceId,
            selectedDiagram?.diagramid,
        );
    }, [selectedCategory, selectedDiagram])


    const checkAccount = () => {
        return AccountService.isAccountCompleted().then((response) => {
            setIsAccountCompleted(response);
        });
    };
    /**
     * @desc SparesFinderNavigationComp has multi view navigation button these buttons returns with their name
     * and SpareFinders triggers re-render the prefered view
     */
    const handleSparesFinderNavigationCompChange = (clickItemName, evt) => {
        changeTabsViewAndUrl(clickItemName);
    };
    /**
     * @desc sets selected view name  by SparesFinderNavigationComp onClick event return value assigned as a param
     */
    const changeTabsViewAndUrl = (tabViewName) => {
        changeTabInUrl(tabViewName);
        changeTabsView(tabViewName);
        newSearchSetState({ ...newSearchState, data: [] })/* TODO: add other setstate data to [] */
    };

    const changeTabsView = (tabViewName) => {
        setOnShowTabView(tabViewName);
        byPartCodeOnPartCodeKeywordSetTotalRecords(0)
        byGcNumberSetTotalRecords(0)
        byPartCodeSearchStateSetData([])
        byGcNumberSearchStateSetData([])
    };

    const changeTabInUrl = (tabViewName) => {
        const newUrl = `/Shop-2?tab=${tabViewName}`;
        history.push(newUrl);
    };

    const navigationArr = [
        {
            id: 1,
            icon: <SearchByApplianceIcon sx={{ fontSize: 30 }} />,
            text: "Select Appliance",
            viewKey: "newSearch",
        },
        {
            id: 2,
            icon: <SearchByNumberIcon sx={{ fontSize: 30 }} />,
            text: "Search by Appliance GC Number",
            viewKey: "searchByNumber",
        },
        {
            id: 3,
            icon: <SearchByKeywordIcon sx={{ fontSize: 30 }} />,
            text: "Search by Part Code or Keyword",
            viewKey: "searchByKeyword",
        },
    ];


    const hangleOnClickNav = (keyName, link) => {
        if (link) {
            return (window.location = link);
        }

        handleSparesFinderNavigationCompChange(keyName);
        newSearchSetState({ highlightActive: keyName });
    };

    const renderMultipleApplianceModal = (appliances, onSearch) => {
        let content = null;

        if (appliances.length > 1) {
            content = (
                <MultipleApplianceModal
                    className={'multiple-appliance'}
                    onClose={() => onSearch(appliances[0])}
                >
                    <div className={'multiple-appliance__content'}>
                        <h1 className={'modal-popup-header'}>We found more than 1 appliance, please select one...</h1>
                        <div className={'multiple-appliance__appliances'}>
                            {appliances.map((appliance, index) => {
                                if (!isMobile()) {
                                    return <div className={"appliance_item"}>
                                        <h4>{appliance.man}</h4>
                                        <div>
                                            <p>{appliance.app}</p>
                                            <button type="button" onClick={() => onSearch(appliance)}>Select</button>
                                        </div>
                                    </div>
                                } else {
                                    return <div className={"appliance_item"}>
                                        <div>
                                            <h4>{appliance.man}</h4>
                                            <p>{appliance.app}</p>
                                        </div>
                                        <button type="button" onClick={() => onSearch(appliance)}>Select</button>
                                    </div>
                                }
                            }
                            )}
                        </div>
                    </div>
                </MultipleApplianceModal>
            );
        }
        return content
    }

    const renderNoResultsMessage = (searchTerm) => {
        let content =
            <>
                <div className="search-term-container">
                    Search Results for <span className="searched-term">'{searchTerm}'</span> <span className="product-count">(0 products)</span>
                </div>
                <p>Your search has found no results. Please re-try using a spares/products search as appropriate, or contact us for assistance during office hours on <a href="tel:01978 666 888">01978 666 888</a> or <a href="mailto:info@buytrade.co.uk">info@buytrade.co.uk</a> .</p>
            </>
        return searchTerm && content;
    }

    const renderNewSearchBox = () => {
        return <FilterCommonFieldComp
            style={{ display: !newSearchState.searched ? "flex" : "none" }}
            gcSearchObj={newSearchState.gcSearchObj}
            partsListChanged={newSearchOnPartsListChanged}
            onSearch={newSearchOnSearch}
            isLoading={newSearchState.loading}
            showSearchByDiagram={showSearchByDiagram}
            setViewDiagrams={handleViewDiagramsNewSearch}
        />
    }


    const renderByGcNumberBox = () => {

        return <div className='search-fields-wrapper'>
            <div className={['search-fields', "finder-inputs-wrapper"].join(' ')} data-columns='3'>
                <>
                    <FinderInput
                        searchTerm={byGcNumberSearchStateSearchTerm}
                        searchTermError={byGcNumberSearchStateSearchTermError}
                        placeholder={"Appliance GC Number (Exclude dashes & spaces)"}
                        onChange={byGcNumberSearchChangeSearchTerm}
                    />
                    <SearchButton loading={byGcNumberSearchStateLoading} onSearch={byGcNumberSearchSearch} />
                </>
            </div>

            <div
                className='selected-appliance-text'
                style={{
                    display: 'flex',
                    padding: 0,
                    marginTop: '15px',
                    opacity: byGcNumberSearchStateFilterManufacturerName ? 1 : 0
                }}
            >
                <p>
                    You have selected: {byGcNumberSearchStateFilterManufacturerName} {byGcNumberSearchStateAppliance?.app}
                </p>
                <Stack direction="row">
                    {byGcNumberSearchApplianceDocumentExists() && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                            onClick={byGcNumberSearchOnViewManual}
                            className='link-like'
                            style={{ marginRight: 14 }}
                        >
                            <ArticleOutlinedIcon sx={{ color: '#fff' }} />
                            <span>View Manual</span>
                        </a>
                    )}
                    {showSearchByDiagram && byGcNumberSearchStateAppliance?.app &&
                        <a
                            onClick={handleViewDiagramsGCNumber}
                            className='link-like'
                        >
                            <QueryStatsOutlinedIcon sx={{ color: '#fff' }} />
                            <span>View Diagram</span>
                        </a>
                    }
                </Stack>
            </div>
        </div>
    }

    const renderPartCodeBox = () => {
        return <div className='search-fields-wrapper'>
            <div className={['search-fields', "finder-inputs-wrapper"].join(' ')} data-columns='3'>
                <>
                    <FinderInput
                        searchTerm={byPartCodeSearchStateSearchTerm}
                        searchTermError={byPartCodeSearchStateSearchTermError}
                        placeholder={"Part Code or Keyword"}
                        onChange={byPartCodeSearchChangeSearchTerm}
                    />
                    <SelectListStaticWithLabel
                        placeholder="Manufacturer"
                        valueProperty="manid"
                        textProperty="man"
                        collection={byPartCodeSearchStateManufacturers}
                        onChange={byPartCodeSearchSetManufacturer}
                        value={byPartCodeSearchStateFilterManufacturerId}
                        className="search-field-list"
                        outlined={byPartCodeSearchStateManufacturerError}
                        withoutWrapper
                    />
                    <SearchButton loading={byPartCodeSearchStateLoading} onSearch={byPartCodeSearchSearch} />
                </>
            </div>
            <div
                className='selected-appliance-text'
                style={{
                    display: 'flex',
                    padding: 0,
                    marginTop: '15px',
                    opacity: byPartCodeSearchStateFilterManufacturerName ? 1 : 0
                }}
            >
                <p>
                    You have selected: {byPartCodeSearchStateFilterManufacturerName} {byPartCodeSearchStateAppliance?.app}
                </p>
                {byPartCodeSearchApplianceDocumentExists() && (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                        onClick={byPartCodeSearchOnViewManual}
                        className='link-like'
                    >
                        View Manual
                    </a>
                )}
            </div>
        </div>
    }

    const renderDiagramBox = () => {
        return (
            <DiagramSearchComp
                style={{ display: !newSearchState.searched ? "flex" : "none" }}
                gcSearchObj={newSearchState.gcSearchObj}
                partsListChanged={newSearchOnPartsListChanged}
                onSearch={newSearchOnSearch}
                getDiagrams={onShowTabView === 'searchByNumber' ? getDiagramsGCNumber() : getDiagrams()}
                isLoading={newSearchState.loading}
                setViewDiagrams={setViewDiagrams}
            />
        )
    }

    const renderResultList = () => {
        let content = null

        switch (onShowTabView) {
            case 'newSearch':
                content = (
                    !viewDiagrams &&
                    <SearchResultsList
                        results={newSearchState.data || []}
                        loading={newSearchState.loading}
                        noResults={newSearchState.searched && !newSearchState.loading && newSearchState.data?.length === 0}
                        toggleLike={newSearchToggleLike}
                        onAddToBasket={addToBasket}
                        changeSort={newSearchChangeSort}
                        sortValues={SELECT_VALUES}
                        noItemsText='Select an appliance above and click search'
                        isAccountCompleted={isAccountCompleted}
                        showNewSortFilter={false}
                    />
                )
                break;
            case 'searchByNumber':
                content = (
                    !viewDiagrams &&
                    <SearchResultsList
                        results={byGcNumberSearchStateData?.filter(byGcNumberSearchFilterSearchResults) || []}
                        loading={byGcNumberSearchStateLoading}
                        noResults={byGcNumberSearchStateSearched && !byGcNumberSearchStateLoading && byGcNumberSearchStateData?.length === 0}
                        skip={byGcNumberSearchStateSkip}
                        take={byGcNumberSearchStateTake}
                        onPageChange={byGcNumberSearchPageChanged}
                        toggleLike={byGcNumberSearchToggleLike}
                        onAddToBasket={addToBasket}
                        changeSort={byGcNumberSearchChangeSort}
                        sortValues={SELECT_VALUES}
                        noItemsText='Enter details above and click search'
                        isAccountCompleted={isAccountCompleted}
                        showNewSortFilter={false}
                        children={
                            byGcNumberSearchStateSearchedAppliances &&
                            !byGcNumberSearchStateLoading
                            && byGcNumberSearchStateMultiple_appliance_modal
                            &&
                            renderMultipleApplianceModal(byGcNumberSearchStateAppliances, byGcNumberSearchOnSelectAppliance)}
                    />
                )
                break;
            case 'searchByKeyword':
                content = (
                    <SearchResultsList
                        results={byPartCodeSearchStateData?.filter(byPartCodeSearchFilterSearchResults) || []}
                        loading={byPartCodeSearchStateLoading}
                        noResults={byPartCodeSearchStateSearched && !byPartCodeSearchStateLoading && byPartCodeSearchStateData?.length === 0}
                        skip={byPartCodeSearchStateSkip}
                        take={byPartCodeSearchStateTake}
                        onPageChange={byPartCodeSearchPageChanged}
                        toggleLike={byPartCodeSearchToggleLike}
                        onAddToBasket={addToBasket}
                        changeSort={byPartCodeSearchChangeSort}
                        sortValues={SELECT_VALUES}
                        showNewSortFilter={false}
                        noItemsText='Enter details above and click search'
                        isAccountCompleted={isAccountCompleted}
                        children={byPartCodeSearchStateSearchedAppliances && !byPartCodeSearchStateLoading && byPartCodeSearchStateMultiple_appliance_modal
                            && renderMultipleApplianceModal(byPartCodeSearchStateAppliances, byPartCodeSearchOnSelectAppliance)}
                        noItemsHTML={
                            !byPartCodeSearchStateLoading
                            && byPartCodeSearchStateData?.length === 0
                            && !byPartCodeSearchRefreshSearchResults
                            && renderNoResultsMessage(byPartCodeSearchStateSearchTerm)
                        }
                    />
                )
                break;
            default:
                break;
        }

        return content
    }

    const renderDiagramSearch = () => {
        return (
            <>
                {diagrams.length > 0 &&
                    <SearchByDiagram
                        diagrams={diagrams}
                        manufacturer={newSearchState.partTypeId}
                        appliance={newSearchState.manufacturerName}
                        selectedCategory={selectedCategory}
                        selectedDiagram={selectedDiagram}
                        handleSelectCategory={handleSelectCategory}
                        handleDeselectCategory={() => {
                            setSelectedCategory(0)
                            setDiagramData([]);
                            setSelectedDiagram(diagrams[0]);
                        }}
                        results={!diagramData.totalCount > 0}
                        searchResultsList={diagramData.totalCount > 0 &&
                            <DiagramResultsList results={diagramData.data} onAddToBasket={addToBasket} />
                        }
                    />
                }
                {diagrams.length === 0 && newSearchState.searched &&
                    <NoItems
                        heading="No diagrams found"
                        body="No diagrams were were found for this appliance."
                    />
                }
            </>
        )
    }

    return (
        <>
            <Wrapper
                text="SparesFinder"
                selected="New search"
                newComponent={true}
                basket={basket}
            >
                <div className='shop-wrapper'>
                    {newBasketItem?.quantityChildValue ? newItemPopup() : null}

                    <div className='wrapper-content'>
                        <div className={'navigation-container max-width-1300'}>
                            <Grid container spacing={4} justifyContent='center'>
                                {(navigationArr.map((nav, index) =>
                                    <Grid item xs={11} sm={11} md={4} lg={4} key={index}>
                                        <div
                                            className={`navigation-item ${onShowTabView === nav.viewKey ? "active" : ""}`}
                                            onClick={() => hangleOnClickNav(nav.viewKey, nav.link)}
                                            style={{ padding: '16px 22px' }}
                                        >
                                            {nav.icon}
                                            <h4 style={{ fontSize: '14px', lineHeight: '16px' }}>{nav.text}</h4>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                        <div className={'search-form-container max-width-1300'}>
                            {onShowTabView === 'newSearch' && renderNewSearchBox()}
                            {onShowTabView === 'searchByNumber' && renderByGcNumberBox()}
                            {onShowTabView === 'searchByKeyword' && renderPartCodeBox()}
                            {showSearchByDiagram && onShowTabView === 'searchByDiagram' && renderDiagramBox()}
                        </div>
                    </div>

                    <div className="max-width-1300" style={{ width: "100vw" }}>
                        <div className={isTablet() ? 'results-wrapper-tablet' : ''}>
                            {renderResultList()}
                            {showSearchByDiagram && viewDiagrams && renderDiagramSearch()}
                        </div>
                        {!!byPartCodeSearchStateTotalRecords &&
                            <div className="pagination-wrapper">
                                <IBasisTablePager
                                    onChange={byPartCodeOnPartCodeKeywordOnPaging}
                                    totalRecords={byPartCodeSearchStateTotalRecords}
                                    pageNumber={byPartCodeSearchStatePageNumber}
                                /></div>}
                        {!!byGcNumberSearchStateTotalRecords &&
                            <div className="pagination-wrapper">
                                <IBasisTablePager
                                    onChange={byGcNumberSearchCodeKeywordOnPaging}
                                    totalRecords={byGcNumberSearchStateTotalRecords}
                                    pageNumber={byGcNumberSearchStatePageNumber}
                                /></div>}
                    </div>
                </div>
            </Wrapper>
        </>
    );
};

export default SparesFinder;
