import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    wrapper: {
        width: "100%",
    },
    "@media (max-width: 768px)": {
        wrapper: {
            height: 500
        },
    },
    "@media (min-width: 769px)": {
        wrapper: {
            height: 600
        }
    }
})

const Iframe = ({ source }) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            {source
                ? <iframe src={source} name="iframe_content" width="100%" height="100%" title="Iframe Component" frameBorder="0" scrolling="no"></iframe>
                : <p>Loading...</p>
            }
        </div>
    );
};

export default Iframe;
