import http from '../utils/http';

const getBranch = (branchId) => {
    const params = new URLSearchParams();
    params.append('branchId', branchId);

    return http.get(`Branch/GetBranch`, { params })
}

export default {
    getBranch
}