import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {isMobile} from "../../../Media/Media";

const useStyles = makeStyles((theme) => ({
    '@media (max-width: 768px)': {
        productCard: {
            background: 'white',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '24px',
            position: 'relative',
            borderBottom: '1px solid #BACAD1',
            [theme.breakpoints.up('md')]: {
                borderRadius: '0px',
                margin: '0px',
            },
            '& img': {
                height: '101px',
                maxWidth: '192px',
                objectFit: "contain",
                marginBottom: 24
            }
        },
        productInfo: {
            alignSelf: 'self-start',
            color: '#454545',
            fontSize: '16px',
            lineHeight: '16px',
            textAlign: 'start',
        },
        description: {
            display: "flex",
            alignSelf: 'self-start',
            flexWrap: 'wrap',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
            color: '#454545',
            textAlign: 'start',
            maxHeight: 35
        },
        removeIconWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
                overflow: 'inherit',
            },
        },
        button: {
            // padding: '8px 15px',
            // background: '#009FDF',
            // borderRadius: '4px',
            // color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
            width: '100%',
            height: 34,
        },
        qtyWrapper: {
            color: '#ccc',
            borderRadius: 6,
            border: '1px solid #ccc',
            display: 'grid',
            gridTemplateColumns: '35px 15px 20px 15px',
            padding: '0 5px',
            alignItems: 'center',
            justifyContent: 'center',
        },
        applianceInfo: {
            alignSelf: 'self-start',
            color: '#454545',
            fontSize: '14px',
            lineHeight: '17px',
            textAlign: 'left',

            '&>span': {
                color: '#009FDF',
                fontSize: '16px',
                lineHeight: '19px',
                fontWeight: 700
            }
        },
        manufactureCode: {
            fontSize: '14px',
            textAlign: 'left'
        }
    },
    '@media (min-width: 769px)': {
        productCard: {
            marginBottom: '0',
            width: 'auto',
            background: 'white',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '50px 28px',
            borderBottom: '1px solid #BACAD1',
            marginRight: '0px',
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                borderRadius: '0px',
                margin: '0px',
            },
            '& :hover': {
                '& + svg': {
                    display: 'block',
                }
            },
            '& img': {
                height: '113.32px',
                maxWidth: '165px',
                objectFit: "contain",
                marginBottom: 34
            }
        },
        productInfo: {
            alignSelf: 'self-start',
            color: '#454545',
            fontSize: '16px',
            lineHeight: '19px',
        },
        applianceInfo: {
            alignSelf: 'self-start',
            color: '#454545',
            fontSize: '14px',
            lineHeight: '17px',
            textAlign: 'left',

            '&>span': {
                color: '#009FDF',
                fontSize: '16px',
                lineHeight: '19px',
                fontWeight: 700
            }
        },
        manufactureCode: {
            fontSize: '14px',
            textAlign: 'left'
        },
        description: {
            display: "flex",
            alignSelf: 'self-start',
            flexWrap: 'wrap',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '22px',
            color: '#454545',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'left',
            maxHeight: 45
        },
        removeIconWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
        },
        button: {
            // padding: '8px 15px',
            // background: '#009FDF',
            height: 34,
            // borderRadius: '4px',
            // color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
            whiteSpace: 'nowrap',
            width: '100%',
        },
    },
    qtyIcon: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '22px',
        lineHeight: '27px',
        height: '100%',
    },
    wrapper: {
        marginTop: '10px',
    },
    wrapperWithQty: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        height: 34,
        alignItems: 'center',
    },
    qty: {
        position: 'absolute',
        left: 10,
        color: '#454545',
        opacity: '50%',
        fontSize: '14px',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    like: {
        position: 'absolute',
        top: '35px',
        right: '27px',
    },
    addText: {
        paddingLeft: '10px',
        fontWeight: '400',
        display: 'inline-block',
    },
    addToBasketModal: {
        height: 'fit-content',
    },
    popup: {
        position: 'fixed',
        zIndex: 10000,
        top: 16,
        right: 16,
        backgroundColor: '#fff',
        borderRadius: 8,
        border: '2px solid #009fdf',
        boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 16,
        width: 250,
    },
    show: {
        transition: 'opacity 0.5s',
        opacity: 1,
    },
    hide: {
        opacity: 0,
        pointerEvents: 'none',
    },
    partFits: {
        fontWeight: '600',
    },
    magnifyingGlass: {
        position: 'absolute',
        cursor: 'pointer',
        right: 30,
        top: isMobile() ? 120 : 160,
        display: isMobile() ? 'block' : 'none'
    },
    removeText: {
        fontSize: 10,
        fontWeight: 600,
        color: '#828282',
        paddingLeft: 5,
        cursor: 'pointer',
    },
    productOptionsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 15,
    },
}));

const Product =({product}) => {

    const classes = useStyles();
    
    return (<div className={classes.productCard}>
        {product.id}
    </div>);
}

export default Product;