import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Wrapper from "./Components/Layout/Wrapper";
import getBasketCount from "./Helpers/common/getBasketCount";
import { useHistory } from "react-router-dom";
import AccountService from "./Services/AccountService";
import OrganisationSupplierService from "./Services/OrganisationSupplierService";
import BasketService from "./Services/BasketService";
import TextBox from "./Components/Form/TextBox";
import TextAreaEntry from "./Components/Form/TextAreaEntry";
import { isMobile, isTablet } from "./Components/Media/Media";
import clsx from 'clsx';
import { MerchantAccountValidationStatus } from "./constants/merchantAccountValidationStatus";
import CompleteAccountPopUp from "./Components/Account/CompleteAccountPopUp";
import NotificationService from "./Services/NotificationService"
import FeatureFlagContext from "./Context/FeatureFlagContext";


const useStyles = makeStyles({
  "@media (max-width: 768px)": {
    wrapper: {
      display: "none",
    },
    mobileWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#fff",
    },
    mobileTopWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "87px 10px 10px 10px",
    },
    headerText: {
      fontSize: 18,
      fontWeight: 700,
      paddingTop: 31,
      letterSpacing: .3,
      // paddingBottom: 10,
      margin: "0px 10px",
      color: "#009FDF",
    },
    messageTextBoxMobile: {
      marginTop: "-12px !important",
      padding: "0 10px !important"
    },
    messageBoxMobileHeight: {
      height: "129px !important",
      paddingTop: "14px !important"
    },
    textBoxesTemplateMobile: {
      padding: "10px",
    },
    slogan: {
      fontSize: 36,
      fontWeight: 800,
      lineHeight: "112.3%",
      color: "#00226A",
      width: 230,
      textAlign: "center",
      marginTop: 18,
    },
    formSloganMobile: {
      margin: `0`,
      fontWeight: 700,
    },
    aboutFormMobile: {
      marginBottom: 40
    },
    about: {
      fontSize: 18,
      textAlign: "center",
      color: "#00226A",
      marginTop: 20,

      "& p": {
        margin: 0,
      },

      "& p:nth-child(3)": {
        color: "#009FDF",
        margin: 0
      },

      "& a": {
        textDecoration: "underline !important",
        fontWeight: 700,
        color: "#00226A",
      },
    },
    buttonBlue: {
      // padding: "15px 20px",
      width: 204,
      height: 54,
      margin: "30px 0",
      alignSelf: "center",
      // borderRadius: 6,
      // backgroundColor: "#009FDF",
      // color: "#FFF",
      fontWeight: 600,
      fontSize: 18,

      // "&:focus,&:active": {
      //   outline: 0,
      // },
    },
    SubmitButtonMobile: {
      marginTop: 24,
      marginBottom: 13
    },
    roadmap: {
      marginTop: "30px",
      marginBottom: "30px",
    },
    bannerContainer: {
      display: "flex",
      justifyContent: "center",
      backgroundImage: "url('/img/bg_dots.png'), url('/img/bg_waves.png')",
      backgroundSize: "80px, 145px",
      backgroundRepeat: "no-repeat, no-repeat",
      backgroundPosition: "top 160px left, bottom 178px right",
    },
    banners: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "36px 33px 76px 33px",
      "& img": {
        maxWidth: "350px",
        marginBottom: 24,
        width: '100%',
      },
    },
    newSearch: {
      backgroundColor: "#009FDF",
      backgroundImage: "url('/img/new_search_mobile_bg.png')",
      height: 221,
      color: "#fff",
      width: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      backgroundSize: "94%",
      backgroundPositionY: 14,
      backgroundRepeat: "no-repeat",
      padding: "52px 10px 24px 10px",
      marginBottom: 50,

      "& p": {
        fontSize: 24,
        fontWeight: 700,
        marginBottom: -30,
      },
    },
    buttonWhite: {
      // padding: "20px 20px",
      width: 224,
      maxWidth: "400px",
      margin: "43px 0",
      alignSelf: "center",
      // borderRadius: 6,
      // backgroundColor: "#FFF",
      // color: "#007AAB",
      height: 54,
      fontSize: 18,
      fontWeight: 600,
      // "&:focus,&:active": {
      //   outline: 0,
      // },
    },
    descriptionMobile: {
      fontSize: 14,
      fontWeight: 400
    },
    descriptionMobileSpace: {
      marginTop: "10px !important",
    },
    termsContainerMobile: {
      marginTop: "18px !important",
    },
    termsTextMobile: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      width: "100%",
      color: "#828282",
      fontSize: 14,
      padding: 0,
      margin: 0,
      "& a": {
        fontWeight: 700,
        color: "#009FDF !important",
      },
    },
    roadmapTablet: {
      display: "none"
    },
    termsContainerTablet: {
      display: "none !important"
    },
  },
  "@media (min-width: 768px) and (max-width: 900px)": {
    roadmap: {
      maxWidth: '-webkit-fill-available !important'
    },
  },
  "@media (min-width: 769px)": {
    wrapper: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "space-between",
      minHeight: "80vh",
      marginBottom: 70,
      backgroundColor: '#fff',
    },
    mobileWrapper: {
      display: "none",
    },
    headerText: {
      fontSize: 18,
      fontWeight: 700,
      paddingTop: "100px",
      margin: "0px 10px",
      color: "#009FDF",
    },
    slogan: {
      fontSize: 48,
      fontWeight: 800,
      color: "#00226A",
      textAlign: "center",
    },
    about: {
      fontSize: 18,
      color: "#00226A",
      marginTop: 20,
      textAlign: "center !important",

      "& p": {
        margin: 20,
        textAlign: "center !important",
      },

      "& p:nth-child(3)": {
        color: "#009FDF",
        margin: 0
      },

      "& a": {
        textDecoration: "underline !important",
        fontWeight: 700,
        color: "#00226A",
      },
    },
    EmailUs: {
      fontSize: 18,
      textAlign: "center",
      color: "#00226A",
      margin: 0,
      marginTop: '-1rem',
      padding: 0,

      "& a": {
        fontWeight: 700,
        color: "#009FDF",
      },
    },
    AboutDescriptionTop: {
      marginBottom: "0 !important"
    },
    AboutDescriptionBottom: {
      marginTop: "0 !important",
      marginBottom: "10px !important",
    },
    buttonBlue: {
      width: 381,
      maxWidth: "400px",
      margin: "43px 0",
      alignSelf: "center",
      height: 59,
    },
    SubmitButton: {
      maxWidth: 240,
      marginTop: 12,
      marginBottom: '17px'
    },
    roadmap: {
      maxWidth: "calc(1300px * 80 / 100)",
      margin: "0 auto",
    },
    bannerContainer: {
      display: "flex",
      justifyContent: "center",
      backgroundImage: "url('/img/bg_dots.png'), url('/img/bg_waves.png')",
      backgroundSize: "70px, 145px",
      backgroundRepeat: "no-repeat, no-repeat",
      backgroundPosition: 'left 78px top 9px, right 73px bottom 30px'
    },
    banners: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "30px 0 60px 0",
      maxWidth: "80%",
      "& img": {
        width: "32%",
        maxWidth: "350px",
      },
    },
    newSearch: {
      backgroundColor: "#009FDF",
      backgroundImage: "url('/img/bg_elements.png')",
      height: 271,
      color: "#fff",
      width: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      backgroundSize: "cover",
      marginTop: 30,

      "& p": {
        fontSize: 48,
        fontWeight: 500,
        marginBottom: -30,
      },
    },
    buttonWhite: {
      width: 381,
      maxWidth: "400px",
      margin: "43px 0",
      alignSelf: "center",
      height: 59,
      fontSize: 18,
      fontWeight: 600,
    },
  },
  '@media (min-width: 640px) and (max-width: 768px)': {
    slogan: {
      width: 580,
      textAlign: "center",
    },
    buttonBlue: {
      margin: "25px 0",
    },
    roadmap: {
      display: "none"
    },
    roadmapTablet: {
      display: "block !important",
      maxWidth: "90vw !important",
      margin: "0 auto",
    },
    bannerContainer: {
      display: "flex",
      justifyContent: "center",
      backgroundImage: "url('/img/bg_dots.png'), url('/img/bg_waves.png')",
      backgroundSize: "100px, 178px",
      backgroundRepeat: "no-repeat, no-repeat",
      backgroundPosition: "top 10px left, bottom right",
    },
    banners: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "30px 0 60px 0",
      maxWidth: "95%",
      "& img": {
        width: "32%",
        maxWidth: "350px",
      },
    },
    newSearch: {
      backgroundImage: "url('/img/bg_elements.png')",
      height: 220,

      "& p": {
        fontSize: 24,
        fontWeight: 500,
      },
    },
    termsContainerMobile: {
      display: 'none !important'
    },
    termsContainerTablet: {
      display: 'block !important',
      marginTop: "18px !important",
    },
    aboutFormMobile: {
      marginBottom: 20
    },
    textBoxesTemplateTablet: {
      width: "calc(100% / 3)"
    },
  },
  innerText: {
    paddingTop: "10px",
    fontSize: "14px",
    margin: "0px 10px",
  },
  suppliers: {
    display: "flex",
  },
  suppliersItem: {
    margin: "10px",
    width: "100px",
    height: "50px",
  },
  transparentButton: {
    border: "1px solid #3EC4F4",
    padding: "15px 20px",
    width: "calc(100% - 20px)",
    maxWidth: "400px",
    color: "#3EC4F4",
    margin: "10px",
  },
  headerWrapper: {
    textAlign: "center",
  },
  welcomePage: {
    width: "100vw",
    padding: "0 70px 60px 70px",
    marginTop: "-10px",
  },
  clickedImage: {
    cursor: 'pointer'
  },
  termsText: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: "center",
    width: "100%",
    color: "#828282",
    "& a": {
      fontWeight: 700,
      color: "#009FDF !important",
    },
  },
  textBoxes: {
    border: "1px solid #009FDF",
    padding: "24px",
    fontSize: "16px",

    "&::placeholder": {
      color: '#b3b3b3'
    }
  },

  messageTextBox: {
    padding: "10px 16px",
    verticalAlign: "text-top",
    borderColor: "#009FDF",
    fontSize: 16,
    rows: 4,
    color: "#009FDF !important",
  },
  messageBoxHeight: {
    height: "109px !important",
    paddingTop: "14px"
  },
  textBoxesTemplate: {
    padding: "20px"
  },
  formHeaderSection: {
    marginTop: 60,
  },
  formSlogan: {
    marginTop: 70,
    marginBottom: -3,
    fontWeight: 700,
  },
  formFirstRow: {
    marginBottom: 38,
  },
  formSecondRow: {
    marginBottom: 0,
  },
  justifyCenter: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  errorField: {
    border: "1px solid red!important",
  },

  inputWithButton: {
    position: "relative",
  },
  inputWithButtoninput: {
  },
  inputWithButtonButton: {
    position: "absolute",
    right: "30px",
    top: "30%",
    width: "20px",
    transform: 'translateY(-20%)'
  },
  errorMessage: {
    color: '#ED1F45',
    fontSize: 14,
    lineHeight: '17px',
    fontWeight: 500,
    marginTop: 6,
    marginLeft: 20
  },
  submittedFormMessage: {
    display: "flex",
    alignItems: "center",
    fontSize: '14px',
    lineHeight: '17px',
    color: '#81C454',

    "&>img": {
      marginRight: 5
    }
    },
  confirmationContainer: {
      backgroundColor: '#e0ffc7',
      border: '1px solid #b4d39b',
      color: '#333',
      margin: '25px',
      padding: '15px 10px 5px 10px'
  },
  selectList: {
    margin: 0
  },
  selectBar: {
    border: "1px solid #009FDF !important",
    height: "50px !important",
    fontSize: "16px !important",
    borderRadius: "6px !important",
    paddingLeft: "24px !important",

    "& > span": {
      color: '#b3b3b3 !important',
      fontSize: "16px",
    },
    "& > input": {
      color: '#454545 !important',
      paddingLeft: "4px !important",
    },
  },
  selectBarSelected: {
    "& > span": {
      color: '#454545 !important',
    },
  },
  popUp: {
    border: "1px solid #009FDF !important",
    top: '48px !important'
  }
});

export default function Homepage() {
  const classes = useStyles();
  const history = useHistory();
  
  const featureFlagContext = useContext(FeatureFlagContext);

  const [basket, setBasket] = useState({ lines: [] });

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setlastNameError] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [messageError, setmessageError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [showCompleteAccountPopUp, setshowCompleteAccountPopUp] = useState(false)

  const goToSearchPage = () => {
    history.push("/shop");
  };

  const getOrganisation = async () => {
    AccountService.isAccountCompleted().then((response) =>
      setshowCompleteAccountPopUp(!response)
    )
  }

  const unShowCompleteAccountPopUp = () => {
    if (showCompleteAccountPopUp) {
      setshowCompleteAccountPopUp(false);
    }
  }
  useEffect(() => {
    getOrganisation();
  }, []);

  const loadBasket = () => {
    BasketService.getBasketForUser().then((response) => {
      if (!response.lines) response.lines = [];
      setBasket(response);
    });
  };

  const onHelpFormSubmit = () => {
    const formIsValid = fieldValidation();

    if (formIsValid) {
      sendForm()
      !formSubmitted && setFormSubmitted(true)
    }
  }

  const sendForm = () => {
      NotificationService.SendContactUsEmail({ firstName, lastName, email, phoneNumber, message })
  }

  const IsAnyErrors = () => {
    return (
      firstNameError ||
      lastNameError ||
      emailError ||
      messageError
    );
  };

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const fieldValidation = () => {
    let formIsValid = true;

    if (firstName) {
      setFirstNameError(false)
    } else {
      setFirstNameError(true)
      formIsValid = false
    }

    if (lastName) {
      setlastNameError(false)
    } else {
      setlastNameError(true)
      formIsValid = false
    }

    if (message) {
      setmessageError(false)
    } else {
      setmessageError(true)
      formIsValid = false
    }

    if (validateEmail(email)) {
      setemailError(false)
    } else {
      setemailError(true)
      formIsValid = false
    }

    return formIsValid
  }

  const formRef = React.useRef(null);
  const mobileFormRef = React.useRef(null);
  const scrollToHelpForm = () => {
    mobileFormRef.current.scrollIntoView();
    formRef.current.scrollIntoView()
  }

    if (featureFlagContext.checkFeatures("all-products")) {
        history.push('/homepage-2')
    }

  return (
    <Wrapper text="HOMEPAGE" selected="Home" count={getBasketCount(basket)}>
    <div className={classes.mobileWrapper}>
        <div className={classes.mobileTopWrapper}>
            <div className={classes.headerText}>Welcome to BuyTrade</div>
            <div className={classes.slogan}>
                Right&nbsp;Product, Right&nbsp;Price, Right&nbsp;Now.
            </div>
            <div className={classes.about}>
                <p className={isMobile() ? classes.descriptionMobile : ""}>Heating spares on demand</p>
                <p className={isMobile() ? classes.descriptionMobile : ""}>Search and shop with the ultimate convenience</p>
                <p className={isMobile() ? [classes.descriptionMobile, classes.descriptionMobileSpace].join(' ') : ""}>Need more than just heating spares?&nbsp;<a onClick={scrollToHelpForm}>Let us help!</a></p>
            </div>
            <button
                type="button"
                className={` ${classes.buttonBlue} button-large button-light-blue`}
                onClick={goToSearchPage}
            >
                Start New Search
            </button>
            <img
                src="/img/roadmap_mobile.png"
                alt="roadmap"
                className={classes.roadmap}
            />
            <img
                src="/img/roadmap.png"
                alt="roadmap"
                className={classes.roadmapTablet}
            />
        </div>
        <div className={classes.bannerContainer}>
            <div className={classes.banners}>
                <img
                    src="/img/banner_1.png"
                    alt="banner_1"
                    className={classes.clickedImage}
                    onClick={goToSearchPage}
                />
                <img
                    src="/img/banner_2.png"
                    alt="banner_2"
                    className={classes.clickedImage}
                    onClick={goToSearchPage}
                />
                <img
                    src="/img/banner_3.png"
                    alt="banner_3"
                    className={classes.clickedImage}
                    onClick={goToSearchPage}
                />
            </div>
        </div>
        <div className={classes.newSearch}>
            <p>Start New Search</p>
            <button
                type="button"
                className={` ${classes.buttonWhite} button-large button-white`}
                onClick={goToSearchPage}
            >
                New Search
            </button>
        </div>
        <div style={{ width: "85%", marginBottom: '40px' }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <h1 className={[classes.slogan, classes.formSloganMobile].join(' ')} style={{ fontSize: "24px" }} ref={mobileFormRef}>Need more than just spares?</h1>
            </div>
             <p className={[classes.about, classes.aboutFormMobile].join(' ')}>Contact us now with your product requirements and we will source these for you at competitive price.</p>
            {!formSubmitted &&
                <div>
                  <div className="template__grid">
                      <div className="row">
                          <div className={clsx((isTablet ? "col-md-3 " : "col-md-12 ") + classes.textBoxesTemplateMobile, classes.inputWithButton)}>
                              <TextBox
                                  required
                                  name="firstName"
                                  placeholder="First Name*"
                                  value={firstName}
                                  onChange={setFirstName}
                                  className={clsx(classes.textBoxes, classes.inputWithButtoninput, firstNameError ? classes.errorField : "")}
                              />
                              {firstNameError && <div className={clsx(classes.errorMessage)} ><strong>First Name</strong> is required </div>}
                          </div>
                          <div className={(isTablet ? "col-md-3 " : "col-md-12 ") + classes.textBoxesTemplateMobile}>
                              <TextBox
                                  required
                                  name="lastName"
                                  placeholder="Last Name*"
                                  value={lastName}
                                  onChange={setLastName}
                                  className={clsx(classes.textBoxes, lastNameError ? classes.errorField : "")}
                              />
                              {lastNameError && <div className={clsx(classes.errorMessage)} ><strong>Last Name</strong> is required </div>}
                          </div>
                          <div className={(isTablet ? "col-md-3 " : "col-md-12 ") + classes.textBoxesTemplateMobile}>
                              <TextBox
                                  required
                                  name="email"
                                  placeholder="Email*"
                                  value={email}
                                  onChange={setEmail}
                                  className={clsx(classes.textBoxes, emailError ? classes.errorField : "")}
                              />
                              {emailError && <div className={clsx(classes.errorMessage)} ><strong>Email</strong> is required </div>}
                          </div>
                          <div className={(isTablet ? "col-md-3 " : "col-md-12 ") + classes.textBoxesTemplateMobile}>
                              <TextBox
                                  name="number"
                                  placeholder="Contact Number"
                                  value={phoneNumber}
                                  onChange={setPhoneNumber}
                                  className={clsx(classes.textBoxes)}
                              />
                          </div>
                      </div>

                      <div className="row">
                          <div className={"col-md-12 " + [classes.messageTextBox, classes.messageTextBoxMobile].join(' ')}>
                              <TextAreaEntry
                                  required
                                  name="message"
                                  placeholder="Message"
                                  value={message}
                                  onChange={setMessage}
                                  className={clsx(classes.textBoxes, classes.messageBoxMobileHeight, messageError ? classes.errorField : "")}
                              />
                              {messageError && <div className={clsx(classes.errorMessage)} ><strong>Message</strong> is required </div>}
                          </div>
                      </div>
                  </div>
                  <span className={[classes.termsTextMobile, classes.termsContainerMobile].join(' ')}>
                      <p className={classes.termsTextMobile}>This site is protected by reCAPTCHA and the </p>
                      <p className={classes.termsTextMobile}>Google&nbsp;
                          <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy&nbsp;</a> and&nbsp;<br />
                          <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a>&nbsp;apply.</p>
                  </span>
                  <p className={[classes.termsText, classes.termsContainerTablet].join(' ')}>This site is protected by reCAPTCHA and the Google&nbsp;
                      <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy&nbsp; <br /></a> and&nbsp;
                      <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a>&nbsp;apply.</p>

                  <div className={classes.justifyCenter}>
                      <button type="button"
                          className={` ${[classes.buttonBlue, classes.SubmitButtonMobile].join(' ')} button-large button-light-blue`}
                          onClick={onHelpFormSubmit}
                      >
                          Submit
                      </button>
                  </div>
                </div>
            }

            {formSubmitted &&
              <div className={classes.confirmationContainer}>
                  <p>Thanks for contacting us! We will be in touch with you shortly.</p>
              </div>
            }
        </div>
    </div>
    <div className={classes.wrapper} onClick={unShowCompleteAccountPopUp}>
        <div className={classes.headerWrapper}>
            <div className={classes.welcomePage}>
                <div className={classes.headerText}>Welcome to BuyTrade</div>
                <div className={classes.slogan}>
                    Right Product, Right Price, Right Now
                </div>
                <div className={classes.about}>

                    <p className={!isMobile() ? classes.AboutDescriptionTop : ""}>Heating spares on demand</p>
                    <p className={!isMobile() ? classes.AboutDescriptionBottom : ""}>Search and shop with the ultimate convenience</p>
                    <p>Need more than just heating spares?&nbsp;<a onClick={scrollToHelpForm}>Let us help!</a></p>

                </div>
                <button
                    type="button"
                    className={` ${classes.buttonBlue} button-large button-light-blue`}
                    onClick={goToSearchPage}
                >
                    New Search
                </button>
                <img
                    src="/img/roadmap.png"
                    alt="roadmap"
                    className={classes.roadmap}
                />
            </div>
        </div>
        <div className={classes.bannerContainer}>
            <div className={classes.banners}>
                <img
                    src="/img/banner_1.png"
                    alt="banner_1"
                    className={classes.clickedImage}
                    onClick={goToSearchPage}
                />
                <img
                    src="/img/banner_2.png"
                    alt="banner_2"
                    className={classes.clickedImage}
                    onClick={goToSearchPage}
                />
                <img
                    src="/img/banner_3.png"
                    alt="banner_3"
                    className={classes.clickedImage}
                    onClick={goToSearchPage}
                />
            </div>
        </div>
        <div className={classes.newSearch}>
            <p>Start New Search</p>
            <button
                type="button"
                className={` ${classes.buttonWhite} button-large button-white`}
                onClick={goToSearchPage}
            >
                New Search
            </button>
        </div>

        <div style={{ width: "85%" }}>
            <h1 className={[classes.slogan, classes.formSlogan].join(' ')} ref={formRef}>Need more than just spares?</h1>

            <p className={classes.about}>Contact us now with your product requirements and we will source these for you at competitive price.</p>
            <p className={classes.EmailUs}>Complete the form or email us on <a href="mailto:info@buytrade.co.uk">info@buytrade.co.uk</a></p>
            {!formSubmitted &&
              <div>
                  <div className={"template__grid " + classes.formHeaderSection}>
                    <div className={"row mb-2"}>
                      <div className={"col-12 col-md-4 col-lg-3"}>
                        <TextBox
                          required
                          name="firstName"
                          placeholder="First Name*"
                          value={firstName}
                          onChange={setFirstName}
                          className={clsx(classes.textBoxes, firstNameError ? classes.errorField : "")}
                        />
                        {firstNameError && <div className={clsx(classes.errorMessage)} ><strong>First Name</strong> is required </div>}
                      </div>
                              <div className={"col-12 col-md-4 col-lg-3"}>
                        <TextBox
                          required
                          name="lastName"
                          placeholder="Last Name*"
                          value={lastName}
                          onChange={setLastName}
                          className={clsx(classes.textBoxes, lastNameError ? classes.errorField : "")}
                        />
                        {lastNameError && <div className={clsx(classes.errorMessage)} ><strong>Last Name</strong> is required </div>}
                      </div>
                        <div className={"col-12 col-md-4 col-lg-3"}>
                        <TextBox
                          required
                          name="email"
                          placeholder="Email*"
                          value={email}
                          onChange={setEmail}
                          className={clsx(classes.textBoxes, emailError ? classes.errorField : "")}/>
                          {emailError && <div className={clsx(classes.errorMessage)} ><strong>Email</strong> is required </div>}
                      </div>
                            <div className={"col-12 col-md-4 col-lg-3"}>
                            <TextBox
                                name="phoneNumber"
                                placeholder="Contact Number"
                                value={phoneNumber}
                                onChange={setPhoneNumber}
                                className={clsx(classes.textBoxes)}/>
                        </div>
                    </div>

                   <div className="row">
                      <div className={"col-md-12 " + classes.messageTextBox}>
                        <TextAreaEntry
                          required
                          name="message"
                          placeholder="Message"
                          value={message}
                          onChange={setMessage}
                          className={clsx(classes.textBoxes, classes.messageBoxHeight, messageError ? classes.errorField : "")}
                        />
                        {messageError && <div className={clsx(classes.errorMessage)} ><strong>Message</strong> is required </div>}
                      </div>
                    </div>
                  </div>
                  <p className={classes.termsText}>This site is protected by reCAPTCHA and the Google&nbsp;
                    <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy&nbsp; <br /></a> and&nbsp;
                    <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a>&nbsp;apply.</p>

                  <div className={classes.justifyCenter}>
                    <button type="button"
                      className={` ${[classes.buttonBlue, classes.SubmitButton].join(' ')} button-large button-light-blue`}
                      onClick={onHelpFormSubmit}
                    >
                      Submit
                    </button>
                  </div>
               </div>
            }
            {formSubmitted &&
              <div className={classes.confirmationContainer}>
                  <p>Thanks for contacting us! We will be in touch with you shortly.</p>
              </div>
             }
        </div>

    </div>
    {showCompleteAccountPopUp && <CompleteAccountPopUp onClose={unShowCompleteAccountPopUp} />}
    </Wrapper>
  );
}
