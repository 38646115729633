import React, {useContext} from "react";
import Grid from "@mui/material/Grid";
import PartsIdentificationModeSelector from "./ModeSelector/PartsIdentificationModeSelector";
import SelectListStaticWithLabel from "../../Form/SelectListStaticWithLabel";
import SearchButton from "../../Shop/SearchButton";
import {isMobile} from "../../Media/Media";
import {Stack} from "@mui/material";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import FinderInput from "../../Shop/FinderInput";
import FeatureFlagContext from "../../../Context/FeatureFlagContext";

const PartsIdentificationSearchFilters = (props) => {
    
    const featureFlagContext = useContext(FeatureFlagContext);
    const areDiagramsEnabled = featureFlagContext.checkFeatures("ibasis-diagrams");
    
    const {
        manufacturers, 
        onManufacturerChanged,
        selectedManufacturer,
        appliances,
        onApplianceChanged,
        selectedAppliance,
        partTypes,
        onPartTypeChanged,
        selectedPartType,
        searchText,
        setSearchText,
        onSearch,
        onDiagramSelected,
        onViewManual,
        isLoading,
        hasSearched
    } = props;
    
    const isApplianceMode=()=> checkMode(1);
    const isGcMode = () => checkMode(2);
    const isGeneralMode = () =>  checkMode(3);
    const checkMode = (modeId) => props.selectedMode.id===modeId;
    
    return (
        <div className={'navigation-container max-width-1300'}>
            <PartsIdentificationModeSelector {...props} />

            <div className={'search-form-container max-width-1300'}>
                <div className='search-fields-wrapper'>
                    <>
                        <div className='search-fields'>
                            { (isApplianceMode() || isGeneralMode() ) && <SelectListStaticWithLabel
                                placeholder="Manufacturer"
                                valueProperty="manid"
                                textProperty="man"
                                collection={manufacturers}
                                onChange={onManufacturerChanged}
                                value={selectedManufacturer?.value}
                                outlined={hasSearched === true && selectedManufacturer === null}
                            />}
                            { isApplianceMode() && <SelectListStaticWithLabel
                                placeholder="Appliance"
                                valueProperty="appid"
                                textProperty="app"
                                collection={appliances}
                                onChange={onApplianceChanged}
                                value={selectedAppliance?.value}
                                outlined={hasSearched === true && selectedAppliance === null}
                            />}
                            { isApplianceMode() && <SelectListStaticWithLabel
                                placeholder="Parts Types"
                                valueProperty="part_type_id"
                                textProperty="part_type"
                                collection={partTypes}
                                onChange={onPartTypeChanged}
                                value={selectedPartType?.value}
                                outlined={hasSearched === true && selectedPartType === null}
                            />}

                            { (isGeneralMode() || isGcMode()) && <FinderInput
                                searchTerm={searchText}
                                searchTermError={hasSearched && (searchText=="" || searchText==null) ? "Please enter search text" : ""}
                                placeholder={isGeneralMode() ? "Part Code or Keyword" : "Appliance GC Number (Exclude dashes & spaces)"}
                                onChange={setSearchText}
                            /> }
                            
                            <SearchButton loading={isLoading} onSearch={onSearch} />
                        </div>
                        <div
                            className='selected-appliance-text'
                            style={{ marginTop: '10px', display: isMobile() ? 'block' : 'flex', opacity: 100 }}
                        >
                            {selectedAppliance!=null && <p>
                                You have
                                selected: {selectedAppliance.text}
                            </p>}
                        </div>

                        <div>
                            <Stack direction="row" spacing={2} mt={2}>
                                {selectedAppliance != null && selectedAppliance.extras !== "" && selectedAppliance.extras !== null && (
                                    <a
                                        onClick={onViewManual}
                                        className="link-like" >
                                        <ArticleOutlinedIcon sx={{ color: '#fff' }} />
                                        <span>View Manual</span>
                                    </a>
                                )}

                                    {selectedAppliance != null && areDiagramsEnabled && <a
                                        onClick={onDiagramSelected}
                                        className="link-like" >
                                        <QueryStatsOutlinedIcon sx={{ color: '#fff' }} />
                                        <span>View Diagrams</span>
                                    </a>}
                            </Stack>
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
}

export default PartsIdentificationSearchFilters;