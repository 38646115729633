import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Wrapper from "./Components/Layout/Wrapper";
import BasketService from "./Services/BasketService";
import { useHistory } from "react-router-dom";
import Header from "./Components/Header/Header";
import continueIcon from "./assets/img/continue-basket.svg";
import FeatureFlagContext from "./Context/FeatureFlagContext";
import SaveForLaterService from "./Services/SaveForLaterService";
import AccountService from "./Services/AccountService";
import SaveForLaterGrid from "./Components/Basket/SaveForLaterGrid/SaveForLaterGrid";
import BasketRow from "./Components/Basket/BasketRow/BasketRow";
import NoItems from "./Components/Basket/NoItems/NoItems";
import toastr from "toastr";
import ReactGA from 'react-ga4';
import configurationService from './Services/ConfigurationService'

const useStyles = makeStyles({
    "@media (max-width: 900px)": {
        tableRow: {
            display: "grid",
            position: "relative",
            gridTemplateColumns: "64px 1fr",
            alignItems: "center",
            gridGap: 16,
            borderBottom: "1px solid #bacad1",
            paddingBottom: 17,
            paddingTop: 20,
            "& img": {
                maxWidth: "100%"
            },
            "&:last-child": {
                borderBottom: "1px solid transparent",
            },
        },
        tableWrapper: {
            borderRadius: 33,
            border: "1px solid #bacad1",
            padding: "0px 20px 0 20px",
            marginBottom: 44,
        },
        button: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "215px",
            alignSelf: "center",
            marginBottom: 40,
        },
        basketContainer: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#fff",
            padding: 30,
        },
    },
    "@media (min-width: 900px)": {
        tableRow: {
            display: "grid",
            gridTemplateColumns: "64px 100px 1fr 90px 150px",
            alignItems: "center",
            gridGap: 32,
            borderBottom: "1px solid #bacad1",
            paddingBottom: 17,
            paddingTop: 17,
            "& img": {
                maxWidth: "100%",
                minHeight: 60,
                objectFit: 'contain',
            },
            "&:last-child": {
                borderBottom: "1px solid transparent",
            },
        },
        tableWrapper: {
            borderRadius: 33,
            border: "1px solid #bacad1",
            padding: "5px 60px 5px 47px",
            marginBottom: 44,
        },
        continueBtnContainer: {
            display: "flex",
            justifyContent: "flex-end"
        },
        button: {
            width: "215px",
            "& img": {
                height: 13,
                paddingLeft: 12,
                display: 'inline'
            },
        },
        saveForLater: {
            fontWeight: 500,
            fontSize: '14px',
            textDecoration: 'underline',
            color: '#009FDF',
            "&:hover": {
                cursor: 'pointer',
            },
        },
    },
    tableHeader: {
        display: "grid",
        gridTemplateColumns: "64px 100px 1fr 90px 150px",
        fontSize: 14,
        color: "#828282",
        padding: "20px 48px",
        gridGap: 32,
    },
});


export default function Basket(props) {

    const featureFlagContext = useContext(FeatureFlagContext);
    const isAllProducts = featureFlagContext.checkFeatures("all-products");

    const classes = useStyles();
    const history = useHistory();
    const [basket, setBasket] = useState({ lines: [] });

    const [savedForLaterItems, setSavedForLaterItems] = useState([])
    const [savedForLaterItemsLoading, setSavedForLaterItemsLoading] = useState(true)
    const [saveForLaterError, setSaveForLaterError] = useState(false);
    const [orderReference, setOrderReference] = useState("");
    const [savedPartsItems, setSavedPartsItems] = useState([]);
    const [disableBuyTradeCheckout, setDisableBuyTradeCheckout] = useState(true)

    const goToAvailability = () => {
        if (basket.lines.length) {
            history.push("/Basket/Availability");
        } else {
            history.push("/shop");
        }
    };

    const continueBrowsing = () => {
        window.location = isAllProducts ? "/" : "/Shop";
    };

    const handleOrderReferenceChange = e => setOrderReference(e)



    const createSaveForLater = async (item) => {
        const saveForLaterObject = {
            OrgID: -1,
            ImageUrl: item.imageUrl,
            Title: item.description,
            DateTime: new Date(),
            ManufacturerCode: item.manufacturerCode,
            Qty: item.qty,
            Description: item.description,
        }
        const createSaveForLaterResult = await SaveForLaterService.createSaveForLater(saveForLaterObject);


        if (createSaveForLaterResult === true) {
            setSavedForLaterItemsLoading(true);
            getSaveForLaters();
        } else {
            setSaveForLaterError(true);
        }

    };

    const deleteSaveForLater = async (id) => {
        const deleteSaveForLaterResult = await SaveForLaterService.deleteSaveForLater(id);

        if (deleteSaveForLaterResult) {
            setSavedForLaterItemsLoading(true);
            getSaveForLaters();
        }
    };

    const getSaveForLaters = async () => {
        const getSaveForLaterResults = await SaveForLaterService.getSaveForLaters();
        setSavedForLaterItems(getSaveForLaterResults);
        setSavedForLaterItemsLoading(false);
    };

    const getSavedParts = async () => {
        const getSavePartsResults = await SaveForLaterService.getSavedParts();
        setSavedPartsItems(getSavePartsResults);
    };

    const bulkDeleteSaveForLater = async (orderReference) => {
        const bulkDeleteSaveForLaterResult = await SaveForLaterService.bulkDeleteSaveForLater(orderReference);
        console.log(bulkDeleteSaveForLaterResult);

        if (bulkDeleteSaveForLaterResult) {
            getSavedParts();
        }
    };




    useEffect(() => {
        getSaveForLaters();
        getSavedParts();
        getUserDetails();
    }, [])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const getUserDetails = async () => {
        await AccountService.userDetails().then((response) => {
            setDisableBuyTradeCheckout(response.disableBuyTradeCheckout)
        });
    }

    const getBasket = () => {
        return new Promise((res) => {
            BasketService.getBasketForUser().then((response) => {
                if (response.lines == null) response.lines = [];
                else response.lines = response.lines.filter(l => l.basketLineType.name === "Product")
                setBasket(response);
                res && res();
            });
        });
    };


    useEffect(() => {
        getBasket();
    }, []);

    const updateBasket = async (newBasket) => {
        await BasketService.updateBasketForUser(newBasket).then((basket = {}) => {
            if (!basket.lines) basket.lines = [];
            setBasket(basket);
        });
    };

    const addToBasket = async (basketItem) => {
        const newBasket = { ...basket };

        const currentIndex = basket.lines
            .map((item) => item.manufacturerCode)
            .indexOf(basketItem.item.manufacturerCode);

        const computedQty = basketItem.item.qty || basketItem.item.quantityChildValue;

        if (currentIndex > -1) {
            if (computedQty > 0) {
                newBasket.lines[currentIndex].qty = computedQty;
            } else if (computedQty == 0) {
                newBasket.lines.splice(currentIndex, 1);
            }
        } else {
            const newItem = {
                qty: computedQty,
                manufacturerCode:
                    basketItem.item.mpn || basketItem.item.manufacturerCode,
                description: basketItem.item.description,
                imageUrl: basketItem.item.imageUrl,
                iBasisManId: basketItem.item.manid || basketItem.item.iBasisManId,
                iBasisAppliance: basketItem.item.app || basketItem.item.iBasisAppliance,
                iBasisApplianceId:
                    basketItem.item.appid || basketItem.item.iBasisApplianceId,
                iBasisGCN: basketItem.item.pgcn || basketItem.item.iBasisGCN,
                IBasisApplianceGC:
                    basketItem.item.appgc || basketItem.item.IBasisApplianceGC,
                basketLineTypeId: basketItem.basketLineTypeId || 1
            };

            newBasket.lines.push(newItem);
        }
        //newBasket.lines = newBasket.lines.filter(({ qty }) => qty !== 0);
        await updateBasket(newBasket);

        configurationService.getConfigurationVariables().then(res => {
            if (res?.enableGoogleAnalytics) {
                ReactGA.event("add_to_cart", {
                    //GA Parameters
                    currency: "GBP",
                    value: basketItem.item.price * basketItem.item.qty,
                    items: [
                        {
                            //GA parameters
                            item_id: basketItem.item.mpn || basketItem.item.manufacturerCode,
                            item_name: basketItem.item.description,
                            index: currentIndex > -1 ? currentIndex : newBasket.lines.length - 1,
                            price: basketItem.item.price,
                            quantity: basketItem.item.qty,

                            //custom parameters
                            manufacturer: basketItem.item.man,
                            manufacturer_id: basketItem.item.manid,
                            location: "Favorite Products",
                            is_liked: basketItem.item.liked
                        }
                    ],

                    //custom parameters 
                    basket_key: newBasket.basketKey,
                    organisation_id: newBasket.organisationId
                })
            }
        });
    };


    const bulkAddToBasket = async (basketItems, orderReference) => {
        const newBasket = { ...basket };

        for (const basketItem of basketItems) {
            const currentIndex = basket.lines
                .map((item) => item.manufacturerCode)
                .indexOf(basketItem.item.manufacturerCode);

            const computedQty = basketItem.item.qty || basketItem.item.quantityChildValue;

            if (currentIndex > -1) {
                if (computedQty > 0) {
                    newBasket.lines[currentIndex].qty = computedQty;
                } else if (computedQty == 0) {
                    newBasket.lines.splice(currentIndex, 1);
                }
            } else {
                const newItem = {
                    qty: computedQty,
                    manufacturerCode:
                        basketItem.item.mpn || basketItem.item.manufacturerCode,
                    description: basketItem.item.description,
                    imageUrl: basketItem.item.imageUrl,
                    iBasisManId: basketItem.item.manid || basketItem.item.iBasisManId,
                    iBasisAppliance: basketItem.item.app || basketItem.item.iBasisAppliance,
                    iBasisApplianceId:
                        basketItem.item.appid || basketItem.item.iBasisApplianceId,
                    iBasisGCN: basketItem.item.pgcn || basketItem.item.iBasisGCN,
                    IBasisApplianceGC:
                        basketItem.item.appgc || basketItem.item.IBasisApplianceGC,
                    basketLineTypeId: basketItem.basketLineTypeId || 1
                };

                newBasket.lines.push(newItem);
            }
        }
        newBasket.customerOrderNo = orderReference
        await updateBasket(newBasket);

        let total = 0;
        const itemsAdded = []
        for (const basketItem of basketItems) {
            total += basketItem.item.price * (basketItem.item.qty || basketItem.item.quantityChildValue);

            const currentIndex = newBasket.lines
                .map((item) => item.manufacturerCode)
                .indexOf(basketItem.item.manufacturerCode);

            itemsAdded.push({
                //GA parameters
                item_id: basketItem.item.mpn || basketItem.item.manufacturerCode,
                item_name: basketItem.item.description,
                index: currentIndex,
                price: basketItem.item.price,
                quantity: basketItem.item.qty || basketItem.item.quantityChildValue,

                //custom parameters
                manufacturer: basketItem.item.man,
                manufacturer_id: basketItem.item.manid,
                location: "Basket",
                is_liked: basketItem.item.liked
            })
        }

        ReactGA.event("add_to_cart", {
            //GA parameters
            currency: "GBP",
            value: total,
            items: itemsAdded,

            //custom parameters 
            basket_key: newBasket.basketKey,
            organisation_id: newBasket.organisationId
        })
    };


    const saveBasket = async () => {
        const saveMultipleForLater = []
        for (const item of basket.lines) {
            saveMultipleForLater.push({
                OrgID: -1,
                ImageUrl: item.imageUrl,
                Title: item.description,
                ManufacturerCode: item.manufacturerCode,
                Qty: item.qty,
                Description: item.description,
                OrderReference: orderReference
            })
        }
        const createSaveForLaterResult = await SaveForLaterService.createMultipleSaveForLater(saveMultipleForLater);

        if (createSaveForLaterResult) {
            toastr.success('Basket saved successfully.')
            updateBasket({
                ...basket,
                lines: []
            })
            getSavedParts();
        }
        else {
            toastr.error('Error occured while saving basket.')
        }
    }

    const loadBasket = async (selectedSavedPart) => {
        const itemsToAdd = selectedSavedPart.saveForLaters.map(item => {
            return {
                item: {
                    ...item,
                    basketLineTypeId: 1
                }
            }
        })
        await bulkAddToBasket(itemsToAdd, selectedSavedPart.orderReference)
        await SaveForLaterService.bulkDeleteSaveForLater(selectedSavedPart.orderReference)
        setSavedForLaterItemsLoading(true);
        getSavedParts();
    }

    const onDelete = async (row) => {
        const basket = await BasketService.getBasketForUser();

        const itemIndex = basket.lines.findIndex((item) => {
            return item.manufacturerCode == row.manufacturerCode || item.id == row.manufacturerCode
        })
        basket.lines.splice(itemIndex, 1);

        updateBasket(basket)
    }

    const onUpdateBasketQty = async (newValue, row) => {
        const basket = await BasketService.getBasketForUser();

        basket.lines = basket.lines.map((item) => {
            if (item.manufacturerCode == row.manufacturerCode || item.id == row.manufacturerCode)
                item.qty = newValue;

            return item;
        });

        updateBasket(basket);
    }



    return (
        <Wrapper text="MY BASKET" basket={basket} selected="Basket">
            <div>
                <Header
                    body="Basket"
                    backBody="Continue browsing"
                    onBack={continueBrowsing}
                />

            </div>



            {/* START NEW BASKET COMPONENT */}

            {!!basket.lines.length ? (

                <>

                    <div className="newBasketHeader">
                        <div className="newBasketHeaderRow">
                            <div className="headerItem"></div>
                            <div className="headerItem">MPN</div>
                            <div className="headerItem">Description</div>
                            <div className="headerItem"></div>
                            <div className="headerItem">Qty</div>
                            <div className="headerItem"></div>
                        </div>
                    </div>


                    <div className="newBasketGrid">
                        {basket.lines.map((line) => {
                            return (
                                <BasketRow
                                    key={line.id}
                                    imageUrl={line.imageUrl}
                                    code={line.manufacturerCode}
                                    qty={line.qty}
                                    description={line.description}
                                    featureFlagContext={featureFlagContext}
                                    saveForLaterOnClick={() => {
                                        onDelete(line);
                                        createSaveForLater(line);
                                    }}
                                    saveForLaterError={saveForLaterError}
                                    onDelete={() => {
                                        onDelete(line);
                                    }}
                                    increaseQty={() => {
                                        const newValue = line.qty + 1;
                                        onUpdateBasketQty(newValue, line);
                                    }}
                                    decreaseQty={() => {
                                        const newValue = line.qty == 1 ? 1 : line.qty - 1;
                                        onUpdateBasketQty(newValue, line);
                                    }}
                                />
                            )
                        })}
                    </div>


                    <div className="continueButtonContainer">
                        <button
                            type="button"
                            className={'button-large button-light-blue'}
                            onClick={goToAvailability}
                            disabled={disableBuyTradeCheckout}
                        >
                            {basket.lines.length ? "Continue" : "New Search"}
                            <img src={continueIcon} alt=">" className={classes.buttonSvg} />
                        </button>
                    </div>
                </>

            ) :

                <NoItems
                    heading="Your basket is empty!"
                    body="Looks like you haven’t added to your basket yet"
                />

            }


            {/* END NEW BASKET COMPONENT */}


            {featureFlagContext.checkFeatures("saveforlater-search") &&


                <SaveForLaterGrid
                    savedForLaterItems={savedForLaterItems}
                    loading={savedForLaterItemsLoading}
                    onRemoveSaveForLater={deleteSaveForLater}
                    saveForLaterError={saveForLaterError}
                    onMoveToBasket={async (item) => {
                        await addToBasket({
                            item: {
                                basketLineTypeId: 1,
                                ...item
                            }
                        });
                        deleteSaveForLater(item.id)
                    }}
                    orderReference={orderReference}
                    basket={basket}
                    loadBasket={loadBasket}
                    bulkDeleteSaveForLater={bulkDeleteSaveForLater}
                    savedPartsItems={savedPartsItems}
                    handleOrderReferenceChange={handleOrderReferenceChange}
                    saveBasket={saveBasket}
                />

            }


        </Wrapper>
    );
}
