import React from 'react';
import exclamationMarkIcon from '../../assets/img/exclamation-red.svg';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong.';

function ErrorMessage({
                          text = DEFAULT_ERROR_MESSAGE,
                          visible,
                          holdSpace = true,
                          paddingBottom = 0,
                          addClassName = '',
                          style = {}
                      }) {
    const holdSpaceStyles = {
        opacity: visible ? 1 : 0,
        paddingBottom,
        ...style,
    };

    const styles = {
        display: visible ? 'flex' : 'none',
        paddingBottom,
        ...style,
    };

    return (
        <div className={`error_message ${addClassName}`} style={holdSpace ? holdSpaceStyles : styles}>
            <img src={exclamationMarkIcon} alt="Error"/>
            <p>{text}</p>
        </div>
    );
}

export default ErrorMessage;