import React, { useEffect } from "react";
import {
  Switch,
  Route,
  Router, 
  Redirect
} from "react-router-dom";

import PrivateRoute from "./Routing/PrivateRoute";
import Homepage from "./homepage";
import Homepage2 from "./homepage-2";
import Account from "./Account";
import OrderHistory from "./OrderHistory";
import SparesFinder from "./sparesFinder";
import SparesFinder2 from "./sparesFinder-2";
import Products from "./products";
import Basket from "./Basket";
import Availability from "./Availability";
import Checkout from "./Checkout"
import RecentlyOrdered from './RecentlyOrdered'
import ScrollToTop from "./hoc/ScrollToTop";
import interceptors from "./utils/interceptors";
import { appHistory } from "./utils/history";
import ReactGA from 'react-ga4';
import configurationService from './Services/ConfigurationService'
import Confirmation from "./Confirmation";
import Notifications from "./notifications"
import NotificationDetails from "./NotificationDetails";
import PaymentComplete from "./PaymentComplete";
import MakePayment from "./MakePayment"
import PaymentFailed from "./PaymentFailed";
import PaymentAborted from "./PaymentAborted";
import ExportedOrder from "./ExportedOrder";
import FeatureFlagProvider from "./Context/FeatureFlagProvider";
import AllProducts from "./AllProducts";
import PartsIdentification from "./PartsIdentification";
import FavouriteProducts from "./FavouriteProducts";
import RequestedProducts from "./RequestedProducts";
import { datadogRum } from "@datadog/browser-rum";

interceptors.setupRequestInterceptors();
interceptors.setupResponseInterceptors(appHistory);

function App({ }) {
    useEffect(() => {
        configurationService.getConfigurationVariables().then(res => {
            localStorage.setItem('isDev', res?.isDev)
           
            if (res?.enableGoogleAnalytics) {
                ReactGA.initialize("GTM-KFFFHL33");
                ReactGA.send("pageview");
            }

            if (res?.enableHotJar) {
                datadogRum.init({
                    applicationId: '47401245-dbd4-4583-b1df-6d5fda4f06b6',
                    clientToken: 'pube4cbd47e05558faecc38b7393ea53eae',
                    site: 'datadoghq.eu',
                    service: 'buytrade-live',
                    env: 'prod',
                    // Specify a version number to identify the deployed version of your application in Datadog
                    // version: '1.0.0',
                    sessionSampleRate: 100,
                    sessionReplaySampleRate: 100,
                    trackUserInteractions: true,
                    trackResources: true,
                    trackLongTasks: true,
                    defaultPrivacyLevel: 'mask-user-input',
                });
            }

        })
    }, []);
    
  return (
      <FeatureFlagProvider>
        <div className="App">
            <Router history={appHistory}>
        <ScrollToTop />
        <Switch>
          <Route path="/login" exact render={(props) => {
            return <Redirect to="/" />
          }} />
          <Route path="/signup" exact render={(props) => {
            return <Redirect to="/" />
          }} />
          <PrivateRoute path="/Settings" exact component={Account} />
          <PrivateRoute path="/OrderHistory" exact component={OrderHistory} />
          <PrivateRoute path="/Basket" exact component={Basket}></PrivateRoute>
          <PrivateRoute path="/Basket/Availability" exact component={Availability}></PrivateRoute>
          <PrivateRoute path="/Basket/Checkout/:basketKey" exact component={Checkout}></PrivateRoute>
          <PrivateRoute path="/make-payment/:basketKey" exact component={MakePayment}></PrivateRoute>
          <PrivateRoute path="/Basket/Confirmation/:basketKey" exact component={Confirmation}></PrivateRoute>
          <PrivateRoute key="/Shop" path="/Shop" exact component={SparesFinder}></PrivateRoute>
          <PrivateRoute key="/Shop-2" path="/Shop-2" exact component={SparesFinder2}></PrivateRoute>
          <PrivateRoute key="/Spares" path="/Spares" exact component={PartsIdentification} />
          <PrivateRoute key="/products" path="/products" exact component={Products}></PrivateRoute>
          <PrivateRoute key="/allproducts" path="/allproducts" exact component={AllProducts}></PrivateRoute>
          {/*<PrivateRoute key="/Returns" path="/Returns" exact component={Returns}></PrivateRoute>*/}
          <PrivateRoute
            key='/recently-ordered'
            path='/recently-ordered'
            exact
            component={RecentlyOrdered}
          />
            <PrivateRoute key="/requested-products" path="/requested-products" component={RequestedProducts} />
          <PrivateRoute key="/favourite-products" path="/favourite-products" component={FavouriteProducts} />
          <PrivateRoute path="/Notifications" exact component={Notifications} />
          <PrivateRoute path="/Notifications/Details/:notificationId" exact component={NotificationDetails}></PrivateRoute>
          <PrivateRoute path="/payment-completed" exact component={PaymentComplete} />
          <PrivateRoute path="/payment-failed" exact component={PaymentFailed} />
          <PrivateRoute path="/payment-abort" exact component={PaymentAborted} />
          <PrivateRoute path="/" exact component={Homepage} />
          <PrivateRoute path="/homepage-2" exact component={Homepage2} />
          <PrivateRoute path="/exportedOrder" exact component={ExportedOrder} />
        </Switch>
      </Router>
        </div>
      </FeatureFlagProvider>
  );
}

export default App;
