import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    '@media (min-width: 993px)': {
        wrapper: {
            display: 'grid',
            gridTemplateColumns: 'auto min-content',
            margin: '60px 0 20px 0',
            backgroundColor: '#ffffff'
        },
        title: {
            fontWeight: 400,
            fontSize: 32,
            lineHeight: '32px',
            color: '#00226A',
            padding: '0 0 10px 0',
            margin: 0,
        },
        backWrapper: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
        },
        backIcon: {
            width: 18,
            height: 14,
            margin: '0 7px 0 0',
        },
        backBody: {
            fontWeight: 400,
            fontSize: 16,
            lineHeight: '19.5px',
            color: '#00226A',
            padding: 0,
            margin: 0,
        },
    },
    '@media (max-width: 993px)': {
        wrapper: {
            display: 'grid',
            gridTemplateColumns: 'auto min-content',
            margin: '77px 0 0 0',
            padding: '40px 28px 0px 28px',
            width: '100vw',
            backgroundColor: '#ffffff'
        },
        title: {
            fontWeight: 400,
            fontSize: 20,
            lineHeight: '24px',
            color: '#00226A',
            padding: '0 0 10px 0',
            margin: 0,
        },
        backWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        backIcon: {
            width: 18,
            height: 14,
            margin: '0 7px 0 0',
        },
        backBody: {
            fontWeight: 400,
            fontSize: 16,
            lineHeight: '19.5px',
            color: '#00226A',
            padding: 0,
            margin: 0,
        },
    },
    '@media (min-width: 640px) and (max-width: 993px)': {
        wrapper: {
            padding: '40px 28px 0 28px',
        },
        title: {
            fontSize: 20,
            lineHeight: '24px',
        },
    }
});

function Header({ body, onBack, backBody, children, rightSide }) {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <div>
                <p className={classes.title}>{body}</p>
                {onBack && !children && (
                    <div className={classes.backWrapper} onClick={onBack}>
                        <img src="/img/goBack.png" alt="back" className={classes.backIcon} />
                        <p className={classes.backBody}>{backBody}</p>
                    </div>
                )}
                {children}
            </div>
            <div>{rightSide}</div>
        </div>
    );
}

export default Header;