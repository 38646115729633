import React from "react";
import SortDropDown from "../Layout/SortDropDown";
import ProductCard from "../Shop/ProductCard/ProductCard";
import { isMobile, isTablet } from "../Media/Media";
import OrdersService from "../../Services/OrdersService";
import Loader from "../Layout/Loader";
import isPlural from "../../Helpers/common/isPlural";
import SearchResultsHeader from "../Shop/SearchResultsHeader";
import SearchResultsList from "../Shop/SearchResultsList";
import FinderInput from "../Shop/FinderInput";

const SELECT_VALUES = [
    {
        sortDir: "desc",
        text: "Purchase Date (Newest to Oldest)",
        sortProp: "CreatedOn",
    },
    {
        sortDir: "asc",
        text: "Purchase Date (Oldest to Newest)",
        sortProp: "CreatedOn",
    },
    { sortDir: "asc", text: "Product Name - A-Z", sortProp: "Description" },
    { sortDir: "desc", text: "Product Name - Z-A", sortProp: "Description" },
];

class RecentlyOrdered extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFilterActived: false,
            searched: true,
            data: [],
            totalRecords: 0,
            skip: 0,
            take: 10,
            likedProducts: this.props.likedProducts || [],
            sortDir: SELECT_VALUES[0].sortDir,
            sortProp: SELECT_VALUES[0].sortProp,
            searchedText: "",
            loading: true
        };
    }

    componentDidMount() {
        this.bindGrid();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.searchTerm !== this.props.searchTerm) this.bindGrid();
    }

    bindGrid = () => {
        const { searchTerm, basket } = this.props;
        const { sortProp, sortDir } = this.state;

        OrdersService.recentlyOrderedLines(searchTerm, sortDir, sortProp).then((response) => {
            const items = response ? response.filter(
                (line, index, self) =>
                    index ===
                    self.findIndex(
                        (currentLine) =>
                            currentLine.manufacturerCode === line.manufacturerCode
                    )
            ) : [];
            this.setState({
                loading: false,
                data:
                    items.map((product) => {
                        const liked =
                            this.state.likedProducts.findIndex(
                                (p) =>
                                    p.manufacturerCode ==
                                    (product.manufacturerCode ?? product.mpn)
                            ) !== -1;
                        return {
                            qty: basket?.lines.find((line) => line.manufacturerCode === product.manufacturerCode)?.qty ?? 0,
                            cost: product.cost,
                            mpn: product.manufacturerCode,
                            img_url: product.imageUrl,
                            description: product.description,
                            liked,
                            manid: product.iBasisManId,
                            app: product.iBasisAppliance,
                            appid: product.iBasisApplianceId,
                            pgcn: product.iBasisGCN,
                            appgc: product.iBasisApplianceGC,
                            man: product.manufacturer
                        };
                    }) || [],
            });
        });
    };

    changeSort = (option) => {
        let index = option.selected;
        this.setState(
            {
                sortDir: SELECT_VALUES[index].sortDir,
                sortProp: SELECT_VALUES[index].sortProp,
            },
            () => this.bindGrid()
        );
    };

    clearingSearch = () => {
        this.setState({ searchedText: "" });
    };

    filterSearched = (item) => {
        if (this.state.searchedText !== "")
            return item.description
                .toLowerCase()
                .includes(this.state.searchedText.toLowerCase());
        return true;
    };

    handleSearch = (value) => {
        this.setState({ searchedText: value})
    };

    render() {
        const { data, loading } = this.state;

        return (
            <>
                <div className={isTablet() ? 'results-wrapper-tablet' : ''}>
                    <SearchResultsList
                        results={data.filter(this.filterSearched)}
                        loading={loading}
                        toggleLike={this.toggleLike}
                        onAddToBasket={this.props.onAddToBasket}
                        changeSort={this.changeSort}
                        sortValues={SELECT_VALUES}
                        showNewSortFilter={false}
                        noItemsText='No orders have been placed'
                        sectionName='Recently Ordered'
                        redirectToSearch
                        isAccountCompleted={this.props.isAccountCompleted}
                    />
                </div>
            </>
        );
    }
}

export default RecentlyOrdered;