import React, { useEffect } from 'react';
import { Box, Grid, Table, TableBody, TableContainer, TableHead } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@mui/material/styles';
import AccountService from '../../Services/AccountService';

const useStyles = makeStyles(() => ({
	Table: {
		margin: '40px 0 10px 0px',
	},
	headerItem: {
		textTransform: 'uppercase',
		fontSize: '14px',
		lineHeight: '17px',
	},
	user: {
		padding: '20px 8px !important',
		background: '#F5F7F9',
		margin: '10px 0',
		fontSize: '16px',
		lineHeight: '19px',
		display: 'flex',
		alignItems: 'center',
	},
}));

const UsersTable = ({ data, onFetchData }) => {
	const theme = useTheme();
	const classes = useStyles();

	useEffect(() => {
		onFetchData();
	}, []);

	const lockUser = (item) => {
		AccountService.lockUser(item.id).then((response) => {
			if (response) onFetchData();
		});
	};

	const unLockUser = (item) => {
		AccountService.unLockUser(item.id).then((response) => {
			if (response) onFetchData();
		});
	};

	return (
		<div>
			{/*<div className={`expanded-mobile_account_info ${isTablet() ? 'expanded-tablet_account_info' : ''}`}>
				<div className='expanded-account-title'>ACCOUNT NUMBER:</div>
				<div className='expanded-account-info-row'>{item.accountNumber}</div>
			</div> */}

			<Box sx={{ display: { xs: 'none', md: 'block' } }}>
				<Box
					sx={{
						margin: '10px 0',
						display: 'grid',
						gridTemplateColumns: { xs: 'repeat(5, 1fr)', md: '25% 20% 15% 15% 12.5% 12.5%' },
						gridTemplateRows: { xs: 'repeat(3, 1fr)', md: 'repeat(1, 1fr)' },
						fontSize: '14px',
						lineHeight: '19px',
						fontFamily: 'proxima-nova',
						textTransform: 'uppercase',
						fontWeight: 300,
						padding: { md: '10px 22px' },
					}}>
					<Box sx={{ gridArea: { xs: '1 / 1 / 2 / 6', md: '1 / 1 / 2 / 2' } }}>Username / Email</Box>
					<Box sx={{ gridArea: { xs: '2 / 1 / 3 / 4', md: '1 / 2 / 2 / 3' } }}>Name</Box>
					<Box sx={{ gridArea: { xs: '2 / 4 / 3 / 6', md: '1 / 3 / 2 / 4' } }}>Telephone</Box>
					<Box sx={{ gridArea: { xs: '3 / 1 / 4 / 4', md: '1 / 4 / 2 / 5' } }}>Position</Box>
					<Box sx={{ gridArea: { xs: '3 / 4 / 4 / 5', md: '1 / 5 / 2 / 6' } }}>Status</Box>
					<Box sx={{ gridArea: { xs: '3 / 5 / 4 / 6', md: '1 / 6 / 2 / 7' } }}>Action</Box>
				</Box>
			</Box>

			<Box>
				{data.map((item) => (
					<Box
						sx={{
							background: '#F5F7F9',
							margin: '12px 0',
							display: 'grid',
							gridTemplateColumns: { xs: 'repeat(5, 1fr)', md: '25% 20% 15% 15% 12.5% 12.5%' },
							gridRowGap: '7px',
							gridTemplateRows: { xs: 'repeat(3, 1fr)', md: 'repeat(1, 1fr)' },
							fontSize: '16px',
							lineHeight: '19px',
							fontFamily: 'proxima-nova',
							padding: { xs: '20px', md: '24px 22px' },
						}}>
						<Box sx={{ gridArea: { xs: '1 / 1 / 2 / 6', md: '1 / 1 / 2 / 2' } }}>{item.userName}</Box>
						<Box sx={{ gridArea: { xs: '2 / 1 / 3 / 4', md: '1 / 2 / 2 / 3' } }}>
							{item.firstName} {item.surname}
						</Box>
						<Box sx={{ gridArea: { xs: '2 / 4 / 3 / 6', md: '1 / 3 / 2 / 4' } }}>{item.telephone}</Box>
						<Box sx={{ gridArea: { xs: '3 / 1 / 4 / 4', md: '1 / 4 / 2 / 5' } }}>{item.roleName}</Box>
						<Box sx={{ gridArea: { xs: '3 / 4 / 4 / 5', md: '1 / 5 / 2 / 6' } }}>
							{item.isActive ? 'Active' : 'Inactive'}
						</Box>
						<Box sx={{ gridArea: { xs: '3 / 5 / 4 / 6', md: '1 / 6 / 2 / 7' } }}>
							{item.isActive ? (
								<a className='account-link' onClick={() => lockUser(item)}>
									Disable
								</a>
							) : (
								<a className='account-link' onClick={() => unLockUser(item)}>
									Enable
								</a>
							)}
						</Box>
					</Box>
				))}
			</Box>
		</div>
	);
};

export default UsersTable;