import React from "react";
import clsx from "clsx";
import TextBox from "./TextBox";
import { isMobile, isTablet } from "../Media/Media";

class TextBoxWithLabel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      labelClassName,
      addCssClass,
      label,
      value,
      placeholder,
      textboxClassName,
      required,
      maxLength,
      newStyle,
      hasErr = false,
      disabled = false
    } = this.props;
    const type = this.props.type || "text";
    const name = this.props.name || "";
    return (
      <div className={clsx("input-field", addCssClass)}>
        <label className={clsx("input-field__label", labelClassName, newStyle ? "thin-label" : "")}
          style={isMobile()
            ? isTablet() ? {} : { marginBottom: 0 }
            : {}}>
          {label}
          {required && <span className="required">*</span>}
        </label>
        <TextBox
          placeholder={placeholder}
          value={value}
          onChange={(val) => this.onChange(val, name)}
          onBlur={(val) => this.onBlur(val, name)}
          className={clsx("", textboxClassName)}
          type={type}
          name={name}
          maxLength={maxLength}
          thinPlaceholder={this.props.thinPlaceholder}
          disabled={disabled}
        />
        {hasErr ? isTablet() ? null : <img src="/img/warning.svg" alt="error icon" style={{ position: "absolute", right: 11, bottom: 11 }} /> : null}
      </div>
    );
  }

  onChange = (value, name) => {
    const { onChange } = this.props;
    if (onChange) onChange(value, name);
  };

  onBlur = (value, name) => {
    const { onBlur } = this.props;
    if (onBlur) onBlur(value, name);
  };
}

export default TextBoxWithLabel;
