import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function searchByApplianceIcon (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 37 48">
            <path d="M33.2527 0H3.74729C1.67355 0 0 1.63659 0 3.60718V43.5534C0 45.5573 1.67355 47.1605 3.74729 47.1605H33.2527C35.3264 47.1605 37 45.5239 37 43.5534V3.60718C37 1.60319 35.3264 0 33.2527 0ZM11.8968 45.8245V34.1012C11.8968 32.9322 12.9154 31.997 14.1888 31.997H22.6293C23.9027 31.997 24.9213 32.9322 24.9213 34.1012V45.8245H11.8968ZM35.5447 43.5534C35.5447 44.8225 34.526 45.8245 33.2527 45.8245H26.413V34.1012C26.413 32.1974 24.7394 30.661 22.6657 30.661H14.2252C12.1514 30.661 10.4779 32.1974 10.4779 34.1012V45.8245H3.78366C2.51031 45.8245 1.49163 44.7891 1.49163 43.5534V3.60718C1.49163 2.33798 2.51031 1.33599 3.78366 1.33599H33.2891C34.5624 1.33599 35.5811 2.37138 35.5811 3.60718V43.5534H35.5447Z" fill="url(#paint1_linear_8166_26354)" />
            <path d="M19.1007 36.0716H17.9001C17.4999 36.0716 17.1725 36.3722 17.1725 36.7396C17.1725 37.107 17.4999 37.4076 17.9001 37.4076H19.1007C19.5009 37.4076 19.8284 37.107 19.8284 36.7396C19.8284 36.3722 19.5009 36.0716 19.1007 36.0716Z" fill="white" />
        </SvgIcon>
    );
}