import http from '../utils/http';

const API_KEY = "GWzuIJLaph90wGOzcqzn07Hd681gTVfo4c5lfpUM";

const searchAddresses = (searchQuery) => {
    return http.get(`https://api.addressian.co.uk/v2/autocomplete/${searchQuery}`, {
        headers:{
            'x-api-key': API_KEY
        }
    })
}

const getPostCodeDetails = (postcode) => {
    return http.get(`https://api.addressian.co.uk/v1/postcode/${postcode}`, {
        headers:{
            'x-api-key': API_KEY
        }
    })
}

export default {
    searchAddresses,
    getPostCodeDetails
}
