import React from "react";
import clsx from "clsx";
import UnNull from "../../Helpers/string/UnNull";

class TextBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: UnNull(this.props.value) };
  }

  render() {
    return (
      <input
        type={this.props.type ? this.props.type : "text"}
        placeholder={this.props.placeholder}
        className={clsx("input-field__input", 
          this.props.className, 
          this.props.thinPlaceholder ? "thin-placeholder" : "", 
          this.props.disabled ? "disable-input" : ""  
        )}
        value={this.props.value}
        onChange={this.onChange}
        onBlur={this.onBlur}
        name={this.props.name ? this.props.name : ""}
        maxLength={this.props.maxLength}
        disabled={this.props.disabled}
      />
    );
  }

  onChange = (e) => {
    const { onChange } = this.props;
    if (onChange) onChange(e.target.value);
  };

  onBlur = (e) => {
    const { onBlur } = this.props;
    if (onBlur) onBlur(e.target.value);
  };
}

export default TextBox;
