import React from "react";

/**
 * @desc Creates inner navigational buttons and return the state of clicked button
 * @todo This will directly chage the page for MPA approach  (waiting sparesFinder page refactor)
 * */

const image_wrapper = {
  height: 50,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

class NavigationComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      highlightActive: this.props.hightligntActiveButton,
      dataViews: this.props.dataViews ? this.props.dataViews : [],
      isBindPathRedirect: this.props.isBindPathRedirect
        ? this.props.isBindPathRedirect
        : false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.hightligntActiveButton !== this.state.highlightActive) {
      this.setState({ highlightActive: nextProps.hightligntActiveButton });
    }
  }

  hangleOnClick = (keyName, link) => {

    if (link) {
      return (window.location = link);
    }

    if (this.state.isBindPathRedirect) {
      window.location = `/SparesFinder/${keyName}?selectedBranchId=${this.props.selectedBranchId ?? ""
        }`;
    }
    this.props.handlerOnClick(keyName);
    this.setState({ highlightActive: keyName });
  };

  render() {
    var elements = this.state.dataViews.map(
      function (item, i) {
        return (
          <button
            key={i}
            onClick={this.hangleOnClick.bind(this, item.viewKey, item.link)}
            name={item.viewKey}
            type="button"
            className={
              this.state.highlightActive === item.viewKey
                ? "search_button search_button_active"
                : "search_button"
            }
          >
            {item.svgActive
              ? this.state.highlightActive === item.viewKey
                ? item.svgActive.svg
                : item.svgNotActive.svg
              : ""}
            <div style={image_wrapper}>
              <img
                src={`/img/searchItems/${this.state.highlightActive === item.viewKey
                  ? item.viewKey + "Selected"
                  : item.viewKey
                  }.png`}
                alt={item.btnText}
              />
            </div>
            <p
              style={
                this.state.highlightActive === item.viewKey
                  ? { color: "#fff" }
                  : { color: "#00226A" }
              }
            >
              {item.btnText}
            </p>
          </button>
        );
      }.bind(this)
    );
    return (
      <>
        <div className="template__grid">
          <div className="spares-finder-navigation tab-finder-navigation">
            {elements}
          </div>
        </div>
      </>
    );
  }
}

export default NavigationComp;
