import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import BranchService from "../../Services/BranchService";
const $ = window.jQuery;
const useStyles = makeStyles(() => ({
  subHeader: {
    fontSize: "12px",
    fontWeight: 700,
    color: "#3EC4F4",
    marginBottom: "15px",
  },
  address: {
    width: "max-content",
    maxWidth: "300px",
    fontWeight: 700,
    fontSize: "14px",
  },
  subText: {
    color: "#454545",
    fontWeight: 400,
    fontSize: "14px",
    width: "100px",
  },
  main: {
    display: "flex",
    justifyContent: "space-between",
  },
  left: {
    paddingRight: "30px",
  },
  subTextWrapper: {
    display: "flex",
  },
  root: {
    margin: "0 -40px",
  },
}));

const ModalBranchDetails = ({ branchId, supplierName, formatOpeningTime }) => {
  const classes = useStyles();
  const [branch, setBranch] = useState(null);

  useEffect(() => {
    getBranch();
  }, []);

  const getBranch = () => {
    BranchService.getBranch(branchId).then((branch) =>  {
      setBranch(branch);
    });
  };

  return branch ? (
    <div className={classes.root}>
      <a href="#close-modal" rel="modal:close" className="modal-close">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.46 12L19 17.54V19H17.54L12 13.46L6.46 19H5V17.54L10.54 12L5 6.46V5H6.46L12 10.54L17.54 5H19V6.46L13.46 12Z"
            fill="#AEAEAE"
          />
        </svg>
      </a>
      <h1>{branch.name}</h1>
      <br />
      <div className={classes.main}>
        <div className={classes.left}>
          <div>
            <div className={classes.subHeader}>Address</div>
            <div className={classes.address}>
              {branch.address1}, {branch.address2}
            </div>
            <div className={classes.address}>
              {branch.address3}, {branch.postCode}
            </div>
          </div>
          <br />
          <br />
          <div>
            <div className={classes.subHeader}>Contact information</div>
            <div>
              <div className={classes.subTextWrapper}>
                <div className={classes.subText}>Telephone:</div>
                <div>{branch.telephone ?? "-"}</div>
              </div>
              <div className={classes.subTextWrapper}>
                <div className={classes.subText}>Email:</div>
                <div>{branch.email ?? "-"}</div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div>
            <div className={classes.subHeader}>Opening Times</div>
            <div>
              <div className={classes.subTextWrapper}>
                <div className={classes.subText}>Monday-Friday</div>
                <div>
                  {formatOpeningTime(branch.openMonday, branch.closeMonday)}
                </div>
              </div>
              <div className={classes.subTextWrapper}>
                <div className={classes.subText}>Saturday</div>
                <div>
                  {formatOpeningTime(branch.openSaturday, branch.closeSaturday)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <iframe
            src={`https://maps.google.com/maps?q=${supplierName}, ${branch.postCode},&t=&z=16&ie=UTF8&iwloc=&output=embed`}
            width="340"
            height="380"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ModalBranchDetails;
