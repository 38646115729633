import React, {useContext, useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/img/header-logo.svg";
import MobileSearchPopup from "./MobileSearchPopup/MobileSearchPopup";
import FeatureFlagContext from "../../Context/FeatureFlagContext";

const $ = window.jQuery;

const useStyles = makeStyles({
    headerMobile: {
        background: "#F5F7F9",
    },
    textTitle: {
        color: "#3EC4F4",
        fontWeight: 600,
    },
    countNums: {
        position: 'absolute',
        color: '#ffffff',
        fontWeight: 600,
        fontSize: 12,
        background: 'linear-gradient(90deg, #00C9FF 0%, #25D6E4 64.12%, #92FE93 126.92%)',
        padding: '1px',
        minWidth: 21,
        minHeight: 21,
        textAlign: 'center',
        borderRadius: "50%"
    },
    notificationTotalCount: {
        top: -4,
        left: 24,
    },
    basketTotalCount: {
        bottom: '21px',
        right: '16%',
        display: 'flex',
        justifyContent: 'center'
    },

    basketTotalCountNew: {
        right: '6%',
        bottom: '18px',
    },
    countNumsNew: {
        fontSize: 10,
        minWidth: 17,
        minHeight: 17,
    },
    notificationTotalCountNew: {
        top: -8,
        left: 18,
    },
});

const HeaderBar = (props) => {
    const { text, onMenuClick, count } = props;
    const classes = useStyles();
    const history = useHistory();
    const featureFlagContext = useContext(FeatureFlagContext);
    const showNewDesign = featureFlagContext.checkFeatures("all-products");
    const [totalNotificationsCount, setTotalNotificationsCount] = useState(0);
    const [showMobileSearchPopup, setShowMobileSearchPopup] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    const goToCheckout = () => {
        history.push("/Basket/Availability");
    };

    const goToHomepage = () => (history.push(featureFlagContext.checkFeatures("all-products") ? '/homepage-2' : '/'));
    const goToBasket = () => (history.push('/Basket'));

    const handleClickMobileSearchPopup = () => {
        setShowMobileSearchPopup(!showMobileSearchPopup);
    }

    const getData = () => {
        $.ajax({
            url: "/NotificationTriggerLog/UnreadNotifications",
            data: {},
            success: (d) => {
                setTotalNotificationsCount(d.count);
            },
            error: (e) => {
                window.location = "/account/logout";
            },
        });
    };

    return (
        <>
            <header className={clsx("header", "main", classes.headerMobile)}>
                <div className="container page-container">
                    <div className={['mobile_header', showNewDesign ? "mobile_header_new" : ""].join(' ')}>
                        <button
                            type="button"
                            onClick={onMenuClick}
                            style={{ display: "flex", width: 50 }}
                        >
                            <svg
                                width="25"
                                height="18"
                                viewBox="0 0 25 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect width="25" height="2" fill="#3EC4F4" />
                                <rect y="8" width="19" height="2" fill="#3EC4F4" />
                                <rect y="16" width="25" height="2" fill="#3EC4F4" />
                            </svg>
                        </button>


                        <div className="header-logo-container">
                            <img
                                src={logo}
                                alt=""
                                className="mobile_logo_bt"
                                onClick={goToHomepage}
                            />
                        </div>
                        <div>
                            <div className="header__buttons">

                                {showNewDesign ?
                                    <div
                                        className="header__menu-button"
                                        style={{ marginRight: showNewDesign ? 30 : 30 }}
                                        onClick={handleClickMobileSearchPopup}
                                    >
                                        <div style={{ margintop: '3px' }}>
                                            <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20.9629 19.499L20.9629 19.499L16.8212 15.1742C18.1155 13.6511 18.8944 11.6967 18.8944 9.56548C18.8944 4.68953 14.8261 0.75 9.82222 0.75C4.81838 0.75 0.75 4.68953 0.75 9.56548C0.75 14.4414 4.81838 18.381 9.82222 18.381C11.87 18.381 13.7604 17.7211 15.2795 16.608L19.4103 20.9209C19.8101 21.3384 20.4789 21.3613 20.9085 20.9746L20.909 20.9741C21.341 20.5832 21.3665 19.9205 20.9629 19.499ZM2.8774 9.56548C2.8774 5.85267 5.97986 2.83004 9.82222 2.83004C13.6646 2.83004 16.767 5.85267 16.767 9.56548C16.767 13.2783 13.6646 16.3009 9.82222 16.3009C5.97986 16.3009 2.8774 13.2783 2.8774 9.56548Z" fill="#232F5E" stroke="#232F5E" stroke-width="0.5" />
                                            </svg>
                                        </div>
                                    </div >
                                    : null}

                                {showNewDesign && !props.disableBuyTradeCheckout ?
                                    <div
                                        className="header__menu-button"
                                        style={{ marginRight: showNewDesign ? 30 : 30 }}
                                    >
                                        <div>
                                            <Link to="/settings">
                                                <svg width="22" height="22" viewBox="0 0 28 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.4718 17.8074C23.1606 15.9848 24.1973 13.5435 24.1973 10.8681C24.1973 5.23309 19.6157 0.634766 13.9639 0.634766C8.31217 0.634766 3.71384 5.23309 3.71384 10.8681C3.71384 13.5435 4.75055 15.9681 6.43939 17.8074C2.5768 20.3323 0.168945 24.5962 0.168945 29.3283C0.168945 30.248 0.921399 31.0005 1.84107 31.0005C2.76073 31.0005 3.51319 30.248 3.51319 29.3283C3.51319 25.332 5.78727 21.7536 9.33216 19.9979C10.72 20.7002 12.2918 21.1182 13.9639 21.1182C15.6361 21.1182 17.1911 20.7169 18.579 19.9979C22.1406 21.7536 24.4147 25.3153 24.4147 29.3283C24.4147 30.248 25.1671 31.0005 26.0868 31.0005C27.0065 31.0005 27.7589 30.248 27.7589 29.3283C27.7589 24.5795 25.3511 20.2989 21.4718 17.8074ZM7.05808 10.8681C7.05808 7.07243 10.1515 3.97901 13.9472 3.97901C17.7429 3.97901 20.8363 7.07243 20.8363 10.8681C20.8363 14.6638 17.7429 17.7573 13.9472 17.7573C10.1515 17.7573 7.05808 14.6638 7.05808 10.8681Z" fill="#232F5E" />
                                                </svg>
                                            </Link>
                                        </div>
                                    </div >
                                    : null}



                                <div
                                    className="header__menu-button"
                                    style={{ marginRight: 30 }}
                                >
                                    <Link to="/Notifications">
                                        <svg width="24" height="25" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.8427 19.7204C8.16238 19.7204 7.59935 19.1691 7.59935 18.4771H5.25342C5.25342 20.4594 6.86038 22.0663 8.8427 22.0663C10.825 22.0663 12.432 20.4594 12.432 18.4771H10.086C10.086 19.1574 9.53475 19.7204 8.8427 19.7204Z" fill="#232F5E" />
                                            <path d="M15.8219 12.612L15.7632 12.5416C15.5755 12.3305 15.4113 12.1663 15.2236 12.0021L14.8835 11.6619V8.67084C14.8835 5.77362 12.9246 3.34558 10.2972 2.63007V2.41893C10.2972 1.56267 9.60513 0.858887 8.73714 0.858887C7.86914 0.858887 7.17709 1.55094 7.17709 2.41893V2.63007C4.54965 3.33385 2.59079 5.76189 2.59079 8.67084V11.6619L2.25064 12.0021C2.06296 12.1663 1.89874 12.3305 1.65242 12.612C0.854804 13.6677 0.772699 14.1721 0.784429 15.1456C0.784429 16.5532 1.81664 17.7027 3.08344 17.7027H14.3791C15.6459 17.7027 16.6781 16.5532 16.6781 15.087C16.6781 14.1838 16.596 13.6677 15.7984 12.612H15.8219ZM14.297 15.3568H3.18901C3.18901 15.3568 3.14209 15.2277 3.14209 15.0752C3.14209 14.5709 3.14209 14.5591 3.48225 14.0899C3.59955 13.9609 3.68166 13.8671 3.79895 13.7732L4.93673 12.612V8.65911C4.93673 6.50086 6.63753 4.74141 8.73714 4.74141C10.8367 4.74141 12.5376 6.50086 12.5376 8.65911V12.612L13.5815 13.6794L13.6753 13.7732C13.7809 13.8554 13.863 13.9492 13.9686 14.0548C14.3439 14.5591 14.3439 14.5826 14.3439 15.1339C14.3439 15.2395 14.3205 15.3098 14.297 15.345V15.3568Z" fill="#232F5E" />
                                        </svg>
                                    </Link>
                                    <span className={[classes.countNums, classes.countNumsNew, classes.notificationTotalCount, classes.notificationTotalCountNew].join(' ')}>{props.unreadNotificationCount || totalNotificationsCount}</span>

                                </div >
                                <div
                                    className="header__menu-button"
                                    style={{ marginRight: 0, marginTop: 2 }}
                                >
                                    <a onClick={goToBasket}>
                                        {showNewDesign
                                            ? <span className={[classes.countNums, classes.countNumsNew, classes.basketTotalCount, classes.basketTotalCountNew].join(' ')}>
                                                {count ? count : 0}
                                            </span>
                                            : <span className={[classes.countNums, classes.countNumsNew, classes.basketTotalCount, classes.basketTotalCountNew].join(' ')}>
                                                {count ? count : 0}
                                            </span>
                                        }
                                        <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M25.6627 5.36308C25.4516 5.03465 25.0762 4.82351 24.6774 4.82351H21.8271C21.182 4.82351 20.6541 5.35135 20.6541 5.99648C20.6541 6.64161 21.182 7.16945 21.8271 7.16945H22.8711L19.7392 14.1955H9.63999L5.65191 1.67996C5.49942 1.18732 5.04196 0.858887 4.53759 0.858887H1.39404C0.748904 0.858887 0.221069 1.38672 0.221069 2.03185C0.221069 2.67698 0.748904 3.20482 1.39404 3.20482H3.68133L7.6694 15.7204C7.82189 16.213 8.27935 16.5414 8.78373 16.5414H20.5017C20.9591 16.5414 21.3814 16.2717 21.5691 15.8494L25.7448 6.4774C25.909 6.11378 25.8738 5.69151 25.6627 5.36308Z" fill="#232F5E" />
                                            <path d="M9.08865 21.7256C10.1187 21.7256 10.9537 20.8906 10.9537 19.8606C10.9537 18.8306 10.1187 17.9956 9.08865 17.9956C8.05863 17.9956 7.22363 18.8306 7.22363 19.8606C7.22363 20.8906 8.05863 21.7256 9.08865 21.7256Z" fill="#232F5E" />
                                            <path d="M20.2436 21.7256C21.2736 21.7256 22.1086 20.8906 22.1086 19.8606C22.1086 18.8306 21.2736 17.9956 20.2436 17.9956C19.2135 17.9956 18.3785 18.8306 18.3785 19.8606C18.3785 20.8906 19.2135 21.7256 20.2436 21.7256Z" fill="#232F5E" />
                                        </svg>
                                    </a>
                                </div>
                            </div >
                        </div >
                        {/*<ButtonBasket count={count} />*/}
                    </div >
                </div >

            </header >


                <MobileSearchPopup
                onClose={() => { setShowMobileSearchPopup(false) }}
                show={showMobileSearchPopup}
                />

        </>
    );

};

export default HeaderBar;
