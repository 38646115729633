import React, { useContext, useState } from "react";
import Grid from '@mui/material/Grid';
import ProductCard from "./ProductCard/ProductCard"
import Skeleton from '@mui/material/Skeleton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useHistory } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import FeatureFlagContext from "../../../Context/FeatureFlagContext";
import TextBoxWithLabel from "../../Form/TextBoxWithLabel";
import { isMobile } from "../../Media/Media";


function SaveForLaterGrid({
    savedForLaterItems,
    basket,
    loading,
    onRemoveSaveForLater,
    onMoveToBasket,
    orderReference,
    handleOrderReferenceChange,
    saveBasket,
    savedPartsItems,
    loadBasket,
    bulkDeleteSaveForLater
}) {

    const featureFlagContext = useContext(FeatureFlagContext);

    const history = useHistory();
    const [tabValue, setTabValue] = useState('one');
    const [selectedPartsItem, setSelectedPartsItem] = useState(null);
    const [partsItemValue, setPartsItemValue] = useState("");
    const [filteredSavedPartsItems, setFilteredSavedPartsItems] = useState([]);

    const filteredItems = savedPartsItems.filter(item => item.orderReference.toLocaleLowerCase().includes(partsItemValue.toLocaleLowerCase()));

    const itemsToDisplay = partsItemValue ? filteredItems : savedPartsItems;


    const handleTabChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };


    const formatDate = (dateStamp) => {

        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const date = new Date(dateStamp);
        const day = date.getDate();
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        const hour = date.getHours();
        const minute = date.getMinutes();

        return (`${day} ${month} ${year} at ${hour}:${minute}`)
    }


    return (

        featureFlagContext.checkFeatures("saveforlater-search") &&

        <>

            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="secondary tabs example"
                className="saveForLaterTabs px-4 px-md-0"
            >
                <Tab value="one" label="Items saved for later" />
                <Tab value="two" label="Saved parts orders" />
            </Tabs>

            {tabValue === 'one' ?

                <Grid container className="saveForLaterGrid">
                    {loading ?
                        <>
                            <Grid item xs={12} md={3} sx={{ padding: '20px 10px' }}>
                                <Skeleton variant="rounded" width="100%"> <ProductCard /> </Skeleton>
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ padding: '20px 10px' }}>
                                <Skeleton variant="rounded" width="100%"> <ProductCard /> </Skeleton>
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ padding: '20px 10px' }}>
                                <Skeleton variant="rounded" width="100%"> <ProductCard /> </Skeleton>
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ padding: '20px 10px' }}>
                                <Skeleton variant="rounded" width="100%"> <ProductCard /> </Skeleton>
                            </Grid>
                        </>

                        :
                        savedForLaterItems.map((item) => {
                            return (
                                <Grid item xs={12} md={3} key={item.index}>
                                    <ProductCard
                                        imgSrc={item.imageUrl}
                                        description={item.description}
                                        code={item.manufacturerCode}
                                        removeFromSaved={() => {
                                            onRemoveSaveForLater(item.id);
                                        }}
                                        moveToBasket={() => {
                                            onMoveToBasket(item);
                                        }} />

                                </Grid>
                            );
                        })}

                </Grid>

                : tabValue === 'two' ?
                    <div className="savedPartsOrdersGrid">


                        <div className="row pb-5 align-items-end px-4 px-md-0">
                            <div className="col-md-3 mb-4 mb-md-4">
                                <TextBoxWithLabel
                                    labelClassName={isMobile() ? 'customer-label account-label' : 'customer-label'}
                                    label="SAVE THIS BASKET:"
                                    placeholder="Enter order reference..."
                                    value={orderReference}
                                    onChange={handleOrderReferenceChange}
                                    required
                                />
                            </div>
                            <div className="col-md-3 mb-5 mb-md-4">
                                <button
                                    type="button"
                                    style={{ margin: 0, height: '50px' }}
                                    className="btn btn_style-outlined btn_style-outlined-account w-100"
                                    disabled={!basket.lines.length || !orderReference}
                                    onClick={saveBasket}
                                >
                                    Save Basket
                                </button>
                            </div>
                            <div className="col-md-4 mb-4 mb-md-4 ml-auto">
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={savedPartsItems}
                                    getOptionLabel={(option) => option.orderReference}
                                    value={selectedPartsItem}
                                    onChange={(event, value) => {
                                        setSelectedPartsItem(value);
                                    }}
                                    inputValue={partsItemValue}
                                    onInputChange={(event, newValue) => {
                                        setPartsItemValue(newValue);
                                    }}
                                    renderOption={(props, option) => (
                                        <div style={{
                                            fontSize: '16px',
                                            color: '#454545',
                                            fontFamily: "proxima-nova, sans-serif",
                                        }} {...props}>
                                            {option.orderReference}
                                        </div>
                                    )}
                                    renderInput={(params) => (
                                        <div ref={params.InputProps.ref} className="savedListsAutocomplete">
                                            <input
                                                placeholder="Search saved lists"
                                                type="text" {...params.inputProps}
                                            />
                                        </div>)}
                                />
                            </div>
                        </div>

                        {itemsToDisplay &&

                            <div className="px-5 px-md-0">
                                <div className="topBorder"></div>
                                <div className="saveForLaterGridRow saveForLaterGridHeader">
                                    <div>User</div>
                                    <div>Date / Time</div>
                                    <div>Order Ref</div>
                                    <div>Items</div>
                                    <div></div>
                                    <div></div>
                                </div>
                                {itemsToDisplay.map((row) =>
                                    <div className="saveForLaterGridRow" key={row.orderReference}>
                                        <div className="email">{row.username}</div>
                                        <div className="dateTime">{formatDate(row.dateTime)}</div>
                                        <div className="orderRef">{row.orderReference}</div>
                                        <div className="items">{row.items} items</div>
                                        <div className="addToBasket">
                                            <span
                                                onClick={() => { loadBasket(row) }}
                                                className="rowLink">Add to Basket
                                            </span>
                                        </div>
                                        <div className="delete">
                                            <span
                                                onClick={() => { bulkDeleteSaveForLater(row.orderReference) }}
                                                className="rowLink">Delete
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>

                        }

                    </div> : null}

        </>






    )
};

export default SaveForLaterGrid;
